import { Box, CircularProgress } from '@project-minerva/design-system'
import { DataTestId } from '@project-minerva/typings'

export function LoadingSpinner({ 'data-testid': dataTestId }: DataTestId) {
  return (
    <Box height={'100%'} p={2} sx={{ display: 'flex' }} data-testid={dataTestId}>
      <CircularProgress sx={{ margin: 'auto' }} />
    </Box>
  )
}

import { Stack } from '@mui/material'
import { StudentLogin } from '@project-minerva/auth-student'
import { currentDomainUrl } from '@project-minerva/core'
import { Box, PageLayout } from '@project-minerva/design-system'
import { Copyright, FooterLink } from '@project-minerva/shared-ui'
import { FormattedMessage } from 'react-intl'
import './login.css'

export const LoginPage = () => (
  <Box className="login-branding" sx={{ height: '100%', width: '100%' }}>
    <PageLayout centred>
      <StudentLogin />
    </PageLayout>
    <Box position="absolute" bottom={0} width="100%" display="flex" justifyContent="center">
      <Stack spacing={4} direction="row" mb={3}>
        <Copyright />
        <FooterLink to={currentDomainUrl('/guardian')}>
          <FormattedMessage id="guardian-login-header" />
        </FooterLink>
        <FooterLink to={currentDomainUrl('/senior-school')}>
          <FormattedMessage id="senior-school-login-header" />
        </FooterLink>
        <FooterLink to={currentDomainUrl('/invigilation-centre')}>
          <FormattedMessage id="invigilation-centre-login-header" />
        </FooterLink>
        <FooterLink to={currentDomainUrl('/debug')} target="_blank">
          <FormattedMessage id="debug-page" />
        </FooterLink>
      </Stack>
    </Box>
  </Box>
)

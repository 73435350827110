import { PageLayout } from '@project-minerva/design-system'
import { AssessmentAuthProvider } from '@project-minerva/auth-student'
import AppRoutes from './app.routes'

const App = () => {
  return (
    <AssessmentAuthProvider loginUrl={'/login'} navigateToOnLoggingIn={'/exam'}>
      <PageLayout>
        <AppRoutes />
      </PageLayout>
    </AssessmentAuthProvider>
  )
}

export default App

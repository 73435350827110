import { AmplifyUser } from '@aws-amplify/ui'
import { Authenticator, AuthenticatorProps, useAuthenticator } from '@aws-amplify/ui-react'
import { isDev } from '@project-minerva/base-utils'
import { logrocketIdentify, MixpanelEventTypes, MixpanelKeys, useAppConfiguration } from '@project-minerva/core'
import { CognitoAppType } from '@project-minerva/typings'
import { Amplify, Auth, I18n, Logger } from 'aws-amplify'
import { ReactNode, useEffect, useRef } from 'react'

import '@aws-amplify/ui-react/styles.css'
import './cognito-authenticator.css'
import mixpanel from 'mixpanel-browser'
import { useTableFilters } from '@project-minerva/context'

//5 mins
const REFRESH_TOKEN_POLL = 5 * 1000 * 60

export const configureAmplify = (authConfig: { region: string; userPoolId: string; userPoolWebClientId: string }) => {
  Amplify.configure({
    Auth: {
      ...authConfig,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: ['REQUIRES_LOWERCASE', 'REQUIRES_NUMBERS', 'REQUIRES_SYMBOLS', 'REQUIRES_UPPERCASE'],
    },
  })
}

if (isDev()) {
  Logger.LOG_LEVEL = 'DEBUG'
}

// 8 chars, 1 uppercase, 1 lowercase, 1 number, 1 special char
export const REGEX_COGNITO_PASSWORD_POLICY =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/

const APP_ROLE_MAPPER: Record<string, string[]> = {
  cms: ['contentCreator'],
  admin: ['schoolAdmin'],
  guardian: ['guardian'],
  'admin-centre': ['invigilationCentre'],
  'admin-schools': ['seniorSchool'],
  'admin-manager': ['isebAdmin'],
}

const usernameError = new Error('Incorrect email address or password.')

I18n.putVocabulariesForLanguage('en', {
  'Incorrect username or password.': usernameError.message,
  // B2B-120: override the translation since the codeDeliveryDetails is not available
  'We Texted You': 'We Emailed You',
})

const formFields = {
  resetPassword: {
    username: {
      placeholder: 'Email Address',
    },
  },
  signIn: {
    username: {
      placeholder: 'Email Address',
    },
  },
}

// custom handle sign in, due to shared cognito user pools across apps
// we need to check which group the user belongs,if applicable, in order to block cross apps sign in
const handleSignIn =
  (app: CognitoAppType) =>
  ({ username, password }: { username: string; password: string }): Promise<AmplifyUser> => {
    const trimmedUsername = username.trim()

    if (trimmedUsername.includes(' ')) {
      throw usernameError
    }

    return Auth.signIn(trimmedUsername, password).then((user: AmplifyUser & { challengeName: string }) => {
      // skip role verification when user is using temporary password after setting up the account
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') return user

      checkAppPermission(user, app)

      mixpanel.track(MixpanelEventTypes.Login, {
        [MixpanelKeys.App]: app,
      })

      return user
    })
  }

export const CognitoAuthorisedApp = (props: { app: CognitoAppType } & AuthenticatorProps) => {
  const { app, children, ...authenticatorProps } = props
  const customServices = { handleSignIn: handleSignIn(app), ...authenticatorProps.services }
  return (
    <Authenticator.Provider>
      <Authenticator
        variation="modal"
        {...authenticatorProps}
        formFields={{ ...formFields, ...authenticatorProps.formFields }}
        services={customServices}
        className={
          app === 'admin-schools' ? 'login-branding-base login-branding-senior-schools' : 'login-branding-base'
        }
      >
        <AuthenticatedApp app={app}>{children}</AuthenticatedApp>
      </Authenticator>
    </Authenticator.Provider>
  )
}

const AuthenticatedApp = (props: { app: CognitoAppType; children: ReactNode | ReactNode[] }) => {
  const { app, children } = props

  useLogrocket(app)
  useAutoRefreshToken()

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export const useAuthContext = () => {
  const { user } = useAuthenticator((context) => [context.user])
  const accessToken = useRef<string>(`Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}`)

  useEffect(() => {
    accessToken.current = `Bearer ${user.getSignInUserSession()?.getIdToken().getJwtToken()}`
  }, [user])

  return { accessToken }
}

export const useCognitoUser = () => {
  const { user } = useAuthenticator((context) => [context.user])
  return user
}

export const useCognitoSignOut = () => {
  const { signOut } = useAuthenticator((context) => [context.signOut])
  return signOut
}

const useAutoRefreshToken = () => {
  const { setHasRefreshedToken } = useTableFilters()
  const user = useCognitoUser()

  const shouldRefreshToken = (exp: number | undefined) => {
    // if token runs out in 10 mins or less (seconds)
    return exp && exp - Date.now() / 1000 <= 600
  }

  useEffect(() => {
    const refreshTimer = setInterval(async () => {
      const expiry = user.getSignInUserSession()?.getAccessToken().getExpiration()
      const refreshToken = user.getSignInUserSession()?.getRefreshToken()
      if (refreshToken && shouldRefreshToken(expiry)) {
        await new Promise((resolve) => user.refreshSession(refreshToken, resolve))
        setHasRefreshedToken(true)
      }
    }, REFRESH_TOKEN_POLL)

    return () => clearTimeout(refreshTimer)
  })
}

const useLogrocket = (app: CognitoAppType) => {
  const user = useCognitoUser()
  const { startLogRocketRecording } = useAppConfiguration()

  useEffect(() => {
    if (user?.attributes?.sub) {
      logrocketIdentify(user.attributes.sub, app)
      startLogRocketRecording()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
}

export const checkAppPermission = (user: AmplifyUser, app: CognitoAppType) => {
  const userRoles = [
    ...(user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'] ?? []),
    ...([user.attributes?.['custom:role']].filter(Boolean) ?? []),
  ]

  if (!userRoles.some((role) => APP_ROLE_MAPPER[app].includes(role))) {
    Auth.signOut()
    throw usernameError
  }
}

export const isSuperAdmin = (user: AmplifyUser) => {
  const userRoles = [
    ...(user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'] ?? []),
    ...([user.attributes?.['custom:role']].filter(Boolean) ?? []),
  ]

  return userRoles.some((role) => APP_ROLE_MAPPER['admin-manager'].includes(role))
}

import { Typography, Chip } from '@project-minerva/design-system'
import { InvigilationCentre, InvigilationCentreStatus } from '@project-minerva/typings'
import { FormattedMessage } from 'react-intl'

interface ChipProps {
  invigilationCentre: InvigilationCentre
  shortLabel?: boolean
  useNameForApproved?: boolean
}

export function InvigilationCentreStatusChip(props: ChipProps) {
  const {
    invigilationCentre: { status, name },
    shortLabel,
    useNameForApproved,
  } = props
  return status === InvigilationCentreStatus.APPROVED ? (
    useNameForApproved ? (
      <Typography variant="body2">{name}</Typography>
    ) : (
      <Chip
        color="success"
        data-testid="centre-status-chip"
        label={<FormattedMessage id="approved" />}
        sx={{ color: 'white' }}
      />
    )
  ) : status === InvigilationCentreStatus.REJECTED ? (
    <Chip
      color="error"
      data-testid="centre-status-chip"
      label={<FormattedMessage id="rejected" />}
      sx={{ color: 'white' }}
    />
  ) : (
    <Chip
      color="warning"
      data-testid="centre-status-chip"
      label={<FormattedMessage id={shortLabel ? 'pending' : 'pending-confirmation'} />}
      sx={{ color: 'white' }}
    />
  )
}

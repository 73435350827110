import { useTableFilters } from '@project-minerva/context'
import { PaginatedRequest, PaginatedResult } from '@project-minerva/typings'
import { useEffect, useState } from 'react'
import { useAbortController } from './hooks/use-abort-controller'
import { request } from './http-client'

export async function fetchFromPaginatedEndpoint<T>(
  baseUrl: string,
  accessToken: string,
  options: PaginatedRequest,
  signal: AbortSignal,
  additionalParams?: Record<string, unknown>
): Promise<PaginatedResult<T>> {
  const url = new URL(baseUrl)

  Object.entries(options).map(([key, value]) => url.searchParams.set(key, value.toString()))

  if (additionalParams) {
    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value === undefined || value === null) return
      url.searchParams.set(key, `${value}`)
    })
  }

  return await request<PaginatedResult<T>>(url.toString(), { method: 'GET', signal }, accessToken)
}

export interface PaginationRequestData<T> {
  data: T[] | null
  loading: boolean
  error: Error | undefined
  setData: (newData: T[] | null) => void
  nextPage?: () => void
  previousPage?: () => void
  reload: () => Promise<void>
  pageNumber: number
  count?: number
}

export function usePaginationRequest<T>(
  getData: (options: PaginatedRequest, signal: AbortSignal) => Promise<PaginatedResult<T>>,
  pageSize = 20,
  skipFetch = false,
  orderBy = 'lastName'
): PaginationRequestData<T> {
  const [data, setData] = useState<PaginatedResult<T> | null>(null)
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState<number | undefined>(undefined)
  const { pageNumber, setPageToken, setPageNumber } = useTableFilters()
  const getSignal = useAbortController()

  useEffect(() => {
    if (skipFetch) return

    setError(undefined)
    setLoading(true)
    getData({ pageSize, orderBy }, getSignal())
      .then((res) => {
        setData(res)
        setCount(res.count)
        setLoading(false)
      })
      .catch((error) => {
        if (!(error instanceof DOMException)) {
          setError(error)
          setLoading(false)
        }
      })
  }, [getData, getSignal, pageSize, orderBy, skipFetch])

  const nextPage = () => {
    if (!data?.nextPageToken) return

    setError(undefined)
    setLoading(true)

    return getData({ orderBy, pageSize, pageToken: data?.nextPageToken }, getSignal())
      .then((res) => {
        setPageNumber(pageNumber + 1)
        setPageToken(data.nextPageToken)
        setData(res)
        setCount(res.count)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const previousPage = () => {
    if (!data?.previousPageToken) return

    setError(undefined)
    setLoading(true)

    return getData({ orderBy, pageSize, pageToken: data?.previousPageToken }, getSignal())
      .then((res) => {
        setPageNumber(pageNumber - 1)
        setPageToken(data.previousPageToken)
        setData(res)
        setCount(res.count)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const reload = async () => {
    setError(undefined)
    setLoading(true)
    return getData({ pageSize, orderBy }, getSignal())
      .then((res) => {
        setData(res)
        setCount(res.count)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  return {
    data: data?.data || null,
    error,
    loading,
    nextPage: data?.nextPageToken ? nextPage : undefined,
    previousPage: data?.previousPageToken ? previousPage : undefined,
    setData: (newData: T[] | null) => setData(newData ? ({ ...data, data: newData } as PaginatedResult<T>) : null),
    reload,
    pageNumber,
    count,
  }
}

import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export const LiveTag = () => {
  return (
    <Box
      data-testid="assessment-card-status"
      sx={{
        background: 'rgba(39, 208, 220, 0.2)',
        borderRadius: '3px',
        width: '50px',
        height: '21px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: '#17787F',
        }}
      />
      <Typography
        sx={{
          color: '#17787F',
          fontSize: '10px',
          fontWeight: 600,
          textTransform: 'uppercase',
          ml: '4px',
        }}
      >
        <FormattedMessage id="tag-live" />
      </Typography>
    </Box>
  )
}

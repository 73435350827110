import { Paper, styled } from '@project-minerva/design-system'

export const PaperPanel = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
}))

export const PaperPanelFullWidth = styled(PaperPanel)(({ theme }) => ({
  margin: theme.spacing(2),
  flexGrow: 1,
  width: `calc(100% - ${theme.spacing(4)})`,
  minWidth: 700,
}))

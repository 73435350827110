import { createActorContext } from '@xstate/react'
import { DEFAULT_TICK_INTERVAL, timerMachine } from './timer.machine'
import { isDev } from '../../player.provider'
import { useRef } from 'react'

const TimerMachineContext = createActorContext(timerMachine, { devTools: isDev() })

interface Props {
  children: React.ReactNode
  duration: number
  interval?: number
  disabled?: boolean
}
const TimerProvider = ({ children, duration, disabled, interval }: Props) => {
  const machineRef = useRef(
    timerMachine.withContext({
      ...timerMachine.context,
      duration,
      enabled: !disabled,
      interval: interval || DEFAULT_TICK_INTERVAL,
    })
  )

  return <TimerMachineContext.Provider machine={machineRef.current}>{children}</TimerMachineContext.Provider>
}

export { TimerProvider, TimerMachineContext }

import { useState } from 'react'
import { useDebounce } from './use-debounce'

export type SearchFunction = (value: string, forceSearch?: boolean) => void

export const defaultSearchCondition = (value: string) => value.trim().length >= 3 || value === ''

export const useSearch = (
  searchCondition = defaultSearchCondition,
  debounceInterval = 500
): [string, SearchFunction] => {
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, debounceInterval)
  const doSearch: SearchFunction = (value: string, forceSearch = false): void => {
    if (forceSearch || searchCondition?.(value)) {
      setSearchQuery(value.trim())
    }
  }

  return [debouncedSearchQuery, doSearch]
}

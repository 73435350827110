import { useCognitoSignOut } from '@project-minerva/auth-cognito'
import { useAnchorElement } from '@project-minerva/core'
import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  useTheme,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  brandingDark,
} from '@project-minerva/design-system'
import { ProfileIcon } from '@project-minerva/icons'
import { FormattedMessage } from 'react-intl'
import { matchPath, useLocation, useHistory } from 'react-router-dom'
import { MainMenuBranding } from '../branding/main-menu-branding'
import { MinervaRouteProps } from './navigation.types'

export function Navigation({
  offsetY = 0,
  routes,
  handleUserProfile,
}: {
  offsetY?: number
  routes: [route: string, routeDetails: MinervaRouteProps][]
  handleUserProfile?: () => void
}) {
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()

  return (
    <nav
      style={{
        minWidth: theme.spacing(8.25),
        height: '100%',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 3,
      }}
    >
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          flexShrink: 0,
          height: '100%',
          whiteSpace: 'nowrap',
          maxWidth: (theme) => theme.spacing(8.25),
          '& .MuiDrawer-paper': {
            borderWidth: (theme) => theme.spacing(0.25),
            maxWidth: (theme) => theme.spacing(8.25),
            overflowX: 'hidden',
            justifyContent: 'space-between',
          },
          '& .MuiListItemIcon-root': {
            minWidth: '40px',
          },
        }}
      >
        <List sx={{ p: 0, top: offsetY }}>
          <MainMenuBranding />
          {routes.map(([route, routeDetails]) => {
            const match = matchPath(location.pathname, {
              path: route,
              exact: false,
              strict: routeDetails.strict,
            })
            const Icon = routeDetails.navIcon
            if (!Icon) return null

            return (
              <div key={`menu-item-${routeDetails.displayName}`}>
                <Divider sx={{ width: '100%' }} />
                <ListItemButton
                  sx={{
                    p: (theme) => theme.spacing(1),
                  }}
                  key={route}
                  onClick={() => {
                    if (!match) {
                      history.push(route, { from: location.pathname })
                    }
                  }}
                  selected={!!match}
                  data-testid={`navigation-item-${routeDetails.displayName.toLowerCase()}`}
                >
                  <Tooltip title={routeDetails.displayName} placement="right">
                    <ListItemIcon
                      sx={{
                        width: (theme) => theme.spacing(6.2),
                        height: (theme) => theme.spacing(6.2),
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Icon color={match ? brandingDark : theme.palette.text.secondary} />
                    </ListItemIcon>
                  </Tooltip>
                </ListItemButton>
              </div>
            )
          })}
        </List>
        <UserAccountNavigationElement handleUserProfile={handleUserProfile} />
      </Drawer>
    </nav>
  )
}

const UserAccountNavigationElement = ({ handleUserProfile }: { handleUserProfile?: () => void }) => {
  const theme = useTheme()
  const logout = useCognitoSignOut()
  const { anchorElement, setAnchor, unsetAnchor } = useAnchorElement()

  const handleLogout = () => {
    unsetAnchor()
    logout()
  }

  const open = Boolean(anchorElement)
  const id = open ? 'simple-popover' : undefined
  const location = useLocation()
  const match = matchPath(location.pathname, { path: '/user-profile' })

  return (
    <div style={{ width: '100%' }}>
      <IconButton
        data-testid="account-menu"
        sx={{ width: '100%', height: (theme) => theme.spacing(8.2) }}
        onClick={setAnchor}
      >
        <ProfileIcon color={open || match ? theme.palette.primary.main : theme.palette.secondary.dark} />
      </IconButton>

      <Menu
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={unsetAnchor}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {handleUserProfile && (
          <MenuItem
            data-testid="user-profile-button"
            sx={{ minWidth: 150 }}
            onClick={() => {
              handleUserProfile()
              unsetAnchor()
            }}
          >
            <FormattedMessage id="user-profile" />
          </MenuItem>
        )}
        <MenuItem data-testid="sign-out-button" sx={{ minWidth: 150 }} onClick={handleLogout}>
          <FormattedMessage id="logout" />
        </MenuItem>
      </Menu>
    </div>
  )
}

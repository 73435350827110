import { Link, Typography } from '@project-minerva/design-system'
import { HTMLAttributeAnchorTarget } from 'react'
import { FormattedMessage } from 'react-intl'

export const Copyright = () => (
  <Typography variant="subtitle1" color="text.secondary" fontWeight={600} fontSize=".75rem">
    <FormattedMessage
      id="copyright-century"
      defaultMessage="\u00A9{year} CENTURY Tech"
      values={{ year: new Date().getFullYear() }}
    />
  </Typography>
)

interface FooterLinkProps {
  to: string
  target?: HTMLAttributeAnchorTarget
}

export const FooterLink: React.FC<FooterLinkProps> = ({ children, to, target = '_self' }) => (
  <Link
    href={to}
    target={target}
    rel="noopener noreferrer"
    variant="subtitle1"
    sx={{
      fontWeight: 600,
      fontSize: '.75rem',
      textDecoration: 'none',
      textTransform: 'capitalize',
      transition: 'color .3s',
      color: (theme) => theme.palette.text.secondary,
      '&:hover': { color: (theme) => theme.palette.primary.main },
    }}
  >
    {children}
  </Link>
)

import { SvgIconProps } from '@mui/material/SvgIcon'
import { alpha, Button, ButtonProps, styled, Typography } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import FormatSizeRoundedIcon from '@mui/icons-material/FormatSizeRounded'
import { useFontSize } from './font-size-provider'
import { Stack } from '@mui/system'

export const FONT_SIZE_REGULAR = 16
const FONT_SIZE_LARGE = 20
const FONT_SIZE_EXTRA_LARGE = 24

export const SIZE_MAPPER = {
  [FONT_SIZE_REGULAR]: 'regular',
  [FONT_SIZE_LARGE]: 'large',
  [FONT_SIZE_EXTRA_LARGE]: 'extra-large',

  regular: FONT_SIZE_REGULAR,
  large: FONT_SIZE_LARGE,
  'extra-large': FONT_SIZE_EXTRA_LARGE,
} as const

export type FontSize = typeof FONT_SIZE_REGULAR | typeof FONT_SIZE_LARGE | typeof FONT_SIZE_EXTRA_LARGE
export type FontSizeLabel = 'regular' | 'large' | 'extra-large'

export const FontSizeChanger = ({ track }: { track?: (previousSize: string | null, currentSize: string) => void }) => {
  const { fontSize, setFontSize } = useFontSize()

  const changeFontSize = (newSize: FontSize) => {
    if (newSize === fontSize) return

    const previousSize = fontSize !== null ? SIZE_MAPPER[fontSize] : null
    const newFontSize = SIZE_MAPPER[newSize]
    track?.(previousSize, newFontSize)
    setFontSize(newSize)
  }

  return (
    <div>
      <Typography variant="subtitle2" sx={{ color: (theme) => theme.palette.text.secondary, fontWeight: 'bold' }}>
        <FormattedMessage id="font-size" />
      </Typography>
      <Stack spacing={1} direction="row" alignItems="flex-end" mt={1}>
        <FontSizeButton selected={fontSize === FONT_SIZE_REGULAR} onClick={() => changeFontSize(FONT_SIZE_REGULAR)}>
          <FontSizeIcon size="small" />
        </FontSizeButton>
        <FontSizeButton selected={fontSize === FONT_SIZE_LARGE} onClick={() => changeFontSize(FONT_SIZE_LARGE)}>
          <FontSizeIcon size="medium" />
        </FontSizeButton>
        <FontSizeButton
          selected={fontSize === FONT_SIZE_EXTRA_LARGE}
          onClick={() => changeFontSize(FONT_SIZE_EXTRA_LARGE)}
        >
          <FontSizeIcon size="large" />
        </FontSizeButton>
      </Stack>
    </div>
  )
}

const AuxButton = ({ selected, ...props }: { selected?: boolean } & ButtonProps) => (
  <Button disableElevation disableRipple {...props} />
)
const FontSizeButton = styled(AuxButton)<{ selected?: boolean } & ButtonProps>(({ theme, selected }) => ({
  pointerEvents: selected ? 'none' : 'auto',
  backgroundColor: alpha(theme.palette.primary.main, selected ? 0.2 : 0),
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  borderRadius: '6px',
  width: '2.2rem',
  minWidth: '2.2rem',
  height: '2.2rem',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
  },
}))

const FontSizeIcon = styled(FormatSizeRoundedIcon)<SvgIconProps & { size: SvgIconProps['fontSize'] }>(({ size }) => ({
  transform: `scaleX(-1) translateY(${size === 'large' ? '-.0625rem' : size === 'small' ? '.0615rem' : 0})`,
  fontSize: size === 'large' ? '1.75rem' : size === 'medium' ? '1.5rem' : '1.25rem',
}))

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useAuthContext } from '@project-minerva/auth-cognito'
import { useDownloadFromPath } from '@project-minerva/core'
import { Alert, Box, Divider, Typography } from '@project-minerva/design-system'
import { getDownloadToken, getDocumentDownloadUrl, getDocumentViewUrl } from '@project-minerva/shared-api'
import { SendStatus } from '@project-minerva/typings'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaperPanel } from '../panel'
import { ApplicantUploadedFile } from './applicant-uploaded-file'

interface Props {
  showAppealInfo?: boolean
  send: SendStatus
  children?: ReactNode
  allowDownload?: boolean
  allowFileView?: boolean
  appealMessage?: ReactNode
  showDocumentNotes?: boolean
}

export function ApplicationSendWidget(props: Props) {
  const { showAppealInfo, send, children, allowDownload, appealMessage, allowFileView, showDocumentNotes } = props
  const { accessToken } = useAuthContext()

  const downloadDocument = useDownloadFromPath()

  const onDownloadClick = async (docId: string, docName: string) => {
    const { token } = await getDownloadToken(docId, accessToken.current)
    downloadDocument(getDocumentDownloadUrl(docId, token, docName), docName)
  }

  const onFileClick = async (docId: string, docName: string) => {
    const { token } = await getDownloadToken(docId, accessToken.current)
    downloadDocument(getDocumentViewUrl(docId, token, docName), docName, true)
  }

  return (
    <PaperPanel>
      <Typography variant="h5">
        <FormattedMessage id="send-details" defaultMessage="SEND Details" />
      </Typography>
      {showAppealInfo && appealMessage && (
        <Alert severity="info" sx={{ mt: 1 }}>
          {appealMessage}
        </Alert>
      )}
      <Typography variant="h6">
        <FormattedMessage id="supporting-documents" defaultMessage="Supporting Documents" />
      </Typography>
      {send.documents.map((doc) => (
        <ApplicantUploadedFile
          onIconClick={() => (allowDownload ? onDownloadClick(doc.documentId, doc.name) : null)}
          onFileClick={allowFileView ? () => onFileClick(doc.documentId, doc.name) : undefined}
          key={doc.documentId}
          icon={allowDownload ? <FileDownloadOutlinedIcon /> : null}
          name={doc.name}
        />
      ))}
      {!!send.documentNotes.length && showDocumentNotes && (
        <Box mb={2}>
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="document-notes" defaultMessage="Document Notes" />
          </Typography>
          {send.documentNotes.map((note, idx) => (
            <Typography variant="subtitle2" key={`note-${idx}`}>
              {note}
            </Typography>
          ))}
        </Box>
      )}
      {(send.additionalTime || send.environmentalProvisions) && (
        <Divider sx={{ m: (theme) => theme.spacing(2, 0, 2) }} />
      )}
      {send.additionalTime && (
        <Box sx={{ m: (theme) => theme.spacing(0, 3, 2, 0) }}>
          <Typography variant="subtitle1" fontWeight={600} data-testid="additional-time-title">
            <FormattedMessage id="requested-time-25-percent" defaultMessage="Requested 25% extra time" />
          </Typography>
          {send.additionalTimeDescriptions.map((desc) => (
            <Typography variant="subtitle1" data-testid="additional-time-text" key={desc}>
              {desc}
            </Typography>
          ))}
        </Box>
      )}
      {send.environmentalProvisions && (
        <Box sx={{ m: (theme) => theme.spacing(0, 3, 2, 0) }}>
          <Typography variant="subtitle1" fontWeight={600} data-testid="send-environmental-provisions-title">
            <FormattedMessage id="requested-other-adjustments" defaultMessage="Requested other adjustments" />
          </Typography>
          {send.environmentalProvisionsDescriptions.map((desc) => (
            <Typography variant="subtitle1" data-testid="send-environmental-provisions-text" key={desc}>
              {desc}
            </Typography>
          ))}
        </Box>
      )}
      {children}
    </PaperPanel>
  )
}

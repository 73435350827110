import { Applicant } from './applicant.types'
import { TestQuestion, TestDocument, Content } from '@ctek/test-player'

export interface Assessment {
  id: string
  name: string
  startTime: Date
  endTime: Date
}

export interface AssessmentData {
  applicantId: string
  schoolId: string
  schoolName: string
  assessmentId: string
  name: string
  firstName?: string
  lastName?: string
  startTime: string
  endTime: string
  serverTime: string
  tests: TestData[]
}

export interface TestData {
  id: string
  isClosed: boolean
  isPaused: boolean
  name: string
  subjectId: string
  timeLimitSeconds: number
  isPublished: boolean
  startTime?: string
  endTime?: string
  pauseStartTime?: string
  percentageComplete?: number
}

export interface AssessmentCode extends Applicant {
  code: string
}

export enum AdminAssessmentStatus {
  LIVE,
  COMPLETE,
  NOT_STARTED,
}

export type AssessmentStatus = 'SCHEDULED' | 'ACTIVE' | 'ENDED'

export interface AssessmentTest {
  testId: string
  name: string
  startedBy: string
  completedBy: string
  timeLimitSeconds?: number
}

export interface ApiAssessment {
  id: string
  name: string
  startTime: string
  endTime: string
}

export interface Pause {
  pauseId: string
  pauseStartTime: string
}

export interface Resume extends Pause {
  pauseEndTime: string
  testEndTime: string
}

export interface AssessmentTestQuestion extends TestQuestion<Content, Content> {
  isebTestSectionId?: string
  questionPoolId?: string
}

export interface AssessmentNextStep {
  testContent: (TestDocument<Content> | AssessmentTestQuestion)[]
  percentageComplete?: number
}

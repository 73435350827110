import { TestNode, TestDocument, TestPractice, TestQuestion } from '../typings'

export const isDocument = <TContent, TAnswer>(
  content: TestNode<TContent, TAnswer>
): content is TestDocument<TContent> => content?.type === 'document'

export const isPractice = <TContent, TAnswer>(
  content: TestNode<TContent, TAnswer>
): content is TestPractice<TContent, TAnswer> =>
  content?.type === 'question' && (content as TestQuestion<TContent, TAnswer>)?.points === 0

export const isQuestion = <TContent, TAnswer>(
  content: TestNode<TContent, TAnswer>
): content is TestQuestion<TContent, TAnswer> =>
  content?.type === 'question' && (content as TestQuestion<TContent, TAnswer>)?.points > 0

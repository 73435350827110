import Stack from '@mui/material/Stack'
import {
  Button,
  darken,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  ThemeType,
  Typography,
} from '@project-minerva/design-system'
import { DataTestId } from '@project-minerva/typings'
import React, { RefObject } from 'react'

interface SimpleDialogProps extends DataTestId {
  open: boolean
  callback?: () => void
  title?: React.ReactNode
  content: React.ReactNode
  buttonLabel?: React.ReactNode
  blurBackground?: boolean
  children?: React.ReactNode // extra buttons
}

const useStyles = makeStyles((theme: ThemeType) => ({
  title: {
    color: darken(theme.palette.primary.dark, 0.3),
    fontWeight: 'bold',
  },
  backdrop: {
    backgroundColor: darken(theme.palette.primary.dark, 0.8),
    opacity: '0.75 !important',
  },
  button: {
    padding: '8px 28px 10px 28px',
    textTransform: 'capitalize',
    margin: 0,
  },
  dialog: {
    borderRadius: 25,
    padding: '1.5rem 1.835rem',
    maxWidth: 460,
  },
}))

// ! TODO: MOVE TO design-system?
export const SimpleDialog = React.forwardRef((props: SimpleDialogProps, ref) => {
  const { buttonLabel, callback, content, 'data-testid': dataTestId, open, title, blurBackground } = props
  const styles = useStyles()
  return (
    <Dialog
      PaperProps={{ className: styles.dialog }}
      BackdropProps={{
        className: styles.backdrop,
      }}
      open={open}
      data-testid={dataTestId}
      ref={ref as RefObject<HTMLDivElement>}
      sx={{ backdropFilter: blurBackground ? 'blur(5px)' : 'none' }}
    >
      {title && (
        <DialogTitle data-testid="complete-modal-title" sx={{ pl: 0, pr: 0, pt: 0 }}>
          <Typography className={styles.title} variant="h4" component="span">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent sx={{ pl: 0, pr: 0 }}>{content}</DialogContent>
      {callback && (
        <DialogActions sx={{ p: 0 }}>
          <Stack spacing={2} direction="row">
            {props.children}
            <Button
              data-testid="complete-modal-btn"
              className={styles.button}
              color="primary"
              onClick={callback}
              autoFocus
            >
              {buttonLabel}
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  )
})

export default SimpleDialog

import { useFontSize, useScreenFilter } from '@project-minerva/assessment-player'
import { useCallback } from 'react'
import { TrackSettingsSnapshot } from '../mixpanel'

export const useTrackSettings = () => {
  const { screenFilter } = useScreenFilter()
  const { fontSizeLabel } = useFontSize()

  const callback = useCallback(
    (): TrackSettingsSnapshot => ({ screenFilter, fontSize: fontSizeLabel }),
    [screenFilter, fontSizeLabel]
  )

  return callback
}

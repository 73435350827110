import { createContext, ReactNode, useContext, useState } from 'react'

const ScreenFilterContext = createContext({
  screenFilter: null as string | null,
  setScreenFilter: (newFilter: null | string) => {
    return
  },
})

export const useScreenFilter = () => {
  const context = useContext(ScreenFilterContext)

  if (!context) {
    throw new Error('useScreenFilter must be called inside a ScreenFilterProvider')
  }

  return context
}

export const ScreenFilterProvider = (props: { children: ReactNode }) => {
  const [screenFilter, setScreenFilter] = useState<string | null>(null)

  return (
    <ScreenFilterContext.Provider value={{ screenFilter, setScreenFilter }}>
      {props.children}
    </ScreenFilterContext.Provider>
  )
}

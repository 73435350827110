import { assign, createMachine, InterpreterFrom } from 'xstate'

export const DEFAULT_TICK_INTERVAL = 1_000

export type TimerContext = {
  enabled: boolean
  duration: number
  interval: number
  lastTick: number
  elapsed: number
  delta: number
  remaining: number
}
export type TimerEvent = { type: 'START'; duration?: number } | { type: 'PAUSE' }
export type TimerInterpreter = InterpreterFrom<typeof timerMachine>

export const timerMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QBcCWBbMAnAdKgdqsgMQDaADALqKgAOA9rEavfjSAB6IC0AjAMwAmHABZe5AOwTyATgAcYuTIBsAGhABPRLxFyc5Xof5j+EuRIEBfS+rSZcBImV7UkIBkzSt2XBH0HCMhIArMFy4eaShsHqWgiC5MKGCsoBvIIKIsr81rYY2HgQADZgxADKACoAggBKFRSudIzM3m6+6TI4FrrGIcEy5MHZsYgqOMEZyrwpGSJZOTYgdgVoAMYA1gRQxAAKVQCqZQCiDeweLWxt2iISOMoyAkGCoQND-CPxyrfiwSL9uhJ+OQDMFckt8rg1pt8NtTm5zl5LqBfHNeDheA8TKEss8ZB8dHoMcohsEpLwJBkrItlpDUBstsQOLBkABDZBgHAsgBm7KwAAoKgBJADCAGkAPqCgByFSONQAalUADIASmINJwUK2cKanhYSM4iEEXxwMgCEmycgEEhEghUH2k4zC-VC4hEBmN1kW+HoEDg7BpZ2aiJ8PH4oVEduU5himh4vGUprE7rmSn65hEYI1jmQQb1rWRYckkZUMfxgjRxOJf0S-C+0gkWYhhRKeYuoYQtvxfy65DkQP6qUy2Sb9k1dOhUDbIau8TkwX0cgSQlkIOkInxxhwgn4S6GBlMxpuo5W+QgAHkAK65+HB-Ud5TkfimhS7sR9uSDPFxhA6ER3FQwh0Q8QnIZQT1wCBUFgFkACMSggad71nPhEn0AJ5nCBtog+Y1vkGP4ZABIEQS9SwgA */
  createMachine(
    {
      predictableActionArguments: true,
      preserveActionOrder: true,
      tsTypes: {} as import('./timer.machine.typegen').Typegen0,
      schema: {
        context: {} as TimerContext,
        events: {} as TimerEvent,
      },

      id: 'timer',

      context: {
        enabled: true,
        duration: 10_000,
        elapsed: 0,
        remaining: 0,
        delta: 0,
        interval: DEFAULT_TICK_INTERVAL,
        lastTick: Date.now(),
      },

      initial: 'init',

      states: {
        init: {
          entry: ['setup'],
          always: [{ target: 'idle', cond: 'isEnabled' }, 'disabled'],
        },

        idle: {
          tags: ['idle'],
          on: {
            START: {
              target: 'ticking',
              actions: ['setDuration', 'setup', 'tick'],
            },
          },
        },

        ticking: {
          tags: ['ticking'],
          entry: ['diff', 'tick'],

          after: {
            TICK_INTERVAL: 'ticking',
          },

          always: {
            cond: 'isTimedOut',
            target: 'timedOut',
          },

          on: {
            PAUSE: 'idle',
          },
        },

        timedOut: {
          tags: ['timeout'],
          type: 'final',
        },

        disabled: {},
      },
    },
    {
      delays: {
        TICK_INTERVAL: (ctx) => ctx.interval,
      },

      actions: {
        tick: assign({
          lastTick: (_) => Date.now(),
        }),
        diff: assign((ctx) => {
          const delta = Date.now() - ctx.lastTick
          const elapsed = ctx.elapsed + delta

          return {
            ...ctx,
            delta,
            elapsed,
            remaining: ctx.duration - elapsed,
          }
        }),
        setDuration: assign({
          duration: (ctx, evt) => evt.duration ?? ctx.duration,
        }),
        setup: assign({
          elapsed: (_) => 0,
          remaining: (ctx) => ctx.duration,
        }),
      },

      guards: {
        isEnabled: (ctx) => ctx.enabled,
        isTimedOut: (ctx) => ctx.elapsed >= ctx.duration,
      },
    }
  )

import { QuestionRenderer, TestScreenProps, useTestController } from '@project-minerva/assessment-player'
import { Content, TestQuestion } from '@ctek/test-player'
import { useEffect } from 'react'
import { useLastContentViewed } from './last-content-viewed.provider'

export const ExaminationQuestion = (props: TestScreenProps) => {
  const { usePingsHook } = props
  const { currentContent } = useTestController()
  const questionPingDataHandler = () => ({
    questionGroupId: (currentContent as TestQuestion<Content, Content>).questionGroupId,
  })
  const { setLastContentViewed } = useLastContentViewed()

  useEffect(() => {
    if (!currentContent) return

    setLastContentViewed(currentContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  usePingsHook?.(questionPingDataHandler)

  return <QuestionRenderer {...props} />
}

import { ContentNavigatorProvider, useTimer } from '@ctek/test-player'
import { LoadingSpinner } from '@project-minerva/shared-ui'
import { useTestController, useTestFlowController } from '../assessment-player.context'
import { useEffect } from 'react'
import { AssessmentPlayerProps } from '../assessment-player'
import { DocumentRenderer } from './document-renderer'
import { QuestionRenderer } from './question-renderer'

export type TestScreenProps = Pick<AssessmentPlayerProps, 'useNavigationHook' | 'usePingsHook'> & {
  documentRenderer?: typeof DocumentRenderer
  questionRenderer?: typeof QuestionRenderer
}

export const TestScreen = (props: TestScreenProps) => {
  const { documentRenderer: DocumentComponent, questionRenderer: QuestionComponent } = props
  const { isDocument, currentContent } = useTestController()
  const { isTestCompleted, timeOut } = useTestFlowController()
  const { isTimeout } = useTimer()
  const contentKey = `${currentContent?.type}-${currentContent?.id}`
  const ContentRenderer = isDocument ? DocumentComponent ?? DocumentRenderer : QuestionComponent ?? QuestionRenderer

  useEffect(() => {
    if (isTimeout && !isTestCompleted) {
      timeOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeout])

  if (!currentContent) {
    return <LoadingSpinner />
  }

  return (
    <ContentNavigatorProvider navigationHook={props.useNavigationHook}>
      <ContentRenderer key={contentKey} {...props} />
    </ContentNavigatorProvider>
  )
}

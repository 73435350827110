import { createContext, ReactNode, useContext, useState } from 'react'

type TestProgressContextType = {
  progress: number
  setProgress: (value: number) => void
}

const TestProgressContext = createContext<TestProgressContextType>({} as unknown as TestProgressContextType)

export const useTestProgress = () => {
  const context = useContext(TestProgressContext)

  if (!context) throw new Error('useTestProgress must be called inside a TestProgressProvider')

  return context
}

export const TestProgressProvider = ({
  children,
  initialProgress,
}: {
  children: ReactNode
  initialProgress: number
}) => {
  const [progress, setProgress] = useState(initialProgress)

  return <TestProgressContext.Provider value={{ progress, setProgress }}>{children}</TestProgressContext.Provider>
}

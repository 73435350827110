import { Box, Button, Typography } from '@project-minerva/design-system'
import { SimpleDialog } from '@project-minerva/assessment-player'
import { FormattedMessage } from 'react-intl'
import { usePauseResumeTest } from './pause-resume.provider'

type ResumeTestDialogProps = {
  open: boolean
  openDobConfirmationDialog: () => void
}
const ResumeTestDialog = ({ open, openDobConfirmationDialog }: ResumeTestDialogProps) => {
  return (
    <SimpleDialog
      open={open}
      content={<ResumeTest openDobConfirmationDialog={openDobConfirmationDialog} />}
      data-testid="resume-test-dialog"
    />
  )
}

export default ResumeTestDialog

type ResumeTestProps = Pick<ResumeTestDialogProps, 'openDobConfirmationDialog'>
const ResumeTest = ({ openDobConfirmationDialog }: ResumeTestProps) => {
  const { isRequesting } = usePauseResumeTest()
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: (theme) => theme.spacing(1), fontWeight: 600 }}>
        <FormattedMessage id="resume-test-modal-title" />
      </Typography>
      <Typography sx={{ mb: (theme) => theme.spacing(1) }}>
        <FormattedMessage id="resume-test-modal-description" />
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <Button data-testid="start-test-button" onClick={openDobConfirmationDialog} disabled={isRequesting}>
          <FormattedMessage id="continue-test" />
        </Button>
      </Box>
    </Box>
  )
}

import { fetchFromPaginatedEndpoint, getApiUrl, useRequest } from '@project-minerva/api'
import { AcademicYear, PaginatedRequest } from '@project-minerva/typings'

const baseUrl = getApiUrl('helios', '/v1/academic-years')

export const useCurrentAcademicYear = () =>
  useRequest<{
    academicYear: AcademicYear
    hasStarted: boolean
    nextAcademicYear?: AcademicYear
    previousAcademicYear?: AcademicYear
  }>(`${baseUrl}/current`, { method: 'GET' })

export const listAllAcademicYears = (token: string, options: PaginatedRequest, signal: AbortSignal) => {
  return fetchFromPaginatedEndpoint<AcademicYear>(baseUrl, token, options, signal)
}

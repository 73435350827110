import { FontSizeProvider, LocalSettingsProvider, ScreenFilterProvider } from '@project-minerva/assessment-player'
import { SubjectProvider, useAppConfiguration } from '@project-minerva/core'
import { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ExaminationDataProvider } from '../data/examination-data.provider'
import { SelectTestPage } from './select-test.page'
import { TestPageWrapper } from './test-page-wrapper'
import { TestPage } from './test.page'

export const ExaminationRouter = () => {
  const { startLogRocketRecording } = useAppConfiguration()

  useEffect(() => {
    startLogRocketRecording()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SubjectProvider>
      <FontSizeProvider>
        <ScreenFilterProvider>
          <LocalSettingsProvider>
            <ExaminationDataProvider>
              <Switch>
                <Route path="/exam/:assessmentId/subject/:subjectId/test/:testId" exact={true}>
                  <TestPageWrapper>
                    <TestPage />
                  </TestPageWrapper>
                </Route>
                <Route path="/exam" exact={true}>
                  <SelectTestPage />
                </Route>
                <Route>
                  <Redirect to="/exam" />
                </Route>
              </Switch>
            </ExaminationDataProvider>
          </LocalSettingsProvider>
        </ScreenFilterProvider>
      </FontSizeProvider>
    </SubjectProvider>
  )
}

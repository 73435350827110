import { Content, TestNode } from '@ctek/test-player'
import { createContext, MutableRefObject, ReactNode, useContext, useRef } from 'react'
import { LastContentViewed } from '../api/study-session.api'

type LastContentViewedContextType = {
  lastContentViewedRef: MutableRefObject<(LastContentViewed & { contentName: string }) | undefined>
  setLastContentViewed: (content: TestNode<Content, Content>) => void
}

const LastContentViewedContext = createContext<LastContentViewedContextType>(
  {} as unknown as LastContentViewedContextType
)

export const useLastContentViewed = () => {
  const context = useContext(LastContentViewedContext)

  if (!context) throw new Error('useLastContentViewed must be called inside a LastContentViewedProvider')

  return context
}

export const LastContentViewedProvider = (props: { children: ReactNode }) => {
  const lastContentViewedRef = useRef<LastContentViewed & { contentName: string }>()
  const setLastContentViewed = (content: TestNode<Content, Content>) => {
    lastContentViewedRef.current = {
      contentId: content.id,
      contentType: content.type,
      contentName: content.name,
    }
  }

  return (
    <LastContentViewedContext.Provider value={{ lastContentViewedRef, setLastContentViewed }}>
      {props.children}
    </LastContentViewedContext.Provider>
  )
}

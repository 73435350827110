import { useSelector } from '@xstate/react'
import { useCallback, useContext } from 'react'
import { State } from 'xstate'
import { PlayerCtx } from '../../player.provider'
import {
  TestFlowMachineContext as Context,
  TestFlowMachineEvent as Event,
  TestFlowMachineEvent,
} from './test-flow.machine'

type TestFlowState = State<Context, Event<unknown>>

const isStartTestScreenSelector = (state: TestFlowState) => state.hasTag('startScreen')
const isTestScreenSelector = (state: TestFlowState) =>
  state.matches('testScreen') && getTestMachineSelector(state)?.getSnapshot()?.hasTag('contentNode')
const isTestCompletedSelector = (state: TestFlowState) => state.matches('completed')
const isTestTimedOutSelector = (state: TestFlowState) => state.matches('timeOut')
const isTestFinishedSelector = (state: TestFlowState) => !!state.done
// TODO: create const/enum for invoked/spawned machines
const getTestMachineSelector = (state: TestFlowState) => state.children['test-machine-invoked'] // state.context.testMachineRef

export const useGenericTestFlowController = <TSession>(playerContext: React.Context<PlayerCtx<TSession>>) => {
  type SessionState = TSession
  const { player: machine } = useContext(playerContext)

  const isStartTestScreen = useSelector(machine, isStartTestScreenSelector)
  const isTestScreen = useSelector(machine, isTestScreenSelector)
  const isTestCompleted = useSelector(machine, isTestCompletedSelector)
  const isTestTimedOut = useSelector(machine, isTestTimedOutSelector)
  const isTestFinished = useSelector(machine, isTestFinishedSelector)

  const testMachine = useSelector(machine, getTestMachineSelector)

  const initialiseStudySessionService = useCallback(
    (promise: () => Promise<SessionState>) =>
      machine.send({ type: 'START_TEST', callback: promise } as TestFlowMachineEvent<SessionState>),
    [machine]
  )

  const testCompleted = useCallback(() => machine.send('TEST_COMPLETED'), [machine])
  const timeOut = useCallback(() => machine.send('TIME_OUT'), [machine])

  return {
    isStartTestScreen,
    isTestScreen,
    isTestCompleted, // has completed all content
    isTestTimedOut, // has time expired
    isTestFinished, // whatever reason the test has been ended
    testMachine,
    initialiseStudySessionService,
    testCompleted,
    timeOut,
  }
}

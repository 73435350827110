import { TestScreen, TestScreenProps } from '@project-minerva/assessment-player'
import { useIntl } from 'react-intl'
import { Prompt } from 'react-router'
import { useAlertOnPageRefresh } from '../hooks/use-alert-on-navigation'
import { ExaminationDocument } from './examination-document'
import { ExaminationQuestion } from './examination-question'

export const ExaminationContentScreen = (props: Omit<TestScreenProps, 'documentRenderer' | 'questionRenderer'>) => {
  const { formatMessage } = useIntl()

  useAlertOnPageRefresh()

  return (
    <>
      <Prompt message={formatMessage({ id: 'leave-test-prompt' })} />
      <TestScreen documentRenderer={ExaminationDocument} questionRenderer={ExaminationQuestion} {...props} />
    </>
  )
}

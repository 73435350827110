import { useSubjects } from '@project-minerva/core'
import { Typography } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'

export const ExamLeftAlign = (props: { examName?: string }) => {
  const { subjectId } = useParams<{ subjectId: string }>()
  const { getSubject } = useSubjects()
  const subject = getSubject(subjectId)
  const { examName } = props

  if (!subject && !examName) {
    return null
  }

  return (
    <Typography
      data-testid="header-subject"
      variant="h6"
      sx={{
        textTransform: 'uppercase',
        fontWeight: 600,
      }}
    >
      {examName || <FormattedMessage id={`subject-${subject?.name.toLowerCase()}`} />}
    </Typography>
  )
}

/**
 * Event names are defined by Data team in the Mixpanel: Taxonomy
 *
 * https://docs.google.com/spreadsheets/d/1YH8SxgBD9cUVaxFyswZuBklTavOEB4vUrFOCUrKtHzM/edit#gid=0
 */

export enum MixpanelEventTypes {
  /** COMMON */
  PageViewed = 'Page Viewed',
  SignUp = 'Sign Up',
  Login = 'Login',
  BondPromotionLearnMoreButtonClicked = 'Bond Promotion Learn More Button Clicked',
  BondPromotionStartFreeTrialButtonClicked = 'Bond Promotion Free Trial Button Clicked',

  /** ADMIN */

  /** ADMIN-CENTRE */

  /** ADMIN-SCHOOLS */

  /** GUARDIAN */

  /** CMS */

  /** EXAM and/or FAMILIARISATION APP */
  // Screen Filter
  ScreenFilterChanged = 'Screen Filter Changed',
  FontSizeChanged = 'FontSize Changed',
  // StudySession -
  StudySessionQuestionAnswered = 'Question Answered',
  StudySessionStarted = 'Study Session Started',
  StudySessionDocumentViewed = 'Document Viewed',
  StudySessionCompleted = 'Study Session Completed',
  TestPaused = 'Test Paused',
  TestResumed = 'Test Resumed',
  TimerClicked = 'Timer Clicked',
  ProgressBarClicked = 'Progress Bar Clicked',
  ScreenFilterIconClicked = 'Screen Filter Icon Clicked',
  ScreenFilterPopoverClosed = 'Screen Filter Popover Closed',
  FontSizeIconClicked = 'Font Size Icon Clicked',
  FontSizePopoverClosed = 'Font Size Popover Closed',
  InformationIconClicked = 'Information Icon Clicked',
  InformationPopoverClosed = 'Information Popover Closed',
}

import './wdyr'
import mixpanel from 'mixpanel-browser'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom'
import { MixpanelProvider } from 'react-mixpanel'
import { BrowserRouter } from 'react-router-dom'
import { ConfigureApplication } from '@project-minerva/core'
import { ThemeProvider, StyledEngineProvider, CSSBaseline } from '@project-minerva/design-system'
import { worker } from '@project-minerva/api-mock'
import { inspect } from '@xstate/inspect'
import App from './app/app'
import { environment } from './environments/environment'

if (window && environment.xstateInspect === true) {
  inspect({ iframe: false, url: 'https://stately.ai/viz?inspect' })
}

// Temporary while there's no api to connect
async function prepareMockApiWorker() {
  if (environment.mswEnabled) {
    await worker().start({ onUnhandledRequest: 'bypass' })
  }
}

prepareMockApiWorker().then(() =>
  ReactDOM.render(
    <StrictMode>
      <ConfigureApplication>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <BrowserRouter>
              <MixpanelProvider mixpanel={mixpanel}>
                <CSSBaseline />
                <App />
              </MixpanelProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </ConfigureApplication>
    </StrictMode>,
    document.getElementById('root')
  )
)

import { useEffect } from 'react'

export const useBrowserBackButton = (callback: () => void) => {
  useEffect(() => {
    window.onpopstate = callback

    return () => {
      window.onpopstate = null
    }
  })
}

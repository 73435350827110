export const AssessmentsIcon = (props: { color: string }) => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon-assesments</title>
    <g id="icon-assesments" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20,1 C21.8712714,1 23.221099,1.89100683 23.9229222,3.54121925 L23.9229222,3.54121925 L23.976,3.675 L25.5,3.67507057 C26.5543618,3.67507057 27.4181651,4.49094836 27.4945143,5.52580822 L27.4945143,5.52580822 L27.5,5.67507057 L27.5,6.687 L32.1875,6.6875 C33.1384653,6.6875 33.9183778,7.41986421 33.9939916,8.35134682 L34,8.5 L34,36.8291667 C34,37.8633791 33.2509482,38.7499511 32.2483482,38.8284117 L32.1034483,38.8340517 L7.89655172,38.8340517 C6.87092788,38.8340517 6.07525677,37.9944894 6.00504209,36.9758356 L6,36.8291667 L6,8.69238506 C6,7.65817258 6.74905185,6.77160064 7.75165184,6.69313999 L7.89655172,6.6875 L12.5,6.687 L12.5,5.67507057 C12.5,4.62070877 13.3158778,3.75690545 14.3507377,3.6805563 L14.5,3.67507057 L16.023,3.675 L16.0770778,3.54121925 C16.7496584,1.95976568 18.017368,1.07556863 19.7688161,1.00463113 L19.7688161,1.00463113 Z M32,8.687 L27.5,8.687 L27.5,9.82708333 C27.5,10.5818244 26.8969824,11.1400422 26.1861481,11.2031033 L26.0517241,11.2090517 L13.9482759,11.2090517 C13.1807504,11.2090517 12.5,10.6289957 12.5,9.82708333 L12.5,9.82708333 L12.5,8.687 L7.999,8.687 L7.999,36.834 L32.001,36.834 L32,8.687 Z M28.8534483,22.6153017 L28.8534483,24.6153017 L11.1465517,24.6153017 L11.1465517,22.6153017 L28.8534483,22.6153017 Z M28.8534483,18.5528017 L28.8534483,20.5528017 L11.1465517,20.5528017 L11.1465517,18.5528017 L28.8534483,18.5528017 Z M28.8534483,14.4903017 L28.8534483,16.4903017 L11.1465517,16.4903017 L11.1465517,14.4903017 L28.8534483,14.4903017 Z M20,3 C18.7554034,3 18.0621437,3.57062108 17.7192474,4.92115874 L17.7192474,4.92115874 L17.5278322,5.67507057 L14.5,5.67507057 L14.5,9.209 L25.5,9.209 L25.5,5.67507057 L22.4721678,5.67507057 L22.2807526,4.92115874 C21.9378563,3.57062108 21.2445966,3 20,3 Z"
        id="Combined-Shape"
        fill={props.color}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { DataTestId } from '@project-minerva/typings'

export const SecondaryIconButton = (props: IconButtonProps & DataTestId) => (
  <IconButton
    {...props}
    sx={{
      padding: (theme) => theme.spacing(1.25),
      backgroundColor: (theme) => theme.palette.secondary.main,
      borderRadius: (theme) => theme.spacing(1),
      height: '30px',
      width: '30px',
      '&:hover': {
        backgroundColor: (theme) => theme.palette.secondary.dark,
      },
      ...props.sx,
    }}
  />
)

/**
 * Keys are defined by Data team in the Mixpanel: Taxonomy
 *
 * https://docs.google.com/spreadsheets/d/1YH8SxgBD9cUVaxFyswZuBklTavOEB4vUrFOCUrKtHzM/edit#gid=0
 */

export enum MixpanelKeys {
  /** COMMON */
  // Page Viewed
  Tab = 'Tab',
  PageName = 'Page Name',
  App = 'App',

  /** ADMIN */

  /** ADMIN-CENTRE */

  /** ADMIN-SCHOOLS */

  /** GUARDIAN */

  /** CMS */

  /** EXAM and/or FAMILIARISATION APP */
  // Study Session
  Action = 'Action',
  AssessmentCode = 'Assessment Code',
  AssessmentId = 'Assessment Id',
  ApplicantId = 'Applicant Id',
  SchoolId = 'School Id',
  SchoolName = 'School Name',
  TestId = 'Test Id',
  TestName = 'Test Name',
  TestSubjectId = 'Test Subject Id',
  TestSubjectName = 'Test Subject Name',
  StudySessionId = 'Study Session Id',
  QuestionGroupId = 'Question Group Id',
  QuestionGroupName = 'Question Group Name',
  QuestionId = 'Question Id',
  QuestionType = 'Question Type',
  CompletedWithinTimeframe = 'Completed Within Timeframe',
  DocumentId = 'Document Id',
  DocumentName = 'Document Name',
  TimeBeforeStart = 'Time Before Start',

  // Pause-Resume
  PauseId = 'Pause Id',
  PauseStartTime = 'Pause Start Time',
  PauseEndTime = 'Pause End Time',
  ResumedFrom = 'Resumed From',
  ContentId = 'Content Id',
  ContentName = 'Content Name',
  ContentType = 'Content Type',

  // Screen Filter
  ScreenFilter = 'Screen Filter',
  PreviousFilter = 'Previous Filter',
  CurrentFilter = 'Current Filter',

  // Font Size
  FontSize = 'Font Size',
  PreviousFontSize = 'Previous Size',
  CurrentFontSize = 'Current Size',

  // Timer
  TestTime = 'Test Time',
  TestDuration = 'Test Duration',
  TimerProgress = 'Timer Progress',
  TimerAction = 'Timer Action',

  // ProgressBar
  ProgressBarValue = 'Progress Value',
  ProgressBarAction = 'Progress Bar Action',

  // Popover
  OpenDuration = 'Open Duration',
}

// import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const AccountSwitcherIcon = (props: { color: string }) => (
  <svg width="39" height="36" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0032 2C7.99595 2 6.30757 3.69764 6.30757 5.86863C6.30757 8.03962 7.99595 9.73726 10.0032 9.73726C12.0105 9.73726 13.6989 8.03962 13.6989 5.86863C13.6989 3.69764 12.0105 2 10.0032 2ZM4.30757 5.86863C4.30757 2.66188 6.82381 0 10.0032 0C13.1826 0 15.6989 2.66188 15.6989 5.86863C15.6989 9.07538 13.1826 11.7373 10.0032 11.7373C6.82381 11.7373 4.30757 9.07538 4.30757 5.86863Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5 8C23.7541 8 21.5 10.2677 21.5 13.1C21.5 15.9323 23.7541 18.2 26.5 18.2C29.2459 18.2 31.5 15.9323 31.5 13.1C31.5 10.2677 29.2459 8 26.5 8ZM19.5 13.1C19.5 9.19442 22.6185 6 26.5 6C30.3815 6 33.5 9.19442 33.5 13.1C33.5 17.0056 30.3815 20.2 26.5 20.2C22.6185 20.2 19.5 17.0056 19.5 13.1Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9342 35.1142C20.9799 35.5813 23.6159 35.9999 26.5 35.9999C30.5429 35.9999 33.5081 35.4628 35.4829 34.9133C36.4698 34.6387 37.2079 34.3614 37.7081 34.1475C37.9581 34.0406 38.1486 33.9496 38.2809 33.8828C38.3471 33.8494 38.3987 33.822 38.4359 33.8018C38.4545 33.7916 38.4695 33.7833 38.4809 33.7768L38.4954 33.7686L38.5006 33.7656L38.5045 33.7633L39 33.4738V32.8999C39 26.8742 33.3405 21.3999 26.5 21.3999C22.8053 21.3999 19.4551 22.9969 17.1551 25.4153C17.616 25.5453 18.0504 25.793 18.4107 26.1582L18.8206 26.5737C20.7272 24.6744 23.4477 23.3999 26.5 23.3999C32.1116 23.3999 36.6015 27.7077 36.9749 32.2857L36.9218 32.3085C36.5011 32.4884 35.8466 32.7361 34.9468 32.9865C33.1482 33.487 30.3634 33.9999 26.5 33.9999C24.3562 33.9999 22.349 33.7521 20.6428 33.4284L18.9342 35.1142ZM38 32.8999C38.5045 33.7633 38.5045 33.7633 38.5045 33.7633L38 32.8999Z"
      fill={props.color}
    />
    <path
      d="M0 22.3388V21.6717C0 16.7565 4.5212 12.2913 10 12.2913C14.8365 12.2913 18.9267 15.7709 19.8193 19.968C19.1779 20.2823 18.5589 20.6391 17.9658 21.0341C17.5924 17.5411 14.2072 14.2913 10 14.2913C5.81047 14.2913 2.43599 17.5139 2.03907 20.9902C2.47256 21.1396 3.10364 21.3402 3.8852 21.5413C4.57235 21.7181 5.37186 21.8942 6.2525 22.0366C6.15831 22.6996 6.12471 23.3697 6.15148 24.0453C5.11318 23.886 4.17792 23.6817 3.38688 23.4782C2.54316 23.2611 1.85831 23.0435 1.38111 22.8789C1.14238 22.7965 0.955242 22.7273 0.825772 22.6778C0.761026 22.653 0.710666 22.6332 0.67545 22.6191L0.634066 22.6024L0.622061 22.5975L0.618261 22.596L0.616915 22.5954C0.588834 22.5837 0.622483 22.5977 0.630574 22.6011C0.644502 22.6069 0.582703 22.5812 0 22.3388Z"
      fill={props.color}
    />
    <path
      d="M17.8688 21.0993C17.5525 21.2281 17.1136 21.387 16.5473 21.5477C15.4809 21.8504 13.9586 22.1606 11.9473 22.2878C11.805 22.9491 11.7782 23.6153 11.867 24.2962C12.8765 24.2391 13.7811 24.1385 14.584 24.013C15.5472 22.9057 16.6526 21.9218 17.8688 21.0993Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0117 29.7691C13.1018 29.4339 12.314 28.929 11.6484 28.2544C10.712 27.3053 10.1351 26.1512 9.91799 24.792C9.90915 24.7367 9.90088 24.6814 9.89318 24.6262C9.76906 23.7348 9.79384 22.8538 9.96763 21.9836C10.0136 21.7536 10.0699 21.5244 10.1367 21.2958C10.1474 21.2589 10.156 21.2216 10.1622 21.1841C10.2091 20.9035 10.1296 20.613 9.92741 20.4081C9.51531 19.9905 8.81377 20.1102 8.62626 20.6661C8.60694 20.7234 8.5882 20.7808 8.57004 20.8382C8.48688 21.1012 8.41584 21.3656 8.35693 21.6316C8.12482 22.6796 8.08094 23.7511 8.2253 24.8461C8.23467 24.917 8.24477 24.9876 8.25562 25.0577C8.52473 26.7963 9.24889 28.2633 10.4281 29.4584C11.3084 30.3506 12.3232 30.9976 13.4727 31.3994C13.5337 31.4208 13.5949 31.4415 13.6561 31.4616C13.915 31.5466 14.1757 31.6204 14.4383 31.6829C15.0988 31.8402 15.7709 31.9264 16.4545 31.9413C16.6448 31.9455 16.836 31.9441 17.0281 31.9373L15.4926 33.4523C15.3756 33.5678 15.3016 33.7104 15.2709 33.8601C15.219 34.1129 15.2901 34.3862 15.485 34.5837C15.7953 34.8982 16.3018 34.9016 16.6163 34.5913L19.6061 31.6414C19.9992 31.2535 20.0034 30.6204 19.6156 30.2272L16.9868 27.5629C16.6543 27.226 16.1116 27.2223 15.7746 27.5548C15.6136 27.7137 15.5286 27.9207 15.5202 28.1302C15.511 28.3591 15.5929 28.591 15.7665 28.7669L17.2575 30.2781C17.1611 30.2817 17.0649 30.2836 16.969 30.2841C16.9333 30.2842 16.8975 30.2841 16.8618 30.2839C16.0816 30.2776 15.3168 30.1664 14.5672 29.9502C14.4453 29.915 14.3238 29.8771 14.2026 29.8363C14.1479 29.8179 14.0936 29.7989 14.0398 29.7794C14.0306 29.776 14.0208 29.7725 14.0117 29.7691Z"
      fill={props.color}
    />
  </svg>
)

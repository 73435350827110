import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'

interface TableFiltersContextType {
  filterEal: boolean
  filterSend: boolean
  searchQuery: string
  pageToken: string
  pageNumber: number
  hasRefreshedToken: boolean
  setFilterEal: Dispatch<SetStateAction<boolean>>
  setFilterSend: Dispatch<SetStateAction<boolean>>
  setSearchQuery: Dispatch<SetStateAction<string>>
  setPageToken: Dispatch<SetStateAction<string>>
  setPageNumber: Dispatch<SetStateAction<number>>
  setHasRefreshedToken: Dispatch<SetStateAction<boolean>>
}

export const TableFiltersContext = createContext<TableFiltersContextType>({
  filterEal: false,
  filterSend: false,
  searchQuery: '',
  pageToken: '',
  pageNumber: 0,
  hasRefreshedToken: false,
  setFilterEal: () => null,
  setFilterSend: () => null,
  setSearchQuery: () => null,
  setPageToken: () => null,
  setPageNumber: () => null,
  setHasRefreshedToken: () => null,
})

export const useTableFilters = () => {
  const context = useContext(TableFiltersContext)

  if (!context) {
    throw new Error('useTableFilters must be called inside a TableFiltersProvider')
  }

  return context
}

export const TableFiltersProvider = ({ children }: { children: ReactNode }) => {
  const [filterEal, setFilterEal] = useState(false)
  const [filterSend, setFilterSend] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [pageToken, setPageToken] = useState('')
  const [pageNumber, setPageNumber] = useState(0)
  const [hasRefreshedToken, setHasRefreshedToken] = useState(false)

  return (
    <TableFiltersContext.Provider
      value={{
        filterEal,
        filterSend,
        searchQuery,
        pageToken,
        pageNumber,
        hasRefreshedToken,
        setFilterEal,
        setFilterSend,
        setSearchQuery,
        setPageToken,
        setPageNumber,
        setHasRefreshedToken,
      }}
    >
      {children}
    </TableFiltersContext.Provider>
  )
}

import { getAssessments, initialiseTest, validateCode, validateDOB } from './assessments'
import { getFamiliarisationAssessment, getSubjects, getTest } from './familiarisation'
import { applicantsList, guardianSignUp } from './guardian'

const handlers = [
  // assessment endpoints
  getAssessments,
  initialiseTest,
  validateCode,
  validateDOB,
  // familiarisation endpoints
  getSubjects,
  getTest,
  getFamiliarisationAssessment,
  // guardian endpoints
  guardianSignUp,
  applicantsList,
]

export default handlers

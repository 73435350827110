import { addHours, format, isAfter, isEqual, parse, set } from 'date-fns'

export const dateTimeIsTwentyFourHoursInFuture = (date: Date | undefined, time: Date) => {
  if (!date) return false
  const minEnd = addHours(new Date(), 24)
  const end = set(date, { hours: time.getHours(), minutes: time.getMinutes(), seconds: 0, milliseconds: 0 })
  return isAfter(end, minEnd) || isEqual(end, minEnd)
}

export const getTimezonedyyyymmddDate = (date: string) => {
  return parse(date, 'yyyy-MM-dd', new Date())
}

export const padDurationValue = (num: number | undefined) => {
  return num !== undefined && num < 10 ? `0${num}` : `${num}`
}

export const formatFullDate = (date: string) => format(new Date(date), 'd LLLL Y')

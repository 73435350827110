import { DataTestId } from '@project-minerva/typings'
import { Content } from '@ctek/test-player'
import { HtmlRender } from './html-renderer/html-renderer'
import { Base64ImageViewer } from './base-64-image-viewer'
import { Box } from '@project-minerva/design-system'
import { forwardRef } from 'react'

interface ContentRendererProps extends DataTestId {
  content: Content
}

export const ContentRenderer = forwardRef((props: ContentRendererProps, ref) => {
  const { content, 'data-testid': dataTestId } = props

  switch (content.type) {
    case 'base64_image':
      return (
        <Box>
          <Base64ImageViewer url={content.data} data-testid={dataTestId} />
        </Box>
      )
    case 'stringified_html':
    case 'string':
    default:
      // overflow hidden required to stop answerboxes from breaking with long content
      return (
        <Box ref={ref} sx={{ overflow: 'hidden' }}>
          <HtmlRender html={content.data} data-testid={dataTestId} />
        </Box>
      )
  }
})

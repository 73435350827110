import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded'
import Stack from '@mui/material/Stack'
import { Box, TertiaryButton, Tooltip, Typography } from '@project-minerva/design-system'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

interface Props {
  timeLeftSeconds: number
  track?: (opened: boolean) => void
}

const MIN_2_SEC = 60

export const formatTime = (timeInSec: number) => {
  const minutes = Math.floor(timeInSec / MIN_2_SEC)
  const seconds = timeInSec - minutes * MIN_2_SEC

  return `${minutes.toLocaleString('en-US', { minimumIntegerDigits: 2 })}:${seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })}`
}

export const Timer = (props: Props) => {
  const { timeLeftSeconds, track } = props
  const [isOpen, setIsOpen] = useState(true)
  const toggle = () => {
    track?.(isOpen)
    setIsOpen((open) => !open)
  }

  return (
    <Tooltip title={<FormattedMessage id="timer" defaultMessage="Timer" />} placement="bottom">
      <Box>
        <TertiaryButton
          sx={{
            padding: (theme) => (isOpen ? theme.spacing(0, 1) : 0),
            height: '2.2rem',
            width: isOpen ? 'auto' : '2.2rem',
            minWidth: '2.2rem',
            display: 'flex',
            justifyContent: isOpen ? 'flex-start' : 'center',
          }}
          onClick={toggle}
          disableRipple
          disableElevation
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <AccessTimeRoundedIcon />
            {isOpen && (
              <Typography variant="body1" sx={{ fontSize: '.875rem', fontVariantNumeric: 'tabular-nums' }}>
                {formatTime(timeLeftSeconds)}
              </Typography>
            )}
          </Stack>
        </TertiaryButton>
      </Box>
    </Tooltip>
  )
}

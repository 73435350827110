import { AssessmentData, TestData } from '@project-minerva/typings'
import { createContext, ReactNode, useContext, useEffect } from 'react'
import { useStudentsExams } from '../api/examination.api'
import { logrocketIdentify } from '@project-minerva/core'
import { useLocalSettings } from '@project-minerva/assessment-player'

interface ExaminationDataContext {
  error: Error | undefined
  assessment: AssessmentData | undefined
  loading: boolean
  findTestById: (testId: string) => TestData | undefined
  reload: () => void
}

const ExaminationDataContext = createContext<ExaminationDataContext>({
  error: undefined,
  assessment: undefined,
  loading: false,
  findTestById: (testId: string) => undefined,
  reload: () => {
    return
  },
})

export const useExaminationData = () => {
  const context = useContext(ExaminationDataContext)
  if (!context) throw new Error('useExaminationData must be called inside a ExaminationDataProvider')
  return context
}

export function ExaminationDataProvider(props: { children: ReactNode }) {
  const { error, data, loading, reload } = useStudentsExams()
  const findTestById = (testId: string) => data?.tests.find((test) => test.id === testId)
  const { setUid } = useLocalSettings()

  useEffect(() => {
    if (data?.applicantId) {
      logrocketIdentify(data.applicantId, 'exam')
      setUid(data.applicantId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.applicantId])

  return (
    <ExaminationDataContext.Provider value={{ error, loading, assessment: data, findTestById, reload }}>
      {props.children}
    </ExaminationDataContext.Provider>
  )
}

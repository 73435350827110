import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import { LinearProgress, linearProgressClasses, Stack } from '@mui/material'
import { Box, TertiaryButton, Tooltip } from '@project-minerva/design-system'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useTestProgress } from './test-progress.provider'

export const ProgressBar = ({ track }: { track?: (opened: boolean) => void }) => {
  const { progress } = useTestProgress()
  const [isOpen, setIsOpen] = useState(true)
  const toggle = () => {
    track?.(isOpen)
    setIsOpen((open) => !open)
  }

  return (
    <Tooltip title={<FormattedMessage id="progress-bar" defaultMessage="Progress Bar" />} placement="bottom">
      <Box>
        <TertiaryButton
          sx={{
            padding: (theme) => (isOpen ? theme.spacing(0, 1) : 0),
            height: '2.2rem',
            width: isOpen ? '9.625rem' : '2.2rem',
            minWidth: '2.2rem',
            display: 'flex',
            justifyContent: isOpen ? 'flex-start' : 'center',
          }}
          onClick={toggle}
          disableRipple
          disableElevation
        >
          <Stack spacing={1} direction="row" alignItems="center">
            <FlagOutlinedIcon />
            {isOpen && (
              <LinearProgress
                variant="determinate"
                sx={{
                  width: '6.375rem',
                  borderRadius: 1,
                  height: '.5rem',
                  backgroundColor: 'white',
                  border: `1px solid white`,
                  outline: `1px solid rgba(130, 80, 232, 0.1)`,
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 1,
                    background: 'linear-gradient(90deg, #B890FF 0%, #7AC4EE 50%, #84DDBF 100%)',
                  },
                }}
                color="success"
                value={progress}
              />
            )}
          </Stack>
        </TertiaryButton>
      </Box>
    </Tooltip>
  )
}

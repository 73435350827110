import { ApplicantPersonalInfo, GuardianApplicant } from '@project-minerva/typings'
import { isWithinInterval } from 'date-fns'

export function personalInfoHasChanged(currentInfo: GuardianApplicant, originalInfo: ApplicantPersonalInfo) {
  return (
    currentInfo.firstName !== originalInfo.firstName ||
    currentInfo.middleName !== originalInfo.middleName ||
    currentInfo.lastName !== originalInfo.lastName ||
    currentInfo.dateOfBirth !== originalInfo.dateOfBirth ||
    currentInfo.gender !== originalInfo.gender ||
    currentInfo.guardianRelation !== originalInfo.guardianRelation ||
    currentInfo.currentSchool !== originalInfo.currentSchool
  )
}

export function applicantIsInCurrentYear(applicant: GuardianApplicant) {
  return isWithinInterval(new Date(), {
    start: new Date(applicant.academicYear.startDate),
    end: new Date(applicant.academicYear.endDate),
  })
}

import { Box, Typography } from '@project-minerva/design-system'

interface Props {
  name: string | undefined
  address?: string | undefined
  email?: string
  dataTestId?: string
}

export function InvigilationCentreInfoCard(props: Props) {
  const { dataTestId, name, address, email } = props
  return (
    <Box data-testid={dataTestId}>
      <Typography variant="body1">{name}</Typography>
      {address && <Typography variant="subtitle1">{address}</Typography>}
      {email && (
        <Typography sx={{ mt: (theme) => theme.spacing(1), wordBreak: 'break-word' }} variant="subtitle1">
          {email}
        </Typography>
      )}
    </Box>
  )
}

import { GuardianApplicant } from '@project-minerva/typings'
import { createContext, ReactNode, useContext } from 'react'
import { PageError, PageLoader } from '../page'

type ApplicantsContextData = {
  data: {
    countCurrent: number
    currentApplicants: GuardianApplicant[]
    countPast: number
    pastApplicants: GuardianApplicant[]
  }
  load: () => void
}

const defaultContextValue: ApplicantsContextData = {
  data: {
    countCurrent: 0,
    currentApplicants: [],
    countPast: 0,
    pastApplicants: [],
  },
  load: () => undefined,
}

const ApplicantsContext = createContext<ApplicantsContextData>(defaultContextValue)

export const useApplicants = () => useContext(ApplicantsContext)

export const ApplicantsProvider = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { children, useFetchApplicants }: { children: ReactNode; useFetchApplicants: any }
) => {
  const {
    data: currentApplicantsData,
    error: errorCurrent,
    loading: loadingCurrent,
    load: loadCurrent,
  } = useFetchApplicants('current')
  const {
    data: pastApplicantsData,
    error: errorPast,
    loading: loadingPast,
    load: loadPast,
  } = useFetchApplicants('past')

  const load = () => {
    loadCurrent()
    loadPast()
  }

  const data =
    currentApplicantsData && pastApplicantsData
      ? {
          countCurrent: currentApplicantsData.count,
          currentApplicants: currentApplicantsData.data,
          countPast: pastApplicantsData.count,
          pastApplicants: pastApplicantsData.data,
        }
      : { countCurrent: 0, currentApplicants: [], countPast: 0, pastApplicants: [] }

  if (loadingCurrent || loadingPast) return <PageLoader />
  if (errorCurrent || errorPast) return <PageError error={errorCurrent ?? errorPast} />

  return <ApplicantsContext.Provider value={{ data, load }}>{children}</ApplicantsContext.Provider>
}

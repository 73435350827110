import { getApiUrl, request, useRequest } from '@project-minerva/api'
import { useAuthContext } from '@project-minerva/auth-cognito'
import { User } from '@project-minerva/typings'

const baseUrl = `${getApiUrl('helios', '/v1')}/users/me`

export const useGetProfile = () => {
  const { accessToken } = useAuthContext()
  return useRequest<{ user: User }>(baseUrl, { method: 'GET' }, accessToken.current)
}

export const editProfile = (data: { user: Partial<User> }, token: string) => {
  const body = JSON.stringify(data)
  return request<{ user: User }>(baseUrl, { method: 'PATCH', body }, token)
}

export const changeEmail = (newEmail: string, token: string) => {
  const url = `${baseUrl}/email`
  const body = JSON.stringify({ newEmail: newEmail })
  return request<{ user: User }>(url, { method: 'PUT', body }, token)
}

import { alpha, IconButton, makeStyles, ScreenFilters, ThemeType, Typography } from '@project-minerva/design-system'
import { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { useScreenFilter } from './screen-filter-provider'
import { HeaderWidget } from '../header-widget'
import { HeaderWidgetTooltip } from '../../header-widget-tooltip'
import { useAnchorElement } from '@project-minerva/core'
import { WaterDropIcon } from '@project-minerva/icons'

const useStyles = makeStyles((theme: ThemeType) => ({
  colorCircle: {
    borderRadius: '50%',
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
  },
  popoverContainer: {
    padding: theme.spacing(3, 2),
  },
  popoverText: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  applyButton: {
    margin: theme.spacing(2),
    float: 'right',
    marginRight: 0,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.secondary.main,
  },
  colorCircleButton: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.25),
    border: '2px solid transparent',
  },
  colorCircleSelected: {
    borderColor: theme.palette.primary.dark,
    borderStyle: 'solid',
  },
}))

interface Props {
  trackIconClicked?: () => void
  trackPopoverClosed?: (duration: number) => void
  trackScreenFilterChanged?: (previousFilter: string | null, currentFilter: string | null) => void
}

export const ScreenFilter = ({ trackIconClicked, trackPopoverClosed, trackScreenFilterChanged }: Props) => {
  const { screenFilter, setScreenFilter } = useScreenFilter()
  const changeScreenFilter = (newFilter: string | null) => {
    if (newFilter === screenFilter) return

    trackScreenFilterChanged?.(screenFilter, newFilter)
    setScreenFilter(newFilter)
  }
  const popoverOpenTime = useRef(0)
  const setAnchorElement = (el?: HTMLButtonElement) => {
    if (el) {
      trackIconClicked?.()
      popoverOpenTime.current = Date.now()
    } else {
      trackPopoverClosed?.(Date.now() - popoverOpenTime.current)
    }
  }
  const anchor = useAnchorElement(setAnchorElement)
  const styles = useStyles()

  return (
    <HeaderWidget {...anchor} buttonIcon={<WaterDropIcon />} tooltip={<FormattedMessage id="screen-filter" />}>
      <HeaderWidgetTooltip className={styles.popoverContainer}>
        <Typography data-testid="screen-filter-prompt" className={styles.popoverText} variant="body1">
          <FormattedMessage id="screen-filter-prompt" />
        </Typography>
        <div>
          <IconButton
            className={clsx(styles.colorCircleButton, {
              [styles.colorCircleSelected]: !screenFilter,
            })}
            aria-describedby="screen-filter-none"
            data-testid="screen-filter-color-none"
            color="primary"
            onClick={() => changeScreenFilter(null)}
            size="large"
          >
            <div className={styles.colorCircle} style={{ backgroundColor: 'white', border: `1px solid gray` }} />
          </IconButton>
          {Object.keys(ScreenFilters).map((filterKey) => {
            const { color, opacity } = ScreenFilters[filterKey]
            return (
              <IconButton
                aria-describedby={`screen-filter-color-${filterKey}`}
                data-testid={`screen-filter-color-${filterKey}`}
                key={filterKey}
                color="primary"
                onClick={() => changeScreenFilter(filterKey)}
                className={clsx(styles.colorCircleButton, {
                  [styles.colorCircleSelected]: screenFilter === filterKey,
                })}
                size="large"
              >
                <div
                  className={styles.colorCircle}
                  style={{ backgroundColor: alpha(color, opacity), border: `1px solid ${color}` }}
                />
              </IconButton>
            )
          })}
        </div>
      </HeaderWidgetTooltip>
    </HeaderWidget>
  )
}

import { FormControlLabel, styled } from '@mui/material'

const RadioControlLabel = styled(FormControlLabel)<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  borderRadius: '6px',
  border: '2px solid #C8C8C8',
  backgroundColor: '#F9F9F9',
  width: '100px',
  height: '40px',
  ...(ownerState.active && {
    borderColor: theme.palette.primary.dark,
  }),
}))

export default RadioControlLabel

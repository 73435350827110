import { LoaderHook } from '@project-minerva/api'
import { Alert, Box, Button, Chip, CircularProgress, Typography } from '@project-minerva/design-system'
import { ApplicantTest } from '@project-minerva/typings'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { TestStatusChip2 } from '../chip'
import { ErrorMessage } from '../error'
import { PaperPanel } from '../panel'

export const ApplicationTestsWidget = ({
  controls,
  controlsProps,
  infoMessage,
  tests,
  includeChips,
  onViewDeclarationClick,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls?: React.JSXElementConstructor<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controlsProps?: any
  infoMessage?: JSX.Element
  tests: LoaderHook<{ count: number; tests: ApplicantTest[] }>
  includeChips?: boolean
  onViewDeclarationClick: (test: ApplicantTest) => void
}) => (
  <PaperPanel>
    <Typography variant="h5" sx={{ mb: (theme) => theme.spacing(1) }}>
      <FormattedMessage id="tests" defaultMessage="Tests" />
    </Typography>
    {infoMessage && (
      <Alert severity="info" sx={{ mb: 1 }}>
        {infoMessage}
      </Alert>
    )}
    {tests.loading && <CircularProgress />}
    {tests.error && <ErrorMessage message={tests.error.message} />}
    {tests.data?.tests
      .sort((a, b) => (a.subjectName < b.subjectName ? -1 : 1))
      .map((test) => (
        <TestListItem
          controls={controls}
          controlsProps={controlsProps}
          test={test}
          key={test.subjectId}
          includeChip={includeChips}
          onViewDeclarationClick={onViewDeclarationClick}
        />
      ))}
  </PaperPanel>
)

const TestListItem = ({
  controls: Controls,
  controlsProps,
  test,
  includeChip,
  onViewDeclarationClick,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controls?: React.JSXElementConstructor<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  controlsProps?: any
  test: ApplicantTest
  includeChip?: boolean
  onViewDeclarationClick: (test: ApplicantTest) => void
}) => (
  <Box
    p={2}
    mb={1}
    borderRadius={1}
    sx={{ backgroundColor: test.testSession?.isClosed ? 'rgba(105, 186, 146, 0.2)' : 'rgba(174, 179, 183, 0.2)' }}
  >
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body1">
          <FormattedMessage id={`subject-${test.subjectName}`} />
        </Typography>
        {Controls && <Controls test={test} {...controlsProps} />}
      </Box>
      {includeChip && !test.testSession?.isClosed ? (
        <Box mt={1}>
          <TestStatusChip2 test={test} />
        </Box>
      ) : null}
      {test.testSession?.isClosed && (
        <>
          <Chip
            size="small"
            sx={{ backgroundColor: 'rgba(105, 186, 146, 1)', color: 'white', mt: 1 }}
            label={
              <FormattedMessage
                id="test-result-completed-on-chip"
                defaultMessage="Completed on {date}"
                values={{ date: format(new Date(test.testSession.endTime), 'dd MMMM yyyy') }}
              />
            }
          />
          <Box>
            {test.testDeclaration ? (
              <Button
                variant="text"
                sx={{ color: (theme) => theme.palette.success.dark, margin: 'auto', mt: 1 }}
                startIcon={<AssignmentOutlinedIcon />}
                onClick={() => onViewDeclarationClick(test)}
              >
                <Typography variant="subtitle2" fontWeight={600}>
                  <FormattedMessage id="view-end-of-test-declaration" defaultMessage="View end of test declaration" />
                </Typography>
              </Button>
            ) : (
              <Box sx={{ display: 'flex', mt: 1, color: (theme) => theme.palette.warning.main, p: 1 }}>
                {/* margins to match icons in a button */}
                <AssignmentOutlinedIcon sx={{ mr: 1, ml: '-4px' }} fontSize="small" />
                <Typography variant="subtitle2" fontWeight={600}>
                  <FormattedMessage id="awaiting-declaration" defaultMessage="Awaiting declaration" />
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  </Box>
)

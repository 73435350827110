import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
  },
  footerRight: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'right',
    alignItems: 'center',
  },
  footerLeft: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  footerCenter: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export const Footer = (props: { children: React.ReactNode }) => {
  const styles = useStyles()

  return <footer className={styles.footer}>{props.children} </footer>
}

export const FooterLeft = (props: { children: React.ReactNode }) => {
  const styles = useStyles()

  return <div className={styles.footerLeft}>{props.children} </div>
}

export const FooterRight = (props: { children: React.ReactNode }) => {
  const styles = useStyles()

  return <div className={styles.footerRight}>{props.children} </div>
}

export const FooterCenter = (props: { children: React.ReactNode }) => {
  const styles = useStyles()

  return <div className={styles.footerCenter}>{props.children} </div>
}

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { Box, Typography } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'

export const FormError = ({ error }: { error: string }) => {
  return (
    <Box
      sx={{
        p: (theme) => theme.spacing(2),
        background: (theme) => theme.palette.warning.light,
        borderRadius: 1,
        opacity: 0.7,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        mb: (theme) => theme.spacing(1),
      }}
    >
      <ReportProblemOutlinedIcon fontSize="large" />
      <Typography
        component="p"
        variant="body2"
        sx={{ opacity: 1, fontWeight: 'bold', ml: (theme) => theme.spacing(2) }}
      >
        <FormattedMessage id={error} />
      </Typography>
    </Box>
  )
}

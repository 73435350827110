import { createContext, ReactNode, useContext, useState } from 'react'
import { FontSize, FontSizeLabel, FONT_SIZE_REGULAR, SIZE_MAPPER } from './font-size-changer'

const FontSizeContext = createContext({
  fontSize: 16 as FontSize,
  fontSizeLabel: 'regular' as FontSizeLabel,
  setFontSize: (size: FontSize) => {
    return
  },
  setFontSizeLabel: (sizeLabel: FontSizeLabel) => {
    return
  },
  resetFontSize: () => {
    return
  },
})

export const useFontSize = () => {
  const context = useContext(FontSizeContext)

  if (!context) {
    throw new Error('useFontSize must be called inside a FontSizeProvider')
  }

  return context
}

export const FontSizeProvider = (props: { children: ReactNode }) => {
  const [fontSize, setFontSize] = useState<FontSize>(FONT_SIZE_REGULAR)
  const [fontSizeLabel, setFontSizeLabel] = useState<FontSizeLabel>('regular')
  const setSize = (size: FontSize) => {
    if (size === fontSize) return
    setFontSize(size)
    setFontSizeLabel(SIZE_MAPPER[size])
    changeDocumentFontSize(size)
  }
  const setSizeLabel = (sizeLabel: FontSizeLabel) => {
    if (sizeLabel === fontSizeLabel) return
    const size = SIZE_MAPPER[sizeLabel]
    setFontSizeLabel(sizeLabel)
    setFontSize(size)
    changeDocumentFontSize(size)
  }

  const resetFontSize = () => {
    changeDocumentFontSize(FONT_SIZE_REGULAR)
  }

  return (
    <FontSizeContext.Provider
      value={{ fontSize, setFontSize: setSize, setFontSizeLabel: setSizeLabel, fontSizeLabel, resetFontSize }}
    >
      {props.children}
    </FontSizeContext.Provider>
  )
}

const changeDocumentFontSize = (newSize: number) => {
  const htmlTag = document.querySelector('html')
  if (htmlTag) {
    htmlTag.style.fontSize = `${newSize}px`
  }
}

import { getApiUrl } from '@project-minerva/api'
import { rest } from 'msw'

export const validateCode = rest.get(getApiUrl('atlas', '/v1/assessment/token/:code'), (req, res, ctx) => {
  // token: { code: 'd2cd3bcf-2f27-489e-ba39-0223f1042259' }
  return res(ctx.status(200), ctx.json({ token: 'eyJjb2RlIjoiZDJjZDNiY2YtMmYyNy00ODllLWJhMzktMDIyM2YxMDQyMjU5In0=' }))
})

export const validateDOB = rest.get(getApiUrl('atlas', '/v1/assessment/dob/:dob/validate'), (req, res, ctx) => {
  // token: { code: 'd2cd3bcf-2f27-489e-ba39-0223f1042259', dob: '2000-01-01' }
  return res(
    ctx.status(200),
    ctx.json({ token: 'eyJjb2RlIjoiZDJjZDNiY2YtMmYyNy00ODllLWJhMzktMDIyM2YxMDQyMjU5IiwiZG9iIjoiMjAwMC0wMS0wMSJ9' })
  )
})

export const getAssessments = rest.get(getApiUrl('atlas', '/v1/assessment'), (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      tests: [
        {
          isClosed: false,
          id: '101',
          name: 'Test 1',
          subjectId: '591b5d4f-49cf-4c0e-918a-08c135ecf50b',
          timeLimitSeconds: 7200,
          isPublished: true,
        },
        {
          isClosed: true,
          id: '102',
          name: 'Test 2',
          subjectId: 'c85517c4-ac6d-4896-a84d-85fca3eeca93',
          timeLimitSeconds: 7200,
          isPublished: true,
        },
        {
          isClosed: false,
          id: '103',
          name: 'Test 3',
          subjectId: 'd2fb0442-7ad3-4ac8-9b08-86bd3efa5aab',
          timeLimitSeconds: 7200,
          isPublished: true,
        },
        {
          isClosed: false,
          id: '104',
          name: 'Test 4',
          subjectId: 'dcd65261-552f-4e0a-a8d4-846dc8db99dd',
          timeLimitSeconds: 7200,
          isPublished: true,
        },
        {
          isClosed: false,
          id: '105',
          name: 'Test 5',
          subjectId: '8346c5c6-0f71-4015-a8a3-8e2e47772d6f',
          timeLimitSeconds: 7200,
          isPublished: true,
        },
        {
          isClosed: false,
          id: '106',
          name: 'Test 6',
          subjectId: 'c85517c4-ac6d-4896-a84d-85fca3eeca93',
          timeLimitSeconds: 7200,
          isPublished: true,
        },
        {
          isClosed: false,
          id: '107',
          name: 'Test 7',
          subjectId: '7bfb36da-03a1-42c5-b328-a1d3c4a555c6',
          timeLimitSeconds: 7200,
          isPublished: true,
        },
      ],
      assessmentId: '12345',
      name: 'Cambridge Assessment',
      startTime: '2021-10-14T08:13:59.551Z',
      endTime: '2021-10-16T08:13:59.551Z',
      applicantId: '098765432',
      schoolId: '7890',
    })
  )
})

export const initialiseTest = rest.post(getApiUrl('atlas', '/v1/assessment/initialise'), (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      token: 'eyJjb2RlIjoiZDJjZDNiY2YtMmYyNy00ODllLWJhMzktMDIyM2YxMDQyMjU5IiwiZG9iIjoiMjAwMC0wMS0wMSJ9',
      studySessionId: '611a6e1317ce160db0d3a96b',
      startTime: '2021-08-16T13:54:27.738Z',
      id: '67f9b3bc-aa9a-430c-a7f2-389033807a1b',
      subjectId: 'd2fb0442-7ad3-4ac8-9b08-86bd3efa5aab',
      name: 'Familiarisation ISEB Maths Questions',
      timeLimitSeconds: 3600,
      isPublished: false,
      testContent: [
        {
          id: '882a0f13-ca54-4d60-9eba-33c7523b1a3e',
          answers: [
            {
              id: 'cda14941-3d3a-4e73-8b88-ae680f718fff',
              content: {
                data: '100,001',
                type: 'string',
              },
            },
            {
              id: '4f82f812-f08d-4e88-bf91-35877c178f7a',
              content: {
                data: '10,000',
                type: 'string',
              },
            },
            {
              id: 'ca7796c4-e38d-481a-a4c2-5c41e702157e',
              content: {
                data: '10,100',
                type: 'string',
              },
            },
            {
              id: '912344bb-5180-4a7c-9806-e745d1938f20',
              content: {
                data: '100,010',
                type: 'string',
              },
            },
            {
              id: '6c4f167a-3bd6-45d0-9db6-a83bd4fca0d4',
              content: {
                data: '100,000',
                type: 'string',
              },
            },
          ],
          content: {
            data: '<p>Work out 12,345 + 87,655</p>',
            type: 'stringified_html',
          },
          completed: false,
          questionGroupId: 'a71d799b-da33-4dd1-a479-a892f039e2c6',
          points: 0,
          type: 'question',
        },
        {
          id: '83c2ae2a-e171-4472-9a40-f010399cf7ad',
          answers: [
            {
              id: '67bc7727-de25-4dd8-b7fd-757858a9cc18',
              content: {
                data: '195',
                type: 'string',
              },
            },
            {
              id: '81b2187d-2b6e-47be-be10-1d43c3951d4e',
              content: {
                data: '255',
                type: 'string',
              },
            },
            {
              id: 'd4746f8a-adaf-4e95-8108-ca21dcf4ff88',
              content: {
                data: '75',
                type: 'string',
              },
            },
            {
              id: '7d893c74-3952-4436-b27d-7d8cfca03e83',
              content: {
                data: '325',
                type: 'string',
              },
            },
            {
              id: 'f1054c9a-e083-4dd9-b782-bfd670f43fd6',
              content: {
                data: '105',
                type: 'string',
              },
            },
          ],
          content: {
            data: '<p>Which of the following numbers is not a multiple of 15?<br>&nbsp;</p>',
            type: 'stringified_html',
          },
          completed: false,
          questionGroupId: '976f35f8-b700-4b46-915f-3547150305e4',
          points: 0,
          type: 'question',
        },
        {
          id: '0a3227db-0fed-4ccc-8302-bb647236f16d',
          answers: [
            {
              id: 'e8a1eb84-65cc-41e0-a546-15754bba55ee',
              content: {
                data: '48 km per hour ',
                type: 'string',
              },
            },
            {
              id: 'cb936563-db1e-4db9-b0af-487414a44b2b',
              content: {
                data: '20 km per hour',
                type: 'string',
              },
            },
            {
              id: '81df28d5-1a47-4803-9c93-d9fe6986ad18',
              content: {
                data: '24 km per hour',
                type: 'string',
              },
            },
            {
              id: '0598b7f8-984c-4f9b-ad81-42b1c5d551e4',
              content: {
                data: '10 km per hour',
                type: 'string',
              },
            },
            {
              id: '33c93004-28f6-471a-a6ab-79a42fefa110',
              content: {
                data: '60 km per hour',
                type: 'string',
              },
            },
          ],
          content: {
            data: '<p>It takes George 5 minutes to cycle to school. If he lives 2 kilometers from school,&nbsp;<br>what is his average speed?</p>',
            type: 'stringified_html',
          },
          completed: false,
          questionGroupId: 'd1a4046a-b5bc-4747-87e8-28b717ce6fec',
          points: 0,
          type: 'question',
        },
        {
          id: '02bc75da-06a6-4579-a837-496cfb0447b7',
          answers: [
            {
              id: '0a971290-a2f8-494d-8cf0-682240298c9a',
              content: {
                data: '180°',
                type: 'string',
              },
            },
            {
              id: '4cd77ed6-c524-4f3e-bd07-7d892448ccb8',
              content: {
                data: '15°',
                type: 'string',
              },
            },
            {
              id: '081fe036-fb28-46a8-8c86-2b6a0bdaf519',
              content: {
                data: '30°',
                type: 'string',
              },
            },
            {
              id: 'aa19e230-16a8-44a1-ad82-cca62e4ecacd',
              content: {
                data: '10°',
                type: 'string',
              },
            },
            {
              id: 'dc0dd656-0713-4fa8-aa8f-2231983cfc51',
              content: {
                data: '90°',
                type: 'string',
              },
            },
          ],
          content: {
            data: '<p>What angle does the hour hand of a clock turn through in half an hour?</p>',
            type: 'stringified_html',
          },
          completed: false,
          questionGroupId: 'b923d953-a3d1-4de8-934a-4d3335688a2f',
          points: 0,
          type: 'question',
        },
      ],
    })
  )
})

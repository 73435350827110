import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FormWrapper } from '@project-minerva/core'
import { TextField, TextFieldProps } from '@project-minerva/design-system'
import { MAX_DOB, MIN_DOB } from '@project-minerva/shared-ui'
import { sub } from 'date-fns'
import { useIntl } from 'react-intl'
import { LoginErrorAndButton } from './student-login-common-components'

export const DEFAULT_AGE = 11
interface DobScreenProps {
  dob: Date | null
  setDob: (dob: Date | null) => void
  error: string | null
  onSubmit: () => void
  loading: boolean
}

export const DobScreen = (props: DobScreenProps) => {
  const { setDob, dob, error, onSubmit, loading } = props
  const { formatMessage } = useIntl()

  return (
    <FormWrapper onSubmit={onSubmit}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          data-testid="date-picker-inline"
          data-private
          label={formatMessage({ id: 'date-of-birth' })}
          inputFormat="dd/MM/yyyy"
          maxDate={MAX_DOB}
          minDate={MIN_DOB}
          defaultCalendarMonth={sub(new Date(), { years: DEFAULT_AGE })}
          value={dob}
          onChange={setDob}
          renderInput={(params: TextFieldProps) => (
            <TextField data-testid="dob-text-field" {...params} type="date" sx={{ pb: (theme) => theme.spacing(3) }} />
          )}
        />
      </LocalizationProvider>

      <LoginErrorAndButton
        loading={loading || !dob}
        error={error ? 'incorrect-date-of-birth' : null}
        buttonLabel="confirm-dob"
      />
    </FormWrapper>
  )
}

import { FC, MutableRefObject, useState } from 'react'

export interface InvigilatorDialogProps {
  open: boolean
  closeDialog: () => void
}

export const useInvigilatorDialog = (
  closeWidgetRef: MutableRefObject<() => void>,
  CustomDialog: FC<InvigilatorDialogProps>
): [() => void, () => JSX.Element] => {
  const [open, setOpen] = useState(false)
  const openModal = () => {
    closeWidgetRef.current()
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }

  const Modal = () => <CustomDialog open={open} closeDialog={closeModal} />

  return [openModal, Modal]
}

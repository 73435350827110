import { useAuthContext } from '@project-minerva/auth-cognito'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  PrimaryTypography,
  CircularProgress,
} from '@project-minerva/design-system'
import { GuardianApplicant, InvigilationCentre, InvigilationCentreForm } from '@project-minerva/typings'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CloseButton } from '../buttons'
import { ErrorMessage } from '../error'
import { ApplicationInvigilationCentreStatusBox } from './application-invigilation-centre-status-box'
import { InvigilationCentres } from './create-profile/steps/invigilation-centre'

export function InvigilationCentreModal(props: {
  modalOpen: boolean
  hideInfoBox?: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  applicant: GuardianApplicant
  fetchApplicant: () => void
  registerApplicantVenueRequest: (
    applicantId: string,
    venueId: string,
    token: string
  ) => Promise<{ applicant: GuardianApplicant }>
}) {
  const { modalOpen, setModalOpen, applicant, fetchApplicant, registerApplicantVenueRequest, hideInfoBox } = props
  const { accessToken } = useAuthContext()
  const [centres, setCentres] = useState<InvigilationCentre[]>([])
  const [newApplicant, setNewApplicant] = useState<GuardianApplicant>(applicant)
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState('')

  const registerVenue = async (formValue: InvigilationCentreForm | null) => {
    setSubmitting(true)
    if (formValue?.invigilationCentre) {
      const { invigilationCentre } = formValue
      try {
        setNewApplicant({ ...newApplicant, invigilationCentre })
        await registerApplicantVenueRequest(
          newApplicant.applicantId,
          invigilationCentre.invigilationCentreId,
          accessToken.current
        )
        fetchApplicant()
        setModalOpen(false)
      } catch (e) {
        setResponseError((e as unknown as Error).message)
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} data-testid="invigilation-centre-modal">
      <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title">
        <Box sx={{ verticalAlign: 'middle' }}>
          <PrimaryTypography variant="h5">
            {applicant.invigilationCentre === null && <FormattedMessage id="select-invigilation-centre" />}
            {applicant.invigilationCentre?.status === 'PENDING' && <FormattedMessage id="change-invigilation-centre" />}
            {applicant.invigilationCentre?.status === 'REJECTED' && (
              <FormattedMessage id="select-invigilation-centre-again" />
            )}
          </PrimaryTypography>
        </Box>
        <CloseButton onClick={() => props.setModalOpen(false)} data-testid="modal-close" disabled={submitting} />
      </DialogTitle>
      <DialogContent data-testid="modal-content">
        {applicant.invigilationCentre && (
          <Box sx={{ mb: (theme) => theme.spacing(3) }}>
            <ApplicationInvigilationCentreStatusBox invigilationCentre={applicant.invigilationCentre} showWarning />
          </Box>
        )}
        <InvigilationCentres
          previousCentreStatus={newApplicant.invigilationCentre?.status}
          centres={centres}
          setCentres={setCentres}
          applicant={newApplicant}
          setApplicant={setNewApplicant}
          hideTitle
          handleSubmit={registerVenue}
          hideInfoBox={hideInfoBox}
        />
        {!!responseError.length && (
          <Box mt={2}>
            <ErrorMessage message={responseError} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color="secondary" disabled={submitting}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          form="create-profile-form"
          type="submit"
          disabled={submitting}
          endIcon={submitting && <CircularProgress size={16} />}
        >
          <FormattedMessage
            id="invigilation-centre-modal-confirm-button"
            defaultMessage="Send Request"
            data-testid="modal-confirm"
          />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

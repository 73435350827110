import { Alert } from '@project-minerva/design-system'
import { renderIntlLink } from '@project-minerva/intl'
import { FormattedMessage } from 'react-intl'

export default function ApplicantEalInfoAlert() {
  return (
    <Alert severity="info" sx={{ mt: 1 }}>
      <FormattedMessage
        id="request-info-eal"
        defaultMessage="This is a request for EAL arrangements. For further information about EAL, <a>click here</a>"
        values={{
          a: (chunks: string) =>
            renderIntlLink(chunks, 'https://pretests.support.iseb.co.uk/support/solutions/folders/101000420673'),
        }}
      />
    </Alert>
  )
}

import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Header = styled('header')({
  display: 'flex',
  flexDirection: 'row',
})

export const LeftAlign = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
})

export const RightAlign = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-end',
  alignItems: 'center',
})

export const CenterAlign = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
})

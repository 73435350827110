import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useAuthContext } from '@project-minerva/auth-cognito'
import { useDownloadFromPath } from '@project-minerva/core'
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  TextArea,
  Radio,
  RadioControlLabel,
  RadioGroup,
  Typography,
  FormLabel,
  FormControl,
  FormHelperText,
  FormControlLabel,
  TextField,
} from '@project-minerva/design-system'
import { renderIntlLink } from '@project-minerva/intl'
import { getDownloadToken, getDocumentViewUrl } from '@project-minerva/shared-api'
import { GuardianApplicant, GuardianDocument, SendForm, SendStatusInput } from '@project-minerva/typings'
import { useFormik } from 'formik'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSENDValidationSchema } from '../../../schemas'
import { AdditionalInfo } from '../../additional-info'
import { DragAndDropFileArea } from '../../../drag-and-drop'
import { usePrivilege } from '../../../privilege'
import { YesNo } from '../../../text'
import { ApplicantUploadedFile } from '../../applicant-uploaded-file'

interface Props {
  adminMode?: boolean
  editMode?: boolean
  applicant: GuardianApplicant
  setApplicant: Dispatch<SetStateAction<GuardianApplicant>>
  applicantTimeDetails?: string[]
  applicantProvisionsDetails?: string[]
  applicantDocumentNotes?: string[]
  hideTitle?: boolean
  disableRadios?: boolean
  initialSendIsNull?: boolean
  initialDocs: string[]
  handleSubmit: (newApplicant: GuardianApplicant) => void
  uploadSendDocument: (
    applicantId: string,
    formData: FormData,
    token: string
  ) => Promise<{ document: GuardianDocument }>
  updateApplicantSEND: (applicantId: string, isSend: boolean, send: SendStatusInput, token: string) => Promise<unknown>
}

//8MB
const MAX_FILE_SIZE_MB = 8
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024

const acceptedFilesSEND = [
  // images
  'image/png',
  'image/jpeg',
  // documents
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
]

export function SendStatus(props: Props) {
  const {
    adminMode,
    editMode,
    applicant,
    setApplicant,
    applicantTimeDetails,
    applicantProvisionsDetails,
    applicantDocumentNotes,
    hideTitle = false,
    disableRadios,
    initialSendIsNull = false,
    initialDocs,
    handleSubmit,
    updateApplicantSEND,
    uploadSendDocument,
  } = props

  const { accessToken } = useAuthContext()
  const { formatMessage } = useIntl()
  const includeMedicalConsent = useMemo(() => initialSendIsNull && !!editMode, [initialSendIsNull, editMode])
  const validationSchema = useSENDValidationSchema(includeMedicalConsent)
  const { applicantId } = useParams<{ applicantId: string }>()
  const { hasPrivilege } = usePrivilege()

  const setStateAndSubmit = (values: SendForm) => {
    const newApplicant = {
      ...applicant,
      isSend: values.isSend,
      send: {
        ...applicant.send,
        additionalTime: values.additionalTime,
        additionalTimeDescriptions: [values.additionalTimeDescription],
        environmentalProvisions: values.environmentalProvisions,
        environmentalProvisionsDescriptions: [values.environmentalProvisionsDescription],
        documents: values.documents,
        documentNotes: [values.documentNote],
      },
      consent: includeMedicalConsent
        ? { ...applicant.consent, medical: !!values.hasConsentedMedical }
        : applicant.consent,
    }
    setApplicant(newApplicant)
    handleSubmit(newApplicant)
  }

  const initialValues = {
    isSend: applicant.isSend,
    additionalTime: applicant.send?.additionalTime || false,
    additionalTimeDescription: editMode ? '' : applicant.send?.additionalTimeDescriptions[0] ?? '',
    environmentalProvisions: applicant.send?.environmentalProvisions || false,
    environmentalProvisionsDescription: editMode ? '' : applicant.send?.environmentalProvisionsDescriptions[0] ?? '',
    documents: applicant.send?.documents || [],
    documentNote: editMode ? '' : applicant.send?.documentNotes[0] ?? '',
    hasConsentedMedical: applicant.consent.medical,
  }

  const sendForm = useFormik<SendForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => setStateAndSubmit(values),
  })

  const onFileDrop = async (event: React.DragEvent) => {
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    if (acceptedFilesSEND.includes(file.type)) await uploadFile(file)
  }

  const onFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) await uploadFile(file)
    // stops bug where uploading the same file in a row would not work
    event.target.value = ''
  }

  const uploadFile = async (file: File) => {
    sendForm.setFieldTouched('documents', true, false)
    const formData = new FormData()
    formData.append('file', file, file.name)
    if (file.size > MAX_FILE_SIZE) {
      sendForm.setFieldError(
        'documents',
        formatMessage({ id: 'send-file-size-error', defaultMessage: 'Files must be smaller than 8MB' })
      )
    } else {
      sendForm.setFieldError('documents', undefined)
      try {
        const { document } = await uploadSendDocument(applicantId, formData, accessToken.current)
        sendForm.setFieldValue('documents', [...sendForm.values.documents, document])
      } catch (e) {
        throw new Error(`File upload failed: ${e}`)
      }
    }
  }

  const downloadDocument = useDownloadFromPath()

  const onFileClick = async (docId: string, docName: string) => {
    const { token } = await getDownloadToken(docId, accessToken.current)
    downloadDocument(getDocumentViewUrl(docId, token, docName), docName, true)
  }

  const updateSEND = async (docs: GuardianDocument[]) => {
    const send: SendStatusInput = {
      additionalTime: applicant.send?.additionalTime || false,
      additionalTimeDescription: applicant.send?.additionalTime ? applicant.send.additionalTimeDescriptions[0] : '',
      environmentalProvisions: applicant.send?.environmentalProvisions || false,
      environmentalProvisionsDescription: applicant.send?.environmentalProvisions
        ? applicant.send.environmentalProvisionsDescriptions[0]
        : '',
      documents: docs.map((doc: GuardianDocument) => doc.documentId),
      documentNote: applicant.send?.documentNotes[0] || '',
    }
    try {
      if (applicant.isSend !== null) await updateApplicantSEND(applicantId, applicant.isSend, send, accessToken.current)
    } catch (e) {
      throw new Error(`Unable to update SEND status for applicant: ${applicantId}`)
    }
  }

  const onFileDelete = async (id: string) => {
    const docs = [...sendForm.values.documents]
    const index = docs.findIndex((val) => val.documentId === id)
    docs.splice(index, 1)
    sendForm.setFieldValue('documents', docs)
    if (!adminMode) {
      await updateSEND(docs)
    }
  }

  return (
    <form
      id="create-profile-form"
      onSubmit={(evt) => {
        evt.preventDefault()
        sendForm.submitForm()
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {!hideTitle && (
            <Typography variant="h5" fontWeight={700} sx={{ mb: (theme) => theme.spacing(2) }}>
              <FormattedMessage id="send-status" defaultMessage="SEND Status" />
            </Typography>
          )}
          <Typography variant="body2" sx={{ mb: (theme) => theme.spacing(2) }}>
            <FormattedMessage
              id="does-applicant-have-send"
              defaultMessage="Does the applicant have any Special Educational Needs or Disabilities?"
            />
          </Typography>
          <FormControl error={sendForm.touched.isSend && !!sendForm.errors.isSend}>
            <RadioGroup
              row={true}
              value={sendForm.values.isSend}
              onChange={(e) => sendForm.setFieldValue('isSend', e.target.value === 'true' ? true : false)}
            >
              {[false, true].map((value) => (
                <RadioControlLabel
                  key={`${value}`}
                  sx={{ m: (theme) => theme.spacing(0, 2, 0, 0) }}
                  value={value}
                  control={<Radio />}
                  name="isSend"
                  label={
                    // TODO: use rem
                    <Typography fontSize="16px">
                      <YesNo truthy={value} />
                    </Typography>
                  }
                  ownerState={{ active: value === sendForm.values.isSend && !disableRadios }}
                  disabled={disableRadios ?? false}
                />
              ))}
            </RadioGroup>
            <FormHelperText>{sendForm.touched.isSend && sendForm.errors.isSend}</FormHelperText>
          </FormControl>
        </Box>
      </Box>
      {sendForm.values.isSend && (
        <>
          <Divider sx={{ m: (theme) => theme.spacing(3, 0, 3, 0) }} />
          {!disableRadios && (
            <Alert severity="info" sx={{ mb: (theme) => theme.spacing(3) }}>
              <FormattedMessage id="send-status-info" values={{ br: <br /> }} />
            </Alert>
          )}
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="documents" defaultMessage="Documents" />
          </Typography>
          <FormHelperText sx={{ mb: (theme) => theme.spacing(1) }}>
            <FormattedMessage id="send-status-provide-report-help" values={{ maxFileSize: MAX_FILE_SIZE_MB }} />
          </FormHelperText>
          {sendForm.values.documents.map((doc) => (
            <ApplicantUploadedFile
              key={doc.documentId}
              name={doc.name}
              dataTestId="uploaded-doc-chip"
              onIconClick={() => onFileDelete(doc.documentId)}
              icon={adminMode || !initialDocs.includes(doc.documentId) ? <CloseRoundedIcon fontSize="small" /> : null}
              onFileClick={() => onFileClick(doc.documentId, doc.name)}
            />
          ))}
          <FormControl error={sendForm.touched.documents && !!sendForm.errors.documents} sx={{ width: '100%', mb: 2 }}>
            <DragAndDropFileArea
              onDrop={onFileDrop}
              onFileSelect={onFileSelect}
              acceptedFilesFormat={acceptedFilesSEND.join()}
              inputId="documents"
            />
            <FormHelperText>{sendForm.touched.documents && sendForm.errors.documents}</FormHelperText>
          </FormControl>
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="document-notes" defaultMessage="Document Notes" />
          </Typography>
          {applicantDocumentNotes &&
            applicantDocumentNotes.map((note, i) => (
              <AdditionalInfo key={`send-doc-note-${i}`} editMode={editMode}>
                {note}
              </AdditionalInfo>
            ))}
          <TextField
            fullWidth={true}
            onBlur={sendForm.handleBlur}
            onChange={sendForm.handleChange}
            value={sendForm.values.documentNote}
            data-testid="middle-name-input"
            id="documentNote"
            name="documentNote"
          />
          <Typography variant="subtitle1" fontWeight={600} sx={{ m: (theme) => theme.spacing(2, 0, 1, 0) }}>
            <FormattedMessage id="special-requirements" defaultMessage="Special Requirements" />
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'max-content 1fr',
              gridTemplateRows: 'max-content 1fr',
            }}
          >
            <Checkbox
              disabled={!adminMode && disableRadios}
              checked={sendForm.values.additionalTime}
              onChange={sendForm.handleChange}
              id="additionalTime"
            />
            <FormLabel
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                color: (theme) =>
                  !adminMode && disableRadios ? theme.palette.text.secondary : theme.palette.text.primary,
              }}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                <FormattedMessage id="request-extra-time" defaultMessage="Request 25% Extra Time" />
              </Typography>
            </FormLabel>
            <Box sx={{ gridColumnStart: 2, gridRowStart: 2 }}>
              <FormHelperText sx={{ m: (theme) => theme.spacing(0, 0, 1) }}>
                <FormattedMessage
                  id="request-extra-time-help"
                  defaultMessage="Select this option if your child's SEND status requires extra time for online tests. Note this is a request for 25% extra time, the senior school/s must agree with the request."
                  values={{ br: <br /> }}
                />
              </FormHelperText>
              {sendForm.values.additionalTime && (
                <>
                  <Alert severity="info" sx={{ mb: (theme) => theme.spacing(2) }}>
                    <FormattedMessage
                      id="request-extra-time-info"
                      defaultMessage="Use the text box to add any additional information that will be useful for the senior schools and invigilation centre. You should include the name and contact details of your school's SENCO."
                      values={{ br: <br /> }}
                    />
                  </Alert>
                  <Typography variant="subtitle2" fontWeight={600} sx={{ mb: (theme) => theme.spacing(1) }}>
                    <FormattedMessage id="additional-information" defaultMessage="Additional information" />
                  </Typography>
                  {applicantTimeDetails &&
                    applicantTimeDetails.map((detail, i) => (
                      <AdditionalInfo key={`send-time-${i}`} editMode={editMode}>
                        {detail}
                      </AdditionalInfo>
                    ))}
                  <TextArea
                    value={sendForm.values.additionalTimeDescription}
                    onChange={sendForm.handleChange}
                    id="additionalTimeDescription"
                    fullWidth={true}
                    data-testid="send-additional-time-input"
                  />
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'max-content 1fr',
              gridTemplateRows: 'max-content 1fr',
            }}
          >
            <Checkbox
              disabled={!adminMode && disableRadios}
              checked={sendForm.values.environmentalProvisions}
              onChange={sendForm.handleChange}
              id="environmentalProvisions"
            />
            <FormLabel
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                color: (theme) =>
                  !adminMode && disableRadios ? theme.palette.text.secondary : theme.palette.text.primary,
              }}
            >
              <Typography variant="subtitle1" fontWeight={600}>
                <FormattedMessage
                  id="send-status-environmental-provisions-label"
                  defaultMessage="Request Other Adjustments"
                />
              </Typography>
            </FormLabel>
            <Box sx={{ gridColumnStart: 2, gridRowStart: 2 }}>
              <FormHelperText sx={{ m: (theme) => theme.spacing(0, 0, 1) }}>
                <FormattedMessage
                  id="send-status-environmental-provisions-helper"
                  defaultMessage="Select this option if your child's SEND status requires any other adjustments for online tests. Note this is a request for access arrangements, the senior school/s must agree with the request."
                />
              </FormHelperText>
              {sendForm.values.environmentalProvisions && (
                <>
                  <Alert severity="info" sx={{ mb: (theme) => theme.spacing(2) }}>
                    <FormattedMessage
                      id="send-status-environmental-provisions-info"
                      values={{
                        br: <br />,
                        ul: (chunk: string) => <ul>{chunk}</ul>,
                        li: (chunk: string) => <li>{chunk}</li>,
                        a: (chunk: string) =>
                          renderIntlLink(
                            chunk,
                            'https://www.iseb.co.uk/wp-content/uploads/ISEB-CPT-SEND-guidance-for-schools-July-2022.pdf'
                          ),
                      }}
                    />
                  </Alert>
                  <Typography variant="subtitle2" fontWeight={600} sx={{ mb: (theme) => theme.spacing(1) }}>
                    <FormattedMessage id="additional-information" defaultMessage="Additional information" />
                  </Typography>
                  {applicantProvisionsDetails &&
                    applicantProvisionsDetails.map((detail, i) => (
                      <AdditionalInfo key={`send-provision-${i}`} editMode={editMode}>
                        {detail}
                      </AdditionalInfo>
                    ))}
                  <TextArea
                    fullWidth={true}
                    value={sendForm.values.environmentalProvisionsDescription}
                    onChange={sendForm.handleChange}
                    id="environmentalProvisionsDescription"
                    data-testid="send-environmental-provisions-input"
                  />
                </>
              )}
            </Box>
          </Box>
          {includeMedicalConsent && (
            <FormControl error={!!sendForm.errors.hasConsentedMedical}>
              <FormControlLabel
                sx={{ mt: (theme) => theme.spacing(2), alignItems: 'flex-start' }}
                control={
                  <Checkbox
                    checked={!!sendForm.values.hasConsentedMedical}
                    id="hasConsentedMedical"
                    onChange={sendForm.handleChange}
                    data-testid="medical-consent-checkbox"
                    disabled={hasPrivilege}
                  />
                }
                label={
                  <FormattedMessage
                    id="applicant-review-medical-consent-text"
                    values={{
                      br: <br />,
                      ul: (chunk: string) => <ul>{chunk}</ul>,
                      li: (chunk: string) => <li>{chunk}</li>,
                    }}
                  />
                }
              />
              <FormHelperText>{sendForm.errors.hasConsentedMedical}</FormHelperText>
            </FormControl>
          )}
        </>
      )}
    </form>
  )
}

export * from './app.types'
export * from './admin-centres.types'
export * from './admin-manager.types'
export * from './admin-schools.types'
export * from './applicant.types'
export * from './assessment.types'
export * from './assessment-auth.types'
export * from './files.types'
export * from './guardian.types'
export * from './shared.types'
export * from './ui.types'
export * from './user.types'
export * from './guardian.forms.types'
export * from './admin-centre.forms.types'

export interface Subject {
  id: string
  name: string
}

export interface NewTest {
  subjectId: string
  name: string
  timeLimitSeconds: number
}

export interface TestListItem {
  name: string
  id: string
  subjectId: string
}

export interface FamiliarisationTest<TTestNode> {
  id: string
  name: string
  subject: Subject
  testContent: TTestNode[]
}

export interface StudySession<TTestNode> extends FamiliarisationTest<TTestNode> {
  token: string
  studySessionId: string
  subjectId: string
  startTime: string
  endTime: string
  serverTime: string
  timeLimitSeconds: number
  isPublished: boolean
  isPaused: boolean
  lastAnsweredQuestion?: string
  percentageComplete?: number
}

export type SubmitAnswer = {
  questionGroupId: string
  questionId: string
  action: 'skip' | 'answer'
  answers: string[]
  answerPosition?: number
  studySessionId: string
  isebTestSectionId?: string
  questionPoolId?: string
}

import { alpha, Box, Typography } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'

const DEFAULT_ID = 'file-upload-input'

export const DragAndDropFileArea = ({
  onDrop,
  onFileSelect,
  acceptedFilesFormat,
  inputId,
}: {
  onDrop: (event: React.DragEvent) => Promise<void>
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  acceptedFilesFormat?: string
  inputId?: string
}) => {
  const onDragOver = (event: React.DragEvent) => {
    // These are required to allow the drop event to upload
    event.stopPropagation()
    event.preventDefault()
  }
  return (
    <Box onDrop={onDrop} onDragOver={onDragOver}>
      <label htmlFor={inputId || DEFAULT_ID} data-testid={DEFAULT_ID}>
        <input
          id={inputId || DEFAULT_ID}
          type="file"
          style={{ display: 'none' }}
          onChange={onFileSelect}
          accept={acceptedFilesFormat}
        />
        <Box
          sx={{
            border: '1px dashed #000000',
            borderRadius: (theme) => theme.spacing(1.5),
            backgroundColor: alpha('#AEB3B7', 0.2),
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <Typography variant="subtitle2">
            <FormattedMessage id="drag-drop-file-or-click" defaultMessage="Drag and drop file or click to open" />
          </Typography>
        </Box>
      </label>
    </Box>
  )
}

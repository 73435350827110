import { Switch, Route, Redirect } from 'react-router-dom'
import { ExaminationRouter } from './pages/examination.routes'
import { LoginPage } from './pages/login.page'

const AppRoutes = () => (
  <Switch>
    <Route path="/login">
      <LoginPage />
    </Route>
    <Route path="/exam">
      <ExaminationRouter />
    </Route>
    <Route>
      <Redirect to="/login" />
    </Route>
  </Switch>
)

export default AppRoutes

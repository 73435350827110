import { Subject } from '@project-minerva/typings'
import * as Sentry from '@sentry/browser'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { getApiUrl, request } from '@project-minerva/api'

interface SubjectContextType {
  subjects: Subject[]
  getSubject: (id: string) => Subject | undefined
}

const SubjectsContext = createContext<SubjectContextType>({
  subjects: [],
  getSubject: (id: string) => undefined,
})

type SubjectsResponse = {
  subjects: Subject[]
  count: number
}

export const useSubjects = () => useContext(SubjectsContext)

const fetchSubjects = () => {
  const atlasSubjectsUrl = getApiUrl('atlas', '/v1/subjects')
  return request<SubjectsResponse>(atlasSubjectsUrl, { method: 'GET' })
}

const orderSubjects = (subjects: Subject[]): Subject[] => {
  return subjects.sort((a: Subject, b: Subject) => a.name.localeCompare(b.name))
}

export const SubjectProvider = (props: { children: ReactNode }) => {
  const [subjects, setSubjects] = useState<Subject[]>([])

  const getSubject = useCallback(
    (id: string) => {
      if (!id) return
      return subjects.find((subject) => id === subject.id)
    },
    [subjects]
  )

  useEffect(() => {
    fetchSubjects()
      .then((res) => {
        const orderedSubjects = orderSubjects(res.subjects)
        setSubjects(orderedSubjects)
      })
      .catch((err) => {
        Sentry.captureException(`Error fetching all subjects. Error message: ${err.message}`)
      })
  }, [])

  return <SubjectsContext.Provider value={{ subjects, getSubject }}>{props.children}</SubjectsContext.Provider>
}

import { TextFieldProps } from '@mui/material'
import { TextField } from '@mui/material'

const MAX_CHARS = 200

export default function ShortLimitInput(props: TextFieldProps) {
  return (
    <TextField
      autoComplete="off"
      size="small"
      inputProps={{ ...props.inputProps, maxLength: MAX_CHARS }}
      {...props}
      maxRows={1}
    />
  )
}

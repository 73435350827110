import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import { DataGridProps, GridSelectionModel } from '@mui/x-data-grid'
import { DataGrid, IconButton, Checkbox, CheckboxProps } from '@project-minerva/design-system'
import { forwardRef, useEffect, useRef } from 'react'

export const PaginatedSelectableGrid = (
  props: DataGridProps & {
    nextPage?: () => void
    previousPage?: () => void
    tempSelection: GridSelectionModel
    setTempSelection: (newMap: GridSelectionModel) => void
    pageNumber: number
    disableCheckedWhenDisabled?: boolean
    searchQuery?: string
  }
) => {
  const {
    previousPage,
    nextPage,
    pageSize = 20,
    pageNumber,
    tempSelection,
    setTempSelection,
    disableCheckedWhenDisabled,
    searchQuery,
    ...otherProps
  } = props

  const prevSearch = useRef('')
  const hasJustChangedPage = useRef(false)
  const hasJustChangedSearch = useRef(false)
  const onPageChange = (newPageNumber: number) => {
    hasJustChangedPage.current = true
    if (newPageNumber < pageNumber && previousPage) {
      previousPage()
    }
    if (newPageNumber > pageNumber && nextPage) {
      nextPage()
    }
  }

  useEffect(() => {
    if (searchQuery !== undefined && searchQuery.length !== prevSearch.current.length) {
      hasJustChangedSearch.current = true
      prevSearch.current = searchQuery
    }
  }, [searchQuery])

  const PaginationFooter = () => {
    return (
      <div>
        {`${tempSelection.length} rows selected`}
        <IconButton disabled={!previousPage} onClick={() => onPageChange(pageNumber - 1)}>
          <ChevronLeftRoundedIcon />
        </IconButton>
        {`${pageNumber + 1}`}
        <IconButton disabled={!nextPage} onClick={() => onPageChange(pageNumber + 1)}>
          <ChevronRightRoundedIcon />
        </IconButton>
      </div>
    )
  }

  const selectionModel = tempSelection || []

  const rowCount = nextPage ? (pageNumber + 1) * pageSize + 1 : (pageNumber + 1) * pageSize

  // This function allows selections to be maintained outside of page changes and searches
  const handleModelChange = (newModel: GridSelectionModel) => {
    const currentSelection = [...tempSelection]
    if (!hasJustChangedPage.current && !hasJustChangedSearch.current) {
      const removedIndices = currentSelection.reduce((prev, curr, i) => {
        return !newModel.includes(curr) ? [...prev, i] : prev
      }, [] as number[])

      if (removedIndices.length) {
        removedIndices.reverse().forEach((idx) => {
          currentSelection.splice(idx, 1)
        })
        setTempSelection([...new Set([...currentSelection]).values()])
        return
      }
    }

    hasJustChangedPage.current = false
    hasJustChangedSearch.current = false
    setTempSelection([...new Set([...tempSelection].concat(newModel)).values()])
  }

  return (
    <DataGrid
      selectionModel={[...selectionModel.values()]}
      onSelectionModelChange={(model: GridSelectionModel) => {
        handleModelChange(model)
      }}
      pagination
      rowCount={rowCount}
      paginationMode="server"
      onPageChange={onPageChange}
      pageSize={pageSize}
      rowsPerPageOptions={[pageSize]}
      {...otherProps}
      components={{
        Footer: PaginationFooter,
        BaseCheckbox: disableCheckedWhenDisabled ? Checkbox : PaginatedSelectableCheckbox,
      }}
    />
  )
}

// Hideous hack - if a checkbox is disabled, we want it to be ticked as it is disabled due to
// it already being selected.
const PaginatedSelectableCheckbox = forwardRef(
  (props: CheckboxProps & { disableCheckedWhenDisabled?: boolean }, ref) => {
    const { checked, disabled, disableCheckedWhenDisabled, ...otherProps } = props

    return <Checkbox {...otherProps} checked={disabled ? true : checked} disabled={disabled} />
  }
)

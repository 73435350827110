import { Box, Typography } from '@project-minerva/design-system'
import { ReactNode } from 'react'

export const LabelValue = ({
  direction,
  label,
  value,
}: {
  direction?: 'row' | 'column'
  label: ReactNode
  value: ReactNode
}) => (
  <Box sx={{ flexDirection: direction ?? 'column' }}>
    <Typography>{label}</Typography>
    {value}
  </Box>
)

import { assign, createMachine, State } from 'xstate'

export type PongState = State<PongContext, PongEvent>
export enum PingAction {
  FOCUS = 'focus',
  PING = 'ping',
  BLUR = 'blur',
}
const DEFAULT_PING_INTERVAL = 2000

type PongContext = {
  pingInterval: number
  msSinceLastPing: number
  lastPing: number
}

type PongEvent = { type: 'FOCUS' } | { type: 'PONG' } | { type: 'BLUR' } | { type: 'PAUSE' } | { type: 'COMPLETED' }

export const pongMachineFactory = (id: string, type: string) =>
  createMachine(
    {
      tsTypes: {} as import('./pong.machine.typegen').Typegen0,
      schema: {
        context: {} as PongContext,
        events: {} as PongEvent,
      },
      id: `ping-${type}-${id}`,
      context: {
        lastPing: Date.now(),
        msSinceLastPing: 0,
        pingInterval: DEFAULT_PING_INTERVAL,
      },
      initial: 'focus',
      states: {
        focus: {
          tags: [PingAction.FOCUS],
          entry: ['updateCurrentTime'],
          on: {
            BLUR: 'blur',
            COMPLETED: 'completed',
          },
          after: {
            PING_INTERVAL: 'pinging',
          },
        },
        pinging: {
          tags: [PingAction.PING],
          entry: ['updateCurrentTime'],
          after: {
            PING_INTERVAL: 'pinging',
          },
          on: {
            BLUR: 'blur',
            PAUSE: 'paused',
            COMPLETED: 'completed',
          },
          invoke: [{ src: 'blurListener' }],
        },
        blur: {
          tags: [PingAction.BLUR],
          entry: ['updateCurrentTime'],
          on: {
            FOCUS: 'focus',
            COMPLETED: 'completed',
          },
          invoke: [{ src: 'focusListener' }],
        },
        paused: {
          entry: ['sendBlur', 'updateCurrentTime'],
          on: {
            FOCUS: 'focus',
          },
        },
        completed: {
          tags: [PingAction.BLUR],
          type: 'final',
          entry: ['updateCurrentTime'],
        },
      },
    },
    {
      actions: {
        updateCurrentTime: assign((ctx) => ({
          ...ctx,
          msSinceLastPing: Date.now() - ctx.lastPing,
          lastPing: Date.now(),
        })),
      },
      delays: {
        PING_INTERVAL: (ctx) => ctx.pingInterval,
      },
      services: {
        blurListener: () => (send) => {
          const listener = () => send('BLUR')
          window.addEventListener('blur', listener)
          return () => window.removeEventListener('blur', listener)
        },
        focusListener: () => (send) => {
          const listener = () => send('FOCUS')
          window.addEventListener('focus', listener)
          return () => window.removeEventListener('focus', listener)
        },
      },
    }
  )

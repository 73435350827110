import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { Stack } from '@mui/material'
import { Box, Button, Typography } from '@project-minerva/design-system'
import { PrivilegeDetail } from '@project-minerva/typings'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { PageHeader } from '../header'
import { PRIVILEGE_CONFIG, usePrivilege } from './privilege.provider'

export const PRIVILEGE_HEADER_HEIGHT = 76

export const PrivilegeHeader = () => {
  const { hasPrivilege, privilege, revoke } = usePrivilege()

  if (hasPrivilege) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const Icon = PRIVILEGE_CONFIG[privilege!.privilege].icon
    return (
      <Box
        sx={{
          width: '100%',
          height: PRIVILEGE_HEADER_HEIGHT,
          backgroundColor: '#E7E0F7',
          p: (theme) => theme.spacing(1.5),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          zIndex: 5,
        }}
      >
        <Stack direction="row" spacing={2} sx={{ color: (theme) => theme.palette.primary.main, alignItems: 'center' }}>
          <Icon fontSize="large" />
          <AccountDetail privilege={privilege} />
        </Stack>
        <Button onClick={revoke} endIcon={<LogoutRoundedIcon />}>
          <FormattedMessage id="privilege-revoke" defaultMessage="Leave account" />
        </Button>
      </Box>
    )
  }

  return null
}

const AccountDetail = ({ privilege }: { privilege?: PrivilegeDetail }) => {
  if (!privilege) return null

  let name
  let address
  let email
  switch (privilege.privilege) {
    case 'GUARDIAN':
      name = `${privilege.guardian.firstName} ${privilege.guardian.lastName}`
      email = privilege.guardian.email
      break
    case 'INVIGILATION_CENTRE':
      name = privilege.invigilationCentre.name
      address = privilege.invigilationCentre.address
      break
    case 'SENIOR_SCHOOL':
      name = privilege.seniorSchool.name
      address = privilege.seniorSchool.address
      break
    default:
      name = `UNKNOWN PRIVILEGE`
  }

  return (
    <Box>
      <Typography variant="subtitle2">{privilege.privilege.replace('_', ' ')}</Typography>
      <Typography variant="body2" fontWeight={700}>
        {name}
      </Typography>
      {email && <Typography variant="subtitle2">{email}</Typography>}
      {address && <Typography variant="subtitle2">{address}</Typography>}
    </Box>
  )
}

export const PrivilegedPageHeader: FC = ({ children }) => {
  const { hasPrivilege } = usePrivilege()

  return <PageHeader sx={{ top: hasPrivilege ? PRIVILEGE_HEADER_HEIGHT : 0 }}>{children}</PageHeader>
}

import {
  Alert,
  Box,
  Divider,
  FormHelperText,
  TextArea,
  Radio,
  RadioGroup,
  Typography,
  RadioControlLabel,
  FormControl,
} from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import { Dispatch, SetStateAction } from 'react'
import { AdditionalInfo } from './additional-info'
import { EalForm, GuardianApplicant } from '@project-minerva/typings'
import { useFormik } from 'formik'
import { useEALValidationSchema } from '../schemas'
import { YesNo } from '../text'

interface Props {
  applicant: GuardianApplicant
  setApplicant: Dispatch<SetStateAction<GuardianApplicant>>
  editMode?: boolean
  applicantProvisionsDetails?: string[]
  hideTitle?: boolean
  disableRadios?: boolean
  handleSubmit: (newApplicant: GuardianApplicant) => void
  changeToYesMode?: boolean
  adminMode?: boolean
}

export function EalStatus(props: Props) {
  const {
    editMode,
    applicantProvisionsDetails,
    hideTitle = false,
    disableRadios,
    applicant,
    setApplicant,
    handleSubmit,
    changeToYesMode,
    adminMode,
  } = props

  const validationSchema = useEALValidationSchema()

  const setStateAndSubmit = (values: EalForm) => {
    const newApplicant = {
      ...applicant,
      isEal: values.isEal,
      eal: {
        environmentalProvisions: values.isEal || false,
        environmentalProvisionsDescriptions: [values.environmentalProvisionsDescription],
      },
    }
    setApplicant(newApplicant)
    handleSubmit(newApplicant)
  }

  const initialValues = {
    isEal: changeToYesMode === true ? true : applicant.isEal,
    environmentalProvisionsDescription: editMode ? '' : applicant.eal?.environmentalProvisionsDescriptions[0] ?? '',
  }

  const ealForm = useFormik<EalForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => setStateAndSubmit(values),
  })

  return (
    <form
      id="create-profile-form"
      onSubmit={(evt) => {
        evt.preventDefault()
        ealForm.submitForm()
      }}
    >
      {adminMode && changeToYesMode && (
        <Alert severity="info" sx={{ mb: (theme) => theme.spacing(1) }}>
          <FormattedMessage id="change-eal-to-yes-info-box" />
        </Alert>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!hideTitle && (
          <Typography variant="h5" fontWeight={700} sx={{ mb: (theme) => theme.spacing(2) }}>
            <FormattedMessage id="eal-status" defaultMessage="EAL Status" />
          </Typography>
        )}
        <Typography variant="body2" sx={{ mb: (theme) => theme.spacing(2) }}>
          <FormattedMessage
            id="does-applicant-have-eal"
            defaultMessage="Do you consider the applicant to have English as an Additional Language?"
          />
        </Typography>
        <FormHelperText sx={{ mb: (theme) => theme.spacing(3) }}>
          <FormattedMessage id="eal-status-helper" values={{ br: <br /> }} />
        </FormHelperText>
      </Box>
      <FormControl error={ealForm.touched.isEal && !!ealForm.errors.isEal}>
        <RadioGroup
          row={true}
          value={ealForm.values.isEal}
          onChange={(e) => ealForm.setFieldValue('isEal', e.target.value === 'true' ? true : false)}
        >
          {[false, true].map((value) => (
            <RadioControlLabel
              key={`${value}`}
              sx={{ m: (theme) => theme.spacing(0, 2, 0, 0) }}
              value={value}
              control={<Radio />}
              name="isEal"
              label={
                // TODO: use rem
                <Typography fontSize="16px">
                  <YesNo truthy={value} />
                </Typography>
              }
              ownerState={{ active: value === ealForm.values.isEal && !disableRadios }}
              disabled={disableRadios || (changeToYesMode && value === false) ? true : false}
            />
          ))}
        </RadioGroup>
        <FormHelperText>{ealForm.touched.isEal && ealForm.errors.isEal}</FormHelperText>
      </FormControl>
      {ealForm.values.isEal && (
        <>
          <Divider sx={{ m: (theme) => theme.spacing(3, 0, 3, 0) }} />
          <Alert severity="info" sx={{ mb: (theme) => theme.spacing(1) }}>
            <FormattedMessage id="eal-status-info" values={{ br: <br /> }} />
          </Alert>
          <Typography variant="subtitle1" fontWeight={600} mb={1}>
            <FormattedMessage id="additional-information" defaultMessage="Additional Information" />
          </Typography>
          <Box>
            {applicantProvisionsDetails &&
              applicantProvisionsDetails.map((detail, i) => (
                <AdditionalInfo key={`eal-provision-${i}`} editMode={editMode}>
                  {detail}
                </AdditionalInfo>
              ))}
            <TextArea
              fullWidth={true}
              value={ealForm.values.environmentalProvisionsDescription}
              id="environmentalProvisionsDescription"
              onChange={ealForm.handleChange}
              data-testid="eal-environmental-provisions-input"
            />
          </Box>
        </>
      )}
    </form>
  )
}

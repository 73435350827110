import { Chip } from '@project-minerva/design-system'
import { ApplicantTest } from '@project-minerva/typings'
import { isWithinInterval, isAfter, isBefore } from 'date-fns'
import { FormattedMessage } from 'react-intl'

interface Props {
  start: string
  end: string
}
export function TestStatusChip(props: Props) {
  const { start, end } = props
  return isWithinInterval(new Date(), { start: new Date(start), end: new Date(end) }) ? (
    <Chip data-testid="assessment-card-status" label={<FormattedMessage id="tag-live" defaultMessage="Live" />} />
  ) : isAfter(new Date(), new Date(end)) ? (
    <Chip
      data-testid="assessment-card-status"
      label={<FormattedMessage id="tag-complete" defaultMessage="Complete" />}
    />
  ) : null
}

// TODO: rename this
export const TestStatusChip2 = ({ test }: { test?: Pick<ApplicantTest, 'testSession' | 'assessmentEndTime'> }) => {
  const now = new Date()

  if (test?.testSession?.isClosed) return <TestCompleteChip />
  else if (test?.assessmentEndTime && isBefore(now, new Date(test.assessmentEndTime)))
    return <Chip data-testid="test-status" label={<FormattedMessage id="tag-scheduled" defaultMessage="Scheduled" />} />
  else return null
}

export const TestCompleteChip = (props: { small?: boolean }) => (
  <Chip
    data-testid="test-status"
    label={<FormattedMessage id="tag-completed" defaultMessage="Completed" />}
    color="success"
    sx={{ color: 'white' }}
    size={props.small ? 'small' : undefined}
  />
)

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useAuthContext } from '@project-minerva/auth-cognito'
import { useDebounce } from '@project-minerva/core'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from '@project-minerva/design-system'
import { renderIntlEmail, renderIntlLink } from '@project-minerva/intl'
import { searchVenues } from '@project-minerva/shared-api'
import {
  GuardianApplicant,
  InvigilationCentre,
  InvigilationCentreForm,
  InvigilationCentreStatus,
} from '@project-minerva/typings'
import { useFormik } from 'formik'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { InvigilationCentreInfoCard } from '../../../invigilation-centre'
import { useInvigilationCentreValidationSchema } from '../../../schemas'

interface Props {
  previousCentreStatus?: InvigilationCentreStatus
  applicant: GuardianApplicant
  setApplicant: Dispatch<SetStateAction<GuardianApplicant>>
  centres: InvigilationCentre[]
  setCentres: Dispatch<SetStateAction<InvigilationCentre[]>>
  hideTitle?: boolean
  handleSubmit: (newApplicant: GuardianApplicant) => Promise<void>
  hideInfoBox?: boolean
}

export function InvigilationCentres(props: Props) {
  const { accessToken } = useAuthContext()
  const {
    applicant,
    setApplicant,
    previousCentreStatus,
    centres,
    setCentres,
    handleSubmit,
    hideTitle = false,
    hideInfoBox = false,
  } = props
  const [searchQuery, setSearchQuery] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const debouncedSearchQuery = useDebounce(searchQuery, 500)
  const validationSchema = useInvigilationCentreValidationSchema()

  const searchCentres = useCallback(async () => {
    if (debouncedSearchQuery.length < 3) return setCentres([])

    try {
      const { results } = await searchVenues(debouncedSearchQuery, accessToken.current)
      setCentres(results)
    } catch (e) {
      throw new Error('Unable to get venues')
    }
  }, [debouncedSearchQuery, setCentres, accessToken])

  useEffect(() => {
    searchCentres()
  }, [debouncedSearchQuery, searchCentres])

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(evt.target.value)
  }

  const setStateAndSubmit = (values: InvigilationCentreForm) => {
    const newApplicant = {
      ...applicant,
      invigilationCentre: values.invigilationCentre,
    }
    setApplicant(newApplicant)
    handleSubmit(newApplicant)
  }

  const initialValues = {
    invigilationCentre: applicant.invigilationCentre,
  }

  const invigilationCentreForm = useFormik<InvigilationCentreForm>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => setStateAndSubmit(values),
  })

  return (
    <form
      id="create-profile-form"
      onSubmit={(evt) => {
        evt.preventDefault()
        invigilationCentreForm.submitForm()
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {!hideTitle && (
          <Typography
            variant="h5"
            fontWeight={700}
            sx={{ mb: (theme) => theme.spacing(2), textTransform: 'capitalize' }}
          >
            <FormattedMessage id="invigilation-centre" defaultMessage="Invigilation centre" />
          </Typography>
        )}
        {!previousCentreStatus && (
          <Alert severity="info" sx={{ mb: (theme) => theme.spacing(3) }}>
            <FormattedMessage
              id="invigilation-centre-description"
              values={{
                br: <br />,
                b: (chunk: string) => <b>{chunk}</b>,
                email: (chunks: string) => renderIntlEmail(chunks, 'pretests@iseb.co.uk'),
                ul: (chunk: string) => <ul>{chunk}</ul>,
                li: (chunk: string) => <li>{chunk}</li>,
              }}
            />
          </Alert>
        )}
        {previousCentreStatus === 'PENDING' && !hideInfoBox && (
          <Alert severity="info" sx={{ mb: (theme) => theme.spacing(3) }}>
            <FormattedMessage id="invigilation-centre-description-pending" />
          </Alert>
        )}
        {previousCentreStatus === 'REJECTED' && !hideInfoBox && (
          <Alert severity="info" sx={{ mb: (theme) => theme.spacing(3) }}>
            <FormattedMessage id="invigilation-centre-description-rejected" />
          </Alert>
        )}
        <Typography sx={{ mb: (theme) => theme.spacing(1) }}>
          <FormattedMessage
            id="invigilation-centre-search-help"
            defaultMessage="Type the name or postcode of the invigilation centre to search."
          />
        </Typography>
        <FormControl>
          <Autocomplete
            sx={{ mb: (theme) => theme.spacing(3) }}
            // using multiple as this allows custom rendering of the selected value using renderTags (see below)
            multiple
            fullWidth
            filterOptions={(x) => x}
            options={centres}
            getOptionLabel={(option) => option.name}
            value={
              invigilationCentreForm.values.invigilationCentre ? [invigilationCentreForm.values.invigilationCentre] : []
            }
            inputValue={invigilationCentreForm.values.invigilationCentre ? '' : searchQuery}
            // set the value of the select to always be the latest centre i.e. the last thing the user clicked
            onChange={(evt, newValue) => {
              invigilationCentreForm.setFieldValue(
                'invigilationCentre',
                newValue.length ? newValue[newValue.length - 1] : null
              )
            }}
            id="invigilationCentre"
            renderInput={(params) => (
              <FormControl sx={{ minWidth: '100%' }}>
                {!applicant.invigilationCentre && !searchQuery.length && (
                  <InputLabel shrink={false}>
                    <FormattedMessage id="invigilation-centre-search-placeholder" defaultMessage="Select Centre" />
                  </InputLabel>
                )}
                <TextField
                  {...params}
                  onChange={handleSearchChange}
                  data-testid="invigilation-centre-search"
                  helperText={
                    invigilationCentreForm.touched.invigilationCentre &&
                    invigilationCentreForm.errors.invigilationCentre
                  }
                  error={
                    invigilationCentreForm.touched.invigilationCentre &&
                    !!invigilationCentreForm.errors.invigilationCentre
                  }
                />
              </FormControl>
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <InvigilationCentreInfoCard
                  name={option?.name}
                  address={option?.address}
                  dataTestId="invigilation-centre-option"
                />
              </Box>
            )}
            renderTags={(value, getTagProps) => {
              const { onDelete, ...props } = getTagProps({ index: 0 })
              return (
                <Box {...props}>
                  <Typography variant="body1">{value[0]?.name}</Typography>
                  <Typography variant="caption">{value[0]?.address}</Typography>
                </Box>
              )
            }}
          />
          <FormHelperText>
            {invigilationCentreForm.touched.invigilationCentre && !!invigilationCentreForm.errors.invigilationCentre}
          </FormHelperText>
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button variant="text" size="small" onClick={() => setShowAlert(true)} sx={{ ml: 0 }}>
          <FormattedMessage id="i-cant-find-my-centre" defaultMessage="I can't find my centre" />
        </Button>
        {showAlert && (
          <IconButton sx={{ ml: 'auto', verticalAlign: 'middle' }} onClick={() => setShowAlert(false)} color="primary">
            <CloseRoundedIcon />
          </IconButton>
        )}
      </Box>
      {showAlert && (
        <Alert severity="info">
          <FormattedMessage
            id="confirm-with-centre-text"
            values={{
              br: <br />,
              a: (chunks: string) =>
                renderIntlLink(
                  chunks,
                  'https://www.iseb.co.uk/assessments/common-pre-tests/common-pre-tests-information-for-invigilation-centres/pre-tests-invigilation-centre-registration/'
                ),
            }}
          />
        </Alert>
      )}
    </form>
  )
}

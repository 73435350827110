import { useTimer } from '@ctek/test-player'
import {
  AssessmentPlayerProps,
  AssessmentPlayer,
  SimpleDialog,
  TestStartScreen,
  useFontSize,
  useScreenFilter,
  formatTime,
  useTestFlowController,
} from '@project-minerva/assessment-player'
import { useBasicAuth } from '@project-minerva/auth-student'
import * as Sentry from '@sentry/browser'
import { useRef, useState, useEffect, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useStudySession } from '../data/study-session.provider'
import { useCompletion } from '../hooks/use-completion'
import { useNavigation } from '../hooks/use-navigation'
import { useTrackSettings } from '../hooks/use-track-settings'
import { useTrackPageViewed, useTrackStudySessionStarted, useTrackTestResumed, ExamPages } from '../mixpanel'
import ConfirmDobDialog from './confirm-dob.dialog'
import { ExaminationContentScreen } from './examination-content-screen'
import { ExaminationEndScreen } from './examination-end-screen'
import { usePauseResumeTest } from './pause-resume.provider'
import ResumeTestDialog from './resume-test.dialog'
import { useTestProgress } from './test-progress.provider'

type ExaminationPlayerProps = Omit<
  AssessmentPlayerProps,
  | 'handleStart'
  | 'useNavigationHook'
  | 'useCompletionHook'
  | 'data'
  | 'loading'
  | 'startScreen'
  | 'testScreen'
  | 'endScreen'
>
const ExaminationPlayer = (props: ExaminationPlayerProps) => {
  const { assessmentId, subjectId, testId } = useParams<{ assessmentId: string; subjectId: string; testId: string }>()
  const { loading, error, initialiseStudySession } = useStudySession()
  const { initialiseStudySessionService } = useTestFlowController()
  const { startTimer } = useTimer()
  const studySessionDialogRef = useRef()
  const resumeErrorDialogRef = useRef()
  const [resumeError, setResumeError] = useState<Error | null>(null)
  const { saveToken } = useBasicAuth()
  const [showDobConfirmation, setShowDobConfirmation] = useState(false)
  const trackPageViewed = useTrackPageViewed()
  const trackStudySessionStarted = useTrackStudySessionStarted()
  const { isPaused, resumeTest } = usePauseResumeTest()
  const trackResume = useTrackTestResumed()
  const { isStartTestScreen } = useTestFlowController()
  const { screenFilter } = useScreenFilter()
  const { fontSizeLabel } = useFontSize()
  const mountedTimeRef = useRef(0)
  const { progress: progressValue, setProgress } = useTestProgress()
  const trackSettings = useTrackSettings()

  useEffect(() => {
    trackPageViewed(ExamPages.TEST_START_SCREEN)
    mountedTimeRef.current = Date.now()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStartTest = useCallback(
    () => {
      initialiseStudySessionService(() => {
        return initialiseStudySession()
          .then(async (studySession) => {
            const { isPaused, serverTime, endTime, percentageComplete, token } = studySession
            saveToken({ token })

            if (isPaused) {
              await resumeTestHandler()
            } else {
              const timeSinceMount = formatTime(Math.floor((Date.now() - mountedTimeRef.current) / 1000))
              startTimer(new Date(endTime).getTime() - new Date(serverTime).getTime())
              trackStudySessionStarted({
                screenFilter,
                fontSize: fontSizeLabel,
                elapsedTime: timeSinceMount,
                progressValue: percentageComplete,
              })
            }

            if (studySession.percentageComplete !== undefined) {
              setProgress(studySession.percentageComplete)
            }

            return studySession
          })
          .catch((error: Error) => {
            Sentry.captureException(
              `Error initialising study session for: ${{ assessmentId, subjectId, testId }}} error message: ${
                error.message
              }`
            )
            throw error
          })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      initialiseStudySession,
      initialiseStudySessionService,
      trackStudySessionStarted,
      screenFilter,
      fontSizeLabel,
      saveToken,
      startTimer,
      assessmentId,
      subjectId,
      testId,
    ]
  )

  const openDobConfirmationDialog = useCallback(() => {
    setShowDobConfirmation(true)
  }, [])
  const closeDobConfirmationDialog = useCallback(() => {
    setShowDobConfirmation(false)
  }, [])

  const resumeTestHandler = async () => {
    setResumeError(null)
    try {
      const res = await resumeTest()
      trackResume({
        resumeData: res,
        from: isStartTestScreen ? 'start-screen' : 'modal',
        progressValue,
        ...trackSettings(),
      })
      startTimer(new Date(res.testEndTime).getTime() - new Date(res.pauseEndTime).getTime())
    } catch (error) {
      setResumeError(error as Error)
    }
  }

  const initTest = isPaused ? resumeTestHandler : handleStartTest
  return (
    <>
      <AssessmentPlayer
        {...props}
        handleStart={openDobConfirmationDialog}
        useNavigationHook={useNavigation}
        useCompletionHook={useCompletion}
        // usePingsHook={usePings}
        loading={loading}
        startScreen={TestStartScreen}
        testScreen={ExaminationContentScreen}
        endScreen={ExaminationEndScreen}
      />
      <SimpleDialog
        open={!!error}
        callback={props.onClose}
        title={<FormattedMessage id="error-dialog-title" />}
        content={<FormattedMessage id="error-initialise-test" values={{ error: error?.message }} />}
        buttonLabel={<FormattedMessage id="ok-button" />}
        data-testid="study-session-error-dialog"
        ref={studySessionDialogRef}
      />
      <SimpleDialog
        open={!!resumeError}
        callback={() => setResumeError(null)}
        title={<FormattedMessage id="error-dialog-title" />}
        content={<FormattedMessage id="error-resume-test" values={{ error: resumeError?.message }} />}
        buttonLabel={<FormattedMessage id="ok-button" />}
        data-testid="study-session-resume-error-dialog"
        ref={resumeErrorDialogRef}
      />
      <ResumeTestDialog open={isPaused && !isStartTestScreen} openDobConfirmationDialog={openDobConfirmationDialog} />
      <ConfirmDobDialog open={showDobConfirmation} onValidate={initTest} onClose={closeDobConfirmationDialog} />
    </>
  )
}

export default ExaminationPlayer

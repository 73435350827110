import { useState } from 'react'

export interface Anchor<T extends HTMLElement> {
  anchorElement: T | null
  setAnchor: (event: React.MouseEvent<T>) => void
  unsetAnchor: () => void
}

export const useAnchorElement = <T extends HTMLElement>(callback?: (element?: T) => void): Anchor<T> => {
  const [anchorElement, setAnchorElement] = useState<T | null>(null)
  const setAnchor = (event: React.MouseEvent<T>) => {
    const element = event.currentTarget
    callback?.(element)
    setAnchorElement(element)
  }

  const unsetAnchor = () => {
    callback?.()
    setAnchorElement(null)
  }

  return { anchorElement, setAnchor, unsetAnchor }
}

export const useDownloadFromPath = (srcPath?: string, fileName?: string, viewMode?: boolean) => {
  return (
    path: string | undefined = srcPath,
    filename: string | undefined = fileName,
    viewMode: boolean | undefined = false
  ) => {
    if (!path || !filename) {
      throw new Error('Path and filename are required')
    }

    const a = document.createElement('a')
    a.href = path
    a.download = filename
    a.target = viewMode ? '_blank' : '_self'
    a.click()
    a.remove()
  }
}

export const useDownloadFromBlob = () => {
  return (blob: Blob, filename: string) => {
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = filename
    a.click()
    a.remove()
  }
}

import { ReactNode, useContext } from 'react'
import { createContext } from 'react'

type MinervaApi = 'insights' | 'atlas' | 'sentinel' | 'helios'

export type MinervaApis = {
  [apiName in MinervaApi]: string
}

interface ApiUrlSchema {
  int: MinervaApis
  prod: MinervaApis
}

const ApiUrlsEnvs: ApiUrlSchema = {
  int: {
    atlas: process.env.NX_API_URL_ATLAS || 'https://api.int.century.tech/atlas',
    helios: process.env.NX_API_URL_HELIOS || 'https://api.int.local.eva.tech/helios',
    insights: process.env.NX_API_URL_INSIGHTS || 'https://api.int.century.tech/insights',
    sentinel: 'https://api.int.century.tech/auth',
  },
  prod: {
    atlas: process.env.NX_API_URL_ATLAS || 'https://api.century.tech/atlas',
    helios: process.env.NX_API_URL_HELIOS || 'https://api.eva.tech/helios',
    insights: process.env.NX_API_URL_INSIGHTS || 'https://api.century.tech/insights',
    sentinel: 'https://api.century.tech/auth',
  },
}

const ApiContext = createContext(ApiUrlsEnvs.int)

export const useApiContext = () => useContext(ApiContext)

const env = (process.env.NX_NODE_ENV || 'int') as 'int' | 'prod'
const urls = ApiUrlsEnvs[env]

export const getApiUrl = (api: MinervaApi, endpoint = '') => `${urls[api]}${endpoint}`

export const ApiProvider = (props: { children: ReactNode }) => {
  return <ApiContext.Provider value={urls}>{props.children}</ApiContext.Provider>
}

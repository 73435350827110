import { HtmlRender } from '@project-minerva/core'
import { AssessmentPlayerProps } from '../assessment-player'
import { AssessmentPlayerLayout } from '../assessment-player.layout'
import { ContentBox } from './content-box'
import { ControlBarStartTestScreen } from './control-bar'
import { ScreenFilterOverlay } from './header-widgets'

export type StartScreenInfoProps = Pick<StartScreenProps, 'introContent'>
export const TestStartScreenInfo = ({ introContent }: StartScreenInfoProps) => <HtmlRender html={introContent} />

export type StartScreenProps = Pick<AssessmentPlayerProps, 'introContent' | 'handleStart' | 'hasStarted'>

export const TestStartScreen = (props: StartScreenProps) => {
  return (
    <>
      <AssessmentPlayerLayout.ContentContainer data-testid="assessment-slide">
        <ScreenFilterOverlay />
        <ContentBox>
          <TestStartScreenInfo {...props} />
        </ContentBox>
      </AssessmentPlayerLayout.ContentContainer>
      <ControlBarStartTestScreen {...props} />
    </>
  )
}

import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  Theme as MuiTheme,
  Theme,
  alpha,
} from '@mui/material/styles'
import { ReactNode } from 'react'
import { brandingDark, brandingLight } from './branding-colors'

const basePalette = {
  primary: {
    main: '#8250E8',
    dark: '#8250E8',
    light: '#E8E8FE',
  },
  secondary: {
    main: '#E4E2E9',
    dark: '#DCD8E4',
    light: '#ECE9F2',
    darker: '6C6C6C',
  },
}

const baseTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained', // This makes the variant color the background and the text the contrast color not vice versa
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontWeight: 600,
          fontSize: '1.0625rem',
          textTransform: 'capitalize',
          '&.Mui-disabled': {
            backgroundColor: '#E4E2E9',
          },
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          // Bottom of DialogContent has padding 20px so adding 4px top here
          padding: '4px 24px 24px 24px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            borderRight: `solid 3px ${brandingDark}`,
            backgroundColor: brandingLight,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiMenu-paper': {
            borderRadius: 6,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          p: {
            fontWeight: 500,
            fontSize: '1.125rem',
            lineHeight: 1.278,
          },
          img: {
            maxWidth: '100%',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "proxima-soft,'Roboto', 'Helvetica', 'Arial', sans-serif",
    body1: {
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.278,
    },
  },
  shape: {
    borderRadius: 12,
  },
})

export const MinervaDefaultTheme = createTheme({
  ...baseTheme,
  palette: {
    ...basePalette,
    background: {
      default: '#F9FAFA',
    },
    text: {
      primary: '#333333',
      secondary: '#928BA0',
    },
    success: {
      main: '#69BA92',
      dark: '#169757',
    },
    warning: {
      main: '#F69134',
      dark: '#F79134',
    },
    info: {
      main: '#0288D1',
      light: alpha('#0288D1', 0.7),
    },
    error: {
      main: '#F52B2B',
    },
  },
})

const MinervaDarkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...basePalette,
    background: {
      paper: '#0A011C',
    },
  },
})

interface ThemeProviderProps {
  children: ReactNode | ReactNode[]
  theme?: Partial<Theme>
  mode?: 'light' | 'dark'
}

export type ThemeType = MuiTheme

export const ThemeProvider = (props: ThemeProviderProps) => {
  const { mode = 'light' } = props
  const defaultTheme = mode === 'light' ? MinervaDefaultTheme : MinervaDarkTheme
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={props.theme || defaultTheme} children={props.children} />
    </StyledEngineProvider>
  )
}

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const InvigilationCentreIcon = (props: SvgIconProps) => (
  <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 28.5C2.72386 28.5 2.5 28.7239 2.5 29V33.3898C2.5 33.666 2.72386 33.8898 3 33.8898H5.71186C5.98801 33.8898 6.21186 33.666 6.21186 33.3898V29C6.21186 28.7239 5.98801 28.5 5.71186 28.5H3ZM3.5 32.8898V29.5H5.21186V32.8898H3.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.75 29C33.75 28.7239 33.9739 28.5 34.25 28.5H36.9619C37.238 28.5 37.4619 28.7239 37.4619 29V33.3898C37.4619 33.666 37.238 33.8898 36.9619 33.8898H34.25C33.9739 33.8898 33.75 33.666 33.75 33.3898V29ZM34.75 29.5V32.8898H36.4619V29.5H34.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5 0H28.4579C29.0102 0 29.4579 0.447715 29.4579 1V5.74576C29.4579 6.29805 29.0102 6.74576 28.4579 6.74576H20.5V8.24071C20.7125 8.30862 20.913 8.4251 21.084 8.59014L32.6264 19.7292C32.9193 20.0119 33.0847 20.4015 33.0847 20.8086V24H38.9576C39.5099 24 39.9576 24.4201 39.9576 24.9384V38.2989C39.9576 38.8171 39.5099 39.2373 38.9576 39.2373H32.8913C32.6338 39.6926 32.1452 40 31.5847 40H8.5C7.97475 40 7.51255 39.73 7.2446 39.3213H1C0.447715 39.3213 0 38.9011 0 38.3829V25.0224C0 24.5041 0.447715 24.084 1 24.084H7V20.8086C7 20.4015 7.16545 20.0119 7.45836 19.7292L19.0007 8.59014C19.1488 8.44721 19.319 8.3407 19.5 8.27062V0ZM7 25.9608H2V37.4444H7V25.9608ZM33.0847 37.3605H37.9576V25.8768H33.0847V37.3605ZM21 2V4.74576H27.4579V2H21ZM10 37V21.4456L20.0424 11.7541L30.0847 21.4456V37H26.1285V27.6433C26.1285 24.2828 23.4042 21.5586 20.0437 21.5586C16.6832 21.5586 13.959 24.2828 13.959 27.6433V37H10ZM24.1285 27.6433V37H20.5V23.5838C22.5412 23.8107 24.1285 25.5416 24.1285 27.6433ZM19.5 23.5945V37H15.959V27.6433C15.959 25.5718 17.5011 23.8603 19.5 23.5945Z"
    />
  </SvgIcon>
)

import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { makeStyles, Menu, MenuItem, ThemeType, IconButton } from '@project-minerva/design-system'
import { DataTestId } from '@project-minerva/typings'
import imageCompression from 'browser-image-compression'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAnchorElement } from '../lib'

const useStyles = makeStyles((theme: ThemeType) => ({
  container: {
    position: 'relative',
    width: 300,
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    margin: theme.spacing(2),
  },
  input: {
    cursor: 'pointer',
    position: 'absolute',
    width: 300,
    height: 200,
    opacity: 0,
    top: 0,
    left: 0,
  },
  icon: {
    width: 50,
    height: 50,
  },
  toolbarButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  imageContainer: {
    position: 'relative',
    width: 'fit-content',
  },
}))

interface Props extends DataTestId {
  url: string | null
  setUrl: (url: null | string) => void
}

export const Base64ImageUploader = (props: Props) => {
  const styles = useStyles()
  const { url, setUrl } = props
  const [selectedFile, setSelectedFile] = useState<string | null>(null)
  const { anchorElement, setAnchor, unsetAnchor } = useAnchorElement()
  const handleDelete = () => {
    setSelectedFile(null)
    setUrl(null)
    unsetAnchor()
  }

  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) return

    const file = event.target.files[0]
    const reader = new FileReader()

    setSelectedFile(reader.result as string)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 500,
    }

    const compressedFile = await imageCompression(file, options)
    const url = await imageCompression.getDataUrlFromFile(compressedFile)
    setUrl(url)
  }

  return (
    <>
      {!url && (
        <div data-testid={props['data-testid']} className={styles.container}>
          <input
            accept="image/*"
            className={styles.input}
            id="base-64-image-upload"
            type="file"
            src={selectedFile || undefined}
            onChange={handleUploadClick}
          />
          <ImageRoundedIcon color="primary" className={styles.icon} />
        </div>
      )}
      {url && (
        <div data-testid={props['data-testid']} className={styles.imageContainer}>
          <IconButton
            className={styles.toolbarButton}
            aria-controls="options-menu"
            aria-haspopup="true"
            onClick={setAnchor}
            data-testid="image-uploader-dropdown"
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorElement}
            keepMounted
            open={Boolean(anchorElement)}
            onClose={unsetAnchor}
          >
            <MenuItem onClick={handleDelete} data-testid="image-uploader-delete-button">
              <FormattedMessage id="delete" defaultMessage="Delete" />
            </MenuItem>
          </Menu>

          <img alt="" src={url} />
        </div>
      )}
    </>
  )
}

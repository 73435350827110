export const HistoricApplicationsIcon = (props: { color: string }) => (
  <svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.7758 6.02053C26.9902 6.82073 26.5154 7.64324 25.7152 7.85765C24.915 8.07206 24.0925 7.59719 23.878 6.79699C23.6636 5.99679 24.1385 5.17428 24.9387 4.95987C25.7389 4.74546 26.5614 5.22033 26.7758 6.02053Z"
      fill={props.color}
    />
    <path
      d="M15.4157 7.37048C16.2442 7.37048 16.9157 6.69891 16.9157 5.87048C16.9157 5.04206 16.2442 4.37048 15.4157 4.37048C14.5873 4.37048 13.9157 5.04206 13.9157 5.87048C13.9157 6.69891 14.5873 7.37048 15.4157 7.37048Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9157 3.60053V34.3705C19.9157 34.9228 19.468 35.3705 18.9157 35.3705H11.9157C11.3634 35.3705 10.9157 34.9228 10.9157 34.3705V1.37048C10.9157 0.818199 11.3634 0.370483 11.9157 0.370483H18.9157C19.468 0.370483 19.9157 0.818199 19.9157 1.37048V2.46758C20.0437 2.24614 20.2561 2.07349 20.5227 2.00206L27.2842 0.190328C27.8176 0.0473864 28.366 0.363969 28.5089 0.897435L37.0499 32.773C37.1929 33.3064 36.8763 33.8548 36.3428 33.9977L29.5814 35.8095C29.0479 35.9524 28.4996 35.6358 28.3566 35.1024L19.9157 3.60053ZM33.824 28.461L26.8359 2.381L22.0063 3.67509L28.9944 29.7551L33.824 28.461ZM29.512 31.6869L30.0297 33.6188L34.8593 32.3247L34.3416 30.3928L29.512 31.6869ZM17.9157 2.37048V29.3705H12.9157V2.37048H17.9157ZM12.9157 33.3705V31.3705H17.9157V33.3705H12.9157Z"
      fill={props.color}
    />
    <path
      d="M5.41573 7.37048C6.24416 7.37048 6.91573 6.69891 6.91573 5.87048C6.91573 5.04206 6.24416 4.37048 5.41573 4.37048C4.5873 4.37048 3.91573 5.04206 3.91573 5.87048C3.91573 6.69891 4.5873 7.37048 5.41573 7.37048Z"
      fill={props.color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.91573 1.37048C0.91573 0.818199 1.36344 0.370483 1.91573 0.370483H8.91573C9.46801 0.370483 9.91573 0.818199 9.91573 1.37048V34.3705C9.91573 34.9228 9.46801 35.3705 8.91573 35.3705H1.91573C1.36344 35.3705 0.91573 34.9228 0.91573 34.3705V1.37048ZM7.91573 2.37048V29.3705H2.91573V2.37048H7.91573ZM2.91573 33.3705V31.3705H7.91573V33.3705H2.91573Z"
      fill={props.color}
    />
  </svg>
)

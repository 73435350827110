import { DocumentRenderer, TestScreenProps, useTestController } from '@project-minerva/assessment-player'
import { PingAction } from '@ctek/test-player'
import { useEffect } from 'react'
import { useLastContentViewed } from './last-content-viewed.provider'

export const ExaminationDocument = (props: TestScreenProps) => {
  const { usePingsHook } = props
  const documentPingDataHandler = (type: PingAction) => ({ completion: type === 'focus' ? 0 : 10000 })
  const { setLastContentViewed } = useLastContentViewed()
  const { currentContent } = useTestController()

  useEffect(() => {
    if (!currentContent) return

    setLastContentViewed(currentContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  usePingsHook?.(documentPingDataHandler)

  return <DocumentRenderer {...props} />
}

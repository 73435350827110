import { Box, BoxProps } from '@mui/system'
import { styled } from '@project-minerva/design-system'
import Brand from '../../../../../img/logo-iseb-nav.png'

export const MainMenuBranding = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundImage: `url(${Brand})`,
  backgroundPosition: 'center center',
  backgroundSize: '40px 60px',
  height: '60px',
  backgroundRepeat: 'no-repeat, no-repeat',
  margin: '16px 0px',
}))

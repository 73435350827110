import {
  isDocument as isDocumentTypeGuard,
  isPractice as isPracticeTypeGuard,
  isQuestion as isQuestionTypeGuard,
} from '../../question-document-utils'
import { TestNode } from '../../typings'
import { ContentNavigatorMachineContext } from './content-navigator.machine'

export const getGuards = <
  TTestNode extends TestNode<TContent, TAnswerContent>,
  TContent,
  TAnswerContent,
  TAnswer
>() => {
  type Context = ContentNavigatorMachineContext<TTestNode, TContent, TAnswerContent, TAnswer>
  const getContentItem = (ctx: Context, offset = 0) => ctx.contents[ctx.currentIndex + offset]

  const hasAnswered = (ctx: Context): boolean => !!ctx.selectedAnswer
  const isFirstContent = (ctx: Context): boolean => ctx.currentIndex <= 0
  const isLastContent = (ctx: Context): boolean => ctx.currentIndex >= ctx.contents.length
  const isDocument = (ctx: Context): boolean => isDocumentTypeGuard(getContentItem(ctx))
  const isPractice = (ctx: Context): boolean => isPracticeTypeGuard(getContentItem(ctx))
  const isNotPractice = (ctx: Context): boolean => !isPractice(ctx)
  const isQuestion = (ctx: Context): boolean => isQuestionTypeGuard(getContentItem(ctx))
  const isNextContentPractice = (ctx: Context): boolean => isPracticeTypeGuard(getContentItem(ctx, 1))
  const isNextContentNotPractice = (ctx: Context): boolean => !isNextContentPractice(ctx)
  const isPreviousContentPractice = (ctx: Context): boolean => isPracticeTypeGuard(getContentItem(ctx, -1))
  const isPreviousContentNotPractice = (ctx: Context): boolean => !isPreviousContentPractice(ctx)
  const isPreviousContentNotQuestion = (ctx: Context): boolean => !isQuestionTypeGuard(getContentItem(ctx, -1))

  return {
    hasAnswered,
    isFirstContent,
    isLastContent,
    isDocument,
    isPractice,
    isNotPractice,
    isQuestion,
    isNextContentPractice,
    isNextContentNotPractice,
    isPreviousContentPractice,
    isPreviousContentNotPractice,
    isPreviousContentNotQuestion,
  }
}

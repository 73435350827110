import { Alert, AlertTitle, alpha, Typography } from '@project-minerva/design-system'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { ReactNode } from 'react'

interface Props {
  title: ReactNode
  children: ReactNode
}

export function ApplicantOverviewErrorAlert(props: Props) {
  const { title, children } = props
  return (
    <Alert
      severity="error"
      sx={{ border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.2)}` }}
      iconCentred
      icon={<WarningRoundedIcon fontSize="large" />}
    >
      <AlertTitle sx={{ color: (theme) => theme.palette.error.main, mb: (theme) => theme.spacing(2) }}>
        <Typography variant="h5">{title}</Typography>
      </AlertTitle>
      {children}
    </Alert>
  )
}

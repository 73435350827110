import { Chip } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export function IncompleteChip() {
  return (
    <Chip
      color="warning"
      data-testid="incomplete-chip"
      label={<FormattedMessage id="incomplete" />}
      sx={{ color: 'white', height: '1.6rem' }}
    />
  )
}

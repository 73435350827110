import { Box, BoxProps } from '@mui/system'
import { styled } from '@project-minerva/design-system'
import Brand from '../../../../../img/logo-iseb-exam.png'

export const HeaderBranding = styled(Box)<BoxProps>({
  backgroundImage: `url(${Brand})`,
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: '187px 60px',
  height: '60px',
  width: '187px',
  top: '-1px',
})

import { Box } from '@project-minerva/design-system'
import { DataTestId } from '@project-minerva/typings'
import { FC, ReactNode } from 'react'

interface Props extends DataTestId {
  children: ReactNode
}
const MainContainer: FC<Props> = (props) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '52rem', // 800 plus padding
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        m: (theme) => theme.spacing(3, 0),
        p: (theme) => theme.spacing(0, 2),
      }}
      data-testid={props['data-testid']}
    >
      {props.children}
    </Box>
  )
}

const ContentContainer: FC<Props> = (props) => {
  return (
    <Box sx={{ width: '100%', position: 'relative', m: '50px 0px' }} data-testid={props['data-testid']}>
      {props.children}
    </Box>
  )
}

export const AssessmentPlayerLayout = Object.assign(
  {},
  {
    MainContainer,
    ContentContainer,
  }
)

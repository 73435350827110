import { useSelector } from '@xstate/react'
import { useCallback } from 'react'
import { State } from 'xstate'
import { PlayerCtx } from '../../player.provider'

import { useGenericTestFlowController } from '../test/test-flow.hooks'
import {
  NavigatorMachineContext as Context,
  NavigatorMachineEvent as Event,
  NavigatorMachineEvent,
} from './navigator.machine'

export const createNavigationControllerHook = <TSession, TNode, TContent, TAnswer, TSelectedAnswer>() => {
  type AssessmentState = State<
    Context<TSession, TNode, TContent, TAnswer, TSelectedAnswer>,
    Event<TSession, TNode, TContent, TAnswer, TSelectedAnswer>
  >
  const isDocumentSelector = (state: AssessmentState) => state.matches('document')
  const isQuestionSelector = (state: AssessmentState) => state.matches('question')
  const isOverviewSelector = (state: AssessmentState) => state.matches('overview')
  const isStartOfTestSelector = (state: AssessmentState) => state.context.currentIndex === 0
  const currentIndexSelector = (state: AssessmentState) => state.context.currentIndex
  const currentAnswerSelector = (state: AssessmentState) => state.context.currentAnswer
  const currentContentSelector = (state: AssessmentState) => state.context.currentContent
  const nodesSelector = (state: AssessmentState) => state.context.states
  const errorMessageSelector = (state: AssessmentState) => state.context.errorMessage

  const useNavigationController = (playerContext: React.Context<PlayerCtx<TSession>>) => {
    const { testMachine: machine } = useGenericTestFlowController(playerContext)
    const isDocument = useSelector(machine, isDocumentSelector)
    const isQuestion = useSelector(machine, isQuestionSelector)
    const isOverview = useSelector(machine, isOverviewSelector)
    const isStartOfTest = useSelector(machine, isStartOfTestSelector)
    const currentIndex = useSelector(machine, currentIndexSelector)
    const currentAnswer = useSelector(machine, currentAnswerSelector)
    const currentContent = useSelector(machine, currentContentSelector)
    const nodes = useSelector(machine, nodesSelector)
    const errorMessage = useSelector(machine, errorMessageSelector)

    const initialiseNavigation = useCallback(
      (
        loadPromise: (node: TNode) => Promise<TContent>,
        viewPromise: (node: TNode) => Promise<TSession>,
        submitPromise: (node: TNode, answer: TSelectedAnswer) => Promise<TSession>
      ) =>
        machine.send({
          type: 'INITIALISE',
          loadContent: loadPromise,
          submitAnswer: submitPromise,
          viewDocument: viewPromise,
        } as NavigatorMachineEvent<TSession, TNode, TContent, TAnswer, TSelectedAnswer>),
      [machine]
    )

    const storeAnswer = useCallback(
      (answer: TSelectedAnswer) => {
        machine.send({
          type: 'STORE_ANSWER',
          answerInput: answer,
        })
      },
      [machine]
    )

    const submitAnswerAndGoToContent = useCallback(
      (index: number) => machine.send({ type: 'GO_TO_CONTENT', index }),
      [machine]
    )

    return {
      isDocument,
      isQuestion,
      isOverview,
      isStartOfTest,
      currentContent,
      currentIndex,
      currentAnswer,
      nodes,
      errorMessage,
      initialiseNavigation,
      storeAnswer,
      submitAnswerAndGoToContent,
    }
  }

  return useNavigationController
}

import { HtmlRender } from '@project-minerva/core'
import { Box } from '@project-minerva/design-system'

const ExaminationDescription = () => (
  <Box
    sx={{
      width: '100%',
      backgroundColor: '#EDEBEF',
      padding: '1rem 2rem',
      borderRadius: 2,
      mb: '1rem',
      fontSize: '.9rem',
    }}
  >
    <HtmlRender
      data-testid="exam-intro-content"
      html={`
        <p>The tests you can take are listed here. Your teacher or invigilator will tell you which test to select and when.</p>
        <p>You will be able to begin by selecting the Start Test button next to the test name.</p>
        <p>The instructions for setting up your page will be shown on each test after you select Start. This will not impact the time you have to answer the questions.</p>
        <p>If you have any questions you should ask them now before you begin.</p>
      `}
    />
  </Box>
)

export default ExaminationDescription

import {
  alpha,
  Box,
  Button,
  CompleteTag,
  List,
  ListItem,
  makeStyles,
  ThemeType,
  Typography,
} from '@project-minerva/design-system'
import { TestData } from '@project-minerva/typings'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme: ThemeType) => ({
  subjectItem: {
    width: '100%',
    minHeight: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px ${theme.palette.secondary.light} solid`,
    boxShadow: `0px 0px 1px 1px ${theme.palette.secondary.light}`,
    textTransform: 'uppercase',
    background: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    '&:hover': {
      borderColor: theme.palette.primary.dark,
      boxShadow: `0px 2px 3px ${alpha('#32058B', 0.15)}`,
      borderWidth: 1,
      transform: 'translateY(-1px)',
    },
  },
  text: {
    fontWeight: 600,
    fontSize: '1.125rem',
    marginLeft: 18,
    height: 'fit-content',
  },
}))

export function TestList(props: { onItemClick: (subjectId: string, testId: string) => void; tests: TestData[] }) {
  const styles = useStyles()
  const { tests, onItemClick } = props

  return (
    <Box sx={{ width: '100%' }}>
      {tests.length > 0 ? (
        <List sx={{ pt: 0, pb: 0 }}>
          {tests
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((test) => (
              <ListItem className={styles.subjectItem} key={test.id} data-testid="subject-item">
                <div
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  data-testid={`subject-item-${test.name}`}
                >
                  <img alt={test.name} src={getSubjectIconUrl(test.subjectId)} />
                  {test.name && (
                    <Typography className={styles.text} variant="h6">
                      {test.name.toLowerCase()}
                    </Typography>
                  )}
                </div>
                {test.isClosed ? (
                  <CompleteTag />
                ) : (
                  <Button sx={{ height: '2rem' }} onClick={() => onItemClick(test.subjectId, test.id)}>
                    {test.startTime ? (
                      <FormattedMessage id="continue-test" defaultMessage="Continue Test" />
                    ) : (
                      <FormattedMessage id="start-test" defaultMessage="Start Test" />
                    )}
                  </Button>
                )}
              </ListItem>
            ))}
        </List>
      ) : (
        <FormattedMessage id="no-subjects-available" />
      )}
    </Box>
  )
}

// ! TODO - find better subject icon mapping
const getSubjectIconUrl = (subjectIdOrName: string) => {
  switch (subjectIdOrName) {
    case 'c85517c4-ac6d-4896-a84d-85fca3eeca93': // critical_thinking_problem_solving
      return '/assets/images/subjects/icon-criticalthinking.svg'
    case '591b5d4f-49cf-4c0e-918a-08c135ecf50b': // english
    case '03ebebf8-d7a1-458d-836d-a4b7e6373c88': // reading_comprehension
    case 'f9e83381-7a1b-4dd5-bd8c-9f5040c25b2c': // grammar
      return '/assets/images/subjects/icon-english.svg'
    case 'd2fb0442-7ad3-4ac8-9b08-86bd3efa5aab': // mathematics
      return '/assets/images/subjects/icon-math.svg'
    case 'dcd65261-552f-4e0a-a8d4-846dc8db99dd': // non_verbal_reasoning
      return '/assets/images/subjects/icon-nonverbalresoning.svg'
    case '8346c5c6-0f71-4015-a8a3-8e2e47772d6f': // verbal_reasoning
      return '/assets/images/subjects/icon-verbalreasoning.svg'
    case '7bfb36da-03a1-42c5-b328-a1d3c4a555c6': // spatial_reasoning
      return '/assets/images/subjects/icon-spatialreasoning.svg'
    case 'creative_response_ID': // creative_response
      return '/assets/images/subjects/icon-creativeresponse.svg'
    default:
      return ''
  }
}

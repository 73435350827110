import { DataGridProps } from '@mui/x-data-grid'
import { DataGrid } from './ctek-data-grid'
import { useState } from 'react'

export const PaginatedDataGrid = (
  props: DataGridProps & {
    nextPage?: () => void
    previousPage?: () => void
    count?: number
    enableColumnMenu?: boolean
    pageNum?: number
    hideColumnHeaders?: boolean
  }
) => {
  const { previousPage, nextPage, pageSize = 30, count, pageNum } = props
  const [pageNumber, setPageNumber] = useState(pageNum ?? 0)

  const onPageChange = (newPageNumber: number) => {
    if (newPageNumber < pageNumber && previousPage) {
      previousPage()
      setPageNumber(pageNumber - 1)
    }
    if (newPageNumber > pageNumber && nextPage) {
      nextPage()
      setPageNumber(pageNumber + 1)
    }
  }

  const getRowCount = () => {
    if (count) return count
    return nextPage ? (pageNumber + 1) * pageSize + 1 : pageNumber * pageSize + props.rows.length
  }

  return (
    <DataGrid
      disableColumnMenu={!props.enableColumnMenu}
      rowCount={getRowCount()}
      page={pageNumber}
      paginationMode="server"
      onPageChange={onPageChange}
      pageSize={pageSize}
      rowsPerPageOptions={[pageSize]}
      {...props}
    />
  )
}

import { useSelector } from '@xstate/react'
import { useCallback } from 'react'
import { State } from 'xstate'
import { PlayerCtx } from '../../player.provider'
import { TestNode } from '../../typings'
import { useGenericTestFlowController } from '../test/test-flow.hooks'
import { getGuards } from './content-navigator.guards'
import {
  ContentNavigatorMachineContext as Context,
  ContentNavigatorMachineEvent as Event,
} from './content-navigator.machine'
import { AnsweredQuestionData } from './content-navigator.types'

export const createContentNavigationControllerHook = <
  TSession,
  TTestNode extends TestNode<TContent, TAnswerContent>,
  TContent,
  TAnswerContent,
  TSelectedAnswer
>() => {
  type AssessmentState = State<
    Context<TTestNode, TContent, TAnswerContent, TSelectedAnswer>,
    Event<TTestNode, TContent, TAnswerContent, TSelectedAnswer>
  >

  const isPracticeSelector = (state: AssessmentState) => state.matches('practiceMode')
  const isPracticeQuestionSelector = (state: AssessmentState) => state.matches('practiceMode.practiceQuestion')
  const isPracticeFeedbackSelector = (state: AssessmentState) => state.matches('practiceMode.practiceFeedback')
  const isDocumentSelector = (state: AssessmentState) => state.matches('document')
  const isQuestionSelector = (state: AssessmentState) => state.matches('question')
  const isPreviousNotQuestionContentSelector = (state: AssessmentState) => {
    const { isPreviousContentNotQuestion } = getGuards<TTestNode, TContent, TAnswerContent, TSelectedAnswer>()
    return isPreviousContentNotQuestion(state.context)
  }
  const isStartOfTestSelector = (state: AssessmentState) => state.context.currentIndex === 0
  const selectedAnswerSelector = (state: AssessmentState) => state.context.selectedAnswer
  const currentContentSelector = (state: AssessmentState) => state.context.contents[state.context.currentIndex]
  const answerHistorySelector = (state: AssessmentState) => state.context.answerHistory
  // TODO: create const/enum for invoked/spawned machines
  const getPongMachineSelector = (state: AssessmentState) => state.children['ping-machine']

  const useGenericTestController = (playerContext: React.Context<PlayerCtx<TSession>>) => {
    const { testMachine: machine } = useGenericTestFlowController(playerContext)
    const isPractice = useSelector(machine, isPracticeSelector)
    const isPracticeQuestion = useSelector(machine, isPracticeQuestionSelector)
    const isPracticeFeedback = useSelector(machine, isPracticeFeedbackSelector)
    const isDocument = useSelector(machine, isDocumentSelector)
    const isQuestion = useSelector(machine, isQuestionSelector)
    const isPreviousContentNotQuestion = useSelector(machine, isPreviousNotQuestionContentSelector)
    const isStartOfTest = useSelector(machine, isStartOfTestSelector)
    const currentContent = useSelector(machine, (state: AssessmentState) => currentContentSelector(state))
    const selectedAnswer = useSelector(machine, (state: AssessmentState) => selectedAnswerSelector(state))
    const answerHistory = useSelector(machine, (state: AssessmentState) => answerHistorySelector(state))
    const pongMachine = useSelector(machine, getPongMachineSelector)

    const setSelectedAnswer = useCallback(
      (answer: TSelectedAnswer) => machine.send({ type: 'ANSWER_QUESTION', selectedAnswer: answer }),
      [machine]
    )
    const appendTestContent = useCallback(
      // (newContent: CoreContent<TTestNode, TContent>[]) => {
      (newContent: TestNode<TContent, TContent>[]) => {
        return machine.send({
          type: 'APPEND_CONTENT',
          content: newContent,
        })
      },
      [machine]
    )
    const next = useCallback(
      (answeredQuestionData?: AnsweredQuestionData<TSelectedAnswer>) => {
        if (answeredQuestionData) {
          const newAnswerHistory = { ...answerHistory }
          newAnswerHistory[answeredQuestionData?.questionId] = answeredQuestionData?.answer
          return machine.send({ type: 'NEXT', answerHistory: newAnswerHistory })
        }
        return machine.send('NEXT')
      },
      [machine, answerHistory]
    )
    const prevHookCallback = useCallback(() => machine.send('PREVIOUS'), [machine])

    // Answer history will be null if the question was 'skipped'
    const isPreviouslyAnswered = currentContent?.id
      ? answerHistory[currentContent.id] !== undefined || currentContent.completed
      : false
    const previousAnswer = isPreviouslyAnswered ? answerHistory[currentContent.id] : null

    return {
      isPractice,
      isPracticeQuestion,
      isPracticeFeedback,
      isDocument,
      isQuestion,
      isStartOfTest,
      currentContent,
      isPreviousContentNotQuestion,
      selectedAnswer,
      setSelectedAnswer,
      next,
      prevHookCallback,
      isPreviouslyAnswered,
      previousAnswer,
      pongMachine,
      appendTestContent,
    }
  }

  return useGenericTestController
}

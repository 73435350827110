import { capitaliseFirstLetterOfString, getTimezonedyyyymmddDate } from '@project-minerva/base-utils'
import { Box, Chip, Divider, Typography } from '@project-minerva/design-system'
import { AdminSchoolsApplicantWithGuardian, Gender, ISEBApplicant } from '@project-minerva/typings'
import { format } from 'date-fns'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaperPanel } from '../panel'
import { PrimaryTypographyTitleValue, YesNoIncomplete } from '../text'
import { EvaGender } from '../text/gender'

interface Props {
  applicant: AdminSchoolsApplicantWithGuardian | ISEBApplicant
  children?: ReactNode
}

export function ApplicationPersonalInformationWidget(props: Props) {
  const { applicant, children } = props

  return (
    <PaperPanel>
      <Typography variant="h5">
        <FormattedMessage id="applicant-information" defaultMessage="Applicant Information" />
      </Typography>
      {/* Row 1 */}
      <Box>
        <PrimaryTypographyTitleValue
          title={<FormattedMessage id="academic-year" defaultMessage="Academic Year" />}
          data-testid="academic-year"
          value={<Chip size="small" color="primary" label={applicant.academicYear.name} />}
        />
      </Box>
      <Divider sx={{ m: (theme) => theme.spacing(2, 0, 2, 0) }} />
      {/* Row 2 */}
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', mt: (theme) => `-${theme.spacing(1)}` }}
      >
        <Box sx={{ m: (theme) => theme.spacing(0, 3, 1, 0) }}>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="first-name" defaultMessage="First Name" />}
            data-testid="first-name"
            value={applicant.firstName}
          />
        </Box>
        {applicant.middleName && (
          <Box sx={{ m: (theme) => theme.spacing(0, 3, 1, 0) }}>
            <PrimaryTypographyTitleValue
              title={<FormattedMessage id="middle-name" defaultMessage="Middle Name" />}
              data-testid="middle-name"
              value={applicant.middleName}
            />
          </Box>
        )}
        <Box sx={{ m: (theme) => theme.spacing(0, 3, 1, 0) }}>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="last-name" defaultMessage="Last Name" />}
            data-testid="last-name"
            value={applicant.lastName}
          />
        </Box>
      </Box>
      {/* Row 3 */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
        <Box mr={3}>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="date-of-birth" defaultMessage="Date of Birth" />}
            data-testid="dob"
            value={format(getTimezonedyyyymmddDate(applicant.dateOfBirth), 'dd MMMM yyyy')}
          />
        </Box>
        <Box mr={3}>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="gender" defaultMessage="Gender" />}
            data-testid="gender"
            value={<EvaGender gender={applicant.gender as Gender} />}
          />
        </Box>
        <Box mr={3}>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="send" defaultMessage="SEND" />}
            data-testid="send"
            value={<YesNoIncomplete truthy={applicant.isSend} />}
          />
        </Box>
        <Box>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="eal" defaultMessage="EAL" />}
            data-testid="eal"
            value={<YesNoIncomplete truthy={applicant.isEal} />}
          />
        </Box>
      </Box>
      <Divider sx={{ m: (theme) => theme.spacing(2, 0, 2) }} />
      {/* Row 4 */}
      <Box sx={{ mt: (theme) => `-${theme.spacing(1)}` }}>
        <PrimaryTypographyTitleValue
          title={<FormattedMessage id="current-school-short" defaultMessage="Current school" />}
          data-testid="current-school"
          value={applicant.currentSchool}
        />
      </Box>
      <Divider sx={{ m: (theme) => theme.spacing(2, 0, 2) }} />
      {/* Row 5 */}
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', mt: (theme) => `-${theme.spacing(1)}` }}
      >
        <Box sx={{ m: (theme) => theme.spacing(0, 3, 1, 0) }}>
          <PrimaryTypographyTitleValue
            title={capitaliseFirstLetterOfString(applicant.guardian.relation)}
            data-testid="guardian-relation"
            value={`${applicant.guardian.firstName} ${applicant.guardian.lastName}`}
          />
        </Box>
        <Box sx={{ m: (theme) => theme.spacing(0, 3, 1, 0), wordBreak: 'break-all' }}>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="email-address" defaultMessage="Email address" />}
            data-testid="guardian-email-address"
            value={applicant.guardian.email}
          />
        </Box>
      </Box>
      <Box>
        {/* Row 6 */}
        {applicant.guardian.phone && (
          <Box>
            <PrimaryTypographyTitleValue
              title={<FormattedMessage id="mobile-number" defaultMessage="Mobile number" />}
              data-testid="guardian-telephone"
              value={applicant.guardian.phone}
            />
          </Box>
        )}
      </Box>
      {children}
    </PaperPanel>
  )
}

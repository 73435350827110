// --- 3rd Party ---
import { jwtDecode } from 'jwt-decode'

const LOCALSTORAGE_AUTH_KEY = process.env.NX_LOCALSTORAGE_AUTH_KEY || 'auth'

export interface LocalStorageAuth {
  accessToken: string
  refreshToken: string
}

export type Auth = LocalStorageAuth | string | Error

export function isLocalStorageAvailable() {
  try {
    return 'localStorage' in window && window['localStorage'] !== null
  } catch (e) {
    return false
  }
}

export function getAuthFromLS(parse = true): Auth | Error {
  try {
    return getItemFromLS(LOCALSTORAGE_AUTH_KEY, parse) as Auth
  } catch (err) {
    return err as Error
  }
}

export function decodeAuthFromLS(): unknown | Error {
  const auth = getAuthFromLS() as LocalStorageAuth
  if (auth instanceof Error || !auth.accessToken || !auth.refreshToken) {
    return new Error('Could not decode the auth object.')
  }

  try {
    return jwtDecode(auth.accessToken)
  } catch (error) {
    return error
  }
}

export function setAuthInLS(auth: LocalStorageAuth): void | Error {
  return setItemInLS(auth, LOCALSTORAGE_AUTH_KEY, true)
}

export function clearAuthInLS(): void | Error {
  return clearItemInLS(LOCALSTORAGE_AUTH_KEY)
}

export function setItemInLS(item: unknown, storageKey: string, stringify?: boolean): void | Error {
  if (!isLocalStorageAvailable()) {
    return new Error('This browser does not support local storage.')
  }

  try {
    localStorage.setItem(storageKey, stringify ? JSON.stringify(item) : (item as string))
  } catch (error) {
    return error as Error
  }
}

export function getItemFromLS(storageKey: string, parse?: boolean): unknown | Error {
  if (!isLocalStorageAvailable()) {
    throw new Error('This browser does not support local storage.')
  }

  const item = localStorage.getItem(storageKey)

  if (item) {
    return parse === true ? JSON.parse(item) : item
  }

  throw new Error(`Could not find any auth item in local storage.`)
}

export function clearItemInLS(storageKey: string): void | Error {
  if (!isLocalStorageAvailable()) {
    return new Error('This browser does not support local storage.')
  }

  try {
    localStorage.removeItem(storageKey)
  } catch (error) {
    return error as Error
  }
}

import { CodeAuthentication } from '@project-minerva/typings'
import React from 'react'
import { BasicAuthProvider } from './basic-auth.context'

const LOCAL_STORAGE_STUDENT_KEY = 'student-login-auth-key'

export const AssessmentAuthProvider = (props: {
  loginUrl: string
  navigateToOnLoggingIn: string
  children: React.ReactNode
}) => {
  const validate = async (validateRequest: () => Promise<CodeAuthentication>): Promise<CodeAuthentication> => {
    return await validateRequest()
  }

  return (
    <BasicAuthProvider {...props} validate={validate} authLocalStorageKey={LOCAL_STORAGE_STUDENT_KEY}>
      {props.children}
    </BasicAuthProvider>
  )
}

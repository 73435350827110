import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { copyToClipboard } from '@project-minerva/base-utils'
import { Alert, Box, PrimaryTypography, Typography, IconButton } from '@project-minerva/design-system'
import { ApplicantStatus } from '@project-minerva/typings'
import { FormattedMessage } from 'react-intl'
import { ColouredStatusBox } from '../containers'
import { PaperPanel } from '../panel'

interface Props {
  applicant: { applicantCode: string; status: ApplicantStatus }
  codeDescription: React.ReactNode
  includeCopyButton?: boolean
  children?: React.ReactNode
}

export function ApplicantIdWidget(props: Props) {
  const { applicant, children, codeDescription, includeCopyButton } = props
  return (
    <PaperPanel data-testid="applicant-id-widget">
      <Typography variant="h5" mb={1.5}>
        <FormattedMessage id="applicant-id" defaultMessage="Applicant ID" />
      </Typography>
      {applicant.status !== 'COMPLETE' ? (
        <Alert severity="info" sx={{ m: (theme) => theme.spacing(0, 1) }}>
          <Typography variant="body2" data-testid="applicant-id-hidden-text">
            <FormattedMessage
              id="applicant-id-hidden-info"
              defaultMessage="Applicant ID hidden until profile is completed."
            />
          </Typography>
        </Alert>
      ) : (
        <>
          <ColouredStatusBox status="primary">
            <Box
              mb={1}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', wordBreak: 'break-all' }}
            >
              <PrimaryTypography variant="h5" fontWeight={600} data-testid="applicant-id-text">
                {applicant.applicantCode}
              </PrimaryTypography>
              {includeCopyButton && (
                <IconButton
                  onClick={() => copyToClipboard(applicant.applicantCode)}
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.dark,
                    ' &:hover, &.Mui-focusVisible ': {
                      backgroundColor: 'primary.main',
                    },
                  }}
                  color="primary"
                >
                  <ContentCopyIcon sx={{ color: 'common.white', fontSize: (theme) => theme.spacing(2) }} />
                </IconButton>
              )}
            </Box>

            {codeDescription && (
              <PrimaryTypography variant="subtitle2" data-testid="applicant-id-share-info">
                {codeDescription}
              </PrimaryTypography>
            )}
          </ColouredStatusBox>
          {children}
        </>
      )}
    </PaperPanel>
  )
}

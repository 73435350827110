import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

interface PageLayoutProps extends BoxProps {
  centred?: boolean
  horizontalCentred?: boolean
  verticalCentred?: boolean
  direction?: 'row' | 'column'
  root?: boolean
  offsetY?: number
}

export const PageLayout = ({
  centred,
  horizontalCentred,
  verticalCentred,
  direction,
  root,
  offsetY,
  ...boxProps
}: PageLayoutProps) => (
  <Box
    {...boxProps}
    sx={{
      backgroundColor: (theme) => (root ? theme.palette.background.default : undefined),
      position: 'relative',
      top: offsetY,
      display: 'flex',
      flexGrow: 1,
      flexDirection: direction ?? 'column',
      alignItems: centred || horizontalCentred ? 'center' : 'flex-started',
      justifyContent: centred || verticalCentred ? 'center' : 'flex-started',
      width: '100%',
      height: 'inherit',
      ...boxProps.sx,
    }}
  >
    {boxProps.children}
  </Box>
)

export const BoxExpanded = styled(Box)({
  position: 'relative',
  flexGrow: 1,
  height: '100%',
})

import { ReactNode } from 'react'

export function renderIntlLink(chunks: string, link: string): ReactNode {
  return (
    <a style={{ color: 'inherit' }} target="_blank" href={link} rel="noreferrer">
      {chunks}
    </a>
  )
}

export function renderIntlEmail(chunks: string, email: string): ReactNode {
  return (
    <a style={{ color: 'inherit' }} href={`mailto:${email}`}>
      {email}
    </a>
  )
}

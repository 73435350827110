import { AnyStateMachine, createMachine, InterpreterFrom } from 'xstate'

export type TestFlowMachineInterpreter<TSession> = InterpreterFrom<ReturnType<WrapperForTestFlow<TSession>['wrapped']>>

// eslint-disable-next-line @typescript-eslint/ban-types
export type TestFlowMachineContext = {}

export type TestFlowMachineEvent<TState> =
  | { type: 'START_TEST'; callback: () => Promise<TState> }
  | { type: 'TEST_COMPLETED' }
  | { type: 'TIME_OUT' }

type TestFlowMachineService<TSession> = {
  initialiseStudySession: { data: TSession }
}

export const getTestFlowMachine = <TSession>(createContentMachine: (data: TSession) => AnyStateMachine) => {
  type TestSession = TSession
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QBc7ILQDMA2B7A7gHSzICGATsgMoDG5YYAdgMRUAqAggEpsD6bAUXYBtAAwBdRKAAOuWAEtk83IykgAHogCMAZgAshHQDYdO3QA5de0Vr0BWADQgAntt2EATEa1GjAdjtzPwBOIxs7AF8Ip1QSLDwieUZFeVJseQVGKCpkAFcIZyo4BRVmCBUwQiSAN1wAa0qklLSMsBz8wuLlRjFJJBBZBSUVNU0EPS07TyM7I0tzc1mtcx0nVwQPHT9CCb0VsxM7UOC9KJi0eIIq5KUWzOy8gqLYEpYwcnJcckJpbFJkTBfAC212a6VgbUenRe3V6akGKRG-TGwS0hDsoh0wQ8GLsITCmLW2j8HkIoj8WNMok2ej0fiMp2iIFiGBwVxZtHoTDKFWutQahBZ6CBpBoAAskmB0DV6pA4f0EcNVMjEGFgoY7B5zPYtKEbOYiQhjOqJkFscFguZgpivGdmRc2UQOXQGCxBOxeABhADyAFkAAoAGQEggAIvKZHJEcrQGMTGjNrM9h4LZbRAaXIgvNsvGE7DoPLqPOmtHahY7BWhOa7mGwAJK+gS8b0AVTYEYGUaVo1VRlJOOtVpMOk1FsNejMhHMojx5JTfj0uaiTMYuAgcDU5YS8K73R7CHQ5iMhC0OMNWlEO1TR58di09NEczLDoSxDIlGrTB3Qz3KoPOnME8z0zBAtHcLwfH8QJ8XCZ84grJpbnBJIHg6Z5Xm-aN91MY9dXzPxREfTUwNsQ1gm2VNLW8GZ7zCPw4NZV9nS5GNOx-JFY0QexSUmEx0wxPwwJTRwQILbYsUWHxllmDwAgYy4iBoXAgV+MBUAgTDuz-G8pxCEkcQ8awTlWEDZO2PEjgvGxRFRTV5IrJQgTAb1cmQTTf04hAgjRYwJhsExglmYJxx0S87HCgiTD7TEKWXCIgA */
      tsTypes: {} as import('./test-flow.machine.typegen').Typegen0,
      schema: {
        context: {} as TestFlowMachineContext,
        events: {} as TestFlowMachineEvent<TestSession>,
        services: {} as TestFlowMachineService<TestSession>,
      },
      id: 'test-flow',
      initial: 'startScreen',
      states: {
        startScreen: {
          tags: ['startScreen'],
          on: {
            START_TEST: 'initialisingStudySession',
          },
        },
        initialisingStudySession: {
          tags: ['startScreen', 'initialising'],
          invoke: {
            id: 'initialiseStudySession',
            src: 'initialiseStudySession',
            onDone: 'testScreen',
            onError: 'startScreen',
          },
        },
        testScreen: {
          on: {
            TEST_COMPLETED: 'completed',
            TIME_OUT: 'timeOut',
          },
          invoke: {
            // NOTE: is handling needed for passing an empty array?
            id: 'test-machine-invoked',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            src: (_, evt) => createContentMachine((evt as any).data),
            onDone: 'completed',
          },
        },
        completed: {
          type: 'final',
        },
        timeOut: {
          type: 'final',
        },
      },
    },
    {
      services: {
        initialiseStudySession: (_, evt) => evt.callback(),
      },
    }
  )
}

// HACK for typings https://stackoverflow.com/questions/50321419/typescript-returntype-of-generic-function
class WrapperForTestFlow<TSession> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapped(createNewMachine: any) {
    return getTestFlowMachine<TSession>(createNewMachine)
  }
}

import { Stack } from '@mui/material'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector } from '@mui/x-data-grid'
import { Box, FormControlLabel, SearchInput, Switch, Typography } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded'
import DensityMediumRoundedIcon from '@mui/icons-material/DensityMediumRounded'
import { AcademicYear } from '@project-minerva/typings'
import AcademicYearDropdown from '../academic-year/academic-year-dropdown'

export const DataGridToolbar = ({
  filterEal,
  filterSend,
  onEalFilterChange,
  onSendFilterChange,
  onSearchChange,
  forceSearch,
  initialSearch,
  includeControls,
  includeAcademicYear,
  availableAcademicYears,
  selectedYear,
  onSelectedYearChange,
}: {
  filterEal: boolean | undefined
  filterSend: boolean | undefined
  onEalFilterChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
  onSendFilterChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
  onSearchChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
  forceSearch: (value: string) => void
  initialSearch: string
  includeControls?: boolean
  includeAcademicYear?: boolean
  availableAcademicYears?: AcademicYear[]
  selectedYear?: AcademicYear
  onSelectedYearChange?: (newYear: AcademicYear) => void
}) => {
  return (
    <GridToolbarContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          mb: (theme) => theme.spacing(0),
        }}
      >
        <Box>
          {includeControls && (
            <Stack spacing={1} direction="row">
              <GridToolbarColumnsButton startIcon={<ViewWeekRoundedIcon />} />
              <GridToolbarDensitySelector startIcon={<DensityMediumRoundedIcon />} />
              <Box sx={{ pl: (theme) => theme.spacing(2) }}>
                <FormControlLabel
                  control={<Switch checked={filterSend} onChange={onSendFilterChange} />}
                  label={
                    <Typography variant="subtitle1" fontWeight={600} color="primary">
                      <FormattedMessage id="send" defaultMessage="SEND" />
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={<Switch checked={filterEal} onChange={onEalFilterChange} />}
                  label={
                    <Typography variant="subtitle1" fontWeight={600} color="primary">
                      <FormattedMessage id="eal" defaultMessage="EAL" />
                    </Typography>
                  }
                />
              </Box>
            </Stack>
          )}
        </Box>
        <Box sx={{ verticalAlign: 'middle' }}>
          <Stack spacing={2} direction="row">
            {includeAcademicYear && availableAcademicYears && selectedYear && onSelectedYearChange && (
              <AcademicYearDropdown
                availableAcademicYears={availableAcademicYears}
                selectedYear={selectedYear}
                onSelectedYearChange={onSelectedYearChange}
              />
            )}
            <SearchInput
              data-testid="applicant-search"
              onChange={onSearchChange}
              onEnterKey={forceSearch}
              value={initialSearch}
            />
          </Stack>
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

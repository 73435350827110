import { Stack } from '@mui/material'
import { Box, BoxExpanded, LeftAlign, PageLayout, TertiaryButton, Typography } from '@project-minerva/design-system'
import { renderIntlLink } from '@project-minerva/intl'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { HeaderTitle } from '../header'
import { PaperPanel } from '../panel'
import { PrivilegedPageHeader } from '../privilege'
import { ChangeEmailModal } from './change-email-modal'
import { ChangePasswordModal } from './change-password-modal'
import { EditPersonalInformationModal } from './edit-personal-information-modal'
import { UserProfileProvider, useUserProfile } from './user-profile.provider'

const toggleValue = (value: boolean) => !value

interface Props {
  deleteInfoBox?: boolean
}

export const UserProfile = (props: Props) => (
  <PageLayout>
    <PrivilegedPageHeader>
      <LeftAlign>
        <HeaderTitle data-testid="user-profile-header-title">
          <FormattedMessage id="user-profile" defaultMessage="Account" />
        </HeaderTitle>
      </LeftAlign>
    </PrivilegedPageHeader>
    <BoxExpanded id="user-profile-container">
      <PageLayout centred>
        <UserProfileProvider>
          <UserProfileContent {...props} />
        </UserProfileProvider>
      </PageLayout>
    </BoxExpanded>
  </PageLayout>
)

const UserProfileContent = ({ deleteInfoBox }: Props) => {
  const { user } = useUserProfile()
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState(false)
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)
  const [editInfoModalOpen, setEditInfoModalOpen] = useState(false)
  const toggleChangeEmailModal = () => setChangeEmailModalOpen(toggleValue)
  const toggleChangePasswordModal = () => setChangePasswordModalOpen(toggleValue)
  const toggleEditInfoModal = () => setEditInfoModalOpen(toggleValue)

  return (
    <>
      <BoxExpanded id="user-profile-container">
        <PageLayout centred>
          <Box sx={{ width: 400 }}>
            <PaperPanel data-testid="user-profile-data">
              <Stack>
                <Typography variant="h5" mb={1}>
                  <FormattedMessage id="personal-information" defaultMessage="Personal Information" />
                </Typography>
                <UserDataField fieldId="first-name" label="onboard-modal-form-first-name" value={user.firstName} />
                <UserDataField fieldId="last-name" label="onboard-modal-form-last-name" value={user.lastName} />
                {user.phone && <UserDataField fieldId="phone-number" label="phone-number" value={user.phone} />}
                <Box>
                  <TertiaryButton onClick={toggleEditInfoModal} data-testid="edit-personal-info-button">
                    <FormattedMessage id="edit-personal-info" defaultMessage="Edit Personal Information" />
                  </TertiaryButton>
                </Box>
              </Stack>
            </PaperPanel>
            <PaperPanel>
              <Stack spacing={1.5}>
                <Typography variant="h5">
                  <FormattedMessage id="account-details" defaultMessage="Account Details" />
                </Typography>
                <UserDataField fieldId="email" label="email" value={user.email} />
                <Stack direction="row" spacing={1.5}>
                  <TertiaryButton onClick={toggleChangeEmailModal} data-testid="change-email-button">
                    <FormattedMessage id="change-email" defaultMessage="Change Email" />
                  </TertiaryButton>
                  <TertiaryButton onClick={toggleChangePasswordModal} data-testid="change-password-button">
                    <FormattedMessage id="change-password" defaultMessage="Change Password" />
                  </TertiaryButton>
                </Stack>
              </Stack>
            </PaperPanel>
            {deleteInfoBox && (
              <PaperPanel data-testid="delete-account-info">
                <FormattedMessage
                  id="delete-account-info"
                  values={{
                    a: (chunk: string) =>
                      renderIntlLink(chunk, 'http://pretests.support.iseb.co.uk/support/tickets/new'),
                  }}
                />
              </PaperPanel>
            )}
          </Box>
        </PageLayout>
      </BoxExpanded>
      <ChangePasswordModal open={changePasswordModalOpen} closeModal={toggleChangePasswordModal} />
      <ChangeEmailModal open={changeEmailModalOpen} closeModal={toggleChangeEmailModal} />
      <EditPersonalInformationModal open={editInfoModalOpen} closeModal={toggleEditInfoModal} />
    </>
  )
}

const UserDataField = ({ fieldId, label, value }: { fieldId: string; label?: string; value: string }) => {
  return (
    <Box mb={2}>
      <Typography data-testid={`${fieldId}-label`} color={(theme) => theme.palette.text.secondary} mb={1}>
        <FormattedMessage id={label ?? fieldId} />
      </Typography>
      <Typography data-testid={`${fieldId}-value`}>{value}</Typography>
    </Box>
  )
}

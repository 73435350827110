import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import { Button } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import { useCognitoSignOut } from './cognito-authenticator'

export const CognitoSignOut = (props: { color?: 'primary' | 'secondary' }) => {
  const { color = 'primary' } = props

  const logout = useCognitoSignOut()

  return (
    <Button sx={{ width: 'fit-content' }} startIcon={<PowerSettingsNewIcon />} onClick={logout} color={color}>
      <FormattedMessage id="logout" />
    </Button>
  )
}

import { isDev } from '@project-minerva/base-utils'
import { AppType } from '@project-minerva/typings'
import * as Sentry from '@sentry/react'
import { Event, EventHint } from '@sentry/react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import mixpanel from 'mixpanel-browser'

export const configureMixpanel = () => {
  mixpanel.init(process.env.NX_MIXPANEL_TOKEN || 'MIXPANEL_TOKEN', {
    debug: isDev(),
  })
}

export const configureBetterStack = () => {
  //TODO: uncomment before release
  // if (isDev()) return

  const script = document.createElement('script')
  script.src = 'https://uptime.betterstack.com/widgets/announcement.js'
  script.async = true
  script.setAttribute('data-id', '180079')
  document.body.appendChild(script)
}

const isLogRocketEnabled = () => process.env.NX_LOGROCKET_ENABLED === 'true' && process.env.NX_LOGROCKET_KEY
const isSentryEnabled = () => process.env.NX_SENTRY_ENABLED === 'true' && process.env.NX_SENTRY_KEY

export const logrocketIdentify = (id: string, app: AppType) => {
  if (isLogRocketEnabled()) {
    LogRocket.identify(id, {
      id,
      // Custom properties:
      app,
    })
  }
}

// Initialise Log Rocket
export const configureLogRocket = (immediateRecording = false) => {
  if (isLogRocketEnabled()) {
    setupLogRocketReact(LogRocket)
    if (immediateRecording) {
      startLogRocketRecording()
    }
  }
}

export const startLogRocketRecording = () => {
  if (isLogRocketEnabled()) {
    LogRocket.init(process.env.NX_LOGROCKET_KEY as string)
  }
}

// Add Sentry
export const configureSentry = () => {
  if (isSentryEnabled()) {
    Sentry.init({
      dsn: process.env.NX_SENTRY_KEY as string,
      integrations: [new Sentry.BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      environment: process.env.NX_NODE_ENV || 'int',
      beforeSend: (event: Event, hint?: EventHint) => {
        if (isDev()) {
          console.error('Sentry Error', event, hint)
        }
        return event
      },
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        // Avast extension error
        '_avast_submit',
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    })
  }
}

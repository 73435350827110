import { AssessmentStatus, CentreAssessment } from '@project-minerva/typings'
import { isAfter, isBefore } from 'date-fns'

export const getAssessmentStatus = (assessment: CentreAssessment): AssessmentStatus => {
  const now = new Date()
  return isBefore(now, new Date(assessment.startTime))
    ? 'SCHEDULED'
    : isAfter(now, new Date(assessment.endTime))
    ? 'ENDED'
    : 'ACTIVE'
}

import { createContext, ReactNode, useContext } from 'react'
import { NavigationHook } from './hooks'

const ContentNavigationContext = createContext<ReturnType<NavigationHook>>({} as ReturnType<NavigationHook>)

export const useContentNavigation = () => {
  const context = useContext(ContentNavigationContext)

  if (!context) {
    throw new Error('useNavigation must be called inside a ContentNavigatorProvider')
  }

  return context
}

interface ContentNavigatorProviderProps {
  children: ReactNode | ReactNode[]
  navigationHook: NavigationHook
}

// TODO: Possibly rename and co-locate
export function ContentNavigatorProvider({ children, navigationHook }: ContentNavigatorProviderProps) {
  const navigation = navigationHook()

  return <ContentNavigationContext.Provider value={navigation}>{children}</ContentNavigationContext.Provider>
}

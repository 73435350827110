import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useAnchorElement } from '@project-minerva/core'
import { FC, MutableRefObject, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { HeaderWidget } from './header-widget'
import { HeaderWidgetTooltip } from '../header-widget-tooltip'
import { TestStartScreenInfo, StartScreenInfoProps } from '../test-start-screen'

export const TestInformationWidget: FC<
  StartScreenInfoProps & {
    closeWidgetRef?: MutableRefObject<() => void>
    trackIconClicked?: () => void
    trackPopoverClosed?: (duration: number) => void
  }
> = ({ children, closeWidgetRef, introContent, trackIconClicked, trackPopoverClosed }) => {
  const popoverOpenTime = useRef(0)
  const setAnchorElement = (el?: HTMLButtonElement) => {
    if (el) {
      trackIconClicked?.()
      popoverOpenTime.current = Date.now()
    } else {
      trackPopoverClosed?.(Date.now() - popoverOpenTime.current)
    }
  }

  const anchor = useAnchorElement(setAnchorElement)

  if (closeWidgetRef) {
    closeWidgetRef.current = anchor.unsetAnchor
  }

  return (
    <HeaderWidget {...anchor} buttonIcon={<InfoOutlinedIcon />} tooltip={<FormattedMessage id="instructions" />}>
      <HeaderWidgetTooltip data-testid="instruction-popover" sx={{ maxWidth: 600 }}>
        <TestStartScreenInfo introContent={introContent} />
        {children}
      </HeaderWidgetTooltip>
    </HeaderWidget>
  )
}

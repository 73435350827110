import { makeStyles } from '@project-minerva/design-system'
import { DataTestId } from '@project-minerva/typings'

const useStyles = makeStyles(() => ({
  imageContainer: {
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
  },
}))

interface Props extends DataTestId {
  url: string
}

export const Base64ImageViewer = (props: Props) => {
  const styles = useStyles()
  const { url, 'data-testid': dataTestId } = props

  return (
    <div className={styles.imageContainer}>
      <img className={styles.image} alt="" src={url} data-testid={dataTestId} />
    </div>
  )
}

import { alpha, AlertColor, Box, styled, BoxProps } from '@mui/material'

interface Props extends BoxProps {
  status: AlertColor | 'primary' | 'secondary'
  dark?: boolean
}

export const ColouredStatusBox = styled(Box)<Props>(({ theme, dark, status }) => ({
  backgroundColor: alpha(theme.palette[status][dark ? 'dark' : 'main'], 0.2),
  borderRadius: '8px',
  padding: '12px',
}))

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const GuardianIcon = (props: SvgIconProps) => (
  <SvgIcon width="37" height="39" viewBox="0 0 37 39" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.46289 8.66567C7.46289 3.90322 11.2668 0 16.0111 0C20.7554 0 24.5593 3.90322 24.5593 8.66567C24.5593 13.4281 20.7554 17.3313 16.0111 17.3313C11.2668 17.3313 7.46289 13.4281 7.46289 8.66567ZM16.0111 3C12.9702 3 10.4629 5.51314 10.4629 8.66567C10.4629 11.8182 12.9702 14.3313 16.0111 14.3313C19.052 14.3313 21.5593 11.8182 21.5593 8.66567C21.5593 5.51314 19.052 3 16.0111 3Z"
    />
    <path d="M1.5 32.7579L0.927361 34.1443L0 33.7612V32.7579C0 25.0677 7.12378 18.0902 15.7411 18.0902C17.7723 18.0902 19.7134 18.4759 21.4937 19.1617C22.2667 19.4596 22.652 20.3277 22.3542 21.1007C22.0563 21.8738 21.1882 22.259 20.4152 21.9612C18.9663 21.403 17.3902 21.0902 15.7411 21.0902C9.0417 21.0902 3.65952 26.2201 3.05627 31.7335L3.1053 31.7504C3.79343 31.9861 4.79765 32.3033 6.0424 32.6213C8.53776 33.2588 11.9689 33.8914 15.7411 33.8914C15.9479 33.8914 16.1535 33.8889 16.3579 33.8839C16.6026 33.878 16.8455 33.8685 17.0863 33.8555C17.9135 33.8112 18.6201 34.4458 18.6645 35.273C18.7089 36.1003 18.0742 36.8068 17.247 36.8512C16.977 36.8657 16.7049 36.8764 16.431 36.883C16.2023 36.8886 15.9723 36.8914 15.7411 36.8914C11.6481 36.8914 7.95872 36.2073 5.29983 35.528C3.96745 35.1876 2.88612 34.8464 2.13297 34.5884C1.7562 34.4593 1.46101 34.3509 1.257 34.2734C1.15497 34.2347 1.0757 34.2037 1.02037 34.1817L0.955475 34.1558L0.936765 34.1481L0.930893 34.1457L0.928834 34.1449C0.92849 34.1447 0.927361 34.1443 1.5 32.7579Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3902 19.1206C25.7998 19.1206 23.6493 21.2014 23.6493 23.831C23.6493 26.4606 25.7998 28.5414 28.3902 28.5414C30.9805 28.5414 33.1311 26.4606 33.1311 23.831C33.1311 21.2014 30.9805 19.1206 28.3902 19.1206ZM25.3435 23.831C25.3435 22.2142 26.6795 20.8525 28.3902 20.8525C30.1008 20.8525 31.4369 22.2142 31.4369 23.831C31.4369 25.4479 30.1008 26.8095 28.3902 26.8095C26.6795 26.8095 25.3435 25.4479 25.3435 23.831Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6172 37.118L20.9233 36.3106C20.6172 37.118 20.6172 37.118 20.6172 37.118L20.6222 37.12L20.6321 37.1238L20.6663 37.137C20.6862 37.1446 20.712 37.1542 20.7434 37.1659L20.7907 37.1832C20.8979 37.2222 21.0529 37.2768 21.2506 37.3418C21.6458 37.4716 22.213 37.6432 22.9118 37.8144C24.3062 38.156 26.2406 38.5 28.3864 38.5C31.024 38.5 32.9626 38.1597 34.2586 37.8095C34.9062 37.6345 35.3922 37.4573 35.7238 37.3196C35.8895 37.2508 36.0165 37.1919 36.1057 37.1482C36.1503 37.1263 36.1855 37.1082 36.2113 37.0946L36.2251 37.0872L36.243 37.0775L36.2536 37.0717L36.2576 37.0694L36.2607 37.0677L36.6967 36.8203V36.3106C36.6967 32.3286 32.88 28.8271 28.3864 28.8271C23.8928 28.8271 20.0762 32.3286 20.0762 36.3106V36.9037L20.6172 37.118ZM28.3864 30.559C31.8955 30.559 34.6306 33.1083 34.9675 35.7631C34.7019 35.868 34.3229 36.0007 33.8256 36.1351C32.6727 36.4467 30.8798 36.7681 28.3864 36.7681C26.4105 36.7681 24.6133 36.4503 23.3066 36.1302C22.6808 35.9769 22.1711 35.8239 21.8128 35.7073C22.1806 33.0726 24.902 30.559 28.3864 30.559Z"
    />
  </SvgIcon>
)

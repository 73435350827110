import { useContext } from 'react'
import MixpanelContext from 'react-mixpanel'
import { MixpanelEventTypes } from './MixpanelEventTypes'
import { MixpanelKeys } from './MixpanelKeys'
import { RequestOptions } from 'mixpanel-browser'

type MixpanelValues = string | number | boolean | null

export type MixpanelParams = Partial<{ [key in MixpanelKeys]: MixpanelValues }>
export type MixpanelTracker = (eventType: MixpanelEventTypes, props: MixpanelParams, options?: RequestOptions) => void

export const useMixpanel = () => useContext(MixpanelContext)

import { useAccessToken } from '@project-minerva/auth-student'
import { Pause, Resume } from '@project-minerva/typings'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { pauseTest as pauseTestRequest, resumeTest as resumeTestRequest } from '../api/study-session.api'

const PauseResumeContext = createContext<PauseResumeReturnProps>({} as PauseResumeReturnProps)

export const usePauseResumeTest = () => {
  const context = useContext(PauseResumeContext)

  if (!context) {
    throw new Error('usePauseResume must be called inside a PauseResumeProvider')
  }

  return context
}

interface PauseResumeProps {
  children: ReactNode | ReactNode[]
  isPaused?: boolean
}

export const PauseResumeProvider = (props: PauseResumeProps) => {
  const { children, isPaused } = props
  const contextValue = usePauseResume(!!isPaused)

  return <PauseResumeContext.Provider value={contextValue}>{children}</PauseResumeContext.Provider>
}

interface PausePayload {
  invigilatorCode: string
  reason: string
}

interface PauseResumeReturnProps {
  isPaused: boolean
  isRequesting: boolean
  pauseTest: (payload: PausePayload) => Promise<Pause>
  resumeTest: () => Promise<Resume>
}

const usePauseResume = (paused: boolean): PauseResumeReturnProps => {
  const { accessTokenRef } = useAccessToken()
  const [isPaused, setPaused] = useState(paused)
  const [isRequesting, setIsRequesting] = useState(false)

  const pauseTest = useCallback(async (payload: PausePayload) => {
    setIsRequesting(true)
    return pauseTestRequest(payload, accessTokenRef.current)
      .then((res: Pause) => {
        setPaused(true)
        return res
      })
      .finally(() => {
        setIsRequesting(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resumeTest = useCallback(async () => {
    setIsRequesting(true)
    return resumeTestRequest(accessTokenRef.current)
      .then((res: Resume) => {
        setPaused(false)
        return res
      })
      .finally(() => {
        setIsRequesting(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isPaused, isRequesting, pauseTest, resumeTest }
}

import { DataTestId } from '@project-minerva/typings'
import React from 'react'
import MathJax from 'react-mathjax-preview'
import sanitizeHtml from 'sanitize-html'
import './html-renderer.css'

const browserSupportsMathMl = () => {
  // https://caniuse.com/mathml
  if (navigator.userAgent.includes('Chrome')) {
    return false
  }
  // FIREFOX
  else if (navigator.userAgent.includes('Firefox')) {
    return true
  }
  // EDGE
  else if (navigator.userAgent.includes('Edge')) {
    return false
  }
  // SAFARI
  else if (navigator.userAgent.includes('Safari')) {
    return true
  }
  // OPERA
  else if (navigator.userAgent.includes('Opera')) {
    return false
  }
  // default - assume that they don't
  return false
}

const OPEN_CLOSE_HTML_TAG_REGEX = /<([a-zA-Z0-9]*)\b[^>]*>(.*?)<\/\1>/g
const hasMathTag = (html: string) => html.includes('</math>')

interface HtmlRendererProps extends DataTestId {
  html: string
}

const CustomEquationRenderer = (props: HtmlRendererProps) => {
  const tags: string[] = props.html.match(OPEN_CLOSE_HTML_TAG_REGEX)?.map((tag) => tag) || []

  return (
    <div data-testid={props['data-testid']}>
      {React.Children.toArray(
        tags.map((tag) => {
          return hasMathTag(tag) ? (
            // This library sanitises the HTML with DomPurify already - so we don't need to do it.
            <MathJax
              math={tag}
              sanitizeOptions={{ ADD_ATTR: ['linebreak'] }}
              config={{ showMathMenu: false, skipStartupTypeset: false, showProcessingMessages: false }}
              msDelayDisplay={500}
            />
          ) : (
            <HtmlRender html={tag} />
          )
        })
      )}
    </div>
  )
}

export const HtmlRender = (props: HtmlRendererProps) => {
  if (hasMathTag(props.html) && !browserSupportsMathMl()) {
    return <CustomEquationRenderer {...props} />
  }

  const sanitizedHtml = sanitizeHtml(props.html, sanitizeHtmlOptions)

  return (
    <div
      className="html-renderer-content"
      data-testid={props['data-testid']}
      dangerouslySetInnerHTML={{
        __html: sanitizedHtml,
      }}
    />
  )
}

const sanitizeHtmlOptions = {
  allowedTags: [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'img',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',

    // MathML
    'math',
    'menclose',
    'merror',
    'mfenced',
    'mfrac',
    'mglyph',
    'mi',
    'mlabeledtr',
    'mmultiscripts',
    'mn',
    'mo',
    'mover',
    'mpadded',
    'mphantom',
    'mroot',
    'mrow',
    'ms',
    'mspace',
    'msqrt',
    'mstyle',
    'msub',
    'msup',
    'msubsup',
    'mtable',
    'mtd',
    'mtext',
    'mtr',
    'munder',
    'munderover',
  ],
  allowedAttributes: {
    img: ['src', 'style'],
    a: ['href'],
    mspace: ['linebreak'],
    ol: ['type'],
    hr: ['style'],
  },
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel', 'data'],
}

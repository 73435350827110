import { alpha } from '@mui/material'
import { Box, styled } from '@project-minerva/design-system'

export const EmptyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'text.secondary',
  backgroundColor: alpha(theme.palette.text.secondary, 0.1),
  borderRadius: theme.shape.borderRadius,
  p: theme.spacing(1),
  height: theme.spacing(10),
}))

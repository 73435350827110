import { Box, PrimaryTypography, Typography } from '@project-minerva/design-system'
import { DataTestId } from '@project-minerva/typings'
import { ReactNode } from 'react'

interface Props extends DataTestId {
  title: string | ReactNode
  value: string | ReactNode
}

export function PrimaryTypographyTitleValue(props: Props) {
  const { title, 'data-testid': testId, value } = props
  return (
    <Box>
      <PrimaryTypography variant="subtitle1" fontWeight={600} data-testid={`${testId}-title`}>
        {title}
      </PrimaryTypography>
      <Typography component="div" data-testid={`${testId}-text`}>
        {value}
      </Typography>
    </Box>
  )
}

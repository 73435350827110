type ScreenFiltersType = Record<string, { color: string; opacity: number }>

export const ScreenFilters: ScreenFiltersType = {
  aquaBlue: {
    color: '#00FFFF',
    opacity: 0.3,
  },
  celery: {
    color: '#ADFF2F',
    opacity: 0.2,
  },
  grass: {
    color: '#00FF00',
    opacity: 0.22,
  },
  jade: {
    color: '#00FFA9',
    opacity: 0.3,
  },
  magenta: {
    color: '#FF0088',
    opacity: 0.15,
  },
  orange: {
    color: '#FF6C02',
    opacity: 0.25,
  },
  pink: {
    color: '#FF0050',
    opacity: 0.18,
  },
  purple: {
    color: '#7300FF',
    opacity: 0.2,
  },
  skyBlue: {
    color: '#00BFFF',
    opacity: 0.2,
  },
  yellow: {
    color: '#F8C500',
    opacity: 0.22,
  },
}

import { Box, Typography } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import { ReactNode } from 'react'
import { ApplicantOverviewErrorAlert } from './applicant-overview-error-alert'

interface Props {
  children: ReactNode
  message: string | ReactNode
}

export function IncompleteProfileAlert({ children, message }: Props) {
  return (
    <ApplicantOverviewErrorAlert
      title={<FormattedMessage id="incomplete-profile" defaultMessage="Incomplete Profile" />}
    >
      <Typography variant="body1" color="black">
        {message}
      </Typography>
      <Box mt={2}>{children}</Box>
    </ApplicantOverviewErrorAlert>
  )
}

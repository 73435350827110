import { ContentRenderer } from '@project-minerva/core'
import { useTestController } from '../assessment-player.context'
import { alpha, ListItem, makeStyles, ThemeType } from '@project-minerva/design-system'
import { Content, TestQuestion } from '@ctek/test-player'
import clsx from 'clsx'
import { AnswerBox } from './answer-box'
import { ContentBox } from './content-box'
import { createRef, useEffect, useMemo, useRef, useState } from 'react'
import { Stack } from '@mui/material'

const useStyles = makeStyles((theme: ThemeType) => ({
  alternative: {
    cursor: (props: { isPracticeFeedback: boolean; disabled?: boolean }) =>
      !props.isPracticeFeedback && !props.disabled ? 'pointer' : 'default',
    justifyContent: 'center',
    borderWidth: 2,
    padding: '16px',
    height: '100%',
  },
  alternativeHover: {
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0px 2px 3px ${alpha('#32058B', 0.15)}`,
      transform: 'translateY(-1px)',
    },
  },
  answerListItem: {
    padding: 0,
    flex: '1 1 auto',
  },
  answers: {
    padding: 0,
    marginTop: theme.spacing(3.75),
    justifyContent: 'center',
  },
  selected: {
    borderColor: theme.palette.primary.main,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: theme.shape.borderRadius,
      content: '""',
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
  },
  correct: {
    borderColor: theme.palette.success.main,
  },
}))

const Question = ({ disabled }: { disabled?: boolean }) => {
  const [shouldStackVertically, setShouldStackVertically] = useState(false)
  const {
    currentContent,
    isPracticeFeedback,
    selectedAnswer,
    setSelectedAnswer,
    isPreviouslyAnswered,
    previousAnswer,
  } = useTestController()
  const question = currentContent as TestQuestion<Content, Content>
  const styles = useStyles({ isPracticeFeedback, disabled })
  const clickHandler = (answerId: string) => {
    if (isPracticeFeedback || disabled || isPreviouslyAnswered) {
      return
    }

    setSelectedAnswer(selectedAnswer !== answerId ? answerId : null)
  }

  // array of refs to assign to each answer to check inidividually if any of their contents overflow
  const refArr = useRef(
    question && question.answers ? [...Array(question.answers.length + 1)].map(() => createRef<HTMLDivElement>()) : []
  )

  // Disgustingly have to check each scrollWidth in the refArr in case some answers are longer than the others
  const refArrWidths = [refArr.current.map((ref) => ref.current?.scrollWidth)]

  // This is what gets used to trigger the useEffect to switch the layout for answers which overflow,
  // as adding the array of refs to the deps caused infinite re-renders
  const totalScrollWidth = useMemo(() => {
    const scrolls = refArr.current.map((a) => a.current?.scrollWidth || 0)
    return scrolls.reduce((a, b) => a + b, 0)
    // Disgustingly have to check each scrollWidth in the refArr in case some answers are longer than the others
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refArrWidths])

  useEffect(() => {
    if (refArr.current.some((item) => item.current && item.current?.scrollWidth > item.current?.clientWidth)) {
      setShouldStackVertically(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalScrollWidth, window.devicePixelRatio, window.innerWidth])

  if (!question) {
    return <p>Question is not available</p>
  }

  const currentAnswer = isPreviouslyAnswered ? previousAnswer : selectedAnswer

  return (
    <>
      <ContentBox>
        <ContentRenderer content={question.content} data-testid="question-content" />
      </ContentBox>
      <Stack
        direction={shouldStackVertically ? 'column' : 'row'}
        alignItems="center"
        className={styles.answers}
        component="div"
        aria-label="answers"
        data-testid="answers-container"
        spacing={1}
      >
        {question.answers.map((answer, i) => (
          <ListItem
            disabled={disabled}
            key={answer.id}
            className={styles.answerListItem}
            style={{ width: shouldStackVertically ? '100%' : `${Math.floor(100 / question.answers.length)}%` }}
            data-testid="answer-alternative"
            onClick={() => clickHandler(answer.id)}
          >
            <AnswerBox
              type={answer.content.type}
              data-testid={'answer-item-' + answer.content.data}
              className={clsx(styles.alternative, {
                [styles.alternativeHover]: !isPracticeFeedback && !disabled && !isPreviouslyAnswered,
                [styles.selected]: answer.id === currentAnswer,
                [styles.correct]: isPracticeFeedback && answer.isCorrect,
              })}
              style={{
                justifyContent: shouldStackVertically ? 'left' : 'center',
                display: shouldStackVertically ? 'block' : 'flex',
                whiteSpace: shouldStackVertically ? 'normal' : 'nowrap',
              }}
            >
              <ContentRenderer content={answer.content} ref={refArr.current[i]} />
            </AnswerBox>
          </ListItem>
        ))}
      </Stack>
    </>
  )
}

export default Question

import { AlertColor, AlertProps, default as MuiAlert } from '@mui/material/Alert'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'

interface Props {
  iconCentred?: boolean
  severity: AlertColor
}

const StyledAlert = forwardRef<HTMLDivElement, AlertProps & Props>((props, ref) => (
  <MuiAlert
    ref={ref}
    {...props}
    iconMapping={{
      error: <ErrorOutlineRoundedIcon />,
      info: <InfoOutlinedIcon />,
      success: <TaskAltRoundedIcon />,
      warning: <WarningAmberRoundedIcon />,
    }}
  />
))

export const Alert = styled(StyledAlert)(({ theme, severity, iconCentred = false, variant }) => ({
  color: variant === 'filled' ? undefined : theme.palette[severity].main,
  '& .MuiAlert-icon': iconCentred ? { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {},
}))

import { ApiProvider } from '@project-minerva/api'
import { IntlProvider } from '@project-minerva/intl'
import { createContext, ReactNode, useContext, useEffect } from 'react'
import {
  configureBetterStack,
  configureLogRocket,
  configureMixpanel,
  configureSentry,
  startLogRocketRecording,
} from './configure.utils'
import * as Sentry from '@sentry/react'

const AppConfigContext = createContext({ startLogRocketRecording })

export const useAppConfiguration = () => {
  const context = useContext(AppConfigContext)

  if (!context) {
    throw new Error('useAppConfiguration must be used within a AppConfigProvider')
  }

  return context
}

export const ConfigureApplication = (props: { children: ReactNode; logRocketAutoRecording?: boolean }) => {
  useEffect(() => {
    configureLogRocket(props.logRocketAutoRecording)
    configureSentry()
    configureMixpanel()
    configureBetterStack()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppConfigContext.Provider value={{ startLogRocketRecording }}>
      <ApiProvider>
        <Sentry.ErrorBoundary fallback={<p>Something went wrong, please refresh your page and try again.</p>}>
          <IntlProvider>{props.children}</IntlProvider>
        </Sentry.ErrorBoundary>
      </ApiProvider>
    </AppConfigContext.Provider>
  )
}

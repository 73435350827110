import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

export const CompleteTag = () => {
  return (
    <Box
      data-testid="assessment-card-status"
      sx={{
        background: 'rgba(24, 209, 107, .15)',
        borderRadius: '3px',
        minWidth: '72px',
        minHeight: '21px',
        alignContent: 'center',
        position: 'relative',
        padding: '4px',
      }}
    >
      <Typography
        sx={{
          color: '#317F3A',
          fontSize: '0.625rem',
          fontWeight: 600,
          textTransform: 'uppercase',
          margin: 'auto',
          width: 'fit-content',
        }}
      >
        <FormattedMessage id="tag-complete" />
      </Typography>
    </Box>
  )
}

import { getApiUrl, useRequest, request } from '@project-minerva/api'
import { useAuthContext } from '@project-minerva/auth-cognito'
import { PrivilegeDetail } from '@project-minerva/typings'

const privilegesUrl = `${getApiUrl('helios', '/v1')}/users/privileges`

export const revokePrivilege = (portal: string, token: string) => {
  const url = `${privilegesUrl}/revoke`
  const body = JSON.stringify({ privilege: portal })
  return request<void>(url, { method: 'POST', body, returnRaw: true }, token)
}

export const usePrivileges = () => {
  const { accessToken } = useAuthContext()
  return useRequest<{ data: PrivilegeDetail[]; count: number }>(privilegesUrl, { method: 'GET' }, accessToken.current)
}

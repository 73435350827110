import { BoxProps } from '@project-minerva/design-system'
import { ContentType } from '@ctek/test-player'
import { useLayoutEffect, useRef, useState } from 'react'
import { ContentBox } from './content-box'

export const AnswerBox = (
  props: BoxProps & {
    type: ContentType
  }
) => {
  const { type, ...otherProps } = props
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number | undefined>(undefined)

  useLayoutEffect(() => {
    if (ref.current) {
      const width = ref.current.clientWidth
      setWidth(width)
    }
  }, [ref])

  if (type === 'base64_image') {
    return <ContentBox style={{ maxHeight: width }} ref={ref} {...otherProps} />
  }

  return <ContentBox style={{ height: 'max-content' }} {...otherProps} />
}

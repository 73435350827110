import { format, isWithinInterval } from 'date-fns'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { MAX_DOB, MIN_DOB } from '../applicant'

const dobIsValid = (dob: Date | undefined) => {
  if (!dob) return false
  return isWithinInterval(dob, { start: MIN_DOB, end: MAX_DOB })
}

export const usePersonalInfoValidationSchema = () => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    firstName: yup.string().required(formatMessage({ id: 'field-required' })),
    middleName: yup.string(),
    lastName: yup.string().required(formatMessage({ id: 'field-required' })),
    // if field left blank, yup throws a type error, so using field required msg
    dateOfBirth: yup
      .date()
      .test(
        'dob-valid',
        formatMessage(
          { id: 'dob-picker-invalid-error' },
          { startDate: format(MIN_DOB, 'dd/MM/yyyy'), endDate: format(MAX_DOB, 'dd/MM/yyyy') }
        ),
        (value) => dobIsValid(value)
      )
      .typeError(formatMessage({ id: 'field-required' }))
      .required(formatMessage({ id: 'field-required' })),
    gender: yup.string().required(formatMessage({ id: 'field-required' })),
    relation: yup.string().required(formatMessage({ id: 'field-required' })),
    currentSchool: yup.string().required(formatMessage({ id: 'field-required' })),
  })
}

export const useInvigilationCentreValidationSchema = () => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    invigilationCentre: yup
      .object()
      .required(formatMessage({ id: 'field-required' }))
      .typeError(formatMessage({ id: 'field-required' })),
  })
}

export const useSENDValidationSchema = (includeMedical: boolean) => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    isSend: yup
      .boolean()
      .typeError(formatMessage({ id: 'field-required' }))
      .required(formatMessage({ id: 'field-required' })),
    documents: yup
      .array()
      .when('isSend', { is: true, then: (schema) => schema.min(1, formatMessage({ id: 'field-required' })) }),
    documentNote: yup.string(),
    additionalTime: yup.string(),
    additionalTimeDescription: yup.string(),
    environmentalProvisions: yup.string(),
    environmentalProvisionsDescription: yup.string(),
    hasConsentedMedical: includeMedical
      ? yup
          .boolean()
          .when('isSend', { is: true, then: (schema) => schema.oneOf([true], formatMessage({ id: 'field-required' })) })
      : yup.boolean(),
  })
}

export const useEALValidationSchema = () => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    isEal: yup
      .boolean()
      .typeError(formatMessage({ id: 'field-required' }))
      .required(formatMessage({ id: 'field-required' })),
    environmentalProvisionsDescription: yup.string(),
  })
}

export const useReviewValidationSchema = (includeMedical: boolean) => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    hasConsentedData: yup.boolean().oneOf([true], formatMessage({ id: 'field-required' })),
    hasConsentedMedical: includeMedical
      ? yup.boolean().oneOf([true], formatMessage({ id: 'field-required' }))
      : yup.boolean(),
  })
}

import { PageLayout } from '@project-minerva/design-system'
import { ErrorMessage } from '../error'
import { LoadingSpinner } from '../loading-spinner'

export const PageLoader = () => (
  <PageLayout centred>
    <LoadingSpinner />
  </PageLayout>
)

export const PageError = ({ error }: { error?: Error }) => (
  <PageLayout centred>
    <ErrorMessage message={error?.message} />
  </PageLayout>
)

import { Box, IconButton, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  dataTestId?: string
  icon: ReactNode | null
  onIconClick: () => void
  onFileClick?: () => void
  name: string
}

export function ApplicantUploadedFile(props: Props) {
  const { icon, name, onIconClick, onFileClick } = props
  return (
    <Box
      sx={{
        display: 'flex',
        p: (theme) => theme.spacing(0, 1, 0, 1),
        background: (theme) => theme.palette.primary.light,
        borderRadius: '10px',
        mb: (theme) => theme.spacing(1),
        height: '36px',
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', cursor: onFileClick ? 'pointer' : 'default', width: '100%' }}
        onClick={onFileClick}
      >
        <Typography variant="body2">{name}</Typography>
      </Box>
      {icon && (
        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
          <IconButton onClick={onIconClick} size="small">
            {icon}
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

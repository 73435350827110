import { getApiUrl } from '@project-minerva/api'
import { request } from '@project-minerva/api'
import { CodeAuthentication } from '@project-minerva/typings'
import { format } from 'date-fns'

const baseUrl = getApiUrl('helios', '/v1/assessments')

export const validateCode = async (code: string): Promise<CodeAuthentication> => {
  const url = `${baseUrl}/auth/code`
  const options = { method: 'POST', body: JSON.stringify({ code }), bypassAuth: true }
  return await request<CodeAuthentication>(url, options)
}

export const validateDob = async (dob: Date, accessToken?: string): Promise<CodeAuthentication> => {
  const url = `${baseUrl}/auth/dob`
  const options = { method: 'POST', body: JSON.stringify({ dob: format(dob, 'yyyy-MM-dd') }) }
  return await request<CodeAuthentication>(url, options, accessToken)
}

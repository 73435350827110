export interface PaginatedResult<T> {
  data: T[]
  nextPageToken: string
  previousPageToken: string
  count?: number
}

export type PaginatedRequestWithOptions = {
  pageSize: number
  orderBy: string
}

export type PaginatedRequestWithToken = {
  pageToken: string
}

export type PaginatedRequest = PaginatedRequestWithOptions | PaginatedRequestWithToken

export enum InvigilationCentreStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum InvigilationCentreStatusMapping {
  APPROVED = 'success',
  PENDING = 'warning',
  REJECTED = 'error',
}

export type SubjectName =
  | 'english'
  | 'mathematics'
  | 'verbal_reasoning'
  | 'non_verbal_reasoning'
  | 'spatial_reasoning'
  | 'critical_thinking_problem_solving'

export interface TestDeclaration {
  testDeclarationId: string
  invigilatorFullName: string
  submitterFullName: string
  comment: string | null
  createdAt: string
  additionalDetails: TestDeclarationAdditionalDetail[]
}

export interface TestDeclarationAdditionalDetail {
  testDeclarationDetailsId: string
  submitterFullName: string
  comment: string
  createdAt: string
}

export interface AcademicYear {
  academicYearId: string
  name: string
  startDate: string
  endDate: string
  registrationStartDate: string
  registrationEndDate: string
  testingStartDate: string
  testingEndDate: string
}

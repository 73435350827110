import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { useAnchorElement } from '@project-minerva/core'
import { memo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { HeaderWidgetTooltip } from '../../header-widget-tooltip'
import { HeaderWidget } from '../header-widget'
import { FontSizeChanger } from './font-size/font-size-changer'

interface Props {
  trackFontSizeChanged?: (previousSize: string | null, currentSize: string) => void
  trackIconClicked?: () => void
  trackPopoverClosed?: (duration: number) => void
}

export const Settings = memo(({ trackFontSizeChanged, trackIconClicked, trackPopoverClosed }: Props) => {
  const popoverOpenTime = useRef(0)
  const setAnchorElement = (el?: HTMLButtonElement) => {
    if (el) {
      trackIconClicked?.()
      popoverOpenTime.current = Date.now()
    } else {
      trackPopoverClosed?.(Date.now() - popoverOpenTime.current)
    }
  }
  const anchor = useAnchorElement(setAnchorElement)

  return (
    <HeaderWidget buttonIcon={<SettingsOutlinedIcon />} {...anchor} tooltip={<FormattedMessage id="font-size" />}>
      <HeaderWidgetTooltip>
        <FontSizeChanger track={trackFontSizeChanged} />
      </HeaderWidgetTooltip>
    </HeaderWidget>
  )
})

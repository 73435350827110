import { setItemInLS, clearItemInLS, getItemFromLS } from '@project-minerva/base-utils'
import { CodeAuthentication, TokenData } from '@project-minerva/typings'
import * as Sentry from '@sentry/browser'
import { jwtDecode } from 'jwt-decode'
import React, { useContext, useMemo, useRef, useState } from 'react'
import { useEffect, createContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface BasicAuthContext {
  auth: CodeAuthentication | undefined
  authData: TokenData | undefined
  validate: (validateRequest: () => Promise<CodeAuthentication>) => void
  saveToken: (response: CodeAuthentication) => void
  logout: () => void
}

const BasicAuthContext = createContext<BasicAuthContext>({
  auth: undefined,
  authData: undefined,
  validate: () => Promise.resolve(),
  saveToken: (response: CodeAuthentication) => {
    return
  },
  logout: () => {
    return
  },
})

export const useBasicAuth = () => useContext(BasicAuthContext)

export const useAccessToken = () => {
  const { auth } = useBasicAuth()
  const accessToken = useMemo(() => (auth?.token ? `Bearer ${auth.token}` : ''), [auth])
  const accessTokenRef = useRef(accessToken)

  useEffect(() => {
    accessTokenRef.current = accessToken
  }, [accessToken])
  return { accessToken, accessTokenRef }
}

export const BasicAuthProvider = (props: {
  loginUrl: string
  navigateToOnLoggingIn: string
  validate: (validateRequest: () => Promise<CodeAuthentication>) => Promise<CodeAuthentication>
  authLocalStorageKey: string
  children: React.ReactNode
}) => {
  const { authLocalStorageKey } = props
  const [auth, setAuth] = useState<CodeAuthentication>()
  const [authData, setAuthData] = useState<TokenData>()
  const history = useHistory()
  const location = useLocation()

  const saveToken = (response: CodeAuthentication) => {
    setAuth(response)
    try {
      const tokenData = jwtDecode<TokenData>(response.token)
      setAuthData(tokenData)
    } catch (e) {
      Sentry.captureException(`Error decoding jwt token`)
    }
    setItemInLS(response, authLocalStorageKey, true)
  }

  const validate = async (validateRequest: () => Promise<CodeAuthentication>): Promise<CodeAuthentication> => {
    const data = await props.validate(validateRequest)
    saveToken(data)
    return data
  }

  const logout = () => {
    setAuth(undefined)
    setAuthData(undefined)
    clearItemInLS(authLocalStorageKey)
    history.push(props.loginUrl)
  }

  // on mount => populate token with local storage data
  useEffect(() => {
    try {
      const storedAuth = getItemFromLS(authLocalStorageKey, true) as CodeAuthentication
      const storedAuthData = jwtDecode<TokenData>(storedAuth.token)
      setAuthData(storedAuthData)
      setAuth(storedAuth)
    } catch (err) {
      if (!location.pathname.includes(props.loginUrl)) {
        history.push(props.loginUrl)
      }
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on token change => redirect to appropriate page
  useEffect(() => {
    const isLoginUrl = location.pathname.includes(props.loginUrl)
    if (isLoginUrl && authData?.dob) {
      history.push(props.navigateToOnLoggingIn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData])

  return (
    <BasicAuthContext.Provider value={{ auth, authData, validate, logout, saveToken }}>
      {props.children}
    </BasicAuthContext.Provider>
  )
}

import { Header, styled } from '@project-minerva/design-system'

export const PageHeader = styled(Header)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(0, 3),
  borderBottom: `2px solid ${theme.palette.secondary.light}`,
  boxSizing: 'border-box',
  width: '100%',
  height: '60px',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 2,
}))

import { useTimer } from '@ctek/test-player'
import Stack from '@mui/material/Stack'
import { SimpleDialog, useTestController } from '@project-minerva/assessment-player'
import { FormError, FormWrapper } from '@project-minerva/core'
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextArea,
  TextField,
  Typography,
} from '@project-minerva/design-system'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useRifm } from 'rifm'
import { useStudySession } from '../data/study-session.provider'
import { InvigilatorDialogProps } from '../hooks/use-invigilator-dialog'
import { useTrackSettings } from '../hooks/use-track-settings'
import { useTrackTimer } from '../hooks/use-track-timer'
import { useTrackTestPaused } from '../mixpanel'
import { usePauseResumeTest } from './pause-resume.provider'
import { useTestProgress } from './test-progress.provider'

const INVIGILATOR_CODE_LENGTH = 4

const reasons = ['reason-send-break', 'reason-applicant-unwell', 'reason-technical-difficulties', 'reason-other']

const numberCharactersOnly = (str: string) => str.replace(/[^0-9]/g, '')

const PauseTestDialog = ({ open, closeDialog }: InvigilatorDialogProps) => {
  return <SimpleDialog open={open} content={<PauseTest closeDialog={closeDialog} />} data-testid="pause-test-dialog" />
}

export default PauseTestDialog

type PauseTestProps = Pick<InvigilatorDialogProps, 'closeDialog'>
const PauseTest = ({ closeDialog }: PauseTestProps) => {
  const { formatMessage } = useIntl()
  const { pauseTimer } = useTimer()
  const { isRequesting, pauseTest } = usePauseResumeTest()
  const [invigilatorCode, setInvigilatorCode] = useState('')
  const [reason, setReason] = useState('')
  const [customReason, setCustomReason] = useState('')
  const rifm = useRifm({ value: invigilatorCode, onChange: setInvigilatorCode, format: numberCharactersOnly })
  const hasReason = (reason === 'reason-other' && customReason) || (!!reason && reason !== 'reason-other')
  const hasInvigilatorCode = invigilatorCode.length === INVIGILATOR_CODE_LENGTH
  const canPause = hasReason && hasInvigilatorCode
  const trackPause = useTrackTestPaused()
  const { currentContent } = useTestController()
  const [error, setError] = useState<string | null>(null)
  const { studySession } = useStudySession()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const trackTimer = useTrackTimer(studySession!.timeLimitSeconds)
  const trackSettings = useTrackSettings()
  const { progress: progressValue } = useTestProgress()

  const handlePauseTest = () => {
    setError(null)
    pauseTest({ invigilatorCode, reason: customReason || formatMessage({ id: reason }) })
      .then((res) => {
        trackPause({ pauseData: res, content: currentContent, progressValue, ...trackSettings(), ...trackTimer() })
        pauseTimer()
        closeDialog()
      })
      .catch((err) => {
        setError(err.status === 401 ? 'invigilator-code-error' : err.message)
      })
  }

  return (
    <FormWrapper data-testid="pause-modal" onSubmit={handlePauseTest}>
      <Typography variant="h5" sx={{ mb: (theme) => theme.spacing(1), fontWeight: 600 }}>
        {formatMessage({ id: 'select-reason-title', defaultMessage: 'Select a reason to pause this test' })}
      </Typography>
      <RadioGroup data-testid="reasons-group">
        {reasons.map((reason) => (
          <FormControlLabel
            value={reason}
            disabled={isRequesting}
            label={<Typography sx={{ fontWeight: 600 }}>{formatMessage({ id: reason })}</Typography>}
            control={<Radio />}
            key={`reason-${reason}`}
            onChange={(evt) => {
              setCustomReason('')
              setReason((evt.target as HTMLInputElement).value)
            }}
          />
        ))}
      </RadioGroup>
      {reason === 'reason-other' && (
        <TextArea
          data-testid="custom-reason"
          maxRows={4}
          value={customReason}
          disabled={isRequesting}
          onChange={(evt) => setCustomReason(evt.target.value)}
          sx={{ pl: (theme) => theme.spacing(4), width: '100%' }}
        />
      )}
      <Typography sx={{ m: (theme) => theme.spacing(2, 0), pt: (theme) => theme.spacing(2), fontWeight: 600 }}>
        {formatMessage({ id: 'invigilator-code', defaultMessage: 'Invigilator code' })}
      </Typography>
      <TextField
        data-testid="invigilator-code-input"
        sx={{ pb: (theme) => theme.spacing(3), width: '100%' }}
        value={rifm.value}
        disabled={isRequesting}
        onChange={rifm.onChange}
        variant="outlined"
        label={formatMessage({ id: 'invigilator-code-placeholder' })}
        type="password"
        inputProps={{ maxLength: INVIGILATOR_CODE_LENGTH }}
      />
      {error && <FormError error={error} />}
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <Stack direction="row" spacing={2}>
          <Button data-testid="pause-test-button" type="submit" disabled={isRequesting || !canPause}>
            {formatMessage({ id: 'pause-test' })}
          </Button>
          <Button data-testid="cancel-button" onClick={closeDialog} disabled={isRequesting}>
            {formatMessage({ id: 'cancel-button', defaultMessage: 'Cancel' })}
          </Button>
        </Stack>
      </Box>
    </FormWrapper>
  )
}

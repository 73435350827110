export const BondLogo = () => {
  return (
    <svg width="62" height="34" viewBox="0 0 62 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5.83325" y="6.5" width="51.3333" height="21" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.666656H56.5563C59.1594 0.666656 61.2705 2.96502 61.2705 5.80015V33.3333H4.7138C2.11031 33.3333 0 31.0346 0 28.1998V0.666656ZM25.992 15.9126C27.4777 15.9126 27.9478 17.3979 27.9478 18.6361C27.9478 19.8746 27.4773 21.3415 25.992 21.3422C24.5071 21.3422 24.055 19.8746 24.055 18.6361C24.055 17.3979 24.5071 15.9126 25.992 15.9126ZM25.992 23.7851C29.0206 23.7851 31.1449 21.5667 31.1449 18.6361C31.1449 15.704 29.0206 13.4676 25.992 13.468C22.9656 13.468 20.8581 15.7044 20.8581 18.6361C20.8581 21.5667 22.9649 23.7851 25.992 23.7851ZM11.6676 20.7021H14.4688C15.5784 20.7021 16.3504 20.3073 16.3504 19.1963C16.3504 18.0183 15.5215 17.6227 14.4688 17.6227H11.6676V20.7021ZM14.3748 12.7907H11.6676V15.3658H14.3748C14.9771 15.3658 15.8046 15.0462 15.8046 14.0869C15.8046 13.1103 15.1079 12.7907 14.3748 12.7907ZM19.3019 13.4475C19.3019 14.6514 18.5875 15.6291 17.5338 16.1561C18.964 16.5701 19.8473 17.8118 19.8469 19.3281C19.8469 22.526 17.4967 23.5026 14.6198 23.5026H10.1092C9.03743 23.5026 8.16923 22.6344 8.16923 21.5627V10.0817H14.4494C16.7632 10.0449 19.3019 10.6466 19.3019 13.4475ZM41.9022 23.503V17.2477C41.9022 15.837 41.64 13.4676 38.3848 13.4676C37.2183 13.4676 35.902 14.0865 35.3195 15.0833H35.282V13.7497H32.1788V23.503H35.3767H35.3789L35.3767 18.1861C35.3767 17.1151 35.9031 16.0999 37.107 16.0999C38.7275 16.0999 38.7178 17.2601 38.7074 18.4901C38.7066 18.5884 38.7058 18.6872 38.7058 18.7859L38.7022 23.503H41.9022ZM46.1618 18.5226C46.1618 19.8555 46.6495 21.3415 48.1172 21.3415C49.6778 21.3415 50.0167 19.8555 50.016 18.6166C50.016 17.2091 49.5088 15.9123 48.1172 15.9123C46.6495 15.9123 46.1618 17.2837 46.1618 18.5226ZM49.9044 10.0821H51.1609C52.2326 10.0821 53.1001 10.9506 53.1001 12.0223V23.5041H50.0535V22.3769H50.016C49.3953 23.2976 48.4547 23.7854 47.3268 23.7854C44.2615 23.7854 42.9642 21.1538 42.9642 18.4116C42.9642 15.8744 44.318 13.4676 47.0836 13.4676C48.2876 13.4676 49.2087 13.9187 49.8669 14.802H49.9044V10.0821Z"
        fill="#00529C"
      />
    </svg>
  )
}

import { useState, useEffect, useCallback } from 'react'

const PHONE_MAX_WIDTH = 600

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<'desktop' | 'phone'>('desktop')

  const onResize = useCallback(() => {
    // For some reason innerWidth was returning an incorrect value, outerWidth seems to work.
    // TODO: possibly try to fix this?
    const width = window.outerWidth

    setDeviceType(width > PHONE_MAX_WIDTH ? 'desktop' : 'phone')
  }, [setDeviceType])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return deviceType
}

import { NavigationHook, PingsHook, TestEndedHook } from '@ctek/test-player'
import { useTestFlowController } from './assessment-player.context'
import { CircularProgress } from '@project-minerva/design-system'
import React from 'react'
import { AssessmentPlayerLayout } from './assessment-player.layout'
import { EndScreenProps } from './components/test-end-screen'
import { TestScreenProps } from './components/test-screen'
import { StartScreenProps } from './components/test-start-screen'

export interface AssessmentPlayerProps {
  onClose: () => void
  testName: string
  introContent: string
  loading: boolean
  hasStarted?: boolean
  handleStart: () => void
  useNavigationHook: NavigationHook
  useCompletionHook?: TestEndedHook
  usePingsHook?: PingsHook

  startScreen: React.FC<StartScreenProps>
  testScreen: React.FC<TestScreenProps>
  endScreen: React.FC<EndScreenProps>
}

export const AssessmentPlayer = (props: AssessmentPlayerProps) => {
  const { loading, startScreen: StartScreen, testScreen: TestScreen, endScreen: EndScreen } = props
  const { isStartTestScreen, isTestScreen, isTestFinished } = useTestFlowController()

  if (loading) return <CircularProgress />

  return (
    <AssessmentPlayerLayout.MainContainer data-testid="assessment-player">
      {isStartTestScreen && <StartScreen {...props} />}
      {isTestFinished && <EndScreen {...props} />}
      {isTestScreen && <TestScreen {...props} />}
    </AssessmentPlayerLayout.MainContainer>
  )
}

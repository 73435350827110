import { ReactNode } from 'react'

export const FormWrapper = (props: { onSubmit: () => void; children: ReactNode }) => {
  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault()
        props.onSubmit()
      }}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {props.children}
    </form>
  )
}

import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded'
import { Typography, Box, Stack } from '@mui/material'
import { MixpanelEventTypes, MixpanelKeys, MixpanelTracker } from '@project-minerva/core'
import { Button } from '@project-minerva/design-system'
import { BondLogo, IsebLogo } from '@project-minerva/icons'
import { useAcademicYear } from '../context'
import { FormattedMessage } from 'react-intl'

import { styled } from '@mui/material'
import PromotionBackground from './box-background.svg'

interface Props {
  appArea?: 'Applicants List' | 'Applicant Profile'
  campaign: 'eva' | 'walkthrough'
  track?: MixpanelTracker
}

export function BondPromotionBox({ appArea, campaign, track }: Props) {
  const { currentAcademicYear } = useAcademicYear()
  const year = new Date(currentAcademicYear.startDate).getFullYear()

  const onLearnMoreClick = () => {
    track?.(MixpanelEventTypes.BondPromotionLearnMoreButtonClicked, {
      [MixpanelKeys.PageName]: appArea,
    })
    window.open(
      `https://www.century.tech/bond-online-premium-plus-iseb/?utm_source=eva&utm_medium=referral&utm_campaign=${campaign}_${year}&utm_content=learn+more`,
      '_blank'
    )
  }

  const onStartTrialClick = () => {
    track?.(MixpanelEventTypes.BondPromotionStartFreeTrialButtonClicked, {
      [MixpanelKeys.PageName]: appArea,
    })
    window.open(
      `https://app.century.tech/bond?utm_source=eva&utm_medium=referral&utm_campaign=${campaign}_${year}&utm_content=start+trial`,
      '_blank'
    )
  }

  return (
    <CustomContainer sx={{ p: 3, mb: 1.5 }} data-testid="promotion-box">
      <Typography variant="h5" fontWeight="600" sx={{ mb: 3, textAlign: 'center' }} data-testid="promotion-box-title">
        <FormattedMessage id="test-preparation" defaultMessage="Test Preparation" values={{ br: <br /> }} />
      </Typography>
      <Typography data-testid="promotion-box-body" fontWeight="600" sx={{ textAlign: 'center' }}>
        <FormattedMessage
          id="bond-promotion-body"
          defaultMessage="Maximise your child's potential with over 20,000 questions written by ISEB and Bond, and the only test preparation platform endorsed by ISEB. Including mock tests, personalised in-depth analysis, and expert guidance. Use the discount code {code} for a {percentage} saving (monthly plans only)."
          values={{ b: (chunks: string) => <b>{chunks}</b>, br: <br />, code: 'ISEB2024', percentage: '20%' }}
        />
      </Typography>
      <Box sx={{ mt: 1.5, mb: 1.5, display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="text"
          onClick={onLearnMoreClick}
          data-testid="learn-more-button"
          sx={{ color: '#00529C' }}
          endIcon={<KeyboardDoubleArrowRightRoundedIcon />}
        >
          <FormattedMessage id="learn-more" defaultMessage="Learn More" />
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
        <Stack spacing={2} direction="row" alignItems="center">
          <BondLogo />
          <IsebLogo />
        </Stack>
        <Box>
          <Button onClick={onStartTrialClick} data-testid="start-free-trial-button">
            <FormattedMessage id="start-free-trial" defaultMessage="Start Free Trial" />
          </Button>
        </Box>
      </Box>
    </CustomContainer>
  )
}

const CustomContainer = styled(Box)(() => ({
  borderRadius: '12px',
  backgroundImage: `url(${PromotionBackground})`,
  backgroundSize: 'cover',
  boxShadow: '0px 3px 10px -3px rgba(0, 0, 0, 0.20)',
  color: 'white',
}))

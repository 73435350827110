import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import Button, { ButtonProps } from '@mui/material/Button'
import { useHistory, useLocation } from 'react-router'

export const BackButton = ({ click, to, ...buttonProps }: { click?: () => void; to?: string } & ButtonProps) => {
  const history = useHistory()
  const location = useLocation()
  const onClick = () => {
    if (click) click()
    else if (to) history.push(to, { from: location.pathname })
    else history.goBack()
  }

  return (
    <Button
      {...buttonProps}
      color="secondary"
      sx={{
        ...buttonProps.sx,
        height: '2rem',
        width: '2rem',
        minWidth: '2rem',
      }}
      onClick={onClick}
      disableRipple
      disableElevation
    >
      <ChevronLeftRoundedIcon sx={{ color: '#928BA0' }} />
    </Button>
  )
}

import { Typography } from '@mui/material'
import { useContentNavigation } from '@ctek/test-player'
import { useScrollTopOnMount } from '@project-minerva/core'
import { FormattedMessage } from 'react-intl'
import { useTestController } from '../assessment-player.context'
import { AssessmentPlayerLayout } from '../assessment-player.layout'
import { ControlBarContent } from './control-bar'
import { ScreenFilterOverlay } from './header-widgets'
import Question from './question'
import { TestScreenProps } from './test-screen'

export const QuestionRenderer = (props: TestScreenProps) => {
  const { isFetching: disabled } = useContentNavigation()
  const { isPractice } = useTestController()
  useScrollTopOnMount()

  return (
    <>
      {isPractice && (
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, textTransform: 'uppercase', color: (theme) => theme.palette.text.secondary }}
          data-testid="practice-label"
        >
          <FormattedMessage id="practice-mode" />
        </Typography>
      )}
      <AssessmentPlayerLayout.ContentContainer data-testid="assessment-slide">
        <ScreenFilterOverlay />
        <Question data-testid="question-screen" disabled={disabled} />
      </AssessmentPlayerLayout.ContentContainer>
      <ControlBarContent {...props} />
    </>
  )
}

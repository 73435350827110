export const SearchIcon = (props: { color: string }) => (
  <svg
    id="icon-search"
    width="30px"
    height="30px"
    viewBox="0 0 30 30"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M13,3 C18.5228475,3 23,7.4771525 23,13 C23,15.6556818 21.9647914,18.0695778 20.2758944,19.8601675 L26.7071068,26.2928932 C27.0976311,26.6834175 27.0976311,27.3165825 26.7071068,27.7071068 C26.3165825,28.0976311 25.6834175,28.0976311 25.2928932,27.7071068 L18.760984,21.1748157 C17.13245,22.3245842 15.1451009,23 13,23 C7.4771525,23 3,18.5228475 3,13 C3,7.4771525 7.4771525,3 13,3 Z M13,5 C8.581722,5 5,8.581722 5,13 C5,17.418278 8.581722,21 13,21 C17.418278,21 21,17.418278 21,13 C21,8.581722 17.418278,5 13,5 Z"
        id="Combined-Shape"
        fill={props.color}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

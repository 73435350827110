import { Content, TestNode } from '@ctek/test-player'
import { StudySession } from '@project-minerva/typings'
import { createContext, ReactNode, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useStudySessionData } from '../api/study-session.api'

interface StudySessionCtx {
  error: Error | undefined
  studySession: StudySession<TestNode<Content, Content>> | undefined
  loading: boolean
  initialiseStudySession: () => Promise<StudySession<TestNode<Content, Content>>>
}

const StudySessionContext = createContext<StudySessionCtx>({
  error: undefined,
  studySession: undefined,
  loading: false,
  initialiseStudySession: () => Promise.resolve({} as StudySession<TestNode<Content, Content>>),
})

export const useStudySession = () => {
  const context = useContext(StudySessionContext)

  if (!context) {
    throw new Error('useStudySession must be called inside a StudySessionProvider')
  }

  return context
}

export function StudySessionProvider(props: { children: ReactNode }) {
  const { subjectId, testId } = useParams<{ subjectId: string; testId: string }>()
  const { error, data, loading, load } = useStudySessionData(subjectId, testId)

  return (
    <StudySessionContext.Provider
      value={{
        error,
        loading,
        studySession: data,
        initialiseStudySession: load,
      }}
    >
      {props.children}
    </StudySessionContext.Provider>
  )
}

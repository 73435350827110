import { useAuthContext } from '@project-minerva/auth-cognito'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  PrimaryTypography,
  CircularProgress,
} from '@project-minerva/design-system'
import { renderIntlLink } from '@project-minerva/intl'
import { GuardianApplicant, GuardianDocument, SendStatusInput } from '@project-minerva/typings'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CloseButton } from '../buttons'
import { ErrorMessage } from '../error'
import { SendStatus } from './create-profile/steps/send-status'

export function SendStatusModal(props: {
  adminMode?: boolean
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  applicant: GuardianApplicant
  fetchApplicant: () => void
  addApplicantSEND: (applicantId: string, send: Partial<SendStatusInput>, token: string) => Promise<unknown>
  confirmApplicantConsent: (applicantId: string, data: boolean, medical: boolean, token: string) => Promise<unknown>
  updateApplicantSEND: (applicantId: string, isSend: boolean, send: SendStatusInput, token: string) => Promise<unknown>
  uploadSendDocument: (
    applicantId: string,
    formData: FormData,
    token: string
  ) => Promise<{ document: GuardianDocument }>
}) {
  const {
    adminMode,
    modalOpen,
    setModalOpen,
    applicant,
    fetchApplicant,
    addApplicantSEND,
    confirmApplicantConsent,
    updateApplicantSEND,
    uploadSendDocument,
  } = props
  const { accessToken } = useAuthContext()
  const [newApplicant, setNewApplicant] = useState<GuardianApplicant>(applicant)
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialSend = useMemo(() => applicant.isSend, [applicant.isSend])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialDocs = useMemo(() => applicant.send?.documents.map((d) => d.documentId) || [], [applicant.send])

  const handleSubmit = async (updatedApplicant: GuardianApplicant) => {
    setSubmitting(true)

    try {
      await updateSEND(updatedApplicant)
      if (initialSend === null) {
        await confirmApplicantConsent(
          updatedApplicant.applicantId,
          updatedApplicant.consent.data,
          updatedApplicant.consent.medical,
          accessToken.current
        )
      }
    } catch (e) {
      setResponseError((e as unknown as Error).message)
    } finally {
      setSubmitting(false)
    }
  }

  const updateSEND = async (updatedApplicant: GuardianApplicant) => {
    const send: SendStatusInput = {
      additionalTime: updatedApplicant.send?.additionalTime || false,
      additionalTimeDescription: updatedApplicant.send?.additionalTime
        ? updatedApplicant.send.additionalTimeDescriptions[0]
        : '',
      environmentalProvisions: updatedApplicant.send?.environmentalProvisions || false,
      environmentalProvisionsDescription: updatedApplicant.send?.environmentalProvisions
        ? updatedApplicant.send.environmentalProvisionsDescriptions[0]
        : '',
      documents: updatedApplicant.send
        ? updatedApplicant.send.documents.map((doc: GuardianDocument) => doc.documentId)
        : [],
      documentNote: updatedApplicant.send?.documentNotes[0] || '',
    }
    try {
      if (updatedApplicant.isSend !== null) {
        if (applicant.isSend) {
          await addApplicantSEND(updatedApplicant.applicantId, send, accessToken.current)
        } else {
          await updateApplicantSEND(updatedApplicant.applicantId, updatedApplicant.isSend, send, accessToken.current)
        }
        fetchApplicant()
        setModalOpen(false)
      }
    } catch (e) {
      setResponseError((e as unknown as Error).message)
    }
  }

  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} data-testid="send-modal">
      <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title">
        <Box sx={{ verticalAlign: 'middle' }}>
          <PrimaryTypography variant="h5">
            <FormattedMessage id="add-send-details" defaultMessage="Add SEND details" />
          </PrimaryTypography>
        </Box>
        <CloseButton onClick={() => props.setModalOpen(false)} data-testid="modal-close" disabled={submitting} />
      </DialogTitle>
      <DialogContent data-testid="modal-content">
        {applicant.isSend && (
          <Alert severity="info" sx={{ mb: (theme) => theme.spacing(3) }}>
            <FormattedMessage
              id="add-send-details-info"
              values={{
                a: (chunk: string) => renderIntlLink(chunk, 'https://pretests.support.iseb.co.uk/'),
              }}
            />
          </Alert>
        )}
        <SendStatus
          applicant={newApplicant}
          setApplicant={setNewApplicant}
          handleSubmit={handleSubmit}
          adminMode={adminMode}
          editMode
          hideTitle
          disableRadios={initialSend !== null}
          initialSendIsNull={initialSend === null}
          initialDocs={initialDocs}
          applicantDocumentNotes={props.applicant.send?.documentNotes}
          applicantTimeDetails={props.applicant.send?.additionalTimeDescriptions}
          applicantProvisionsDetails={props.applicant.send?.environmentalProvisionsDescriptions}
          uploadSendDocument={uploadSendDocument}
          updateApplicantSEND={updateApplicantSEND}
        />
        {!!responseError.length && (
          <Box mt={2}>
            <ErrorMessage message={responseError} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color="secondary" disabled={submitting}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          form="create-profile-form"
          type="submit"
          data-testid="send-modal-confirm"
          disabled={submitting}
          endIcon={submitting && <CircularProgress size={16} />}
        >
          <FormattedMessage id="add-details" defaultMessage="Add details" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

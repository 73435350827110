import { getApiUrl, request } from '@project-minerva/api'

const baseUrl = getApiUrl('helios', '/v1/documents')

export const getDownloadToken = (docId: string, token: string) => {
  const url = `${baseUrl}/${docId}/token`
  return request<{ token: string; expiresAt: string }>(url, { method: 'GET' }, token)
}

export const getDocumentDownloadUrl = (docId: string, docToken: string, filename: string) => {
  const url = new URL(`${baseUrl}/${docId}/download`)
  url.searchParams.set('token', docToken)

  return url.toString()
}

export const getDocumentViewUrl = (docId: string, docToken: string, filename: string) => {
  const url = new URL(`${baseUrl}/${docId}/download`)
  url.searchParams.set('token', docToken)
  url.searchParams.set('view', 'inline')

  return url.toString()
}

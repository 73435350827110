import { Box, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

// TODO: use Chip component from MUI?
export const IncompleteTag = () => {
  return (
    <Box
      data-testid="applicant-status"
      sx={{
        background: 'rgba(237, 108, 2, 0.1)',
        borderRadius: 4,
        width: '92px',
        height: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          color: '#F69134',
          fontSize: '0.625rem',
          fontWeight: 600,
          textTransform: 'uppercase',
          margin: 'auto',
          width: 'fit-content',
        }}
      >
        <FormattedMessage id="incomplete" />
      </Typography>
    </Box>
  )
}

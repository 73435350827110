import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { Stack } from '@mui/material'
import { ExamLeftAlign } from '@project-minerva/assessment-common'
import {
  ContentLayout,
  formatTime,
  Settings,
  TestList,
  useFontSize,
  useLocalSettings,
} from '@project-minerva/assessment-player'
import { useBasicAuth } from '@project-minerva/auth-student'
import { Button, CircularProgress, LeftAlign, RightAlign, PageLayout, Typography } from '@project-minerva/design-system'
import { HeaderBranding, PageHeader } from '@project-minerva/shared-ui'
import { useCallback, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router'
import { useExaminationData } from '../data/examination-data.provider'
import ExaminationDescription from '../components/examination-description'
import {
  ExamPages,
  useTrackFontSizeChanged,
  useTrackFontSizeIconClicked,
  useTrackFontSizePopoverClosed,
  useTrackPageViewed,
} from '../mixpanel'

export const SelectTestPage = () => {
  const pageViewedTracked = useRef(false)
  const { assessment, loading, error, reload } = useExaminationData()
  const { pathname } = useLocation()
  const history = useHistory()
  const { logout } = useBasicAuth()
  const { fontSizeLabel } = useFontSize()
  const { clearSettings } = useLocalSettings()
  const trackPageViewed = useTrackPageViewed()
  const trackFontSizeChanged = useTrackFontSizeChanged()
  const trackFontSizeFromPage = useCallback(
    (previousSize: string | null, currentSize: string) =>
      trackFontSizeChanged({ pageName: ExamPages.TESTS_LIST, previousSize, currentSize }),
    [trackFontSizeChanged]
  )
  const trackFontSizeIconClicked = useTrackFontSizeIconClicked()
  const trackFontSizeIconClickedFromPage = useCallback(() => {
    trackFontSizeIconClicked({
      pageName: ExamPages.TESTS_LIST,
      fontSize: fontSizeLabel,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackFontSizeIconClicked])

  const trackFontSizePopoverClosed = useTrackFontSizePopoverClosed()
  const trackFontSizePopoverClosedFromPage = useCallback(
    (duration: number) => {
      trackFontSizePopoverClosed({
        pageName: ExamPages.TESTS_LIST,
        duration: formatTime(Math.floor(duration / 1000)),
        fontSize: fontSizeLabel,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackFontSizePopoverClosed]
  )
  const logoutAndReset = () => {
    clearSettings()
    logout()
  }

  let content: JSX.Element = <div />

  useEffect(() => {
    if (assessment && !pageViewedTracked.current) {
      trackPageViewed(ExamPages.TESTS_LIST)
      pageViewedTracked.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment])

  useEffect(() => {
    // if assessment is available on mount, it's because it has been previously loaded by the context
    // so we force reload to update test status when using the browser back button
    if (assessment) {
      reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    content = <CircularProgress />
  } else if (error) {
    content = (
      <>
        <Typography ml="20%" mr="20%">
          <FormattedMessage id="error-load-assessment" />
        </Typography>
        <Button data-testid="refreshbtn" onClick={reload}>
          <FormattedMessage id="refresh" />
        </Button>
      </>
    )
  } else if (assessment) {
    content = (
      <Stack spacing={3} width="100%">
        {assessment.firstName && assessment.lastName && (
          <Typography variant="h4">
            <FormattedMessage
              id="hello-applicant"
              defaultMessage="Hello, {firstName} {lastName}"
              values={{ ...assessment }}
            />
          </Typography>
        )}
        <ExaminationDescription />
        <TestList
          tests={assessment.tests}
          onItemClick={(subjectId: string, testId: string) => {
            history.push(`${pathname}/${assessment?.assessmentId}/subject/${subjectId}/test/${testId}`)
          }}
        />
      </Stack>
    )
  }

  return (
    <PageLayout root centred>
      <PageHeader>
        <LeftAlign ml={-3}>
          <HeaderBranding mr={-4} />
          <ExamLeftAlign />
        </LeftAlign>
        <RightAlign>
          <Stack direction="row" spacing={2}>
            <Settings
              trackFontSizeChanged={trackFontSizeFromPage}
              trackIconClicked={trackFontSizeIconClickedFromPage}
              trackPopoverClosed={trackFontSizePopoverClosedFromPage}
            />
            <LogoutButton onClick={logoutAndReset} />
          </Stack>
        </RightAlign>
      </PageHeader>
      <ContentLayout>
        <PageLayout centred>{content}</PageLayout>
      </ContentLayout>
    </PageLayout>
  )
}

const LogoutButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    data-testid="logout-btn"
    color="secondary"
    onClick={onClick}
    sx={{
      textTransform: 'lowercase',
      height: '2.2rem',
    }}
    disableElevation
    disableRipple
  >
    <Stack spacing={1} direction="row" alignItems="center">
      <Typography fontWeight={600} fontSize="1rem">
        <FormattedMessage id="logout" />
      </Typography>
      <LogoutRoundedIcon />
    </Stack>
  </Button>
)

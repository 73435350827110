import { Menu, MenuItem, ListItemText } from '@mui/material'
import { useAnchorElement } from '@project-minerva/core'
import { TertiaryButton } from '@project-minerva/design-system'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { AcademicYear } from '@project-minerva/typings'

interface Props {
  availableAcademicYears: AcademicYear[]
  selectedYear: AcademicYear
  onSelectedYearChange: (academicYear: AcademicYear) => void
}

export default function AcademicYearDropdown(props: Props) {
  const { availableAcademicYears, selectedYear, onSelectedYearChange } = props

  const { anchorElement, setAnchor, unsetAnchor } = useAnchorElement()
  const open = Boolean(anchorElement)

  const handleMenuItemClick = (year: AcademicYear) => () => {
    onSelectedYearChange(year)
    unsetAnchor()
  }

  return (
    <>
      <TertiaryButton
        size="small"
        endIcon={<KeyboardArrowDownRoundedIcon />}
        onClick={setAnchor}
        data-testid="academic-year-dropdown"
      >
        {`Year ${selectedYear.name}`}
      </TertiaryButton>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={unsetAnchor}
      >
        {availableAcademicYears.map((year) => (
          <MenuItem
            key={year.academicYearId}
            onClick={handleMenuItemClick(year)}
            selected={year.academicYearId === selectedYear.academicYearId}
          >
            <ListItemText>{`Year ${year.name}`}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

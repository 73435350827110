import { useEffect, useRef } from 'react'
import { getApiUrl } from '@project-minerva/api'
import { useAccessToken } from '@project-minerva/auth-student'
import { useRequest } from '@project-minerva/api'
import { AssessmentData } from '@project-minerva/typings'

const baseUrl = getApiUrl('atlas', '/v1/assessment')

export const useStudentsExams = () => {
  const { accessToken } = useAccessToken()
  const { error, loading, data, load } = useRequest<AssessmentData>(baseUrl, { method: 'GET' }, accessToken)
  const isFirstLoad = useRef(true)

  useEffect(() => {
    if (accessToken && isFirstLoad.current) {
      isFirstLoad.current = false
      load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  return { data, loading, error, reload: load }
}

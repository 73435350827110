import { Typography } from '@project-minerva/design-system'
import { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { AssessmentPlayerProps } from '../assessment-player'
import { useTestFlowController } from '../assessment-player.context'
import { AssessmentPlayerLayout } from '../assessment-player.layout'
import SimpleDialog from './simple-dialog'

export type EndScreenProps = Pick<AssessmentPlayerProps, 'onClose' | 'testName' | 'useCompletionHook'>

export const TestEndScreen = ({ onClose, testName, useCompletionHook }: EndScreenProps) => {
  const testEndedDialogRef = useRef()
  const { isTestCompleted, isTestTimedOut } = useTestFlowController()
  useCompletionHook?.()

  return (
    <AssessmentPlayerLayout.ContentContainer>
      <SimpleDialog
        open={isTestCompleted || isTestTimedOut}
        callback={onClose}
        title={<FormattedMessage id="test-ended-dialog-title" defaultMessage="Well done!" />}
        content={
          <Typography mb={2}>
            <FormattedMessage
              id="test-ended-dialog-content"
              defaultMessage="You have reached the end of the {name} test. You can click the close button and wait for further instructions from your teacher."
              values={{ name: testName, br: <br /> }}
            />
          </Typography>
        }
        buttonLabel={<FormattedMessage id="test-ended-dialog-button" />}
        data-testid="test-ended-dialog"
        ref={testEndedDialogRef}
      />
    </AssessmentPlayerLayout.ContentContainer>
  )
}

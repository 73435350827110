import { ReactNode } from 'react'
import { ApplicantTest } from '@project-minerva/typings'

export const renderHeaderWithTestId = (name: string | undefined, testId: string, style = {}) => (
  <div data-testid="list-header">
    <div data-testid={`${testId}`} style={style}>
      {name}
    </div>
  </div>
)

export const renderCellWithTestId = (cellContent: ReactNode, testId: string) => (
  <div data-testid={`${testId}`}>{cellContent}</div>
)

export const getSubjectClosedFromTestsList = (subject: string, tests: ApplicantTest[]) => {
  const test = tests.find((t) => t.subjectName === subject)
  return test && test.testSession?.isClosed
}

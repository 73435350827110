import { useBasicAuth, useAccessToken } from '../basic-auth/basic-auth.context'
import { Box, Button, Typography } from '@project-minerva/design-system'
import React, { useEffect, useState } from 'react'
import { CodeScreen } from './student-login-code'
import { DobScreen } from './student-login-dob'
import { validateCode, validateDob } from '../api/login-code.api'
import { FormattedMessage } from 'react-intl'

const StudentLoginWrapper = (props: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        background: (theme) => theme.palette.background.paper,
        p: (theme) => theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        width: '450px',
        borderRadius: 1,
      }}
    >
      {props.children}
    </Box>
  )
}

export const StudentLogin = () => {
  const [code, setCode] = useState('')
  const [dob, setDob] = useState<Date | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { authData, validate, logout } = useBasicAuth()
  const { accessToken } = useAccessToken()

  useEffect(() => setError(null), [dob, code])

  const onSubmitCode = async () => {
    try {
      setError(null)
      setLoading(true)
      await validate(() => validateCode(code))
      setLoading(false)
    } catch (err) {
      setError((err as Error).message)
      setLoading(false)
    }
  }

  if (!authData) {
    return (
      <StudentLoginWrapper>
        <CodeScreen code={code} setCode={setCode} onSubmit={onSubmitCode} error={error} loading={loading} />
      </StudentLoginWrapper>
    )
  }

  const onSubmitDob = async () => {
    try {
      setError(null)
      setLoading(true)
      await validate(() => validateDob(dob as Date, accessToken))
      setLoading(false)
    } catch (err) {
      setError((err as Error).message)
      setLoading(false)
    }
  }

  const reset = () => {
    setLoading(false)
    setError(null)
    setDob(null)
    setCode('')
    logout()
  }

  return (
    <>
      <StudentLoginWrapper>
        <DobScreen dob={dob} setDob={setDob} onSubmit={onSubmitDob} error={error} loading={loading} />
      </StudentLoginWrapper>
      <Button
        data-testid="back-to-access-code-btn"
        variant="text"
        color="secondary"
        onClick={reset}
        sx={{
          m: (theme) => theme.spacing(0),
          p: (theme) => theme.spacing(2, 4),
          width: '450px',
          justifyContent: 'flex-start',
        }}
      >
        <img alt="back arrow icon" src="assets/images/icon-arrow-back.svg" />
        <Typography
          sx={{ ml: (theme) => theme.spacing(1), fontWeight: 600, fontSize: '14px', color: 'text.secondary' }}
        >
          <FormattedMessage id="change-student-code" />
        </Typography>
      </Button>
    </>
  )
}

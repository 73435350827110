import { useGetProfile } from '@project-minerva/shared-api'
import { User } from '@project-minerva/typings'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import { PageError, PageLoader } from '../page'

interface UserProfileContextType {
  user: User
  updateUser: (value: User) => void
}

const UserProfileContext = createContext<UserProfileContextType>({
  user: {} as unknown as User,
  updateUser: (value: User) => {
    return
  },
})

export const useUserProfile = () => {
  const context = useContext(UserProfileContext)

  if (!context) {
    throw new Error('useUserProfile must be called inside a UserProfileProvider')
  }

  return context
}

export const UserProfileProvider: FC = ({ children }) => {
  const profileLoader = useGetProfile()

  const [user, setUser] = useState<User>({} as User)

  useEffect(() => {
    profileLoader.load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (profileLoader.data) {
      setUser(profileLoader.data.user)
    }
  }, [profileLoader.data])

  return (
    <>
      {profileLoader.loading && <PageLoader />}
      {profileLoader.error && <PageError error={profileLoader.error} />}
      {user && (
        <UserProfileContext.Provider value={{ user, updateUser: setUser }}>{children}</UserProfileContext.Provider>
      )}
    </>
  )
}

import { REGEX_COGNITO_PASSWORD_POLICY, useAuthContext } from '@project-minerva/auth-cognito'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@project-minerva/design-system'
import { changeEmail } from '@project-minerva/shared-api'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { CloseButton } from '../buttons/close-button'
import { useToast } from '../hooks'
import { useUserProfile } from './user-profile.provider'

interface ChangeEmailFormValues {
  newEmail: string
  confirmNewEmail: string
  password: string
}

interface ChangeEmailModalProps {
  closeModal: () => void
  open: boolean
}

const REGEX_EMAIL =
  /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g

const useChangeEmailValidationSchema = () => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    newEmail: yup
      .string()
      .required(formatMessage({ id: 'field-required' }))
      .matches(REGEX_EMAIL, formatMessage({ id: 'invalid-email-format' })),
    confirmNewEmail: yup
      .string()
      .required(formatMessage({ id: 'field-required' }))
      .matches(REGEX_EMAIL, formatMessage({ id: 'invalid-email-format' }))
      .test(
        'matches new email',
        formatMessage({ id: 'change-email-error-match-current-email' }),
        function (confirmNewEmail) {
          return confirmNewEmail === this.parent.newEmail
        }
      ),
    password: yup
      .string()
      .required(formatMessage({ id: 'field-required' }))
      .matches(REGEX_COGNITO_PASSWORD_POLICY, formatMessage({ id: 'change-password-error-policy' })),
  })
}

export const ChangeEmailModal = ({ closeModal, open }: ChangeEmailModalProps) => {
  const { accessToken } = useAuthContext()
  const { updateUser } = useUserProfile()
  const { formatMessage } = useIntl()
  const [genericError, setGenericError] = useState('')
  const validationSchema = useChangeEmailValidationSchema()
  const [toast, openToast] = useToast(
    'success',
    <FormattedMessage id="change-email-success" defaultMessage={'Email successfully changed.'} />
  )
  const changeEmailForm = useFormik<ChangeEmailFormValues>({
    initialValues: { newEmail: '', confirmNewEmail: '', password: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values) => {
      setGenericError('')

      // sign in to validate current password
      try {
        const { username } = await Auth.currentAuthenticatedUser()
        await Auth.signIn(username, values.password)
      } catch (error) {
        setGenericError(formatMessage({ id: 'change-password-error-incorrect' }))
        return
      }

      await changeEmail(values.newEmail, accessToken.current)
        .then(async ({ user }) => {
          Auth.signIn(values.newEmail, values.password)
          updateUser(user)
          openToast()
          closeModalAndResetForm()
        })
        .catch((err) => setGenericError(err.message))
        .finally(() => changeEmailForm.setSubmitting(false))
    },
  })

  const closeModalAndResetForm = () => {
    setGenericError('')
    changeEmailForm.resetForm()
    closeModal()
  }

  return (
    <>
      {toast}
      <Dialog open={open} data-testid="change-email-modal">
        <form
          onSubmit={(evt) => {
            evt.preventDefault()
            changeEmailForm.submitForm()
          }}
        >
          <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title">
            <Box sx={{ verticalAlign: 'middle' }}>
              <FormattedMessage id="change-email" defaultMessage="Change Email" />
            </Box>
            <CloseButton
              onClick={closeModalAndResetForm}
              data-testid="modal-close"
              disabled={changeEmailForm.isSubmitting}
            />
          </DialogTitle>
          <DialogContent data-testid="modal-content" sx={{ width: 400, maxWidth: 400 }}>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="new-email" defaultMessage="New Email Address" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                data-testid="new-email-input"
                id="newEmail"
                name="newEmail"
                onChange={(evt) => {
                  changeEmailForm.setFieldError('newEmail', undefined)
                  changeEmailForm.handleChange(evt)
                }}
                value={changeEmailForm.values.newEmail}
                error={changeEmailForm.touched.newEmail && !!changeEmailForm.errors.newEmail}
                helperText={changeEmailForm.touched.newEmail && changeEmailForm.errors.newEmail}
              />
            </Box>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="confirm-new-email" defaultMessage="Confirm New Email Address" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                data-testid="confirm-new-email-input"
                id="confirmNewEmail"
                name="confirmNewEmail"
                onChange={(evt) => {
                  changeEmailForm.setFieldError('confirmNewEmail', undefined)
                  changeEmailForm.handleChange(evt)
                }}
                value={changeEmailForm.values.confirmNewEmail}
                error={changeEmailForm.touched.confirmNewEmail && !!changeEmailForm.errors.confirmNewEmail}
                helperText={changeEmailForm.touched.confirmNewEmail && changeEmailForm.errors.confirmNewEmail}
              />
            </Box>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="password" defaultMessage="Password" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                type="password"
                data-testid="password-input"
                id="password"
                name="password"
                onChange={(evt) => {
                  changeEmailForm.setFieldError('password', undefined)
                  changeEmailForm.handleChange(evt)
                }}
                value={changeEmailForm.values.password}
                error={changeEmailForm.touched.password && !!changeEmailForm.errors.password}
                helperText={changeEmailForm.touched.password && changeEmailForm.errors.password}
              />
            </Box>
            {genericError && (
              <Alert severity="error" data-testid="generic-error">
                {genericError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              data-testid="modal-cancel-button"
              onClick={closeModalAndResetForm}
              disabled={changeEmailForm.isSubmitting}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button data-testid="modal-submit-button" type="submit" disabled={changeEmailForm.isSubmitting}>
              <FormattedMessage id="apply" defaultMessage="Apply" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

import { useAuthContext } from '@project-minerva/auth-cognito'
import { personalInfoHasChanged } from '@project-minerva/base-utils'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  PrimaryTypography,
  CircularProgress,
} from '@project-minerva/design-system'
import { GuardianApplicant, ISEBApplicant } from '@project-minerva/typings'
import { connect } from 'formik'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorMessage } from '../error'
import { CloseButton } from '../buttons'
import { PersonalInformation } from './create-profile/steps/personal-information'

function PersonalInformationModal(props: {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  applicant: GuardianApplicant
  fetchApplicant: () => void
  patchApplicant: (id: string, body: Partial<GuardianApplicant | ISEBApplicant>, token: string) => Promise<unknown>
  adminMode?: boolean
  onPatchComplete?: () => void
}) {
  const { modalOpen, setModalOpen, applicant, fetchApplicant, patchApplicant, adminMode, onPatchComplete } = props
  const { accessToken } = useAuthContext()
  const [newApplicant, setNewApplicant] = useState<GuardianApplicant>(applicant)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState('')

  const updateApplicant = async (updatedApplicant: GuardianApplicant) => {
    setSubmitting(true)
    const { firstName, middleName, lastName, dateOfBirth, gender, guardianRelation, currentSchool } = updatedApplicant
    const applicantBody: Partial<GuardianApplicant> = {
      firstName,
      middleName,
      lastName,
      dateOfBirth,
      gender,
      guardianRelation,
      currentSchool,
    }
    try {
      if (personalInfoHasChanged(updatedApplicant, applicant))
        await patchApplicant(applicant.applicantId, applicantBody, accessToken.current)
      if (updatedApplicant.dateOfBirth !== applicant.dateOfBirth) onPatchComplete?.()
      setModalOpen(false)
      fetchApplicant()
    } catch (e) {
      setResponseError((e as unknown as Error).message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} data-testid="personal-info-modal">
      <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title">
        <Box sx={{ verticalAlign: 'middle' }}>
          <PrimaryTypography variant="h5">
            {adminMode ? (
              <FormattedMessage id="edit-information" />
            ) : (
              <FormattedMessage id="edit-applicant-information" defaultMessage="Edit Applicant Information" />
            )}
          </PrimaryTypography>
        </Box>
        <CloseButton onClick={() => props.setModalOpen(false)} data-testid="modal-close" disabled={submitting} />
      </DialogTitle>
      <DialogContent data-testid="modal-content">
        <PersonalInformation
          applicant={newApplicant}
          setApplicant={setNewApplicant}
          isDuplicate={isDuplicate}
          setIsDuplicate={setIsDuplicate}
          hideTitle
          handleSubmit={updateApplicant}
          adminMode={adminMode}
        />
        {!!responseError.length && (
          <Box mt={2}>
            <ErrorMessage message={responseError} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color="secondary" disabled={submitting}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          form="create-profile-form"
          type="submit"
          data-testid="personal-info-modal-confirm"
          disabled={submitting}
          endIcon={submitting && <CircularProgress size={16} />}
        >
          {adminMode ? (
            <FormattedMessage id="save-changes" />
          ) : (
            <FormattedMessage id="confirm" defaultMessage="Confirm" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default connect(PersonalInformationModal)

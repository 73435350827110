import { useAuthContext } from '@project-minerva/auth-cognito'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@project-minerva/design-system'
import { editProfile } from '@project-minerva/shared-api'
import { User } from '@project-minerva/typings'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { CloseButton } from '../buttons/close-button'
import { useToast } from '../hooks'
import { useUserProfile } from './user-profile.provider'

interface EditInfoFormValues {
  firstName: string
  lastName: string
  phone: string
}

interface Props {
  closeModal: () => void
  open: boolean
}

const useEditPersonalInformationValidationSchema = () => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    firstName: yup.string().required(formatMessage({ id: 'field-required' })),
    lastName: yup.string().required(formatMessage({ id: 'field-required' })),
    phone: yup.string(),
  })
}

export const EditPersonalInformationModal = ({ closeModal, open }: Props) => {
  const { accessToken } = useAuthContext()
  const { user, updateUser } = useUserProfile()
  const [genericError, setGenericError] = useState('')
  const validationSchema = useEditPersonalInformationValidationSchema()
  const [toast, openToast] = useToast(
    'success',
    <FormattedMessage id="profile-update-success" defaultMessage={'Profile successfully changed.'} />
  )

  const editInformationForm = useFormik<EditInfoFormValues>({
    initialValues: { firstName: '', lastName: '', phone: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values) => {
      setGenericError('')
      await editProfile({ user: editInformationForm.values as Partial<User> }, accessToken.current)
        .then(({ user }) => {
          openToast()
          closeModalAndResetForm()
          updateUser(user)
        })
        .catch((err) => setGenericError(err.message))
        .finally(() => editInformationForm.setSubmitting(false))
    },
  })

  const closeModalAndResetForm = () => {
    setGenericError('')
    editInformationForm.resetForm()
    editInformationForm.setValues(user)
    closeModal()
  }

  useEffect(() => {
    if (user) {
      editInformationForm.setValues(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <>
      {toast}
      <Dialog open={open} data-testid="edit-info-modal">
        <form
          onSubmit={(evt) => {
            evt.preventDefault()

            if (editInformationForm.dirty) {
              editInformationForm.submitForm()
            } else {
              closeModalAndResetForm()
            }
          }}
        >
          <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title">
            <Box sx={{ verticalAlign: 'middle' }}>
              <FormattedMessage id="edit-personal-info" defaultMessage="Edit Personal Information" />
            </Box>
            <CloseButton
              onClick={closeModalAndResetForm}
              data-testid="modal-close"
              disabled={editInformationForm.isSubmitting}
            />
          </DialogTitle>
          <DialogContent data-testid="modal-content" sx={{ width: 400, maxWidth: 400 }}>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="first-name" defaultMessage="First Name" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                data-testid="first-name-input"
                id="firstName"
                name="firstName"
                onChange={(evt) => {
                  editInformationForm.setFieldError('firstName', undefined)
                  editInformationForm.handleChange(evt)
                }}
                value={editInformationForm.values.firstName}
                error={editInformationForm.touched.firstName && !!editInformationForm.errors.firstName}
                helperText={editInformationForm.touched.firstName && editInformationForm.errors.firstName}
              />
            </Box>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="last-name" defaultMessage="Last Name" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                data-testid="last-name-input"
                id="lastName"
                name="lastName"
                onChange={(evt) => {
                  editInformationForm.setFieldError('lastName', undefined)
                  editInformationForm.handleChange(evt)
                }}
                value={editInformationForm.values.lastName}
                error={editInformationForm.touched.lastName && !!editInformationForm.errors.lastName}
                helperText={editInformationForm.touched.lastName && editInformationForm.errors.lastName}
              />
            </Box>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="phone-number" defaultMessage="Phone Number" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="off"
                data-testid="phone-input"
                id="phone"
                name="phone"
                onChange={(evt) => {
                  editInformationForm.setFieldError('phone', undefined)
                  editInformationForm.handleChange(evt)
                }}
                value={editInformationForm.values.phone}
                error={editInformationForm.touched.phone && !!editInformationForm.errors.phone}
                helperText={editInformationForm.touched.phone && editInformationForm.errors.phone}
              />
            </Box>
            {genericError && (
              <Alert severity="error" data-testid="generic-error">
                {genericError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              data-testid="modal-cancel-button"
              onClick={closeModalAndResetForm}
              disabled={editInformationForm.isSubmitting}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button data-testid="modal-submit-button" type="submit" disabled={editInformationForm.isSubmitting}>
              <FormattedMessage id="apply" defaultMessage="Apply" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

import { createContext, ReactNode } from 'react'
import { useInterpret } from '@xstate/react'
import { getTestFlowMachine, TestFlowMachineInterpreter, useGenericTestFlowController } from './machines/test'
import { AnyStateMachine } from 'xstate/lib/types'

export interface PlayerCtx<TSession> {
  player: TestFlowMachineInterpreter<TSession>
}

export const isDev = () => process.env.NX_NODE_ENV !== 'prod'

// Test content may not need extending of type
export function createPlayerContext<TSession, TNavHook>(
  createNewMachine: (data: TSession) => AnyStateMachine,
  useCustomTestController: (playerContext: React.Context<PlayerCtx<TSession>>) => TNavHook
) {
  const testFlowMachine = getTestFlowMachine<TSession>(createNewMachine)

  const PlayerContext = createContext<PlayerCtx<TSession>>({
    player: {} as TestFlowMachineInterpreter<TSession>,
  })

  const PlayerProvider = ({ children }: { children: ReactNode }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const player = useInterpret(testFlowMachine, { devTools: isDev() } as any)
    return <PlayerContext.Provider value={{ player }}>{children}</PlayerContext.Provider>
  }

  const useTestFlowController = () => useGenericTestFlowController<TSession>(PlayerContext)

  const useTestController = () => useCustomTestController(PlayerContext)

  return {
    PlayerProvider,
    useTestController,
    useTestFlowController,
  }
}

import { useFontSize, useScreenFilter, useTestController } from '@project-minerva/assessment-player'
import { useAccessToken } from '@project-minerva/auth-student'
import { Content, NavigationHook, TestDocument, TestQuestion } from '@ctek/test-player'
import { AssessmentTestQuestion, SubmitAnswer } from '@project-minerva/typings'
import * as Sentry from '@sentry/browser'
import { useCallback, useState } from 'react'
import { submitAnswer } from '../api/study-session.api'
import { useStudySession } from '../data/study-session.provider'
import { useTrackStudySessionDocumentViewed, useTrackStudySessionQuestionAnswered } from '../mixpanel'
import { useTrackTimer } from './use-track-timer'
import { useTestProgress } from '../components/test-progress.provider'

export const useNavigation: NavigationHook = () => {
  const { isDocument } = useTestController()
  const documentHook = useDocumentNavigation()
  const questionHook = useQuestionNavigation()

  return isDocument ? documentHook : questionHook
}

export const useDocumentNavigation: NavigationHook = () => {
  const { studySession } = useStudySession()
  const { currentContent, next } = useTestController()
  const trackStudySessionDocumentViewed = useTrackStudySessionDocumentViewed()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const trackTimerCallback = useTrackTimer(studySession!.timeLimitSeconds * 1000)
  const { screenFilter } = useScreenFilter()
  const { fontSizeLabel } = useFontSize()

  const callback = useCallback(() => {
    trackStudySessionDocumentViewed({
      content: currentContent as TestDocument<Content>,
      screenFilter,
      fontSize: fontSizeLabel,
      ...trackTimerCallback?.(),
    })
    next()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent, fontSizeLabel, next, screenFilter])
  return { handleNext: callback }
}

export const useQuestionNavigation: NavigationHook = () => {
  const { currentContent, isPractice, isPreviouslyAnswered, selectedAnswer, appendTestContent } = useTestController()
  const { next } = useTestController()
  const { studySession } = useStudySession()
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false)
  const [error, setError] = useState<Error>()
  const { accessTokenRef } = useAccessToken()
  const { screenFilter } = useScreenFilter()
  const { fontSizeLabel } = useFontSize()
  const trackStudySessionQuestionAnswered = useTrackStudySessionQuestionAnswered()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const trackTimerCallback = useTrackTimer(studySession!.timeLimitSeconds * 1000)
  const { setProgress } = useTestProgress()

  if (error) {
    throw error
  }

  const callback = useCallback(() => {
    const action = isPractice && !selectedAnswer ? 'skip' : 'answer'
    const answerBody: SubmitAnswer = {
      questionGroupId: (currentContent as TestQuestion<Content, Content>).questionGroupId,
      questionId: currentContent.id,
      action,
      answerPosition:
        action === 'answer'
          ? (currentContent as TestQuestion<Content, Content>).answers.findIndex(
              (answer) => answer.id === selectedAnswer
            )
          : undefined,
      answers: action === 'answer' ? [selectedAnswer as string] : [],
      studySessionId: studySession?.studySessionId as string,
      isebTestSectionId: (currentContent as AssessmentTestQuestion).isebTestSectionId,
      questionPoolId: (currentContent as AssessmentTestQuestion).questionPoolId,
    }
    setError(undefined)
    setIsSubmittingAnswer(true)
    submitAnswer(answerBody, accessTokenRef.current)
      .then((res) => {
        trackStudySessionQuestionAnswered({
          question: currentContent as TestQuestion<Content, Content>,
          action,
          screenFilter,
          fontSize: fontSizeLabel,
          ...trackTimerCallback?.(),
        })

        if (res.testContent.length) {
          appendTestContent(res.testContent)
        }

        if (res.percentageComplete !== undefined) {
          setProgress(res.percentageComplete)
        }

        setIsSubmittingAnswer(false)
        next({
          questionId: currentContent.id,
          answer: action === 'answer' ? (selectedAnswer as string) : null,
        })
      })
      .catch((error) => {
        setIsSubmittingAnswer(false)
        // question already answered to cover practice questions
        if (error.status === 409 && isPractice) return next()
        else {
          // TODO: remove when sentry is set up, adding for follow up on logrocket
          console.error(error)
          Sentry.captureException(`Error submitting question answer: ${error.message}`)
          setError(error)
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent, fontSizeLabel, isPractice, next, screenFilter, selectedAnswer, studySession?.studySessionId])

  const nextHandler = isPreviouslyAnswered ? next : callback

  return { handleNext: nextHandler, isFetching: isSubmittingAnswer }
}

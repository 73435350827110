import { alpha, Typography } from '@project-minerva/design-system'
import { ReactNode } from 'react'

export const AdditionalInfo = ({ editMode, children }: { editMode?: boolean; children: ReactNode }) => (
  <Typography
    variant={editMode ? 'subtitle2' : 'body1'}
    sx={{
      background: editMode ? (theme) => alpha(theme.palette.secondary.main, 0.3) : undefined,
      p: editMode ? (theme) => theme.spacing(1.5) : undefined,
      mb: (theme) => theme.spacing(1),
    }}
  >
    {children}
  </Typography>
)

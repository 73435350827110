import { Button } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import { FormError } from '@project-minerva/core'

export const LoginErrorAndButton = (props: { buttonLabel?: string; error: string | null; loading: boolean }) => {
  const { buttonLabel, error, loading } = props

  return (
    <>
      {error && <FormError error={error} />}
      <Button
        data-testid="access-code-submit-btn"
        type="submit"
        color="primary"
        disabled={loading}
        sx={{ m: (theme) => theme.spacing(0) }}
      >
        <FormattedMessage id={buttonLabel ?? 'submit-button'} />
      </Button>
    </>
  )
}

import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { TextFieldProps } from '@mui/material'
import { TextField, InputAdornment } from '../..'

export default function SearchInput({
  onEnterKey,
  ...props
}: TextFieldProps & { onEnterKey?: (value: string) => void }) {
  return (
    <TextField
      data-testid="search-box"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon />
          </InputAdornment>
        ),
      }}
      {...props}
      onKeyUp={(evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === 'Enter') {
          evt.preventDefault()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onEnterKey?.((evt.target as any).value)
        }
      }}
    />
  )
}

import { tooltipClasses, styled, TooltipProps, Tooltip, AlertColor } from '@mui/material'

export const CustomTooltip = styled(({ className, status, ...props }: { status?: AlertColor } & TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" disableInteractive />
))(({ status, theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '4px',
    padding: '6px 9px',
    backgroundColor: status ? theme.palette[status].main : undefined,
  },
}))

import { useAuthContext } from '@project-minerva/auth-cognito'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  PrimaryTypography,
  CircularProgress,
} from '@project-minerva/design-system'
import { GuardianApplicant } from '@project-minerva/typings'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CloseButton } from '../buttons'
import { ErrorMessage } from '../error'
import { EalStatus } from './eal-status'

export function EalStatusModal(props: {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  applicant: GuardianApplicant
  fetchApplicant: () => void
  addEal: (id: string, desc: string, token: string) => Promise<unknown>
  updateEal: (id: string, eal: boolean, desc: string, token: string) => Promise<unknown>
  changeToYesMode?: boolean
  adminMode?: boolean
}) {
  const { modalOpen, setModalOpen, applicant, fetchApplicant, addEal, updateEal, changeToYesMode, adminMode } = props
  const { accessToken } = useAuthContext()
  const [newApplicant, setNewApplicant] = useState<GuardianApplicant>(applicant)
  const [submitting, setSubmitting] = useState(false)
  const [responseError, setResponseError] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialEal = useMemo(() => applicant.isEal, [])

  const onSubmit = async (updatedApplicant: GuardianApplicant) => {
    setSubmitting(true)
    const description = updatedApplicant.eal?.environmentalProvisionsDescriptions[0] || ''
    try {
      if (updatedApplicant !== null)
        if (applicant.isEal) {
          await addEal(applicant.applicantId, description, accessToken.current)
        } else {
          await updateEal(applicant.applicantId, updatedApplicant.isEal || false, description, accessToken.current)
        }
      fetchApplicant()
      setModalOpen(false)
    } catch (e) {
      setResponseError((e as unknown as Error).message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} data-testid="eal-modal">
      <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title">
        <Box sx={{ verticalAlign: 'middle' }}>
          {changeToYesMode ? (
            <PrimaryTypography variant="h5">
              <FormattedMessage id="change-eal-to-yes" />
            </PrimaryTypography>
          ) : (
            <PrimaryTypography variant="h5">
              <FormattedMessage id="add-eal-details" defaultMessage="Add EAL details" />
            </PrimaryTypography>
          )}
        </Box>
        <CloseButton onClick={() => props.setModalOpen(false)} data-testid="modal-close" disabled={submitting} />
      </DialogTitle>
      <DialogContent data-testid="modal-content">
        <EalStatus
          editMode
          applicant={newApplicant}
          setApplicant={setNewApplicant}
          applicantProvisionsDetails={applicant.eal?.environmentalProvisionsDescriptions || []}
          hideTitle
          disableRadios={!changeToYesMode && initialEal !== null}
          handleSubmit={onSubmit}
          changeToYesMode={changeToYesMode}
          adminMode={adminMode}
        />
        {!!responseError.length && (
          <Box mt={2}>
            <ErrorMessage message={responseError} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color="secondary" disabled={submitting}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          form="create-profile-form"
          type="submit"
          data-testid="eal-modal-confirm"
          disabled={submitting}
          endIcon={submitting && <CircularProgress size={16} />}
        >
          <FormattedMessage id="add-details" defaultMessage="Add details" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

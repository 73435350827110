export const ProfileIcon = (props: { color: string }) => (
  <svg
    id="icon-profile"
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon-profile</title>
    <g id="icon-profile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M20,0 C31.045695,0 40,8.954305 40,20 C40,31.045695 31.045695,40 20,40 C8.954305,40 0,31.045695 0,20 C0,8.954305 8.954305,0 20,0 Z M20,2.05128205 C10.0871968,2.05128205 2.05128205,10.0871968 2.05128205,20 C2.05128205,29.9128032 10.0871968,37.9487179 20,37.9487179 C29.9128032,37.9487179 37.9487179,29.9128032 37.9487179,20 C37.9487179,10.0871968 29.9128032,2.05128205 20,2.05128205 Z M20.2342157,19.2980747 C26.9829258,19.2980747 32.4762266,24.4941189 32.4762266,30.9331391 L32.4762266,30.9331391 L32.4762266,31.6722036 L31.7751412,31.9060744 C28.3181802,33.0592614 24.4702498,33.6339523 20.2342157,33.6339523 C15.9981816,33.6339523 12.1502512,33.0592614 8.6932902,31.9060744 L8.6932902,31.9060744 L7.99220484,31.6722036 L7.99220484,30.9331391 C7.99220484,24.4941189 13.4855056,19.2980747 20.2342157,19.2980747 Z M20.2342157,21.3493567 C14.8854183,21.3493567 10.5171122,25.2195916 10.0795286,30.1221489 L10.0795286,30.1221489 L10.0738462,30.1907692 L10.5017075,30.3191558 C13.2446548,31.1045293 16.2529784,31.5245337 19.5282787,31.5770437 L19.5282787,31.5770437 L20.2342157,31.5826702 C23.7843385,31.5826702 27.0278518,31.1606274 29.9667239,30.3191558 L29.9667239,30.3191558 L30.3938462,30.1907692 L30.3889028,30.1221489 C29.9592753,25.308729 25.7405612,21.4904338 20.5249782,21.3531779 L20.5249782,21.3531779 Z M20.2342157,4.1025641 C24.0559174,4.1025641 27.1350981,7.32170755 27.1350981,11.2705029 C27.1350981,15.2192982 24.0559174,18.4384417 20.2342157,18.4384417 C16.412514,18.4384417 13.3333333,15.2192982 13.3333333,11.2705029 C13.3333333,7.32170755 16.412514,4.1025641 20.2342157,4.1025641 Z M20.2342157,6.15384615 C17.566305,6.15384615 15.3846154,8.43470347 15.3846154,11.2705029 C15.3846154,14.1063023 17.566305,16.3871596 20.2342157,16.3871596 C22.9021264,16.3871596 25.083816,14.1063023 25.083816,11.2705029 C25.083816,8.43470347 22.9021264,6.15384615 20.2342157,6.15384615 Z"
        id="Combined-Shape"
        fill={props.color}
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
)

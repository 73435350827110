import { alpha, Chip } from '@mui/material'
import { getAssessmentStatus } from '@project-minerva/base-utils'
import { CentreAssessment } from '@project-minerva/typings'
import { FormattedMessage } from 'react-intl'

interface Props {
  assessment: CentreAssessment
  small?: boolean
}

export function AssessmentStatusChip({ assessment, small }: Props) {
  const status = getAssessmentStatus(assessment)
  return status === 'SCHEDULED' ? (
    <Chip
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.info.main, 0.2),
        color: (theme) => theme.palette.info.main,
      }}
      data-testid="assessment-status-chip"
      label={<FormattedMessage id="scheduled" />}
      size={small ? 'small' : undefined}
    />
  ) : status === 'ENDED' ? (
    <Chip
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.success.main, 0.2),
        color: (theme) => theme.palette.success.main,
      }}
      data-testid="assessment-status-chip"
      label={<FormattedMessage id="ended" />}
      size={small ? 'small' : undefined}
    />
  ) : (
    <Chip
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
        color: (theme) => theme.palette.primary.main,
      }}
      data-testid="assessment-status-chip"
      label={<FormattedMessage id="active" />}
      size={small ? 'small' : undefined}
    />
  )
}

import { useTestFlowController } from '@project-minerva/assessment-player'
import { useAccessToken } from '@project-minerva/auth-student'
import { TestEndedHook, useTimer } from '@ctek/test-player'
import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { closeStudySession } from '../api/study-session.api'
import { useLastContentViewed } from '../components/last-content-viewed.provider'
import { useStudySession } from '../data/study-session.provider'
import { useTrackStudySessionCompleted } from '../mixpanel'
import { useTrackTimer } from './use-track-timer'
import { useTestProgress } from '../components/test-progress.provider'
import { useTrackSettings } from './use-track-settings'

export const useCompletion: TestEndedHook = () => {
  const { isTestTimedOut, isTestFinished } = useTestFlowController()
  const { lastContentViewedRef } = useLastContentViewed()
  const { pauseTimer } = useTimer()
  const { accessToken } = useAccessToken()
  const { studySession } = useStudySession()
  const trackSettingsCallback = useTrackSettings()
  const { progress: progressValue } = useTestProgress()
  const studySessionId = studySession?.studySessionId
  const trackStudySessionCompleted = useTrackStudySessionCompleted()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const trackTimerCallback = useTrackTimer(studySession!.timeLimitSeconds * 1000)

  useEffect(() => {
    const unansweredQuestion = isTestTimedOut ? lastContentViewedRef.current : undefined
    pauseTimer()
    closeStudySession(accessToken, unansweredQuestion)
      .then(() => {
        trackStudySessionCompleted({
          completedWithinTimeFrame: isTestFinished && !isTestTimedOut,
          progressValue,
          content: unansweredQuestion,
          ...trackTimerCallback?.(),
          ...trackSettingsCallback?.(),
        })
      })
      .catch((error: Error) => {
        Sentry.captureException(`Error to close study session: ${studySessionId}, error: ${error.message}`)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

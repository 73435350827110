import { useEffect } from 'react'
import { useIntl } from 'react-intl'

export const useAlertOnPageRefresh = () => {
  const { formatMessage } = useIntl()
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      //
      const message = formatMessage({ id: 'leave-test-prompt' })
      e.returnValue = message
      return message
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

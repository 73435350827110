import { useCurrentAcademicYear } from '@project-minerva/shared-api'
import { AcademicYear } from '@project-minerva/typings'
import React from 'react'
import { LoadingSpinner } from '../loading-spinner'
import { isAfter, isBefore } from 'date-fns'

interface AcademicYearData {
  currentAcademicYear: AcademicYear
  nextAcademicYear?: AcademicYear
  previousAcademicYear?: AcademicYear
  hasStarted: boolean
  isRegistrationPeriod: boolean
  isTestingPeriod: boolean
}

const AcademicYearContext = React.createContext<AcademicYearData>({} as AcademicYearData)

export const useAcademicYear = () => React.useContext(AcademicYearContext)

export const AcademicYearProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: currentAcademicYearResponse, load: loadCurrentAcademicYear } = useCurrentAcademicYear()

  React.useEffect(() => {
    loadCurrentAcademicYear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!currentAcademicYearResponse) return <LoadingSpinner />

  const { academicYear: currentAcademicYear, ...restAcademicYear } = currentAcademicYearResponse
  const now = new Date()
  const isRegistrationPeriod =
    isAfter(now, new Date(currentAcademicYear.registrationStartDate)) &&
    isBefore(now, new Date(currentAcademicYear.registrationEndDate))
  const isTestingPeriod =
    isAfter(now, new Date(currentAcademicYear.testingStartDate)) &&
    isBefore(now, new Date(currentAcademicYear.testingEndDate))

  return (
    <AcademicYearContext.Provider
      value={{
        currentAcademicYear: currentAcademicYear,
        ...restAcademicYear,
        isRegistrationPeriod,
        isTestingPeriod,
      }}
    >
      {children}
    </AcademicYearContext.Provider>
  )
}

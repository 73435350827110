import { getApiUrl } from '@project-minerva/api'
import { useAccessToken } from '@project-minerva/auth-student'
import { request, useRequest } from '@project-minerva/api'
import { AssessmentNextStep, Pause, Resume, StudySession, SubmitAnswer } from '@project-minerva/typings'
import { Content, TestNode } from '@ctek/test-player'

const baseUrl = getApiUrl('atlas', '/v1/assessment')

export const useStudySessionData = (subjectId: string, testId: string) => {
  const body = JSON.stringify({ subjectId, testId })
  const { accessToken } = useAccessToken()
  const { error, loading, data, load } = useRequest<StudySession<TestNode<Content, Content>>>(
    `${baseUrl}/initialise`,
    { method: 'POST', body },
    accessToken
  )

  return { data, loading, error, load }
}

export const submitAnswer = (body: SubmitAnswer, token: string) => {
  return request<AssessmentNextStep>(
    `${baseUrl}/question-answered`,
    { method: 'POST', body: JSON.stringify(body) },
    token
  )
}

export type LastContentViewed = {
  contentId: string
  contentType: string
}

export const closeStudySession = (token: string, lastContentViewed?: LastContentViewed) => {
  const body = JSON.stringify(lastContentViewed)
  return request(`${baseUrl}/close-session`, { method: 'PUT', body, returnRaw: true }, token)
}

export const pauseTest = (payload: { invigilatorCode: string; reason: string }, token: string) => {
  const body = JSON.stringify(payload)
  return request<Pause>(`${baseUrl}/pause-test`, { method: 'POST', body }, token)
}

export const resumeTest = (token: string) => {
  return request<Resume>(`${baseUrl}/resume-test`, { method: 'PUT' }, token)
}

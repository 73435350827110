import {
  Content,
  CoreContent,
  CoreSession,
  createContentNavigationControllerHook,
  createPlayerContext,
  createTestMachine,
  findLastIndex,
  SelectedAnswer,
  TestNode,
} from '@ctek/test-player'
import { StudySession } from '@project-minerva/typings'

type TestSession = CoreSession<StudySession<TestNode<Content, Content>>, Content, Content>
type ContentNode = CoreContent<Content, Content>
type TNavInit<TNode> = { contents: TNode[]; startIndex: number }

const getMachineWrapper = (computationFnc: (data: TestSession) => TNavInit<ContentNode>) => (data: TestSession) => {
  const { contents, startIndex } = computationFnc(data)
  return createTestMachine<ContentNode, Content, Content, SelectedAnswer>(contents, startIndex)
}

const computeInitialisation = (data: TestSession) => {
  const states = data.testContent
  const startIndex = findLastIndex(data.testContent, (item) => item.id === data.lastAnsweredQuestion)
  return {
    contents: states,
    startIndex: startIndex,
  } as const
}

const useTestControllerHook = createContentNavigationControllerHook<
  TestSession,
  ContentNode,
  Content,
  Content,
  SelectedAnswer
>()

export const { PlayerProvider, useTestFlowController, useTestController } = createPlayerContext<
  TestSession,
  ReturnType<typeof useTestControllerHook>
>(getMachineWrapper(computeInitialisation), useTestControllerHook)

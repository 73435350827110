import { ReactNode } from 'react'
import EN from '../translations/en.json'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

export function IntlProvider(props: { children: ReactNode }) {
  return (
    <ReactIntlProvider locale={'en'} defaultLocale={'en'} messages={EN as Record<string, string>}>
      {props.children}
    </ReactIntlProvider>
  )
}

// export default Intl

import * as MUIDataGrid from '@mui/x-data-grid'
import { DataGrid as MaterialUiDataGrid } from '@mui/x-data-grid'

interface Props extends MUIDataGrid.DataGridProps {
  enableColumnMenu?: boolean
  hideColumnHeaders?: boolean
}

export const DataGrid = (props: Props) => {
  return (
    <MaterialUiDataGrid
      {...props}
      sx={{
        ...props.sx,
        border: 0,
        '& .MuiDataGrid-columnHeaderTitleContainerContent': { textTransform: 'capitalize' },
        '& .MuiDataGrid-columnHeaders': { display: props.hideColumnHeaders ? 'none' : 'block' },
      }}
      disableColumnMenu={!props.enableColumnMenu}
    />
  )
}

export { MUIDataGrid }

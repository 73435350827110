import { Box, BoxProps, makeStyles, ThemeType } from '@project-minerva/design-system'
import clsx from 'clsx'
import { forwardRef } from 'react'

const useStyles = makeStyles((theme: ThemeType) => ({
  box: {
    padding: theme.spacing(4.5, 3.75),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.secondary.light}`,
    width: '100%'
  },
}))

export const ContentBox = forwardRef((props: BoxProps, ref) => {
  const styles = useStyles()
  const { className, ...otherProps } = props
  return <Box {...{ ref }} {...otherProps} className={clsx(styles.box, className)} />
})

import { useContentNavigation } from '@ctek/test-player'
import { useTestController } from '../assessment-player.context'
import { Box, Button, ButtonProps } from '@project-minerva/design-system'
import { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { AssessmentPlayerProps } from '../assessment-player'

const ControlBarContainer: FC = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </Box>
  )
}

const Left: FC = ({ children }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexGrow: 1 }}>{children}</Box>
)
const Right: FC = ({ children }) => (
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>{children}</Box>
)
const CustomButton: FC<ButtonProps> = (props) => <Button sx={{ m: 0, textTransform: 'capitalize' }} {...props} />
export const ControlBar = Object.assign(ControlBarContainer, { Left, Right, Button: CustomButton })

export const ControlBarContent = ({ useNavigationHook }: Pick<AssessmentPlayerProps, 'useNavigationHook'>) => {
  const { isQuestion, isPreviousContentNotQuestion, selectedAnswer, isStartOfTest } = useTestController()
  const { isFetching: disabled, handlePrevious, handleNext } = useContentNavigation()
  const nextButtonEnabled = selectedAnswer || !isQuestion
  const showBackButton = handlePrevious && !isQuestion && isPreviousContentNotQuestion && !isStartOfTest

  return (
    <ControlBar>
      <ControlBar.Left>
        {showBackButton && (
          <ControlBar.Button
            color="secondary"
            onClick={handlePrevious}
            data-testid="previous-button"
            disabled={disabled}
          >
            <FormattedMessage id="previous" defaultMessage="Back" />
          </ControlBar.Button>
        )}
      </ControlBar.Left>
      <ControlBar.Right>
        <ControlBar.Button
          disabled={!nextButtonEnabled || disabled}
          color="primary"
          onClick={handleNext}
          data-testid="next-button"
        >
          <FormattedMessage id={isQuestion ? 'submit-answer' : 'next'} />
        </ControlBar.Button>
      </ControlBar.Right>
    </ControlBar>
  )
}

export const ControlBarStartTestScreen = ({
  handleStart,
  hasStarted,
}: Pick<AssessmentPlayerProps, 'handleStart' | 'hasStarted'>) => {
  return (
    <ControlBar>
      <ControlBar.Left />
      <ControlBar.Right>
        <ControlBar.Button color="primary" onClick={handleStart} data-testid="next-button">
          {hasStarted ? (
            <FormattedMessage id="continue-test" defaultMessage="Continue Test" />
          ) : (
            <FormattedMessage id="start-test" defaultMessage="Start Test" />
          )}
        </ControlBar.Button>
      </ControlBar.Right>
    </ControlBar>
  )
}

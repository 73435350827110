import { Alert, AlertColor, Snackbar, SnackbarCloseReason } from '@mui/material'
import React, { ReactNode, useState } from 'react'

export function useToast(severity: AlertColor, msg: ReactNode, rightAlign = false) {
  const [toastOpen, setToastOpen] = useState(false)

  function handleToastClose(event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === 'clickaway') return
    setToastOpen(false)
  }

  const openToast = () => setToastOpen(true)

  const toast = (
    <Snackbar
      anchorOrigin={{ horizontal: rightAlign ? 'right' : 'center', vertical: 'bottom' }}
      open={toastOpen}
      autoHideDuration={2000}
      onClose={handleToastClose}
    >
      <Alert data-testid="toast-notification" variant="filled" onClose={handleToastClose} severity={severity}>
        {msg}
      </Alert>
    </Snackbar>
  )

  return [toast, openToast] as const
}

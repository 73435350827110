import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
  Alert,
  AlertTitle,
  Box,
  FormControl,
  InputLabel,
  TextArea,
  MenuItem,
  TextField,
  TextFieldProps,
  Typography,
  Grid,
} from '@project-minerva/design-system'
import { renderIntlLink } from '@project-minerva/intl'
import { ApplicantGender, GuardianApplicant, GuardianRelation, PersonalInfoForm } from '@project-minerva/typings'
import { format, sub } from 'date-fns'
import { useFormik } from 'formik'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { getTimezonedyyyymmddDate } from '@project-minerva/base-utils'
import { useApplicants } from '../../../context'
import { usePersonalInfoValidationSchema } from '../../../schemas'
import { MAX_DOB, MIN_DOB } from '../../utils'

interface Props {
  applicant: GuardianApplicant
  setApplicant: Dispatch<SetStateAction<GuardianApplicant>>
  isDuplicate: boolean
  setIsDuplicate: Dispatch<SetStateAction<boolean>>
  hideTitle?: boolean
  handleSubmit: (newApplicant: GuardianApplicant) => void
  adminMode?: boolean
  registrationWarning?: React.ReactNode
}

export function PersonalInformation(props: Props) {
  const params = useParams<{ applicantId: string }>()
  const validationSchema = usePersonalInfoValidationSchema()
  const { formatMessage } = useIntl()
  const {
    applicant,
    setApplicant,
    isDuplicate,
    setIsDuplicate,
    hideTitle = false,
    handleSubmit,
    adminMode,
    registrationWarning,
  } = props
  const applicantsData = useApplicants()
  const currentApplicants = applicantsData.data.currentApplicants

  const setStateAndSubmit = (values: PersonalInfoForm) => {
    const newApplicant = {
      ...applicant,
      ...values,
      dateOfBirth: format(values.dateOfBirth, 'yyyy-MM-dd'),
      gender: values.gender as ApplicantGender,
      guardianRelation: values.relation as GuardianRelation,
    }
    setApplicant(newApplicant)
    if (checkIfDuplicate()) return
    handleSubmit(newApplicant)
  }

  const initialValues = useMemo(
    () => ({
      firstName: applicant.firstName,
      middleName: applicant.middleName,
      lastName: applicant.lastName,
      dateOfBirth: applicant.dateOfBirth ? getTimezonedyyyymmddDate(applicant.dateOfBirth) : new Date(''),
      gender: applicant.gender,
      relation: applicant.guardianRelation,
      currentSchool: applicant.currentSchool,
    }),
    [applicant]
  )

  const personalInfoForm = useFormik<PersonalInfoForm>({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => setStateAndSubmit(values),
  })

  const checkIfDuplicate = useCallback(() => {
    const isDup = currentApplicants.some((currentApplicant: GuardianApplicant) => {
      return (
        currentApplicant.firstName.toLowerCase().trim() === personalInfoForm.values.firstName.toLowerCase().trim() &&
        currentApplicant.lastName.toLowerCase().trim() === personalInfoForm.values.lastName.toLowerCase().trim() &&
        !isNaN(personalInfoForm.values.dateOfBirth.getTime()) &&
        currentApplicant.dateOfBirth === format(personalInfoForm.values.dateOfBirth, 'yyyy-MM-dd') &&
        params.applicantId !== currentApplicant.applicantId
      )
    })
    setIsDuplicate(isDup)
    return isDup
  }, [
    currentApplicants,
    personalInfoForm.values.firstName,
    personalInfoForm.values.lastName,
    personalInfoForm.values.dateOfBirth,
    params.applicantId,
    setIsDuplicate,
  ])

  useEffect(() => {
    if (isDuplicate) {
      checkIfDuplicate()
    }
  }, [isDuplicate, checkIfDuplicate])

  return (
    <form
      id="create-profile-form"
      onSubmit={(evt) => {
        evt.preventDefault()
        personalInfoForm.submitForm()
      }}
    >
      {!hideTitle && (
        <>
          <Typography variant="h5" fontWeight={700} sx={{ mb: (theme) => theme.spacing(2) }}>
            <FormattedMessage id="applicant-page-applicant-information-title" defaultMessage="Applicant Information" />
          </Typography>
          {registrationWarning}
          <Alert severity="info" sx={{ mb: (theme) => theme.spacing(1) }}>
            <FormattedMessage
              id="applicant-page-applicant-information-description"
              defaultMessage="Complete the following information for the child taking the tests."
              values={{ br: <br /> }}
            />
          </Alert>
        </>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid xs={12} md={6} spacing={2} item>
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="legal-first-name" defaultMessage="Legal First Name" />
          </Typography>
          <TextField
            fullWidth={true}
            data-testid="first-name-input"
            onBlur={personalInfoForm.handleBlur}
            onChange={personalInfoForm.handleChange}
            value={personalInfoForm.values.firstName}
            id="firstName"
            name="firstName"
            error={personalInfoForm.touched.firstName && !!personalInfoForm.errors.firstName}
            helperText={personalInfoForm.touched.firstName && personalInfoForm.errors.firstName}
          />
        </Grid>
        <Grid xs={12} md={6} spacing={2} item>
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="middle-names" defaultMessage="Middle names (optional)" />
          </Typography>
          <TextField
            fullWidth={true}
            onBlur={personalInfoForm.handleBlur}
            onChange={personalInfoForm.handleChange}
            value={personalInfoForm.values.middleName}
            data-testid="middle-name-input"
            id="middleName"
            name="middleName"
            error={personalInfoForm.touched.middleName && !!personalInfoForm.errors.middleName}
            helperText={personalInfoForm.touched.middleName && personalInfoForm.errors.middleName}
          />
        </Grid>
        <Grid xs={12} md={6} spacing={2} item>
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="legal-last-name" defaultMessage="Legal Last Name" />
          </Typography>
          <TextField
            fullWidth={true}
            data-testid="last-name-input"
            onBlur={personalInfoForm.handleBlur}
            onChange={personalInfoForm.handleChange}
            value={personalInfoForm.values.lastName}
            id="lastName"
            name="lastName"
            error={personalInfoForm.touched.lastName && !!personalInfoForm.errors.lastName}
            helperText={personalInfoForm.touched.lastName && personalInfoForm.errors.lastName}
          />
        </Grid>
      </Grid>
      {adminMode && (
        <Alert severity="info">
          <FormattedMessage id="change-dob-admin-info" />
        </Alert>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={adminMode ? 6 : 4}>
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="date-of-birth" defaultMessage="Date of Birth" />
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              data-testid="dob-picker"
              maxDate={MAX_DOB}
              minDate={MIN_DOB}
              inputFormat="dd/MM/yyyy"
              defaultCalendarMonth={sub(new Date(), { years: 11 })}
              value={personalInfoForm.values.dateOfBirth}
              onChange={(value: Date | null) => personalInfoForm.setFieldValue('dateOfBirth', value)}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  data-testid="dob-text-field"
                  placeholder="dd/mm/yyyy"
                  type="date"
                  onBlur={personalInfoForm.handleBlur}
                  id="dateOfBirth"
                  name="dateOfBirth"
                  error={personalInfoForm.touched.dateOfBirth && !!personalInfoForm.errors.dateOfBirth}
                  helperText={personalInfoForm.touched.dateOfBirth && personalInfoForm.errors.dateOfBirth}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={adminMode ? 6 : 4}>
          <Typography variant="subtitle1" fontWeight={600}>
            <FormattedMessage id="gender" defaultMessage="Gender" />
          </Typography>
          <FormControl sx={{ minWidth: '100%' }} size="small">
            {!personalInfoForm.values.gender.length && (
              <InputLabel shrink={false}>{formatMessage({ id: 'select', defaultMessage: 'Select' })}</InputLabel>
            )}
            <TextField
              select
              size="small"
              fullWidth={true}
              data-testid="gender-select"
              defaultValue=""
              onBlur={personalInfoForm.handleBlur}
              onChange={personalInfoForm.handleChange}
              value={personalInfoForm.values.gender}
              id="gender"
              name="gender"
              error={personalInfoForm.touched.gender && !!personalInfoForm.errors.gender}
              helperText={personalInfoForm.touched.gender && personalInfoForm.errors.gender}
            >
              <MenuItem data-testid="item-female" value={'FEMALE'}>
                <FormattedMessage id="gender-female" defaultMessage="Female" />
              </MenuItem>
              <MenuItem data-testid="item-male" value={'MALE'}>
                <FormattedMessage id="gender-male" defaultMessage="Male" />
              </MenuItem>
              <MenuItem data-testid="item-other" value={'OTHER'}>
                <FormattedMessage id="gender-other" defaultMessage="Other" />
              </MenuItem>
              <MenuItem data-testid="item-male" value={'PREFER_NOT_TO_SAY'}>
                <FormattedMessage id="gender-prefer_not_to_say" defaultMessage="Prefer Not to Say" />
              </MenuItem>
            </TextField>
          </FormControl>
        </Grid>
        {!adminMode && (
          <Grid item xs={12} md={adminMode ? 6 : 4}>
            <Typography variant="subtitle1" fontWeight={600}>
              <FormattedMessage id="your-relation-to-applicant" defaultMessage="Your relation to applicant" />
            </Typography>
            <FormControl sx={{ minWidth: '100%' }} size="small">
              {!personalInfoForm.values.relation.length && (
                <InputLabel shrink={false}>{formatMessage({ id: 'select', defaultMessage: 'Select' })}</InputLabel>
              )}
              <TextField
                select
                size="small"
                fullWidth={true}
                data-testid="relation-select"
                defaultValue=""
                onBlur={personalInfoForm.handleBlur}
                onChange={personalInfoForm.handleChange}
                value={personalInfoForm.values.relation}
                id="relation"
                name="relation"
                error={personalInfoForm.touched.relation && !!personalInfoForm.errors.relation}
                helperText={personalInfoForm.touched.relation && personalInfoForm.errors.relation}
              >
                <MenuItem data-testid="item-parent" value={'PARENT'}>
                  <FormattedMessage id="relation-parent" defaultMessage="Parent" />
                </MenuItem>
                <MenuItem data-testid="item-guardian" value={'GUARDIAN'}>
                  <FormattedMessage id="relation-guardian" defaultMessage="Guardian" />
                </MenuItem>
                <MenuItem data-testid="item-agent" value={'OTHER_FAMILY_MEMBER'}>
                  <FormattedMessage id="relation-other_family_member" defaultMessage="Other Family Member" />
                </MenuItem>
                <MenuItem data-testid="item-agent" value={'AGENT'}>
                  <FormattedMessage id="relation-agent" defaultMessage="Agent" />
                </MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Box>
        <Typography variant="subtitle1" fontWeight={600}>
          <FormattedMessage id="current-school" defaultMessage="Current School" />
        </Typography>
        <TextArea
          fullWidth={true}
          data-testid="current-school-input"
          onBlur={personalInfoForm.handleBlur}
          onChange={personalInfoForm.handleChange}
          value={personalInfoForm.values.currentSchool}
          id="currentSchool"
          name="currentSchool"
          error={personalInfoForm.touched.currentSchool && !!personalInfoForm.errors.currentSchool}
          helperText={personalInfoForm.touched.currentSchool && personalInfoForm.errors.currentSchool}
        />
      </Box>
      {isDuplicate && (
        <Alert severity="warning" sx={{ mt: (theme) => theme.spacing(2) }}>
          <AlertTitle>
            <FormattedMessage id="duplicate-profile-title" defaultMessage="Duplicate Profile" />
          </AlertTitle>
          <FormattedMessage
            id="duplicate-profile-info"
            values={{
              br: <br />,
              a: (chunks: string) => renderIntlLink(chunks, 'https://pretests.support.iseb.co.uk/support/tickets/new'),
            }}
          />
        </Alert>
      )}
    </form>
  )
}

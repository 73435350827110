import { getApiUrl } from '@project-minerva/api'
import { rest } from 'msw'

export const subjects = [
  {
    id: '591b5d4f-49cf-4c0e-918a-08c135ecf50b',
    name: 'english',
  },
  {
    id: 'd2fb0442-7ad3-4ac8-9b08-86bd3efa5aab',
    name: 'mathematics',
  },
  {
    id: 'dcd65261-552f-4e0a-a8d4-846dc8db99dd',
    name: 'non_verbal_reasoning',
  },
  {
    id: '8346c5c6-0f71-4015-a8a3-8e2e47772d6f',
    name: 'verbal_reasoning',
  },
  {
    id: 'c85517c4-ac6d-4896-a84d-85fca3eeca93',
    name: 'critical_thinking_problem_solving',
  },
  {
    id: '7bfb36da-03a1-42c5-b328-a1d3c4a555c6',
    name: 'spatial_reasoning',
  },
]

export const getSubjects = rest.get(getApiUrl('atlas', '/v1/subjects'), (req, res, ctx) => {
  return res(ctx.status(200), ctx.json({ subjects, count: subjects.length }))
})

export const getTest = rest.get(getApiUrl('atlas', '/v1/familiarisation/test'), (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      id: 'test_id',
      name: 'test_name',
      testContent: generateMockContent(6, 3, 5),
      subject: subjects.find((subject) => subject.id === req.params.subject),
    })
  )
})

export const getFamiliarisationAssessment = rest.get(
  getApiUrl('atlas', '/v1/familiarisation/assessment'),
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        tests: subjects.map((subject) => ({
          id: subject.id,
          subjectId: subject.id,
          name: subject.name,
          isClosed: false,
          isPublished: false,
          timeLimitSeconds: 0,
        })),
      })
    )
  }
)

function generateMockContent(questionsAmount: number, practiceAmount: number, answersAmount = 3): unknown[] {
  const contents = []

  contents.push({
    type: 'document',
    id: 'document_START_PRACTICE',
    content: { data: 'DOCUMENT: START_PRACTICE' },
  })

  for (let i = 0; i < questionsAmount; i++) {
    if (i === practiceAmount) {
      contents.push({
        type: 'document',
        id: 'document_END_PRACTICE',
        content: { data: 'DOCUMENT: END_PRACTICE' },
      })
    }
    const correctAnswerIndex = Math.floor(Math.random() * answersAmount)

    contents.push({
      type: 'question',
      id: `question_${i}`,
      points: i < practiceAmount ? 0 : 10,
      name: `question_name_${i}`,
      content: { data: `question_content_${i}` },
      answerType: 'multi-one-correct',
      answers: new Array(answersAmount)
        .fill(1)
        .map((_, j) => ({
          id: `answer_${j}`,
          content: { type: 'string', data: `answer <b>${j}</b>` },
          ...(i < practiceAmount ? { isCorrect: j === correctAnswerIndex } : {}),
        }))
        .sort(() => Math.random() - 0.5),
    })
  }
  contents.push({
    type: 'document',
    id: 'document_TEST_COMPLETED',
    content: { data: 'DOCUMENT: TEST_COMPLETED' },
  })

  return contents
}

import { useCallback, useState } from 'react'
import { request, RequestOptions } from '../http-client'
import { useAbortController } from './use-abort-controller'

export interface LoaderHook<T> {
  error: Error | undefined
  loading: boolean
  data: T | undefined
  load: () => Promise<T>
  reset: () => void
}

export const useRequest = <T>(url: string, options: RequestOptions, token?: string): LoaderHook<T> => {
  const [error, setError] = useState<Error>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T>()
  const getSignal = useAbortController()
  const load = useCallback(async () => {
    let response
    setError(undefined)
    setLoading(true)

    try {
      response = await request<T>(url, { ...options, signal: getSignal() }, token)
      setData(response)
      setLoading(false)
    } catch (error) {
      if (!(error instanceof DOMException)) {
        setError(error as Error)
        setLoading(false)
      }
    }

    return response as T
  }, [options, token, url, getSignal])

  const reset = useCallback(() => {
    setError(undefined)
    setLoading(false)
    setData(undefined)
  }, [])

  return {
    error,
    loading,
    data,
    load,
    reset,
  }
}

import { isSuperAdmin, useAuthContext, useCognitoUser } from '@project-minerva/auth-cognito'
import { isLocalhost } from '@project-minerva/base-utils'
import { GuardianIcon, InvigilationCentreIcon, SeniorSchoolIcon } from '@project-minerva/icons'
import { usePrivileges, revokePrivilege } from '@project-minerva/shared-api'
import { Privilege, PrivilegeDetail } from '@project-minerva/typings'
import { createContext, FC, useContext, useEffect } from 'react'
import { PageLoader } from '../page'

interface PrivilegeContextType {
  hasPrivilege: boolean
  privilege?: PrivilegeDetail
  revoke: () => Promise<void>
  redirectToPrivilegeApp: (app: Privilege) => void
}

const PrivilegeContext = createContext<PrivilegeContextType>({
  hasPrivilege: false,
  privilege: undefined,
  revoke: () => Promise.resolve(),
  redirectToPrivilegeApp: (app: Privilege) => {
    return
  },
})

export const usePrivilege = () => {
  const context = useContext(PrivilegeContext)

  if (!context) {
    throw new Error('usePrivilege must be called inside a PrivilegeProvider')
  }

  return context
}

interface Props {
  app?: Privilege // TODO: rely on CognitoAuthProvider? will need mapping to match helios/cognito value
}

export const PrivilegeProvider: FC<Props> = ({ app, children }) => {
  const { accessToken } = useAuthContext()
  const user = useCognitoUser()
  const privilegesLoader = usePrivileges()
  const hasPrivilege = !!privilegesLoader.data?.data.length
  const revoke = async () => {
    if (!hasPrivilege) return

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const refreshToken = user.getSignInUserSession()!.getRefreshToken()
    await Promise.all(
      privilegesLoader.data?.data.map((privilege) => revokePrivilege(privilege.privilege, accessToken.current)) ?? []
    )
    await new Promise((resolve) => user.refreshSession(refreshToken, resolve))
    redirectToAdmin()
  }

  useEffect(() => {
    if (isSuperAdmin(user)) {
      privilegesLoader.load().then((privileges) => {
        // when privilege doesn't match match the app, redirect to appropriate app
        if (privileges.data?.[0]?.privilege !== app) {
          redirectToPrivilegeApp(privileges.data[0].privilege)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (privilegesLoader.loading) {
    return <PageLoader />
  }

  return (
    <PrivilegeContext.Provider
      value={{ hasPrivilege, redirectToPrivilegeApp, revoke, privilege: privilegesLoader.data?.data?.[0] }}
    >
      {children}
    </PrivilegeContext.Provider>
  )
}

const redirectToAdmin = () => {
  const origin = isLocalhost() ? 'http://localhost:4208' : window.location.origin
  window.location.href = `${origin}/admin`
}

const redirectToPrivilegeApp = (app: Privilege) => {
  const origin = isLocalhost() ? PRIVILEGE_CONFIG[app].localhost : window.location.origin
  const url = `${origin}/${PRIVILEGE_CONFIG[app].appUrl}`
  window.location.href = url
}

export const PRIVILEGE_CONFIG = {
  GUARDIAN: {
    api: '/guardians',
    privilege: 'GUARDIAN',
    translation: 'relation-guardian',
    idKey: 'guardianId',
    appUrl: 'guardian',
    appId: 'guardian',
    localhost: 'http://localhost:4204',
    icon: GuardianIcon,
  },
  INVIGILATION_CENTRE: {
    api: '/centres',
    privilege: 'INVIGILATION_CENTRE',
    translation: 'invigilation-centre',
    idKey: 'invigilationCentreId',
    appUrl: 'invigilation-centre',
    appId: 'admin-centre',
    localhost: 'http://localhost:4207',
    icon: InvigilationCentreIcon,
  },
  SENIOR_SCHOOL: {
    api: '/schools',
    privilege: 'SENIOR_SCHOOL',
    translation: 'senior-schools',
    idKey: 'seniorSchoolId',
    appUrl: 'senior-school',
    appId: 'admin-schools',
    localhost: 'http://localhost:4205',
    icon: SeniorSchoolIcon,
  },
} as const

import { useScreenFilter } from './screen-filter-provider'
import { alpha, makeStyles, ScreenFilters, ThemeType } from '@project-minerva/design-system'

const useStyles = makeStyles((theme: ThemeType) => ({
  screenFilter: {
    position: 'absolute',
    top: -25,
    bottom: -25,
    right: -25,
    left: -25,
    zIndex: 1,
    touchAction: 'none',
    pointerEvents: 'none',
    backgroundColor: (props: { screenFilter: null | string }) => {
      if (props.screenFilter && ScreenFilters[props.screenFilter]) {
        const filter = ScreenFilters[props.screenFilter]
        return alpha(filter.color, filter.opacity)
      }
      return 'transparent'
    },
    borderRadius: theme.shape.borderRadius,
  },
}))

export const ScreenFilterOverlay = () => {
  const { screenFilter } = useScreenFilter()
  const styles = useStyles({ screenFilter })
  return <div data-testid="screen-filter-overlay" className={styles.screenFilter} />
}

import { ButtonProps, styled } from '@mui/material'
import { AuxButton } from './tertiary-button'

export const MutedSecondaryButton = styled(AuxButton)<ButtonProps & { thin?: boolean }>(({ theme, thin }) => ({
  color: '#6C6C6C',
  backgroundColor: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  // TODO: change this when thin pixel values are added to theme
  height: thin ? '2rem' : undefined,
}))

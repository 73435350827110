import { ContentRenderer, useScrollTopOnMount } from '@project-minerva/core'
import { useTestController } from '../assessment-player.context'
import { Content, TestDocument } from '@ctek/test-player'
import { ContentBox } from './content-box'

const Document = () => {
  const { currentContent } = useTestController()
  const document = currentContent as TestDocument<Content>
  useScrollTopOnMount()

  if (!document) {
    return <p>Document is not available</p>
  }

  return (
    <ContentBox>
      <ContentRenderer content={document.content} data-testid="document-content" />
    </ContentBox>
  )
}

export default Document

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const SeniorSchoolIcon = (props: SvgIconProps) => (
  <SvgIcon width="38" height="40" viewBox="0 0 38 40" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2396 3.10791C12.1323 3.10791 9.57122 5.67575 9.57122 8.89576C9.57122 12.1158 12.1323 14.6836 15.2396 14.6836C18.3469 14.6836 20.908 12.1158 20.908 8.89576C20.908 5.67575 18.3469 3.10791 15.2396 3.10791ZM6.57122 8.89576C6.57122 4.06583 10.4289 0.10791 15.2396 0.10791C20.0503 0.10791 23.908 4.06583 23.908 8.89576C23.908 13.7257 20.0503 17.6836 15.2396 17.6836C10.4289 17.6836 6.57122 13.7257 6.57122 8.89576Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 32.5515L0.933506 33.9404C0.368997 33.7101 0 33.1611 0 32.5515C0 25.1652 6.91749 18.5068 15.2394 18.5068C16.3822 18.5068 17.496 18.6315 18.5672 18.865C19.3767 19.0415 19.8898 19.8407 19.7133 20.6502C19.5368 21.4596 18.7376 21.9727 17.9282 21.7962C17.063 21.6076 16.1634 21.5068 15.2394 21.5068C8.80968 21.5068 3.67267 26.3451 3.06104 31.5228C3.72404 31.7471 4.69167 32.0489 5.89109 32.3515C8.29647 32.9583 11.6037 33.5604 15.2394 33.5604C16.2776 33.5604 17.2496 33.5292 18.1568 33.4741C18.9837 33.4239 19.6948 34.0536 19.745 34.8805C19.7952 35.7074 19.1655 36.4184 18.3386 36.4686C17.3704 36.5274 16.3377 36.5604 15.2394 36.5604C11.287 36.5604 7.72456 35.908 5.15726 35.2603C3.87072 34.9358 2.82649 34.6105 2.099 34.3644C1.73508 34.2412 1.44987 34.1377 1.25265 34.0638C1.15402 34.0269 1.07735 33.9973 1.02378 33.9763L0.960898 33.9514L0.942712 33.9441L0.93698 33.9418L0.93496 33.941C0.93496 33.941 0.933506 33.9404 1.5 32.5515Z"
    />
    <path d="M29.0965 14.2446C30.2362 14.2446 31.0582 14.8419 31.4856 15.9482L31.518 16.0379L32.4461 16.038C33.0882 16.038 33.6143 16.5849 33.6608 17.2787L33.6641 17.3787V18.0571L36.5189 18.0574C37.0981 18.0574 37.573 18.5484 37.6191 19.1728L37.6227 19.2725V38.2639C37.6227 38.9572 37.1666 39.5516 36.556 39.6042L36.4677 39.6079H21.7253C21.1007 39.6079 20.6161 39.0451 20.5734 38.3622L20.5703 38.2639V19.4015C20.5703 18.7082 21.0265 18.1138 21.6371 18.0612L21.7253 18.0574L24.5289 18.0571V17.3787C24.5289 16.6719 25.0258 16.0928 25.656 16.0416L25.7469 16.038L26.6745 16.0379L26.7074 15.9482C27.117 14.888 27.8891 14.2953 28.9557 14.2477L29.0965 14.2446ZM36.4047 19.3979H33.6641V20.1622C33.6641 20.6681 33.2969 21.0423 32.864 21.0846L32.7821 21.0886H25.4109C24.9435 21.0886 24.5289 20.6997 24.5289 20.1622V19.3979H21.7877V38.2671H36.4053L36.4047 19.3979ZM34.4884 28.7352V30.0759H23.7046V28.7352H34.4884ZM34.4884 26.0117V27.3525H23.7046V26.0117H34.4884ZM34.4884 23.2883V24.6291H23.7046V23.2883H34.4884ZM29.0965 15.5854C28.3386 15.5854 27.9163 15.9679 27.7075 16.8733L27.5909 17.3787H25.7469V19.7478H32.4461V17.3787H30.6021L30.4855 16.8733C30.2767 15.9679 29.8545 15.5854 29.0965 15.5854Z" />
  </SvgIcon>
)

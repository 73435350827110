import { useBasicAuth } from '@project-minerva/auth-student'
import { FontSizeLabel } from '@project-minerva/assessment-player'
import {
  MixpanelEventTypes,
  MixpanelKeys,
  MixpanelParams,
  MixpanelTracker,
  useMixpanel,
  useSubjects,
} from '@project-minerva/core'
import { Pause, Resume } from '@project-minerva/typings'
import { Content, TestDocument, TestQuestion } from '@ctek/test-player'
import { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useExaminationData } from './data/examination-data.provider'
import { useStudySession } from './data/study-session.provider'
import { LastContentViewed } from './api/study-session.api'

export enum ExamPages {
  TESTS_LIST = 'tests-list',
  TEST_START_SCREEN = 'test-player-start-screen',
  TEST_CONTENT_SCREEN = 'test-player-content-screen',
}

export type TrackTimerSnapshot = {
  testTime: string
  totalTime: string
  timerProgress: number
}

export type TrackProgressSnapshot = {
  progressValue?: number
}

export type TrackSettingsSnapshot = {
  screenFilter?: string | null
  fontSize?: FontSizeLabel
}

export type TrackContentSnapshot = {
  content: LastContentViewed & { contentName: string }
}

export const useTrackPageViewed = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.PageViewed)
  return useCallback(
    (pageName: string, tab?: string) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.Tab]: tab,
      })
    },
    [track]
  )
}

type StudySessionStartedFields = {
  elapsedTime: string
} & TrackSettingsSnapshot &
  TrackProgressSnapshot
export const useTrackStudySessionStarted = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.StudySessionStarted)
  return useCallback(
    ({ elapsedTime, fontSize, screenFilter }: StudySessionStartedFields) =>
      track({
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TimeBeforeStart]: elapsedTime,
      }),
    [track]
  )
}

type StudySessionCompletedFields = {
  completedWithinTimeFrame: boolean
} & Partial<TrackTimerSnapshot> &
  Partial<TrackContentSnapshot> &
  TrackSettingsSnapshot &
  TrackProgressSnapshot

export const useTrackStudySessionCompleted = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.StudySessionCompleted)
  return useCallback(
    ({
      completedWithinTimeFrame,
      content,
      fontSize,
      screenFilter,
      testTime,
      totalTime,
      timerProgress,
      progressValue,
    }: StudySessionCompletedFields) => {
      track({
        [MixpanelKeys.CompletedWithinTimeframe]: completedWithinTimeFrame,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
        [MixpanelKeys.ProgressBarValue]: progressValue,
      })
    },
    [track]
  )
}

type DocumentViewedFields = {
  content: TestDocument<Content>
} & Partial<TrackTimerSnapshot> &
  TrackSettingsSnapshot
export const useTrackStudySessionDocumentViewed = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.StudySessionDocumentViewed)
  return useCallback(
    ({ content, screenFilter, fontSize, testTime, totalTime, timerProgress }: DocumentViewedFields) => {
      track({
        [MixpanelKeys.DocumentId]: content.id,
        [MixpanelKeys.DocumentName]: content.name,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
      })
    },
    [track]
  )
}

type QuestionAnsweredFields = {
  question: TestQuestion<Content, Content>
  action: string
} & Partial<TrackTimerSnapshot> &
  TrackSettingsSnapshot

export const useTrackStudySessionQuestionAnswered = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.StudySessionQuestionAnswered)
  return useCallback(
    ({ question, action, screenFilter, fontSize, testTime, totalTime, timerProgress }: QuestionAnsweredFields) => {
      track({
        [MixpanelKeys.Action]: action,
        [MixpanelKeys.QuestionGroupId]: question.questionGroupId,
        [MixpanelKeys.QuestionGroupName]: question.name,
        [MixpanelKeys.QuestionId]: question.id,
        [MixpanelKeys.QuestionType]: question.answerType,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
      })
    },
    [track]
  )
}

type TrackScreenFilterFields = {
  pageName: string
  previousFilter: string | null
  currentFilter: string | null
} & TrackProgressSnapshot &
  TrackSettingsSnapshot &
  Partial<TrackTimerSnapshot> &
  Partial<TrackContentSnapshot>

export const useTrackScreenFilterChanged = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.ScreenFilterChanged)
  return useCallback(
    ({
      pageName,
      content,
      previousFilter,
      currentFilter,
      fontSize,
      progressValue,
      testTime,
      totalTime,
      timerProgress,
    }: TrackScreenFilterFields) => {
      track({
        [MixpanelKeys.PreviousFilter]: previousFilter,
        [MixpanelKeys.CurrentFilter]: currentFilter,
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.ProgressBarValue]: progressValue,
      })
    },
    [track]
  )
}

type TrackFontSizeFields = {
  pageName: string
  previousSize: string | null
  currentSize: string
} & TrackProgressSnapshot &
  TrackSettingsSnapshot &
  Partial<TrackTimerSnapshot> &
  Partial<TrackContentSnapshot>
export const useTrackFontSizeChanged = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.FontSizeChanged)
  return useCallback(
    ({
      pageName,
      content,
      previousSize,
      currentSize,
      testTime,
      totalTime,
      timerProgress,
      progressValue,
      screenFilter,
    }: TrackFontSizeFields) => {
      track({
        [MixpanelKeys.PreviousFontSize]: previousSize,
        [MixpanelKeys.CurrentFontSize]: currentSize,
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ScreenFilter]: screenFilter,
      })
    },
    [track]
  )
}

type TrackTestPausedFields = {
  pauseData: Pause
  content: TestQuestion<Content, Content> | TestDocument<Content>
} & TrackProgressSnapshot &
  TrackSettingsSnapshot &
  Partial<TrackTimerSnapshot>
export const useTrackTestPaused = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.TestPaused)
  return useCallback(
    ({
      pauseData,
      content,
      testTime,
      totalTime,
      timerProgress,
      fontSize,
      progressValue,
      screenFilter,
    }: TrackTestPausedFields) => {
      track({
        [MixpanelKeys.PauseId]: pauseData.pauseId,
        [MixpanelKeys.PauseStartTime]: pauseData.pauseStartTime,
        [MixpanelKeys.ContentId]: content.id,
        [MixpanelKeys.ContentName]: content.name,
        [MixpanelKeys.ContentType]: content.type,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
      })
    },
    [track]
  )
}

type TrackTestResumedFields = {
  resumeData: Resume
  from: 'modal' | 'start-screen'
} & TrackProgressSnapshot &
  TrackSettingsSnapshot
export const useTrackTestResumed = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.TestResumed)
  return useCallback(
    ({ resumeData, from, fontSize, progressValue, screenFilter }: TrackTestResumedFields) => {
      track({
        [MixpanelKeys.PauseId]: resumeData.pauseId,
        [MixpanelKeys.PauseStartTime]: resumeData.pauseStartTime,
        [MixpanelKeys.PauseEndTime]: resumeData.testEndTime,
        [MixpanelKeys.ResumedFrom]: from,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.ProgressBarValue]: progressValue,
      })
    },
    [track]
  )
}

type TestClickedFields = { pageName: string; opened: boolean } & TrackSettingsSnapshot &
  TrackProgressSnapshot &
  Partial<TrackTimerSnapshot> &
  Partial<TrackContentSnapshot>
export const useTrackTimerClicked = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.TimerClicked)
  return useCallback(
    ({
      pageName,
      content,
      opened,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: TestClickedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.TimerAction]: opened ? 'close' : 'open',
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
        [MixpanelKeys.ProgressBarValue]: progressValue,
      })
    },
    [track]
  )
}

export const useTrackProgressBarClicked = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.ProgressBarClicked)
  return useCallback(
    ({
      pageName,
      content,
      opened,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: TestClickedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.ProgressBarAction]: opened ? 'close' : 'open',
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
      })
    },
    [track]
  )
}

type IconClickedFields = { pageName: string } & TrackSettingsSnapshot &
  TrackProgressSnapshot &
  Partial<TrackTimerSnapshot> &
  Partial<TrackContentSnapshot>
export const useTrackScreenFilterIconClicked = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.ScreenFilterIconClicked)
  return useCallback(
    ({
      pageName,
      content,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: IconClickedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
      })
    },
    [track]
  )
}

export const useTrackFontSizeIconClicked = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.FontSizeIconClicked)
  return useCallback(
    ({
      pageName,
      content,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: IconClickedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
      })
    },
    [track]
  )
}

export const useTrackInformationIconClicked = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.InformationIconClicked)
  return useCallback(
    ({
      pageName,
      content,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: IconClickedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
      })
    },
    [track]
  )
}

type PopoverClosedFields = { duration: string } & IconClickedFields
export const useTrackScreenFilterPopoverClosed = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.ScreenFilterPopoverClosed)
  return useCallback(
    ({
      duration,
      pageName,
      content,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: PopoverClosedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
        [MixpanelKeys.OpenDuration]: duration,
      })
    },
    [track]
  )
}

export const useTrackFontSizePopoverClosed = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.FontSizePopoverClosed)
  return useCallback(
    ({
      duration,
      pageName,
      content,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: PopoverClosedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
        [MixpanelKeys.OpenDuration]: duration,
      })
    },
    [track]
  )
}

export const useTrackInformationPopoverClosed = () => {
  const track = useMixpanelTracker(MixpanelEventTypes.InformationPopoverClosed)
  return useCallback(
    ({
      duration,
      pageName,
      content,
      fontSize,
      screenFilter,
      progressValue,
      timerProgress,
      testTime,
      totalTime,
    }: PopoverClosedFields) => {
      track({
        [MixpanelKeys.PageName]: pageName,
        [MixpanelKeys.ScreenFilter]: screenFilter,
        [MixpanelKeys.FontSize]: fontSize,
        [MixpanelKeys.TestTime]: testTime,
        [MixpanelKeys.TestDuration]: totalTime,
        [MixpanelKeys.TimerProgress]: timerProgress,
        [MixpanelKeys.ProgressBarValue]: progressValue,
        [MixpanelKeys.ContentId]: content?.contentId,
        [MixpanelKeys.ContentName]: content?.contentName,
        [MixpanelKeys.ContentType]: content?.contentType,
        [MixpanelKeys.OpenDuration]: duration,
      })
    },
    [track]
  )
}

const useMixpanelTracker = (event: MixpanelEventTypes) => {
  const mixpanel = useMixpanel()
  const defaultProps = useDefaultMixpanelParams()
  return useCallback(
    (params: MixpanelParams = {}) => {
      const props: MixpanelParams = {
        ...defaultProps.current,
        ...params,
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;((mixpanel as any).track as MixpanelTracker)(event, props)
    },
    [mixpanel, event, defaultProps]
  )
}

const useDefaultMixpanelParams = () => {
  const { subjectId, testId } = useParams<{ assessmentId: string; subjectId: string; testId: string }>()
  const { authData } = useBasicAuth()
  const { getSubject } = useSubjects()
  const { assessment, findTestById } = useExaminationData()
  const { studySession } = useStudySession()
  const testInfo = findTestById(testId)
  const subject = getSubject(subjectId)
  const ref = useRef<MixpanelParams>({})

  useEffect(() => {
    ref.current = {
      [MixpanelKeys.App]: 'exam',
      [MixpanelKeys.AssessmentCode]: authData?.code,
      [MixpanelKeys.AssessmentId]: assessment?.assessmentId,
      [MixpanelKeys.ApplicantId]: assessment?.applicantId,
      [MixpanelKeys.SchoolId]: assessment?.schoolId,
      [MixpanelKeys.SchoolName]: assessment?.schoolName,
      [MixpanelKeys.StudySessionId]: studySession?.id,
      [MixpanelKeys.TestId]: testId,
      [MixpanelKeys.TestName]: testInfo?.name,
      [MixpanelKeys.TestSubjectId]: subjectId,
      [MixpanelKeys.TestSubjectName]: subject?.name,
    }
  }, [authData, assessment, studySession, subject, testInfo, testId, subjectId])

  return ref
}

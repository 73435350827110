import { LoaderHook } from '@project-minerva/api'
import { getTimezonedyyyymmddDate } from '@project-minerva/base-utils'
import { Alert, Box, CircularProgress, Divider, Typography } from '@project-minerva/design-system'
import { ApplicantApplication } from '@project-minerva/typings'
import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { ErrorMessage } from '../error'
import { PaperPanel } from '../panel'

export const SeniorSchoolApplicationsWidget = ({
  applicantName,
  applications,
}: {
  applicantName: string
  applications: LoaderHook<{ count: number; applications: ApplicantApplication[] }>
}) => (
  <PaperPanel>
    <Typography variant="h5" mb={1}>
      <FormattedMessage id="senior-schools" defaultMessage="Senior Schools" />
    </Typography>
    {applications.loading && <CircularProgress />}
    {applications.error && <ErrorMessage message={applications.error.message} />}
    {applications.data?.applications.length ? (
      applications.data.applications.map(({ submissionDeadline, seniorSchool }) => (
        <Box mb={2} key={seniorSchool.seniorSchoolId}>
          <Typography variant="body1">{seniorSchool.name}</Typography>
          <Typography variant="body2">{seniorSchool.address}</Typography>
          <Typography variant="body2" color="primary">
            <FormattedMessage
              id="test-window-end"
              defaultMessage="Test window end: {date}"
              values={{ date: format(getTimezonedyyyymmddDate(submissionDeadline.split('T')[0]), 'dd MMM') }}
            />
          </Typography>
          <Divider sx={{ mt: (theme) => theme.spacing(1) }} />
        </Box>
      ))
    ) : (
      <Alert severity="info">
        <FormattedMessage
          id="senior-school-applications-info-bubble"
          defaultMessage="Once a senior school uploads {name}’s Applicant ID, you will see it listed here."
          values={{ name: applicantName }}
        />
      </Alert>
    )}
  </PaperPanel>
)

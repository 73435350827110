import { Chip } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  label: string | ReactNode
}

export function ErrorChip({ label }: Props) {
  return <Chip color="error" data-testid="error-chip" label={label} sx={{ color: 'white' }} />
}

import { useRef } from 'react'
import { TimerMachineContext } from './timer.provider'

export const useTimer = () => {
  const isTicking = TimerMachineContext.useSelector((state) => state.hasTag('ticking'))
  const isTimeout = TimerMachineContext.useSelector((state) => state.hasTag('timeout'))
  const elapsedTime = TimerMachineContext.useSelector((state) => state.context.elapsed)
  const duration = TimerMachineContext.useSelector((state) => state.context.duration)
  const delta = TimerMachineContext.useSelector((state) => state.context.delta)
  const remainingTime = TimerMachineContext.useSelector((state) => Math.max(state.context.remaining, 0))
  const enabled = TimerMachineContext.useSelector((state) => state.context.enabled)

  const timerMachine = TimerMachineContext.useActorRef()
  const startTimer = (duration?: number) => timerMachine.send({ type: 'START', duration })
  const pauseTimer = () => timerMachine.send({ type: 'PAUSE' })

  return {
    enabled,
    elapsedTime,
    remainingTime,
    isTicking,
    isTimeout,
    duration,
    delta,
    startTimer,
    pauseTimer,
  }
}

export const useTimerRef = () => {
  const remainingTime = TimerMachineContext.useSelector((state) =>
    Math.max(state.context.duration - state.context.elapsed, 0)
  )

  const ref = useRef(0)
  ref.current = remainingTime

  return ref
}

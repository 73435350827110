import { alpha, Button, ButtonProps, styled } from '@mui/material'

export const AuxButton = ({ thin, ...props }: { thin?: boolean } & ButtonProps) => <Button {...props} />

export const TertiaryButton = styled(AuxButton)<ButtonProps & { thin?: boolean }>(({ theme, thin }) => ({
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.dark, 0.15),
  },
  // TODO: change this when thin pixel values are added to theme
  height: thin ? '2rem' : undefined,
}))

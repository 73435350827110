import { Alert, alpha, Box } from '@project-minerva/design-system'
import { InvigilationCentre, InvigilationCentreStatus, InvigilationCentreStatusMapping } from '@project-minerva/typings'
import { ColouredStatusBox } from '../containers'
import { InvigilationCentreInfoCard } from '../invigilation-centre'
import { InvigilationCentreStatusChip } from '../chip'
import { FormattedMessage } from 'react-intl'

interface Props {
  invigilationCentre: InvigilationCentre
  showWarning?: boolean
}
export function ApplicationInvigilationCentreStatusBox(props: Props) {
  const { invigilationCentre, showWarning } = props
  return (
    <ColouredStatusBox status={InvigilationCentreStatusMapping[invigilationCentre.status]}>
      <Box sx={{ mb: (theme) => theme.spacing(2) }}>
        <InvigilationCentreInfoCard
          name={invigilationCentre.name}
          address={invigilationCentre.address}
          email={invigilationCentre.email}
        />
      </Box>
      {invigilationCentre?.status === InvigilationCentreStatus.PENDING && showWarning && (
        <Alert
          severity="warning"
          sx={{
            mb: (theme) => theme.spacing(2),
            color: (theme) => theme.palette.warning.dark,
            backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.15),
          }}
        >
          <FormattedMessage
            id="pending-invigilation-centre-warning"
            defaultMessage="Waiting for approval from this invigilation centre. Once approved, you will only be able to change centres by contacting ISEB."
          />
        </Alert>
      )}
      <InvigilationCentreStatusChip invigilationCentre={invigilationCentre} />
    </ColouredStatusBox>
  )
}

import { TimerProvider, useTimer } from '@ctek/test-player'
import { Stack } from '@mui/material'
import { ExamLeftAlign } from '@project-minerva/assessment-common'
import {
  formatTime,
  ScreenFilter,
  Settings,
  TestInformationWidget,
  Timer,
  useFontSize,
  useScreenFilter,
  useTestFlowController,
  ContentLayout,
} from '@project-minerva/assessment-player'
import {
  ThemeType,
  PageLayout,
  makeStyles,
  LeftAlign,
  BackButton,
  RightAlign,
  CircularProgress,
  Typography,
  Button,
} from '@project-minerva/design-system'
import { HeaderBranding, PageHeader } from '@project-minerva/shared-ui'
import { TestData } from '@project-minerva/typings'
import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import ExaminationPlayer from '../components/examination.player'
import { PauseResumeProvider } from '../components/pause-resume.provider'
import PauseTestDialog from '../components/pause-test.dialog'
import { useExaminationData } from '../data/examination-data.provider'
import { useInvigilatorDialog } from '../hooks/use-invigilator-dialog'
import { useTrackSettings } from '../hooks/use-track-settings'
import { useTrackTimer } from '../hooks/use-track-timer'
import {
  ExamPages,
  useTrackFontSizeChanged,
  useTrackFontSizeIconClicked,
  useTrackFontSizePopoverClosed,
  useTrackInformationIconClicked,
  useTrackInformationPopoverClosed,
  useTrackProgressBarClicked,
  useTrackScreenFilterChanged,
  useTrackScreenFilterIconClicked,
  useTrackScreenFilterPopoverClosed,
  useTrackTimerClicked,
} from '../mixpanel'
import { LastContentViewedProvider, useLastContentViewed } from '../components/last-content-viewed.provider'
import { ProgressBar } from '../components/progress-bar'
import { TestProgressProvider, useTestProgress } from '../components/test-progress.provider'

const useStyles = makeStyles((theme: ThemeType) => ({
  RightAlign: {
    flexBasis: 'fit-content',
    flexGrow: 'unset',
  },
}))

export const TestPage = () => {
  const { testId } = useParams<{ testId: string }>()
  const styles = useStyles()
  const { assessment, findTestById, error, loading } = useExaminationData()
  const test = findTestById(testId)
  const history = useHistory()
  const { isStartTestScreen } = useTestFlowController()
  const goBack = useCallback(() => {
    history.push('/exam')
  }, [history])

  const closeWidgetRef = useRef(() => {
    return
  })

  const [openPauseTestModal, PauseTestModal] = useInvigilatorDialog(closeWidgetRef, PauseTestDialog)

  if (error) {
    return <ErrorFeedback callback={goBack}>{error.message}</ErrorFeedback>
  } else if (loading) {
    return (
      <PageLayout centred>
        <CircularProgress />
      </PageLayout>
    )
  } else if (!test || !assessment) {
    return (
      <ErrorFeedback callback={goBack}>
        <FormattedMessage id="test-unavailable" values={{ testId }} />
      </ErrorFeedback>
    )
  } else if (test.isClosed && isStartTestScreen) {
    history.push('/exam')
  }

  const timeLimitInMinutes = Math.round(test.timeLimitSeconds / 60)
  const testIntroContent = introContent(test.name, timeLimitInMinutes)

  const calculateAvailableDuration = (test: TestData) =>
    test.pauseStartTime && test.endTime
      ? new Date(test.endTime).getTime() - new Date(test.pauseStartTime).getTime()
      : test.timeLimitSeconds * 1000

  return (
    <LastContentViewedProvider>
      <TestProgressProvider initialProgress={test.percentageComplete ?? 0}>
        <TimerProvider duration={calculateAvailableDuration(test)}>
          <PauseResumeProvider>
            <PageLayout root centred>
              <PageHeader>
                <LeftAlign ml={-3}>
                  <HeaderBranding mr={-4} />
                  <Stack spacing={2} direction="row">
                    {isStartTestScreen && <BackButton click={goBack} />}
                    <ExamLeftAlign examName={test.name} />
                  </Stack>
                </LeftAlign>
                <RightAlign className={styles.RightAlign}>
                  <Stack spacing={2} direction="row">
                    <TestTimer test={test} serverTime={new Date(assessment?.serverTime)} />
                    <ProgressBarWrapper test={test} />
                    <SettingsWrapper test={test} />
                    <ScreenFilterWrapper test={test} />
                    <TestInformationWidgetWrapper
                      test={test}
                      introContent={testIntroContent}
                      openPauseTestModal={openPauseTestModal}
                      closeWidgetRef={closeWidgetRef}
                    />
                  </Stack>
                </RightAlign>
              </PageHeader>
              <ContentLayout>
                <PageLayout centred>
                  {test && (
                    <ExaminationPlayer
                      onClose={goBack}
                      testName={test.name}
                      hasStarted={!!test.startTime}
                      introContent={testIntroContent}
                    />
                  )}
                </PageLayout>
              </ContentLayout>
            </PageLayout>
            <PauseTestModal />
          </PauseResumeProvider>
        </TimerProvider>
      </TestProgressProvider>
    </LastContentViewedProvider>
  )
}

const TestInformationWidgetWrapper = ({
  test,
  introContent,
  closeWidgetRef,
  openPauseTestModal,
}: {
  test: TestData
  introContent: string
  openPauseTestModal: () => void
  closeWidgetRef: MutableRefObject<() => void>
}) => {
  const { isStartTestScreen } = useTestFlowController()
  const { lastContentViewedRef } = useLastContentViewed()
  const { progress: progressValue } = useTestProgress()
  const pageName = isStartTestScreen ? ExamPages.TEST_START_SCREEN : ExamPages.TEST_CONTENT_SCREEN
  const trackSettingsCallback = useTrackSettings()
  const trackTimerCallback = useTrackTimer(test.timeLimitSeconds * 1000)
  const trackInformationIconClicked = useTrackInformationIconClicked()
  const trackInformationIconClickedFromPage = useCallback(() => {
    trackInformationIconClicked({
      pageName,
      content: lastContentViewedRef.current,
      progressValue,
      ...trackTimerCallback?.(),
      ...trackSettingsCallback(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, progressValue, trackTimerCallback, trackSettingsCallback])
  const trackInformationPopoverClosed = useTrackInformationPopoverClosed()
  const trackInformationPopoverClosedFromPage = useCallback(
    (duration: number) => {
      trackInformationPopoverClosed({
        pageName,
        duration: formatTime(Math.floor(duration / 1000)),
        content: lastContentViewedRef.current,
        progressValue,
        ...trackTimerCallback?.(),
        ...trackSettingsCallback(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageName, progressValue, trackTimerCallback, trackSettingsCallback]
  )
  return (
    <TestInformationWidget
      closeWidgetRef={closeWidgetRef}
      introContent={introContent}
      trackIconClicked={trackInformationIconClickedFromPage}
      trackPopoverClosed={trackInformationPopoverClosedFromPage}
    >
      {!isStartTestScreen && <InvigilatorControls onPauseClick={openPauseTestModal} />}
    </TestInformationWidget>
  )
}

const SettingsWrapper = ({ test }: { test: TestData }) => {
  const { isStartTestScreen } = useTestFlowController()
  const { lastContentViewedRef } = useLastContentViewed()
  const { screenFilter } = useScreenFilter()
  const { progress: progressValue } = useTestProgress()
  const pageName = isStartTestScreen ? ExamPages.TEST_START_SCREEN : ExamPages.TEST_CONTENT_SCREEN
  const trackSettingsCallback = useTrackSettings()
  const trackTimerCallback = useTrackTimer(test.timeLimitSeconds * 1000)
  const trackFontSizeChanged = useTrackFontSizeChanged()
  const trackFontSizeChangedFromPage = useCallback(
    (previousSize: string | null, currentSize: string) => {
      trackFontSizeChanged({
        pageName,
        previousSize,
        currentSize,
        screenFilter,
        progressValue,
        content: lastContentViewedRef.current,
        ...trackTimerCallback?.(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageName, progressValue, screenFilter, trackFontSizeChanged, trackTimerCallback]
  )

  const trackFontSizeIconClicked = useTrackFontSizeIconClicked()
  const trackFontSizeIconClickedFromPage = useCallback(() => {
    trackFontSizeIconClicked({
      pageName,
      content: lastContentViewedRef.current,
      progressValue,
      ...trackTimerCallback?.(),
      ...trackSettingsCallback(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, progressValue, trackTimerCallback, trackSettingsCallback])

  const trackFontSizePopoverClosed = useTrackFontSizePopoverClosed()
  const trackFontSizePopoverClosedFromPage = useCallback(
    (duration: number) => {
      trackFontSizePopoverClosed({
        pageName,
        duration: formatTime(Math.floor(duration / 1000)),
        content: lastContentViewedRef.current,
        progressValue,
        ...trackTimerCallback?.(),
        ...trackSettingsCallback(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageName, progressValue, trackTimerCallback, trackSettingsCallback]
  )

  return (
    <Settings
      trackFontSizeChanged={trackFontSizeChangedFromPage}
      trackIconClicked={trackFontSizeIconClickedFromPage}
      trackPopoverClosed={trackFontSizePopoverClosedFromPage}
    />
  )
}

const ScreenFilterWrapper = ({ test }: { test: TestData }) => {
  const { isStartTestScreen } = useTestFlowController()
  const { lastContentViewedRef } = useLastContentViewed()
  const { progress: progressValue } = useTestProgress()
  const { fontSizeLabel: fontSize } = useFontSize()
  const pageName = isStartTestScreen ? ExamPages.TEST_START_SCREEN : ExamPages.TEST_CONTENT_SCREEN
  const trackSettingsCallback = useTrackSettings()
  const trackTimerCallback = useTrackTimer(test.timeLimitSeconds * 1000)
  const trackScreenFilterChanged = useTrackScreenFilterChanged()
  const trackScreenFilterFromPage = useCallback(
    (previousFilter: string | null, currentFilter: string | null) => {
      trackScreenFilterChanged({
        pageName,
        previousFilter,
        currentFilter,
        fontSize,
        progressValue,
        content: lastContentViewedRef.current,
        ...trackTimerCallback?.(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fontSize, pageName, progressValue, trackScreenFilterChanged, trackTimerCallback]
  )

  const trackScreenFilterIconClicked = useTrackScreenFilterIconClicked()
  const trackScreenFilterIconClickedFromPage = useCallback(() => {
    trackScreenFilterIconClicked({
      pageName,
      content: lastContentViewedRef.current,
      ...trackTimerCallback?.(),
      ...trackSettingsCallback(),
      progressValue,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName, progressValue, trackTimerCallback, trackSettingsCallback])

  const trackScreenFilterPopoverClosed = useTrackScreenFilterPopoverClosed()
  const trackScreenFilterPopoverClosedFromPage = useCallback(
    (duration: number) => {
      trackScreenFilterPopoverClosed({
        pageName,
        duration: formatTime(Math.floor(duration / 1000)),
        content: lastContentViewedRef.current,
        progressValue,
        ...trackTimerCallback?.(),
        ...trackSettingsCallback(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageName, progressValue, trackTimerCallback, trackSettingsCallback]
  )

  return (
    <ScreenFilter
      trackScreenFilterChanged={trackScreenFilterFromPage}
      trackIconClicked={trackScreenFilterIconClickedFromPage}
      trackPopoverClosed={trackScreenFilterPopoverClosedFromPage}
    />
  )
}

const TestTimer = ({ test, serverTime }: { test: TestData; serverTime: Date }) => {
  const { startTimer, remainingTime } = useTimer()
  const { isStartTestScreen } = useTestFlowController()
  const trackSettingsCallback = useTrackSettings()
  const { lastContentViewedRef } = useLastContentViewed()
  const { progress: progressValue } = useTestProgress()
  const trackTimerCallback = useTrackTimer(test.timeLimitSeconds * 1000)
  const trackTimerClicked = useTrackTimerClicked()
  const trackTimerClickedFromPage = useCallback(
    (opened: boolean) => {
      const pageName = isStartTestScreen ? ExamPages.TEST_START_SCREEN : ExamPages.TEST_CONTENT_SCREEN
      trackTimerClicked({
        pageName,
        opened,
        progressValue,
        content: lastContentViewedRef.current,
        ...trackTimerCallback?.(),
        ...trackSettingsCallback(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isStartTestScreen, progressValue, trackSettingsCallback, trackTimerCallback, trackTimerClicked]
  )

  useEffect(() => {
    if (!test.isClosed && !test.isPaused && test.startTime && test.endTime) {
      const duration = new Date(test.endTime).getTime() - serverTime.getTime()
      startTimer(duration)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [test])

  return <Timer timeLeftSeconds={Math.round(remainingTime / 1000)} track={trackTimerClickedFromPage} />
}

const ProgressBarWrapper = ({ test }: { test: TestData }) => {
  const { isStartTestScreen } = useTestFlowController()
  const trackSettingsCallback = useTrackSettings()
  const { lastContentViewedRef } = useLastContentViewed()
  const { progress: progressValue } = useTestProgress()
  const trackTimerCallback = useTrackTimer(test.timeLimitSeconds * 1000)
  const trackProgressBarClicked = useTrackProgressBarClicked()
  const trackProgressBarClickedFromPage = useCallback(
    (opened: boolean) => {
      const pageName = isStartTestScreen ? ExamPages.TEST_START_SCREEN : ExamPages.TEST_CONTENT_SCREEN
      trackProgressBarClicked({
        pageName,
        opened,
        progressValue,
        content: lastContentViewedRef.current,
        ...trackTimerCallback?.(),
        ...trackSettingsCallback?.(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isStartTestScreen, progressValue, trackSettingsCallback, trackProgressBarClicked, trackTimerCallback]
  )

  return <ProgressBar track={trackProgressBarClickedFromPage} />
}

const ErrorFeedback = ({ children, callback }: { children: React.ReactNode; callback: () => void }) => {
  return (
    <PageLayout>
      <Typography>{children}</Typography>
      <Button onClick={callback}>
        <FormattedMessage id="previous" />
      </Button>
    </PageLayout>
  )
}

const InvigilatorControls = ({ onPauseClick }: { onPauseClick: () => void }) => (
  <Button data-testid="pause-test-btn" onClick={onPauseClick}>
    <FormattedMessage id="pause-test" defaultMessage="Pause Test" />
  </Button>
)

const introContent = (name: string, duration: number) => `
  <h1>INSTRUCTIONS</h1>
  <h2>Using your timer and progress bar</h2>
  <ul>
    <li>To hide or show the timer, click on the timer button.</li>
    <li>To hide or show the progress bar, click on the progress bar button.</li>
  </ul>
  <img src="assets/img/instructions/header-options-opened.png" style="margin:0;"/>
  <img src="assets/img/instructions/header-options-closed.png" style="margin:0;"/>
  <p> </p>
  <hr style="border-color:rgba(130, 80, 232, 0.15);"/>
  <h2>Setting Up Your Page</h2>
  <p>Before you start the test you can use the buttons on the top right of the screen to choose:</p>
  <ul>
    <li>a coloured overlay (this will change the background colour and may help you read the questions better)</li>
  </ul>
  <img src="assets/img/instructions/overlay.png" style="margin:0;"/>
  <ul>
    <li>the font size</li>
  </ul>
  <img src="assets/img/instructions/font-size.png"  style="margin:0;"/>
  <p>We recommend you setup your page BEFORE the test starts.</p>
  <p>Changing these features during the test will reduce the amount of time you have to answer the questions.</p>
  <hr style="border-color:rgba(130, 80, 232, 0.15);"/>
  <h2>Navigating The Test</h2>
  <p>Read the instructions for each question carefully.</p>
  <p>Choose your answer by clicking on it. If you want to change your mind, click on a different answer.</p>
  <p>Once you are sure of your answer click ‘Submit Answer’. You will not be able to go back to change your answer.</p>
  <img src="assets/img/instructions/answer.png"  style="margin:0;"/>
  <p>You can use a pen/pencil and paper to make notes if you wish. Your working and notes will not be marked.</p>
`

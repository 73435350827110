import { Box, Typography } from '@project-minerva/design-system'
import { EalStatus } from '@project-minerva/typings'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaperPanel } from '../panel'
import ApplicantEalInfoAlert from './applicant-eal-info-alert'

interface Props {
  eal: EalStatus
  button?: ReactNode
  showAppealInfo?: boolean
}

export function ApplicationEalWidget(props: Props) {
  const { eal, button, showAppealInfo } = props
  return (
    <PaperPanel>
      <Typography variant="h5">
        <FormattedMessage id="eal-details" defaultMessage="EAL Details" />
      </Typography>
      {showAppealInfo && <ApplicantEalInfoAlert />}
      {eal.environmentalProvisionsDescriptions.length ? (
        <Box sx={{ m: (theme) => theme.spacing(2, 3, 2, 0) }}>
          <Typography variant="subtitle1" fontWeight={600} data-testid="eal-environmental-provisions-title">
            <FormattedMessage id="environmental-provisions" defaultMessage="Environmental Provisions" />
          </Typography>
          {eal.environmentalProvisionsDescriptions.map((desc) => (
            <Typography variant="subtitle1" data-testid="eal-environmental-provisions-text" key={desc}>
              {desc}
            </Typography>
          ))}
        </Box>
      ) : (
        <Box sx={{ m: (theme) => theme.spacing(2, 3, 2, 0) }}>
          <Typography color="text.secondary">
            <FormattedMessage id="no-info-provided" defaultMessage="No information provided." />
          </Typography>
        </Box>
      )}
      {button}
    </PaperPanel>
  )
}

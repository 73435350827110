import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@project-minerva/design-system'
import { REGEX_COGNITO_PASSWORD_POLICY, useCognitoUser } from '@project-minerva/auth-cognito'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import { CloseButton } from '../buttons/close-button'
import { useToast } from '../hooks'

interface ChangePasswordFormValues {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

interface ChangePasswordModalProps {
  closeModal: () => void
  open: boolean
}

const useChangePasswordValidationSchema = () => {
  const { formatMessage } = useIntl()
  return yup.object().shape({
    currentPassword: yup.string().required(formatMessage({ id: 'field-required' })),
    newPassword: yup
      .string()
      .required(formatMessage({ id: 'field-required' }))
      .matches(REGEX_COGNITO_PASSWORD_POLICY, formatMessage({ id: 'change-password-error-policy' }))
      .test(
        'matches current password',
        formatMessage({ id: 'change-password-error-match-current-password' }),
        function (newPassword) {
          return newPassword !== this.parent.currentPassword
        }
      ),
    confirmNewPassword: yup
      .string()
      .required(formatMessage({ id: 'field-required' }))
      .test(
        'matches new password',
        formatMessage({ id: 'change-password-error-match-new-confirmation' }),
        function (confirmNewPassword) {
          return confirmNewPassword === this.parent.newPassword
        }
      ),
  })
}

export const ChangePasswordModal = ({ closeModal, open }: ChangePasswordModalProps) => {
  const { formatMessage } = useIntl()
  const user = useCognitoUser()
  const [genericError, setGenericError] = useState('')
  const validationSchema = useChangePasswordValidationSchema()
  const [toast, openToast] = useToast(
    'success',
    <FormattedMessage id="change-password-success" defaultMessage={'Password successfully changed.'} />
  )
  const changePasswordForm = useFormik<ChangePasswordFormValues>({
    initialValues: { currentPassword: '', newPassword: '', confirmNewPassword: '' },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async (values) => {
      setGenericError('')
      await Auth.changePassword(user, values.currentPassword, values.newPassword)
        .then(() => {
          openToast()
          closeModalAndResetForm()
        })
        .catch((err) => {
          switch (err.code) {
            case 'NotAuthorizedException':
              changePasswordForm.setErrors({
                currentPassword: formatMessage({ id: 'change-password-error-incorrect' }),
              })
              break
            case 'InvalidPasswordException':
              changePasswordForm.setErrors({ newPassword: err.message })
              break
            case 'LimitExceededException':
            default:
              setGenericError(err.message)
          }
        })
        .finally(() => changePasswordForm.setSubmitting(false))
    },
  })
  const closeModalAndResetForm = () => {
    setGenericError('')
    changePasswordForm.resetForm()
    closeModal()
  }

  return (
    <>
      {toast}
      <Dialog open={open} data-testid="change-password-modal">
        <form
          onSubmit={(evt) => {
            evt.preventDefault()
            changePasswordForm.submitForm()
          }}
        >
          <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title">
            <Box sx={{ verticalAlign: 'middle' }}>
              <FormattedMessage id="change-password" defaultMessage="Change Password" />
            </Box>
            <CloseButton
              onClick={closeModalAndResetForm}
              data-testid="modal-close"
              disabled={changePasswordForm.isSubmitting}
            />
          </DialogTitle>
          <DialogContent data-testid="modal-content" sx={{ width: 400, maxWidth: 400 }}>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="current-password" defaultMessage="Current Password" />
              </Typography>
              <TextField
                autoFocus
                fullWidth
                autoComplete="current-password"
                type="password"
                data-testid="current-password-input"
                id="currentPassword"
                name="currentPassword"
                onChange={(evt) => {
                  changePasswordForm.setFieldError('currentPassword', undefined)
                  changePasswordForm.handleChange(evt)
                }}
                value={changePasswordForm.values.currentPassword}
                error={changePasswordForm.touched.currentPassword && !!changePasswordForm.errors.currentPassword}
                helperText={changePasswordForm.touched.currentPassword && changePasswordForm.errors.currentPassword}
              />
            </Box>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="new-password" defaultMessage="New Password" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="new-password"
                type="password"
                data-testid="new-password-input"
                id="newPassword"
                name="newPassword"
                onChange={(evt) => {
                  changePasswordForm.setFieldError('newPassword', undefined)
                  changePasswordForm.handleChange(evt)
                }}
                value={changePasswordForm.values.newPassword}
                error={changePasswordForm.touched.newPassword && !!changePasswordForm.errors.newPassword}
                helperText={changePasswordForm.touched.newPassword && changePasswordForm.errors.newPassword}
              />
            </Box>
            <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography mb={1}>
                <FormattedMessage id="confirm-new-password" defaultMessage="Confirm New Password" />
              </Typography>
              <TextField
                fullWidth
                autoComplete="confirm-new-password"
                type="password"
                data-testid="confirm-new-password-input"
                id="confirmNewPassword"
                name="confirmNewPassword"
                onChange={(evt) => {
                  changePasswordForm.setFieldError('confirmNewPassword', undefined)
                  changePasswordForm.handleChange(evt)
                }}
                value={changePasswordForm.values.confirmNewPassword}
                error={changePasswordForm.touched.confirmNewPassword && !!changePasswordForm.errors.confirmNewPassword}
                helperText={
                  changePasswordForm.touched.confirmNewPassword && changePasswordForm.errors.confirmNewPassword
                }
              />
            </Box>
            {genericError && (
              <Alert severity="error" data-testid="generic-error">
                {genericError}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              data-testid="modal-cancel-button"
              onClick={closeModalAndResetForm}
              disabled={changePasswordForm.isSubmitting}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button data-testid="modal-submit-button" type="submit" disabled={changePasswordForm.isSubmitting}>
              <FormattedMessage id="apply" defaultMessage="Apply" />
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

// --- Utils ---
import { getAuthFromLS, LocalStorageAuth } from '@project-minerva/base-utils'

export interface RequestOptions extends RequestInit {
  bypassAuth?: boolean
  headers?: Record<string, string>
  returnRaw?: boolean
}

const defaultHeaders: Record<string, string> = {
  'Content-Type': 'application/json',
}

const defaultOptions: Partial<RequestOptions> = {
  method: 'GET',
  mode: 'cors',
}

// eslint-disable-next-line
export async function request<T>(url: string, requestOptions: RequestOptions, accessToken?: string): Promise<T> {
  let headers = requestOptions.headers ? requestOptions.headers : defaultHeaders
  if (!requestOptions.bypassAuth) {
    const auth = getAuthFromLS(true) as LocalStorageAuth
    headers = {
      ...headers,
      authorization: accessToken ? accessToken : `Bearer ${auth.accessToken}`,
    }
  }

  const init: RequestInit = {
    ...defaultOptions,
    ...requestOptions,
    headers,
  }

  return await fetch(url, init)
    .then(async (response) => {
      // used for debug page when mode: no-cors is used
      if (response.type === 'opaque') return '' as unknown as T

      const parsedResponse: T =
        (requestOptions.returnRaw || !response.headers.get('content-type')?.includes('application/json')) && response.ok
          ? response
          : await response.json()
      if (!response.ok) {
        if ((parsedResponse as unknown as Error)?.message) {
          // eslint-disable-next-line no-throw-literal
          throw parsedResponse as unknown as Error
        } else {
          throw new Error('There has been an issue with this request. Please try again.')
        }
      }
      return parsedResponse as T
    })
    .catch((error) => {
      throw error
    })
}

import { Gender } from '@project-minerva/typings'
import { FormattedMessage } from 'react-intl'

interface GenderProps {
  gender?: Gender
}

export const GenderOptions = [
  {
    value: 'male',
    translationKey: 'gender-male',
  },
  {
    value: 'female',
    translationKey: 'gender-female',
  },
  {
    value: 'other',
    translationKey: 'gender-other',
  },
  {
    value: 'prefer_not_to_say',
    translationKey: 'gender-prefer_not_to_say',
  },
  {
    value: 'unknown',
    translationKey: 'gender-unknown',
  },
]

export function EvaGender({ gender }: GenderProps): JSX.Element {
  return (
    <FormattedMessage
      id={GenderOptions.find((item) => item.value === gender?.toLowerCase())?.translationKey || 'gender-unknown'}
    />
  )
}

export const DeclarationsIcon = (props: { color: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 30 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9229 2.54122C17.2211 0.891007 15.8713 0 14 0L13.7688 0.00463116C12.0174 0.0755687 10.7497 0.959766 10.0771 2.54122L10.023 2.675L8.5 2.67507L8.35074 2.68056C7.31588 2.75691 6.5 3.62071 6.5 4.67507V5.687L1.89655 5.6875L1.75165 5.69314C0.749052 5.7716 0 6.65817 0 7.69238V35.8292L0.00504208 35.9758C0.0752568 36.9945 0.870928 37.8341 1.89655 37.8341H18.5733C18.2971 37.2091 18.1207 36.5424 18.0441 35.834H1.999V7.687H6.5V8.82708C6.5 9.629 7.18075 10.2091 7.94828 10.2091H20.0517L20.1861 10.2031C20.897 10.14 21.5 9.58182 21.5 8.82708V7.687H26L26.0007 28.064C26.7098 28.1572 27.3762 28.3522 28 28.649V7.5L27.994 7.35135C27.9184 6.41986 27.1385 5.6875 26.1875 5.6875L21.5 5.687V4.67507L21.4945 4.52581C21.4182 3.49095 20.5544 2.67507 19.5 2.67507L17.976 2.675L17.9229 2.54122ZM11.7192 3.92116C12.0621 2.57062 12.7554 2 14 2C15.2446 2 15.9379 2.57062 16.2808 3.92116L16.4722 4.67507H19.5V8.209H8.5V4.67507H11.5278L11.7192 3.92116Z"
      fill={props.color}
    />
    <path d="M22.8534 23.6153V21.6153H5.14655V23.6153H22.8534Z" fill={props.color} />
    <path d="M22.8534 19.5528V17.5528H5.14655V19.5528H22.8534Z" fill={props.color} />
    <path d="M22.8534 15.4903V13.4903H5.14655V15.4903H22.8534Z" fill={props.color} />
    <path
      d="M25 40C23.6167 40 22.4377 39.5127 21.463 38.538C20.4877 37.5627 20 36.3833 20 35C20 33.6167 20.4877 32.4373 21.463 31.462C22.4377 30.4873 23.6167 30 25 30C26.3833 30 27.5627 30.4873 28.538 31.462C29.5127 32.4373 30 33.6167 30 35C30 36.3833 29.5127 37.5627 28.538 38.538C27.5627 39.5127 26.3833 40 25 40Z"
      fill={props.color}
    />
  </svg>
)

export const DeclarationsIconSmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
    <path
      d="M2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4C0 3.45 0.195667 2.979 0.587 2.587C0.979 2.19567 1.45 2 2 2H6.2C6.41667 1.4 6.77933 0.916667 7.288 0.55C7.796 0.183333 8.36667 0 9 0C9.63333 0 10.2043 0.183333 10.713 0.55C11.221 0.916667 11.5833 1.4 11.8 2H16C16.55 2 17.021 2.19567 17.413 2.587C17.8043 2.979 18 3.45 18 4V10.7C17.6833 10.55 17.3583 10.4207 17.025 10.312C16.6917 10.204 16.35 10.125 16 10.075V4H2V18H8.05C8.1 18.3667 8.17933 18.7167 8.288 19.05C8.396 19.3833 8.525 19.7 8.675 20H2ZM2 18V4V10.075V10V18ZM5 16H8.075C8.125 15.65 8.204 15.3083 8.312 14.975C8.42067 14.6417 8.54167 14.3167 8.675 14H5C4.71667 14 4.479 14.0957 4.287 14.287C4.09567 14.479 4 14.7167 4 15C4 15.2833 4.09567 15.5207 4.287 15.712C4.479 15.904 4.71667 16 5 16ZM5 12H10.1C10.6333 11.5 11.229 11.0833 11.887 10.75C12.5457 10.4167 13.25 10.1917 14 10.075V10H5C4.71667 10 4.479 10.0957 4.287 10.287C4.09567 10.479 4 10.7167 4 11C4 11.2833 4.09567 11.5207 4.287 11.712C4.479 11.904 4.71667 12 5 12ZM5 8H13C13.2833 8 13.5207 7.904 13.712 7.712C13.904 7.52067 14 7.28333 14 7C14 6.71667 13.904 6.479 13.712 6.287C13.5207 6.09567 13.2833 6 13 6H5C4.71667 6 4.479 6.09567 4.287 6.287C4.09567 6.479 4 6.71667 4 7C4 7.28333 4.09567 7.52067 4.287 7.712C4.479 7.904 4.71667 8 5 8ZM9 3.25C9.21667 3.25 9.396 3.179 9.538 3.037C9.67933 2.89567 9.75 2.71667 9.75 2.5C9.75 2.28333 9.67933 2.104 9.538 1.962C9.396 1.82067 9.21667 1.75 9 1.75C8.78333 1.75 8.60433 1.82067 8.463 1.962C8.321 2.104 8.25 2.28333 8.25 2.5C8.25 2.71667 8.321 2.89567 8.463 3.037C8.60433 3.179 8.78333 3.25 9 3.25ZM15 22C13.6167 22 12.4377 21.5127 11.463 20.538C10.4877 19.5627 10 18.3833 10 17C10 15.6167 10.4877 14.4373 11.463 13.462C12.4377 12.4873 13.6167 12 15 12C16.3833 12 17.5627 12.4873 18.538 13.462C19.5127 14.4373 20 15.6167 20 17C20 18.3833 19.5127 19.5627 18.538 20.538C17.5627 21.5127 16.3833 22 15 22Z"
      fill="#169757"
    />
  </svg>
)

import { useTimerRef } from '@ctek/test-player'
import { formatTime, useTestFlowController } from '@project-minerva/assessment-player'
import { useCallback } from 'react'
import { TrackTimerSnapshot } from '../mixpanel'

export const useTrackTimer = (durationMs: number) => {
  const { isStartTestScreen } = useTestFlowController()
  const timeLeftRef = useTimerRef()
  const callback = useCallback((): TrackTimerSnapshot | undefined => {
    const elapsed = durationMs - timeLeftRef.current
    return !isStartTestScreen
      ? {
          testTime: formatTime(Math.floor(elapsed / 1000)),
          totalTime: formatTime(durationMs / 1000),
          timerProgress: Math.floor((elapsed / durationMs) * 100),
        }
      : undefined
  }, [durationMs, isStartTestScreen, timeLeftRef])

  return callback
}

import { Anchor } from '@project-minerva/core'
import { Box, Popover, TertiaryButton, Tooltip } from '@project-minerva/design-system'
import { DataTestId } from '@project-minerva/typings'
import { ReactNode } from 'react'

interface Props extends Anchor<HTMLButtonElement>, DataTestId {
  children: ReactNode | ReactNode[]
  buttonIcon: ReactNode
  tooltip: Exclude<ReactNode, null | undefined>
}

export const HeaderWidget = (props: Props) => {
  const { anchorElement, setAnchor, tooltip, unsetAnchor } = props
  const open = Boolean(anchorElement)

  return (
    <>
      <Tooltip title={tooltip} placement="bottom">
        <Box>
          <TertiaryButton
            sx={{
              width: '2.2rem',
              minWidth: '2.2rem',
              ...(open && {
                backgroundColor: 'primary.main',
                color: 'white',
              }),
            }}
            onClick={setAnchor}
            disableRipple
            disableElevation
            data-testid={props['data-testid']}
          >
            {props.buttonIcon}
          </TertiaryButton>
        </Box>
      </Tooltip>
      <Popover
        id={'screen-filter-color-popover'}
        open={open}
        anchorEl={anchorElement}
        onClose={unsetAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ mt: (theme) => theme.spacing(1.5) }}
        marginThreshold={3}
      >
        {props.children}
      </Popover>
    </>
  )
}

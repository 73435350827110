import { TextFieldProps, TextField } from '@mui/material'

const MAX_CHARS = 1000

export default function LongLimitInput(props: TextFieldProps) {
  return (
    <TextField
      autoComplete="off"
      inputProps={{ ...props.inputProps, maxLength: MAX_CHARS }}
      multiline
      minRows={3}
      maxRows={5}
      size="small"
      {...props}
    />
  )
}

import { ButtonProps, styled } from '@mui/material'
import { AuxButton } from './tertiary-button'

export const PromotionButton = styled(AuxButton)<ButtonProps & { thin?: boolean }>(({ theme, thin }) => ({
  borderRadius: '6px',
  background: 'rgba(255,255,255, 0.70)',
  '&:hover': {
    background: 'rgba(240,240,240, 0.70)',
  },
  color: theme.palette.primary.main,
  // TODO: change this when thin pixel values are added to theme
  height: thin ? '1.5rem' : undefined,
  fontSize: '1rem',
}))

import { SimpleDialog } from '@project-minerva/assessment-player'
import { useBasicAuth, DobScreen } from '@project-minerva/auth-student'
import { format } from 'date-fns'
import { useRef, useState } from 'react'

interface ConfirmDobDialogProps {
  open: boolean
  onClose: () => void
  onValidate: () => void
}

const ConfirmDobDialog = ({ open, onValidate, onClose }: ConfirmDobDialogProps) => {
  const confirmDobDialogRef = useRef()
  const [dob, setDob] = useState<Date | null>(null)
  const [dobError, setDobError] = useState<string | null>(null)
  const { authData } = useBasicAuth()

  const confirmDob = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const formattedDob = format(dob!, 'yyyy-MM-dd')
      if (authData?.dob === formattedDob) {
        onValidate()
        setDob(null)
        closeDobDialog()
      } else {
        setDobError('incorrect-date-of-birth')
      }
    } catch (error) {
      setDobError((error as Error).message)
    }
  }
  const closeDobDialog = () => {
    setDobError(null)
    onClose()
  }

  const setDobClearError = (dob: Date | null) => {
    setDob(dob)
    setDobError(null)
  }

  return (
    <SimpleDialog
      open={open}
      // callback={closeDobDialog}
      content={<DobScreen dob={dob} setDob={setDobClearError} onSubmit={confirmDob} error={dobError} loading={false} />}
      data-testid="confirm-dob-dialog"
      ref={confirmDobDialogRef}
    />
  )
}

export default ConfirmDobDialog

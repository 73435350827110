import { FormWrapper } from '@project-minerva/core'
import { TextField, Typography } from '@project-minerva/design-system'
import InputMask from 'react-input-mask'
import { FormattedMessage, useIntl } from 'react-intl'
import { LoginErrorAndButton } from './student-login-common-components'

interface CodeScreenProps {
  code: string
  error: string | null
  loading: boolean
  onSubmit: () => void
  setCode: (code: string) => void
}

export const CodeScreen = (props: CodeScreenProps) => {
  const { onSubmit, setCode, code, error, loading } = props
  const { formatMessage } = useIntl()

  return (
    <>
      <Typography variant="h5" fontWeight={600} mb={2}>
        <FormattedMessage id="enter-applicant-test-login-code" defaultMessage="Enter applicant's Test Login Code" />
      </Typography>
      <FormWrapper onSubmit={onSubmit}>
        <InputMask
          mask="aaaa-aaaa-aaaa-aaaa"
          value={code}
          onChange={(evt) => setCode(evt.target.value.trim().toUpperCase())}
        >
          <TextField
            data-testid="access-code-text-field"
            sx={{ pb: (theme) => theme.spacing(3), fontVariantNumeric: 'tabular-nums' }}
            variant="outlined"
            label={formatMessage({ id: 'student-code' })}
          />
        </InputMask>
        <LoginErrorAndButton loading={loading || code.trim() === ''} error={error ? 'invalid-login-code' : null} />
      </FormWrapper>
    </>
  )
}

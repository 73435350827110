import { getItemFromLS, setItemInLS } from '@project-minerva/base-utils'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useScreenFilter } from '../screen-filter'
import { FontSizeLabel, useFontSize } from './font-size'

const LS_KEY_EXAM_FONT_SIZE = 'exam-font-size'
const LS_KEY_EXAM_SCREEN_FILTER = 'exam-screen-filter'

interface LocalSettingsDataContext {
  setUid: (id: string) => void
  clearSettings: () => void
}

const LocalSettingsContext = createContext<LocalSettingsDataContext>({
  clearSettings: () => {
    return
  },
  setUid: (id: string) => {
    return
  },
})

export const useLocalSettings = () => {
  const context = useContext(LocalSettingsContext)
  if (!context) throw new Error('useLocalSettings must be called inside a LocalSettingsProvider')
  return context
}

export function LocalSettingsProvider(props: { children: ReactNode }) {
  const [uid, setUid] = useState<string>()
  const { fontSizeLabel, setFontSizeLabel, resetFontSize } = useFontSize()
  const { screenFilter, setScreenFilter } = useScreenFilter()

  const clearSettings = () => {
    setUid(undefined)
    resetFontSize()
    setScreenFilter(null)
  }

  useEffect(() => {
    if (uid) {
      try {
        const storedSizeLabel = getItemFromLS(`${LS_KEY_EXAM_FONT_SIZE}-${uid}`) as FontSizeLabel
        setFontSizeLabel(storedSizeLabel)

        const storedScreenFilter = getItemFromLS(`${LS_KEY_EXAM_SCREEN_FILTER}-${uid}`) as string
        setScreenFilter(storedScreenFilter === 'null' ? null : storedScreenFilter)
      } catch {
        //
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid])

  useEffect(() => {
    if (uid) {
      setItemInLS(fontSizeLabel, `${LS_KEY_EXAM_FONT_SIZE}-${uid}`)
    }
  }, [uid, fontSizeLabel])

  useEffect(() => {
    if (uid) {
      setItemInLS(screenFilter, `${LS_KEY_EXAM_SCREEN_FILTER}-${uid}`)
    }
  }, [uid, screenFilter])

  return (
    <LocalSettingsContext.Provider value={{ clearSettings, setUid }}>{props.children}</LocalSettingsContext.Provider>
  )
}

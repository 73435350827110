import { AssessmentPlayerLayout } from '../assessment-player.layout'
import { ControlBarContent } from './control-bar'
import Document from './document'
import { ScreenFilterOverlay } from './header-widgets'
import { TestScreenProps } from './test-screen'

export const DocumentRenderer = (props: TestScreenProps) => {
  return (
    <>
      <AssessmentPlayerLayout.ContentContainer data-testid="assessment-slide">
        <ScreenFilterOverlay />
        <Document data-testid="document-screen" />
      </AssessmentPlayerLayout.ContentContainer>
      <ControlBarContent {...props} />
    </>
  )
}

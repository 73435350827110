import { Box, Typography } from '@project-minerva/design-system'
import { FormattedMessage } from 'react-intl'
import { InvigilationCentre } from '@project-minerva/typings'
import { PaperPanel } from '../panel'
import { ReactNode } from 'react'
import { ApplicationInvigilationCentreStatusBox } from './application-invigilation-centre-status-box'

interface Props {
  invigilationCentre: InvigilationCentre | null
  showWarning?: boolean
  children?: ReactNode
}

export function ApplicationInvigilationCentreReview(props: Props) {
  const { invigilationCentre, children, showWarning } = props

  return (
    <PaperPanel data-testid="invigilation-centre-review">
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        <Box sx={{ display: 'flex', mb: (theme) => theme.spacing(2) }}>
          <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
            <FormattedMessage id="invigilation-centre" defaultMessage="Invigilation Centre" />
          </Typography>
        </Box>
        {invigilationCentre ? (
          <ApplicationInvigilationCentreStatusBox invigilationCentre={invigilationCentre} showWarning={showWarning} />
        ) : (
          <Typography variant="body2" sx={{ color: 'grey' }} data-testid="invigilation-centre-answer-later">
            <FormattedMessage id="selected-to-answer-later" defaultMessage="Selected to answer later" />
          </Typography>
        )}
      </Box>
      {children}
    </PaperPanel>
  )
}

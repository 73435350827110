import { useEffect, useState } from 'react'

export function useDebounce<T>(value: T, delay: number, cond?: (value: T) => boolean) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (cond && !cond(value)) return

      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay, cond])

  return debouncedValue
}

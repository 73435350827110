import { alpha, Box, BoxProps, styled } from '@project-minerva/design-system'

export const IconBackgroundCircle = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: 18,
  height: 30,
  width: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px',
  backgroundColor: alpha(theme.palette.success.main, 0.1),
}))

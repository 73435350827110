import { getApiUrl } from '@project-minerva/api'
import { rest } from 'msw'

export const guardianSignUp = rest.post(getApiUrl('helios', '/v1/guardians/signup'), (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      guardian: {
        guardianId: '1faa0922-f11e-413a-a251-114e4635f597',
        firstName: 'Pedro',
        lastName: 'Taranto',
        email: 'pedro.taranto+test001@century.tech',
        phone: '+44 757578575',
        externalId: '3a1bf334-1a89-48e4-af36-89573e16d4b1',
      },
    })
  )
})

export const applicantsList = rest.get(getApiUrl('helios', '/v1/guardians/applicants'), (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      count: 2,
      applicants: [
        {
          applicantId: '1d1ef21e-6db2-4567-a915-7293a645ca91',
          guardianId: '9b24b1fa-b058-464d-a5b1-1849c3cd362f',
          applicantCode: 'AB1DEF',
          firstName: 'Emily',
          middleName: 'Rose',
          lastName: 'Taylor',
          dateOfBirth: '2010-02-01',
          gender: 'FEMALE',
          status: 'COMPLETE',
        },
        {
          applicantId: '00100cd3-ded0-47f5-b58f-8fb44f131a02',
          guardianId: '9b24b1fa-b058-464d-a5b1-1849c3cd362f',
          applicantCode: 'CD2DEF',
          firstName: 'Jacob',
          middleName: '',
          lastName: 'Taylor',
          dateOfBirth: '2011-03-02',
          gender: 'MALE',
          status: 'INCOMPLETE',
        },
      ],
    })
  )
})

import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LeftAlign,
  PrimaryTypography,
  RightAlign,
  styled,
  Typography,
  TypographyProps,
} from '@project-minerva/design-system'
import { CloseButton } from '../buttons/close-button'
import { PrimaryTypographyTitleValue } from '../text'
import { AdminSchoolsApplicant, Applicant, GuardianApplicant, TestDeclaration } from '@project-minerva/typings'
import { format } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

interface Props {
  applicant: Applicant | GuardianApplicant | AdminSchoolsApplicant
  modalOpen: boolean
  setModalOpen: (val: boolean) => void
  declaration: TestDeclaration
  testName: string
  includeViewProfileButton?: boolean
  profilePath?: string
}

export function ViewDeclarationModal(props: Props) {
  const { applicant, modalOpen, setModalOpen, declaration, testName, includeViewProfileButton, profilePath } = props
  const history = useHistory()
  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} data-testid="deadline-modal">
      <DialogTitle sx={{ display: 'flex' }} data-testid="modal-title" variant="h5" fontWeight={600}>
        <PrimaryTypography variant="h5" fontWeight={600}>
          {`${applicant.firstName} ${applicant.lastName}`}
        </PrimaryTypography>
        <CloseButton onClick={() => setModalOpen(false)} data-testid="modal-close-button" />
      </DialogTitle>
      <DialogContent data-testid="modal-content">
        <Box>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="test" />}
            value={<FormattedMessage id={`subject-${testName}`} />}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', mt: 2 }}>
          <Box sx={{ mr: 4 }}>
            <PrimaryTypographyTitleValue
              title={<FormattedMessage id="invigilator-name" />}
              value={declaration.invigilatorFullName}
            />
          </Box>
          <Box sx={{ mr: 4 }}>
            <PrimaryTypographyTitleValue
              title={<FormattedMessage id="completed-by" />}
              value={declaration.submitterFullName}
            />
          </Box>
          <Box sx={{ mr: 4 }}>
            <PrimaryTypographyTitleValue
              title={<FormattedMessage id="date" />}
              value={format(new Date(declaration.createdAt), 'dd/MM/yyyy')}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          <PrimaryTypographyTitleValue
            title={<FormattedMessage id="view-declaration-were-there-any-instances" values={{ br: <br /> }} />}
            value={<FormattedMessage id="no" />}
          />
        </Box>
        {(declaration.additionalDetails.length > 0 || declaration.comment) && (
          <Box sx={{ mt: 2 }}>
            <PrimaryTypography variant="subtitle1" fontWeight={600} data-testid="additional-details-title">
              <FormattedMessage id="additional-details" />
            </PrimaryTypography>
            {declaration.comment && (
              <>
                {declaration.additionalDetails.length > 0 && (
                  <AdditionalDetailsInfo name={declaration.submitterFullName} date={declaration.createdAt} />
                )}
                <AdditionalDetailBox variant={'subtitle2'}>{declaration.comment}</AdditionalDetailBox>
              </>
            )}
            {declaration.additionalDetails.map((detail, i) => (
              <>
                <AdditionalDetailsInfo name={detail.submitterFullName} date={detail.createdAt} />
                <AdditionalDetailBox variant={'subtitle2'}>{detail.comment}</AdditionalDetailBox>
              </>
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <>
          {includeViewProfileButton && profilePath && (
            <Button color="secondary" data-testid="modal-view-profile-button" onClick={() => history.push(profilePath)}>
              <FormattedMessage id="open-applicant-profile" defaultMessage="Open applicant profile" />
            </Button>
          )}
          <Button data-testid="modal-action-close" onClick={() => setModalOpen(false)}>
            <FormattedMessage id="close" defaultMessage="Close" />
          </Button>
        </>
      </DialogActions>
    </Dialog>
  )
}

export const AdditionalDetailBox = styled(Typography)<TypographyProps>(({ theme }) => ({
  background: alpha(theme.palette.secondary.main, 0.3),
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  borderRadius: '4px',
}))

export const AdditionalDetailsInfo = ({ name, date }: { name: string; date: string }) => {
  return (
    <Box display="flex">
      <LeftAlign>
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }} variant="subtitle1">
          {name}
        </Typography>
      </LeftAlign>
      <RightAlign>
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }} variant="subtitle1">
          {format(new Date(date), 'dd/MM/yyyy')}
        </Typography>
      </RightAlign>
    </Box>
  )
}
